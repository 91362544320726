// import React, { Component, Fragment } from 'react';

// import FroalaEditor from 'react-froala-wysiwyg';

// import 'froala-editor/css/froala_style.min.css';
// import 'froala-editor/js/froala_editor.pkgd.min';
// import 'froala-editor/css/froala_editor.pkgd.min.css';
// import 'froala-editor/js/plugins.pkgd.min';
// import 'froala-editor/css/plugins.pkgd.min.css';
// import 'froala-editor/js/plugins/image.min';
// import 'froala-editor/css/plugins/image.css';
// import 'froala-editor/js/languages/ko';
// import 'font-awesome/css/font-awesome.css';

// class EditorComp extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {};
//     this.config = {
//       placeholderText: 'Edit Your Content Here!',
//       language:'ko'
//     };
//   }

//   render() {
//   return (
//     <Fragment>
//       <FroalaEditor id="editor" tag="textarea" config={this.config} />
//     </Fragment>
//     );
//   }
// }

// export default EditorComp;

// Note that Froala Editor has to be required separately

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

import 'froala-editor/js/plugins.pkgd.min';
import 'froala-editor/js/languages/ko';
import 'froala-editor/js/froala_editor.pkgd.min';

import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_editor.min.css';
import 'froala-editor/css/froala_style.min.css';

// import './EditorComp.scss';

// Render Froala Editor component.
class EditorComp extends Component {
  constructor() {
    super();

    this.state = {
      content: ''
    };

    this.config = {
      language: 'ko',
      // imageUploadToS3: {
      //   bucket: 'from.kr.editor',
      //   // Your bucket region.
      //   region: 'ap-northeast-2',
      //   keyStart: 'admin/',
      //   params: {
      //     acl: 'public-read', // ACL according to Amazon Documentation.
      //     AWSAccessKeyId: 'AKIAI7QAAIZRMWKN2XNQ', // Access Key from Amazon.
      //     policy: '', // Policy string computed in the backend.
      //     signature: '' // Signature computed in the backend.

      //     // If you are using Amazon Signature V4, the followings should be used instead.
      //     // "X-Amz-Credential": "...",
      //     // "X-Amz-Algorithm": "AWS4-HMAC-SHA256",
      //     // "X-Amz-Date": "...",
      //     // Policy: "...", //
      //     // "X-Amz-Signature": "", // computed in backend
      //   }
      // }
      // requestWithCORS: false,
      // imageUploadMethod: 'PUT',
      imageUploadURL:
        'https://akuotb3hfi.execute-api.ap-northeast-2.amazonaws.com/dev/s3/upload',
      imageDefaultWidth: 420,
      width: 430
    };
  }

  componentDidMount() {
    this.initialize();
  }

  handleModelChange = model => {
    this.setState({
      content: model
    });

    const { content } = this.state;
    console.log(content);
  };

  handleClickSaveEditor = () => {
    this.froalaHtmlData = this.froalaRef.editor.html.get();
    // eslint-disable-next-line
    console.log(`### start ###`);
    // eslint-disable-next-line
    console.log(JSON.stringify(this.froalaHtmlData, null, 2));
    // eslint-disable-next-line
    console.log(`### end ###`);

    const { onClickSaveButton } = this.props;
    onClickSaveButton(this.froalaHtmlData);
  };

  initialize = () => {
    const { initHtmlData } = this.props;

    this.setState({
      content: initHtmlData
    });
  };

  render() {
    const { content } = this.state;
    // const { initHtmlData } = this.props;
    return (
      <div className="edtior-sample" id="editor">
        <FroalaEditor
          ref={froalaRef => {
            this.froalaRef = froalaRef;
          }}
          model={content}
          onModelChange={this.handleModelChange}
          config={this.config}
        />
        <FroalaEditorView model={content} />
        <button
          className="float-right mt-3 mb-3 btn btn-primary"
          type="button"
          onClick={this.handleClickSaveEditor}
        >
          저장
        </button>
      </div>
    );
  }
}

EditorComp.propTypes = {
  onClickSaveButton: PropTypes.func,
  initHtmlData: PropTypes.string
};

EditorComp.defaultProps = {
  onClickSaveButton: () => {},
  initHtmlData: ''
};

export default EditorComp;
