// eslint-disable-next-line
export function getPriceFormat(price) {
  if (!price) {
    if (price === 0) {
      return '0';
    }

    return '';
  }

  return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

export function autoHypenPhone(_str) {
  if (!_str) {
    return '';
  }
  const str = _str.replace(/[^0-9]/g, '');
  let tmp = '';
  let result = str;
  if (str.length < 4) {
    result = str;
  } else if (str.length < 7) {
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3);
    result = tmp;
  } else if (str.length < 11) {
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3, 3);
    tmp += '-';
    tmp += str.substr(6);
    result = tmp;
  } else {
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3, 4);
    tmp += '-';
    tmp += str.substr(7);
    result = tmp;
  }

  return result;
}