import React from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';

import RenderField from 'components/reduxForm/RenderField';
import RenderFieldTextArea from 'components/reduxForm/RenderFieldTextArea';
import RenderFieldRadio from 'components/reduxForm/RenderFieldRadio';
import { mendatoryValidation } from 'lib/form/validation';

import './StopSellingProductForm.scss';

function StopSellingProductForm({
  handleSubmit,
  isSubmitLoading,
  onSubmit,
  flagStopSelling
}) {
  return (
    <div className="stop-selling-product-form">
      <h1 className="font-weight-bold mt-4 mb-4">
        판매중단
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group d-flex align-items-center flex-wrap mt-3">
          <span className="d-inline-block text-left pr-2 ">
            현재 판매 중 / 종료 상태 변경<br />안내 팝업 설정
          </span>
          <div>
            <Field
              name="flagStopSelling"
              id="nostopSelling"
              component={RenderFieldRadio}
              value="0"
              type="radio"
              label="판매중"
            />
            <Field
              name="flagStopSelling"
              id="stopSelling2"
              component={RenderFieldRadio}
              value="1"
              type="radio"
              label="판매중 & 안내 팝업(구매가능)"
            />
            <Field
              name="flagStopSelling"
              id="stopSelling1"
              component={RenderFieldRadio}
              value="11"
              type="radio"
              label="판매종료 & 안내 팝업"
            />
            <Field
              name="flagStopSelling"
              id="stopSelling3"
              component={RenderFieldRadio}
              value="12"
              type="radio"
              label="판매종료 & 안내 팝업 & Redirect URL"
            />
          </div>
        </div>
        <Field
          name="titleStopSelling"
          label="안내 팝업 제목"
          type="text"
          component={RenderField}
          placeholder="안내 팝업 제목"
          readOnly={flagStopSelling.toString() === '0'}
          validate={flagStopSelling.toString() === '0' ? [] : [mendatoryValidation]}
        />
        <Field
          name="descStopSelling"
          label="안내 팝업 본문"
          type="text"
          component={RenderFieldTextArea}
          readOnly={flagStopSelling.toString() === '0'}
          validate={flagStopSelling.toString() === '0' ? [] : [mendatoryValidation]}
        />
        <Field
          name="descStopAction.buttonUrl"
          label="RedirectURL"
          type="text"
          component={RenderField}
          placeholder="상대경로( ex) '/product/p123123' )"
          readOnly={flagStopSelling.toString() !== '12'}
          validate={flagStopSelling.toString() !== '12' ? [] : [mendatoryValidation]}

        />
        <div className="product-regist-buttons">
          <button
            type="submit"
            className={`btn btn-primary ${isSubmitLoading ? 'disabled' : ''}`}
            isLoading={isSubmitLoading}
            disabled={isSubmitLoading}
          >
            수정
          </button>
        </div>
      </form>
    </div>
  );
}

StopSellingProductForm.propTypes = {
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitLoading: PropTypes.bool,
  flagStopSelling: PropTypes.string
};

StopSellingProductForm.defaultProps = {
  onSubmit: () => { },
  handleSubmit: () => { },
  isSubmitLoading: false,
  flagStopSelling: '0'
};

export default reduxForm({
  form: 'stopSellingProductForm',
  initialValues: {
    flagStopSelling: '0' // string 형식으로 값을 초기화 해줘야 radio에서 초기값 설정 및 값 선택이 됨...
  }
})(StopSellingProductForm);
