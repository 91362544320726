import React, { Component, Fragment } from 'react';
import './OrderForm.scss'

class OrderForm extends Component {

  constructor() {
    super();

    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <Fragment>
        <div>
          <h4 className="h4">주문관리</h4>
          <hr />
        </div>
      </Fragment>
    );
  }
}

export default OrderForm;
