// eslint-disable-next-line
export function getRemovedEmptyString(text) {
  if (!text) {
    return '';
  }

  if (!text.trim()) {
    return '';
  }

  return text;
}
