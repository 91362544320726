import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, ModalBody } from 'reactstrap';

import './ModalDialog.scss';

function ModalDialog({ className, isOpen, size, onToggle, children }) {
  return (
    <Modal
      className={classNames('os-modal', className)}
      size={size}
      isOpen={isOpen}
      toggle={onToggle}
    >
      <ModalBody>
        <button type="button" onClick={onToggle} className="modal-close-button">
          닫기
        </button>
        <div className="pt-3 pb-3">{children}</div>
      </ModalBody>
    </Modal>
  );
}

ModalDialog.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  size: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

ModalDialog.defaultProps = {
  isOpen: false,
  className: '',
  size: 'sm',
  onToggle: () => {},
  children: null || []
};

export default ModalDialog;
