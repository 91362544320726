import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Tab.scss';

function Tab({ children, className, isSelected, width }) {
  return (
    <li
      aria-selected={isSelected ? 'true' : 'false'}
      className={classNames(
        'tab',
        {
          active: isSelected
        },
        className
      )}
      role="tab"
      style={{ width: `${width}%` }}
    >
      {children}
    </li>
  );
}

Tab.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  width: PropTypes.number
};

Tab.defaultProps = {
  className: '',
  isSelected: false,
  width: 100
};

export default Tab;
