export default {
    '000': '직접전달',
    '01': '우체국택배',
    '04': 'CJ대한통운',
    '06': '로젠택배',
    '05': '한진택배',
    '11': '일양로지스',
    '08': '롯데택배',
    '46': 'CU편의점택배',
    '23': '경동택배',
    '17': '천일택배',
    '56': 'KGB택배',
    '22': '대신택배',
    '32': '합동택배',
    '18': '건영택배',
    '40': '굿투럭',
    '54': '홈픽택배',
    '53': '농협택배',
    '52': '세방',
    '43': '애니트랙',
    '20': '한덱스',
    '16': '한의사랑택배',
    '45': '호남택배',
    '24': 'CVSnet편의점택배',
    '30': 'KGL네트웍스',
    '44': 'SLX'
};