/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, Fragment } from 'react';
// import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';

import AlertModal from 'components/modal/AlertModal';

import useModal from 'hooks/useModal';
import NavBar from './NavBar';
import './NavBar.scss';

function MainNavContainer() {
  const mainNavLinks = [
    { label: '대시보드', path: '/dashboard' },
    {
      label: '발굴관리',
      path: '/discover'
    },
    { label: '상품관리', path: '/product' },
    {
      label: '공급자관리',
      path: '/provider'
    },
    {
      label: '회원관리',
      path: '/user'
    },
    {
      label: '배송관리',
      path: '/delivery'
    },
    {
      label: '정산관리',
      path: '/delivery_adj'
    },
    {
      label: '미수신선물관리',
      path: '/gift'
    },
    {
      label: 'DM발송',
      path: '/sendDm'
    },
    {
      label: '단체선물',
      path: '/sendgift'
    },
    {
      label: '마케팅관리',
      path: '/marketing'
    },
    {
      label: '월초정산',
      path: '/clearing'
    }
  ];

  return (
    <Fragment>
      <NavBar navLinks={mainNavLinks} />
    </Fragment>
  );
}

export default withRouter(MainNavContainer);
