import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import renderHTML from 'react-render-html';
import ModalDialog from 'components/modal/ModalDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import SearchForm from 'components/commons/SearchForm/index';
import { getFormatDate } from 'lib/time';
import { autoHypenPhone } from 'lib/utils/numberUtils';
import { getHost } from 'lib/utils/envUtils';

import './DeliveryList.scss';
import TrackingDelivery from 'components/trackingDelivery/TrackingDelivery';
import { sendNotice } from 'api/sms';
// eslint-disable-next-line import/named
import { updateDeliverer } from 'api/delivery';

import Paging from 'components/commons/Paging/Index';

const host = getHost();

// const options = [{
//   id : 2,
//   type : 'M', // modal
//   data : {value : 'P'},
//   fnName : 'provider'
// }];

const options = [
  {
    id: 1,
    type: 'S', // select
    data: [
      { text: '구매자 이름', id: 'buyerName' },
      { text: '공급자 이름', id: 'providerId' }
    ],
    fnName: 'type'
  },
  // {
  //     id : 2,
  //     type : 'I', // input
  //     fnName : 'keyword'
  // },
  {
    id: 2,
    type: 'IM', // input
    openModalIndex: 1,
    fnName: 'keyword'
  }
];

class DeliveryList extends Component {
  // 이벤트 정의
  initFunction = {
    type: (value) => {
      this.setInitValues('type', value);
    },
    keyword: (value) => {
      this.setInitValues('keyword', value);
    },
    // 송장입력여부
    serial: (value) => {
      this.setInitValues('serial', value);
    },
    // 공급자 id
    id: (value) => {
      this.setInitValues('id', value);
    },
    // 공급자
    provider: (value) => {
      this.setInitValues('provider', value);
    },
    selectedOptionIndex: (value) => {
      this.selectedOptionIndex = value;
    }
  };

  constructor() {
    super();

    this.state = {
      isOpen: false,
      isCancelOpen: false,
      type: 'buyerName'
      // startDate : new Date(),
      // endDate : new Date()
    };

    // 주문을취소 storeId, paymentId
    this.storeId = '';
    this.orderId = '';
    this.flagForceCancel = false;
    this.selectedOptionIndex = 0;

    this.columns = [
      {
        dataField: 'experienceType',
        text: '유형',
        sort: true,
        formatter: (cell, row) => {
          const { status, buyer, orderId, deliverer } = row;
          const { id: buyerId } = buyer;
          const { trackingNumber } = deliverer || {};

          // const { providerId, status } = row;
          const isCanceled = status === 'canceled';
          // 운송장 입력 전

          let cellName = null;
          let cellButton = null;

          if (cell === 'buy') {
            cellName = <div>구매</div>;
          } else {
            cellName = (
              <Fragment>
                <div>선물</div>
                <div className={status === 'ordered' ? 'text-danger' : ''}>
                  {status === 'ordered' ? '미수신' : '수신'}
                </div>
              </Fragment>
            );
          }

          if (isCanceled) {
            cellButton = (
              <div>
                <span className="text-danger">취소됨</span>
              </div>
            );
          } else {
            cellButton = (
              <div>
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm"
                  onClick={() => {
                    this.storeId = buyerId;
                    this.orderId = orderId;
                    this.flagForceCancel = !!trackingNumber;
                    this.openCancelModal();
                  }}
                >
                  취소
                </button>
              </div>
            );
          }

          return (
            <Fragment>
              {cellName}
              {cellButton}
            </Fragment>
          );
        },
        headerStyle: {
          width: '60px',
          textAlign: 'center'
        },
        csvFormatter: (cell, row) => `${row.payType && row.payType}`,
        csvExport: true
      },
      {
        dataField: 'status',
        text: '주문상태',
        hidden: true
      },

      {
        dataField: 'createDate',
        text: '구매일',
        sort: true,
        formatter: (cell) => getFormatDate(cell, 'YYYY.MM.DD HH:mm'),
        csvFormatter: (cell) => getFormatDate(cell, 'YYYY.MM.DD HH:mm'),
        headerStyle: {
          width: '80px'
        },
        csvExport: true
      },
      {
        dataField: 'buyerName',
        text: '구매자',
        formatter: (cell, row) => (
          <div>
            <a
              target="_blank"
              href={`${host}/store/${row.buyerId}`}
              rel="noopener noreferrer"
            >
              {row.buyerName}
            </a>
            {row.buyerMobile && (
              <Fragment>
                <br />
                <br />
                <a href={`tel:${row.buyerMobile}`}>
                  {autoHypenPhone(row.buyerMobile)}
                </a>
              </Fragment>
            )}
          </div>
        ),
        csvFormatter: (cell, row) => `${row.buyerName && row.buyerName}`,
        headerStyle: {
          width: '100px'
        },
        filter: textFilter({
          placeholder: '구매자'
        }),
        csvExport: true
      },
      {
        dataField: 'buyerMobile',
        text: '구매자 연락처',
        hidden: true,
        csvFormatter: (cell, row) =>
          `${row.buyerMobile && autoHypenPhone(row.buyerMobile)}`,
        csvExport: true
      },
      {
        dataField: 'receiverName',
        text: '수신자',
        formatter: (cell, row) => (
          <div>
            <a
              target="_blank"
              href={`${host}/store/${row.receiverId}`}
              rel="noopener noreferrer"
            >
              {row.receiverName}
            </a>
            {row.receiverMobile && (
              <Fragment>
                <br />
                <br />
                <a href={`tel:${row.receiverMobile}`}>
                  {autoHypenPhone(row.receiverMobile)}
                </a>
              </Fragment>
            )}
          </div>
        ),
        csvFormatter: (cell, row) => `${row.receiverName && row.receiverName}`,
        headerStyle: {
          width: '100px'
        },
        filter: textFilter({
          placeholder: '수신자'
        }),
        csvExport: true
      },
      {
        dataField: 'receiverMobile',
        text: '수신자 연락처',
        hidden: true,
        csvFormatter: (cell, row) =>
          `${row.receiverMobile && autoHypenPhone(row.receiverMobile)}`,
        csvExport: true
      },
      {
        dataField: 'productName',
        text: '상품',
        formatter: (cell, row) => (
          <div>
            <a
              target="_blank"
              href={`${host}/product/${row.productId}`}
              rel="noopener noreferrer"
            >
              {row.productName}
            </a>
          </div>
        ),
        csvFormatter: (cell, row) => `${row.productName && row.productName}`,
        headerStyle: {
          width: '120px'
        },
        filter: textFilter({
          placeholder: '상품명'
        })
      },
      {
        dataField: 'optionName',
        text: '옵션',
        headerStyle: {
          width: '120px'
        },
        filter: textFilter({
          placeholder: '옵션'
        }),
        csvExport: true
      },
      {
        dataField: 'amountNew',
        text: '수량',
        formatter: (cell, row) => {
          const { amount, quantity } = row;
          const amountVal = typeof amount === 'object' ? 'NaN' : amount;
          return <div>{`${quantity || amountVal}`}</div>;
        },
        csvFormatter: (cell, row) => {
          const { amount, quantity } = row;
          const amountVal = typeof amount === 'object' ? 'NaN' : amount;
          return quantity || amountVal;
        },
        headerStyle: {
          width: '40px'
        },
        csvExport: true
      },
      {
        dataField: 'recipient.name',
        text: '수령인',
        headerStyle: {
          width: '60px'
        },
        filter: textFilter({
          placeholder: '수령인'
        }),
        formatter: (cell, row) => {
          const recipientName = row.recipient ? row.recipient.name : '';
          const buttonName = row.recipient ? '수정' : '입력';
          return (
            <div>
              <div>{recipientName}</div>
              {!row.invoiceNumber && (
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => {
                      this.handleUpdateRecipient(row);
                    }}
                  >
                    {buttonName}
                  </button>
                </div>
              )}
            </div>
          );
        },
        csvFormatter: (cell, row) => {
          let rname = '';

          if (row.recipient) {
            rname = row.recipient.name;
          }

          return rname;
        }
      },
      {
        dataField: 'recipient.mobile',
        text: '연락처',
        formatter: (cell, row) => (
          <div>
            {row.recipient && row.recipient.mobile && (
              <Fragment>
                <a href={`tel:${row.recipient.mobile}`}>
                  {autoHypenPhone(row.recipient.mobile)}
                </a>
              </Fragment>
            )}
          </div>
        ),

        csvFormatter: (cell) => autoHypenPhone(cell),
        headerStyle: {
          width: '100px'
        }
      },
      {
        dataField: 'recipient.zipcode',
        text: '우편번호',
        csvFormatter: (cell, row) => {
          let zipcode = '';

          if (row.recipient) {
            zipcode = `'${row.recipient.zipcode}`;
          }

          return zipcode;
        },
        headerStyle: {
          width: '70px'
        }
      },
      {
        dataField: 'recipient.addr',
        text: '주소',
        formatter: (cell, row) => {
          let address = '';
          if (row.recipient && row.recipient.addr) {
            if (row.recipient.addrSub) {
              address = `${row.recipient.addr} ${row.recipient.addrSub}`;
            } else {
              address = row.recipient.addr;
            }
          }

          return (
            <Fragment>
              <div>{address}</div>
            </Fragment>
          );
        },
        headerStyle: {
          width: '80px'
        },
        csvFormatter: (cell, row) => {
          let address = '';

          if (row.recipient) {
            address = `${row.recipient.addr} ${
              row.recipient.addrSub ? row.recipient.addrSub : ''
            }`;
          }

          return address;
        }
      },
      {
        dataField: 'orderId',
        text: '주문번호',
        hidden: true
      },
      {
        dataField: 'id',
        text: '배송번호',
        hidden: true
      },

      {
        dataField: 'recipient.deliveryMessage',
        text: '배송 메세지',
        csvFormatter: (cell, row) => {
          const msg =
            row.recipient &&
            row.recipient.deliveryMessage &&
            row.recipient.deliveryMessage !== null
              ? row.recipient.deliveryMessage
              : '';

          return msg;
        },
        headerStyle: {
          width: '70px'
        }
      },
      {
        dataField: 'recipient.reserveDate',
        text: '예약 발송일',
        csvFormatter: (cell, row) => {
          const reserveDate =
            row.recipient &&
            row.recipient.reserveDate &&
            row.recipient.reserveDate !== null
              ? row.recipient.reserveDate
              : '';
          // `'${row.deliveryInfo.reserveDate}`
          return reserveDate;
        },
        headerStyle: {
          width: '70px'
        }
      },
      {
        dataField: 'modify',
        text: '배송정보수정',
        headerStyle: {
          width: '50px'
        },
        formatter: (cell, row) => {
          if (!row.invoiceNumber) {
            return (
              <div>
                <button
                  type="button"
                  onClick={() => {
                    this.handleUpdateRecipient(row);
                  }}
                  style={{ color: '#286ddc' }}
                >
                  수정
                </button>
              </div>
            );
          }

          return null;
        },
        editable: false,
        csvExport: false,
        hidden: true
      },
      {
        dataField: 'providerName',
        text: '공급자',
        formatter: (cell, row) => (
          <div>
            <a
              target="_blank"
              href={`${host}/store/${row.providerId}`}
              rel="noopener noreferrer"
            >
              {row.providerName}
            </a>
            {row.providerMobile && (
              <Fragment>
                <br />
                <br />
                <a href={`tel:${row.providerMobile}`}>
                  {autoHypenPhone(row.providerMobile)}
                </a>
              </Fragment>
            )}
          </div>
        ),
        csvFormatter: (cell, row) =>
          `${row.providerId}
          ${row.providerName && row.providerName}
          ${row.providerMobile && autoHypenPhone(row.providerMobile)}`,
        headerStyle: {
          width: '100px'
        },
        filter: textFilter({
          placeholder: '공급자'
        }),
        csvExport: false
      },
      {
        dataField: 'deliveryName',
        text: '택배사',
        headerStyle: {
          width: '90px'
        },
        formatter: (cell, row) => {
          const { status, deliveryName, deliverer } = row;
          if (status === 'confirmed' || status === 'requested') {
            return (
              <div>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() => {
                    this.sendMessage(row);
                  }}
                >
                  입력요청
                </button>
              </div>
            );
          }
          if (deliverer || deliveryName) {
            // console.log({ deliverer });
            return deliverer.name || deliveryName;
          }
          return '';
        },
        csvFormatter: (cell) => {
          if (!cell) {
            return '';
          }
          return cell;
        },
        csvExport: false
      },
      {
        dataField: 'deliverer.trackingNumber',
        text: '운송장번호',
        headerStyle: {
          width: '100px'
        },
        formatter: (cell, row) => {
          const {
            deliveryCode,
            invoiceNumber,
            providerId,
            deliverer,
            id,
            isCanceled,
            status
          } = row;
          let button = null;

          if (deliverer || invoiceNumber) {
            button = (
              <div>
                <button
                  type="button"
                  onClick={() =>
                    this.openPopup(
                      deliverer.id || deliveryCode,
                      deliverer.trackingNumber || invoiceNumber
                    )
                  }
                  style={{ color: '#286ddc' }}
                >
                  {deliverer.trackingNumber || invoiceNumber}
                </button>
                <button
                  id={`${id}-input-btn`}
                  type="button"
                  className="btn btn-outline-secondary btn-sm mt-3"
                  onClick={() =>
                    this.handleUpdateDeliverer(id, providerId, deliverer)
                  }
                >
                  번호수정
                </button>
              </div>
            );
          } else if (
            (status === 'confirmed' || status === 'requested') &&
            !isCanceled &&
            !deliverer &&
            providerId
          ) {
            button = (
              <div>
                <button
                  id={`${id}-input-btn`}
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() =>
                    this.handleUpdateDeliverer(id, providerId, deliverer)
                  }
                >
                  직접입력
                </button>
                <button
                  id={`${id}-confirm-btn`}
                  type="button"
                  className="btn btn-outline-danger btn-sm mt-3"
                  onClick={() =>
                    this.handleInvoiceModify(id, providerId, cell, row)
                  }
                >
                  수령확인
                </button>
              </div>
            );
          }

          return button;
        },
        csvFormatter: (cell) => `${!cell ? '' : cell}`
      },
      {
        dataField: 'id',
        text: '배송번호/주문번호',
        formatter: (cell, row) => {
          const { orderId } = row;
          return (
            <Fragment>
              <div>{cell}</div>
              <div>{orderId}</div>
            </Fragment>
          );
        },
        csvFormatter: (cell, row) => `${cell}\n${row.orderId}`,
        headerStyle: {
          width: '150px'
        },
        csvExport: false
      }
    ];
  }

  componentDidMount() {}

  openPopup = (deliveryCode, invoiceNumber) => {
    this.setState({
      isOpen: true,
      deliveryCode,
      invoiceNumber,
      hasDeliveryInfo: true
    });
  };

  // 취소모달
  openCancelModal = () => {
    const { isCancelOpen } = this.state;
    this.setState({
      isCancelOpen: !isCancelOpen
    });
  };

  // 주문을취소
  handleCloseCancelPayment = () => {
    const { handleCancelPayment } = this.props;
    handleCancelPayment(this.storeId, this.orderId, this.flagForceCancel);
    this.handleCloseModal('isCancelOpen');
    this.storeId = '';
    this.orderId = '';
    this.flagForceCancel = false;
  };

  handleCloseModal = (type) => {
    this.setState({
      [type]: false
    });
  };

  /**
   * 수정 페이지로 이동
   */
  handleUpdateDeliverer = (deliveryId, providerId, deliverer) => {
    const { handleMoveToUpdateDeliverer } = this.props;
    // const { id, serial, provider } = this.state;
    // const param = {};

    // console.log(this.state);
    // if (serial) {
    //   param.serial = serial;
    // }

    // if (id) {
    //   param.id = id;
    // }

    // if (provider) {
    //   param.provider = provider;
    // }

    handleMoveToUpdateDeliverer(deliveryId, providerId, deliverer);
  };

  handleUpdateRecipient = ({
    id,
    status,
    receiver,
    recipient,
    flagDelivery
  }) => {
    const { handleMoveToUpdateRecipient } = this.props;

    handleMoveToUpdateRecipient(id, status, flagDelivery, receiver, recipient);
  };

  // 현장확인
  handleInvoiceModify = async (deliveryId, providerId, cell, row) => {
    // console.log('handleInvoiceModify');
    const params = {
      deliverer: {
        id: '000',
        name: '직접전달',
        trackingNumber: getFormatDate(Date.now(), 'YYYY.MM.DD HH:mm:ss a')
      },
      id: deliveryId
    };

    try {
      const result = await updateDeliverer(providerId, deliveryId, params);

      if (result && result.data && result.data.success) {
        console.log(`success`);
        console.log(row);
        console.log(cell);

        const x = document.getElementById(`${deliveryId}-confirm-btn`);
        const y = document.getElementById(`${deliveryId}-input-btn`);

        x.style.display = 'none';
        y.style.display = 'none';
      }
    } catch (error) {
      console.log(error);
    }
  };

  setInitValues = (column, value) => {
    const { onChangeSearchForm } = this.props;
    this.setState({
      [column]: value
    });

    onChangeSearchForm(column, value);
  };

  onSelectCellButton = (row, isSelect, rowIndex, e) => {
    console.log(row);
    console.log(isSelect);
    console.log(rowIndex);
    console.log(e);
  };

  /**
   * 이전 페이지 이동
   */
  handlePrev = (page) => {
    this.onSearch(page);
  };

  /**
   * 다음 페이지 이동
   */
  handleNext = (page) => {
    this.onSearch(page);
  };

  /**
   * @param integer, integer
   */
  onSearch = (page) => {
    const { onSearch } = this.props;

    onSearch({
      page
    });
  };

  onSubmit = (e) => {
    console.log(e);

    const { onSearch } = this.props;
    // const { id, provider } = this.state;
    // onSearch({ id, provider });
    const { type, keyword, id } = this.state;
    onSearch({
      type,
      keyword,
      id,
      isModalSearch: this.selectedOptionIndex === options[1].openModalIndex
    });
  };

  sendMessage = (row) => {
    if (row) {
      this.setState({
        sendIsOpen: true,
        rowData: row
      });
    }
  };

  /**
   * 메세지 전송
   */
  handlerSend = async () => {
    try {
      const { rowData } = this.state;
      const { provider } = rowData;

      if (rowData) {
        const message = this.makerMessage(rowData);
        let returnMessage = '';

        const params = {
          sendNumber: '0269511234',
          subject: '프롬',
          msgBody: message,
          recvNumber: provider.mobile
        };

        const response = await sendNotice(params);
        if (response.status === 200) {
          returnMessage += `<p><span>*</span> 공급자 ${
            provider.name
          } 님에게 알림을 발송하였습니다.<p><br><p className="work-break">${message}</p>`;
        }

        if (message !== '') {
          this.setState({
            isOpenConfirm: true,
            message: returnMessage
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 메세지 만들기
   */
  makerMessage = (row) => {
    const { recipient, provider, orderId, product, order } = row;
    const { optionName, option } = order || {};
    const { id: providerId } = provider || {};
    const { name: productName } = product || {};

    const productSelectOpt = optionName || option;

    let address = '';
    if (recipient.addr) {
      if (recipient.addrSub) {
        address = `${recipient.addr} ${recipient.addrSub}`;
      } else {
        address = recipient.addr;
      }
    }

    const link = `${host}/provider/${providerId}/setting/deliveryproducts`;
    const name = recipient ? recipient.name : '';
    const mobile = recipient ? recipient.mobile : '';
    const message = `[배송정보 입력요청]
다음 주문에 대해 배송정보를 입력해 주세요. 운송정보가 입력되지 않은 주문은 정산이 지연될 수 있습니다.

주문번호: ${orderId}
수령인: ${name}
연락처: ${mobile}
배송지 : ${address}
상품: ${productName} X ${productSelectOpt}
배송메세지: ${recipient.deliveryMessage}
    
배송정보 입력하기: ${link}`;
    return message;
  };

  render() {
    const {
      gridList,
      page,
      isLast,
      resultId,
      resultName,
      onChangeSelect,
      selectListCount
    } = this.props;
    const {
      isOpen,
      sendIsOpen,
      isOpenConfirm,
      isCancelOpen,
      message,
      deliveryCode,
      hasDeliveryInfo,
      invoiceNumber,
      rowData,
      type
    } = this.state;
    const { ExportCSVButton } = CSVExport;

    return (
      <Fragment>
        <ToolkitProvider
          keyField="id"
          data={gridList}
          columns={this.columns}
          exportCSV={{
            fileName: `배송리스트_.csv`,
            noAutoBOM: false,
            blobType: 'text/plain;charset=utf-8',
            exportAll: false,
            onlyExportFiltered: true
          }}
        >
          {(props) => (
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <h4 className="h4">배송관리</h4>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-4">
                  <SearchForm
                    condition={options}
                    initFunction={this.initFunction}
                    onSubmit={this.onSubmit}
                    resultId={resultId}
                    resultName={resultName}
                    type={type}
                  />
                </div>
                <div>
                  <select
                    className="form-control"
                    onChange={onChangeSelect}
                    value={selectListCount}
                  >
                    <option>100</option>
                    <option>150</option>
                    <option>200</option>
                  </select>
                </div>
                <div className="col-4 text-right gift-list-btn">
                  <ExportCSVButton {...props.csvProps}>
                    Export CSV <FontAwesomeIcon icon={faFileExcel} />
                  </ExportCSVButton>
                </div>
              </div>
              <div className="row">
                <div className="col" style={{ overflow: 'auto' }}>
                  <BootstrapTable
                    {...props.baseProps}
                    // selectRow={{
                    //   mode: 'radio',
                    //   clickToSelect: false,
                    //   onSelect: (row, isSelect, rowIndex, e) => {
                    //     this.onSelectCellButton(row, isSelect, rowIndex, e);
                    //   }
                    // }}
                    options={{ noDataText: '데이터가 없습니다.' }}
                    filter={filterFactory()}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col text-center">
                  <Paging
                    page={page}
                    isLast={isLast}
                    handlePrev={this.handlePrev}
                    handleNext={this.handleNext}
                  />
                </div>
              </div>
            </div>
          )}
        </ToolkitProvider>
        <ModalDialog
          className="modal-control discover-register-modal"
          isOpen={isOpen}
          onToggle={() => {
            this.handleCloseModal('isOpen');
          }}
          size="md"
        >
          <TrackingDelivery
            deliveryCode={deliveryCode} // 택배사 코드
            hasDeliveryInfo={hasDeliveryInfo} // 운송장 번호 입력 여부 boolean
            invoiceNumber={invoiceNumber} // 운송장 번호
          />
        </ModalDialog>
        <ModalDialog
          className="modal-control discover-register-modal"
          isOpen={sendIsOpen}
          onToggle={() => {
            this.handleCloseModal('sendIsOpen');
          }}
          size="md"
        >
          <div className="container-fluid">
            <div className="row p-2">
              <div className="col">
                <p className="h5">알림발송</p>
              </div>
            </div>
            <div className="row p-2">
              <div className="col-6">
                <span>공급자명 : {rowData ? rowData.providerName : null}</span>
              </div>
              <div className="col-6">
                <span>
                  전화번호 : {rowData ? rowData.providerMobile : null}
                </span>
              </div>
            </div>
            <div className="row p-2">
              <div className="col">
                <p>알림을 발송하시겠습니까?</p>
              </div>
            </div>
            <div className="row p-2">
              <div className="col text-center">
                <span className="px-1">
                  <button
                    className="btn btn-info"
                    type="button"
                    onClick={this.handlerSend}
                  >
                    발송
                  </button>
                </span>
                <span className="px-1">
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => this.handleCloseModal('sendIsOpen')}
                  >
                    취소
                  </button>
                </span>
              </div>
            </div>
          </div>
        </ModalDialog>
        <ModalDialog
          isOpen={isOpenConfirm}
          onToggle={() => {
            this.handleCloseModal('sendIsOpen');
            this.handleCloseModal('isOpenConfirm');
          }}
          size="md"
        >
          <div>{message ? renderHTML(message) : null}</div>
        </ModalDialog>
        <ModalDialog
          isOpen={isCancelOpen}
          onToggle={() => {
            this.handleCloseModal('isCancelOpen');
          }}
          size="md"
        >
          <div className="container-fluid">
            <div className="row p-2">
              <div className="col">
                <p>
                  주문을 취소하시겠습니까?
                  <br />
                  <br />
                  <span className="text-danger">
                    {`*** 취소시 주문번호 '${
                      this.orderId
                    }'  전체가 취소됩니다!!!`}
                  </span>
                  <br />
                  <br />
                  {this.flagForceCancel && (
                    <span className="text-danger">
                      ** 이 항목은 운송장이 있습니다!!
                      <br />* 배송 중일 수도 있습니다!!
                    </span>
                  )}
                </p>
              </div>
            </div>
            <div className="row p-2">
              <div className="col text-center">
                <span className="px-1">
                  <button
                    className="btn btn-info"
                    type="button"
                    onClick={this.handleCloseCancelPayment}
                  >
                    주문취소
                  </button>
                </span>
                <span className="px-1">
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => this.handleCloseModal('isCancelOpen')}
                  >
                    취소
                  </button>
                </span>
              </div>
            </div>
          </div>
        </ModalDialog>
      </Fragment>
    );
  }
}

DeliveryList.propTypes = {
  onSearch: PropTypes.func,
  // moveInvoice: PropTypes.func,
  handleCancelPayment: PropTypes.func
};

DeliveryList.defaultProps = {
  onSearch: () => {},
  // moveInvoice: () => {},
  handleCancelPayment: () => {}
};

export default DeliveryList;
