/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux';
import penderMiddleware from 'redux-pender';

import reducers from 'modules';

const isDevelopment = process.env.NODE_ENV === 'development'; // 환경이 개발모드인지 확인
const composeEnhancers = isDevelopment
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose; // 개발모드에서만 리덕스 개발자도구 적용

// const composeEnhancers = compose;

export default (initialState = {}) => {
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(penderMiddleware()))
  );

  if (module.hot) {
    module.hot.accept('../modules/index.js', () => {
      const nextReducer = require('../modules/index.js').default;

      store.replaceReducer(nextReducer);
    });
  }

  return store;
};
