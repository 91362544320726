import { getFormatDate } from '../time';

// TODO load dynamically 

const dynamicSchema = {
	// 1p
	'oneProduct' : {
		// bootstrap-table2 에 지정될 키필드
		'keyField' : 'storeIdProductId',
		// dm 발송시 1s id필수
		'keyFieldSub' : 'storeId',
		'keyName' : 'storeName',
		'keyMobile' : 'storeMobile',
		// 'keyMobile' : 'store.mobile',
		'alias' : '1P',
		// 검색 필드 배열
		'fields' : {
			'q' : {
				'label' : '검색조건',
				'items' : [
					{label : '회원명', type : 'text', name : 'op_name', original : 'storeName'},
					{label : '상품명', type : 'text', name : 'op_productName', original : 'productName'},
					{label : '생성유형', 
						type : 'select', 
						// select 일 경우 options에 option으로 생성될 배열을 넘겨주면 됩니다.
						options : [
							{text : '전체' , value : ''},
							{text : '구매' , value : 'buy'},
							{text : '선물받기' , value : 'gift-recv'},
							{text : '선물하기' , value : 'gift-send'}
						],
						name : 'op_createType', 
						original : 'createType'
					},
					{label : '생성일', 
						type : 'date', 
						options : [
							{name : 'from', label : '시작일'},
							{name : 'to', label : '종료일'}
						],
						name : 'op_createDate', 
						original : 'createDate'
					}
				]
			},
			's' : {
				'label' : '정렬',
				'items' : [
					{label : '생성일', type : 'text', name : 'op_createDate', original : 'createDate'}
				]
			}
		},
		'columns' : [
			{
				dataField: 'storeName',
				text: '회원명',
				headerStyle: {
					width: '80px'
				}
			},
			{
				dataField: 'storeMobile',
				// dataField: 'store.mobile',
				text: '전화번호',
				headerStyle: {
					width: '100px'
				},
				// eslint-disable-next-line
				csvFormatter: cell => cell
			},
			{
				dataField: 'productName',
				text: '상품명',
				headerStyle: {
					width: '80px'
				}
			},
			{
				dataField: 'createType',
				text: '생성유형',
				headerStyle: {
					width: '80px'
				},
				formatter: cell => cell
			},
			{
				dataField: 'createDate',
				text: '생성일',
				headerStyle: {
					width: '80px'
				},
				formatter: cell => getFormatDate(cell, 'YYYY-MM-DD')
			}
		],
		'recipientColumns': [
			{
				dataField: 'storeName',
				text: '수신자',
				headerStyle: {
					width: '80px'
				},
				csvText: 'recipient'
			},
			{
				dataField: 'storeMobile',
				// dataField: 'store.mobile',
				text: '전화번호',
				headerStyle: {
					width: '100px'
				},
				csvText: 'phone',
				// eslint-disable-next-line
				csvFormatter: cell => cell
			},
			{
				dataField: 'productName',
				text: '상품명',
				headerStyle: {
					width: '80px'
				},
				csvText: 'productName'
			},
			{
				dataField: 'productId',
				text: '상품ID',
				headerStyle: {
					width: '80px'
				},
				csvText: 'productId'
			},
			{
				dataField: 'createDate',
				text: '생성일',
				headerStyle: {
					width: '80px'
				},
				formatter: cell => getFormatDate(cell, 'YYYY-MM-DD'),
				csvText: 'createDate'
			}
		]
	},
	// 1s
	'oneStore' : {
		// bootstrap-table2 에 지정될 키필드
		'keyField' : 'id',
		// dm 발송시 1s id필수
		'keyFieldSub' : 'id',
		'keyName' : 'userName',
		'keyMobile' : 'mobile',
		'alias' : '1S',
		'fields' : {
			'q' : {
				'label' : '검색조건',
				'items' : [
					{label : '회원명', type : 'text', name : 'os_name', original : 'userName'},
					{label : '상점번호', type : 'text', name : 'os_storeNo', original : 'storeNo'}
				]
			},
			's' : {
				'label' : '정렬',
				'items' : [
					{label : '생성일', type : 'text', name : 'op_createDate', original : 'createDate'}
				]
			}
		},
		'columns' : [
			{
				dataField: 'userName',
				text: '회원명',
				headerStyle: {
					width: '80px'
				}
			},
			{
				dataField: 'mobile',
				text: '전화번호',
				headerStyle: {
					width: '80px'
				}
			},
			{
				dataField: 'storeNo',
				text: '상점번호',
				headerStyle: {
					width: '80px'
				}
			}
		],
		'recipientColumns' : [
			{
				dataField: 'userName',
				text: '회원명',
				headerStyle: {
					width: '80px'
				}
			},
			{
				dataField: 'mobile',
				text: '전화번호',
				headerStyle: {
					width: '80px'
				}
			},
			{
				dataField: 'storeNo',
				text: '상점번호',
				headerStyle: {
					width: '80px'
				}
			}
		]
	}
}

/* eslint-disable import/prefer-default-export */
export const schema = dynamicSchema;