import React, { Component } from 'react';

import { getDiscover } from 'api/discover';

import DiscoverRegisterForm from 'components/discover/DiscoverRegisterForm';

class DiscoverRegisterContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      discovererName: '',
      productName: ''
    };
  }

  componentDidMount() {
    this.getDiscover();
  }

  getDiscover = async () => {
    try {
      const { match } = this.props;
      const { discoverId } = match.params;

      const response = await getDiscover(discoverId);

      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }

      const { data } = response.data;

      this.setState({
        discovererName: data.discovererName,
        productName: data.productName
      });
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { discovererName, productName } = this.state;

    return (
      <DiscoverRegisterForm
        discovererName={discovererName}
        productName={productName}
      />
    );
  }
}

export default DiscoverRegisterContainer;
