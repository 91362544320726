import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import GiftList from 'components/gift/GiftList/GiftList';
import { getGiftList } from 'api/gift';

class GiftContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gridList: [],
      totalCount: 0,
      page: 1
    };

    this.pageCount = 100;
    this.lastKeys = new Map();
    this.lastKeys.set(1, null);
  }

  componentDidMount() {
    this.initialize();
  }

  /**
   * 초기화
   */
  initialize = () => {
    this.onSearch();
  };

  /**
   * 조회
   */
  onSearch = async searchKeywords => {
    let params;
    let page = 0;

    try {
      // 페이지 로드일 때
      if (searchKeywords === undefined) {
        params = {};
        page = 1;
        params.lastKey = this.lastKeys.get(page);
      } else {
        // 페이징 버튼을 누루거나 검색일 때
        params = {};
        params.lastKey = this.lastKeys.get(searchKeywords.page);

        this.setState({
          page: searchKeywords.page
        });

        // eslint-disable-next-line
        page = searchKeywords.page;
      }

      params.pageCount = this.pageCount;
      const response = await getGiftList(params);

      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }

      // flagReceive 0 : 미수신,1 : 수신(주소를 다 적은 것),2 : 수신(수령인이 직접 인력한 케이스)
      // const { data } = response.data;

      // if (data.lastKey) {
      //   if (this.lastKeys.get(page+1) === undefined) {
      //     this.lastKeys.set((page+1), data.lastKey);
      //   }
      // }

      // if (data.items) {
      //   this.setState({
      //     gridList: data.items,
      //     totalCount : data.totalCount
      //   });
      // }

      // flagReceive 0 : 미수신,1 : 수신(주소를 다 적은 것),2 : 수신(수령인이 직접 인력한 케이스)
      const { data } = response.data;

      if (data.LastEvaluatedKey) {
        if (this.lastKeys.get(page + 1) === undefined) {
          this.lastKeys.set(page + 1, data.LastEvaluatedKey);
        }
      }

      if (data.Items) {
        this.setState({
          gridList: data.Items,
          totalCount: data.totalCount
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  handleUpdateReceiver = (
    deliveryId,
    status,
    flagDelivery,
    receiver,
    recipient
  ) => {
    const { history } = this.props;
    if (status !== 'ordered') return;
    history.push({
      pathname: `/delivery/register/${deliveryId}`,
      state: { deliveryId, receiver, recipient, flagDelivery, flag: 'edit' }
    });
  };

  render() {
    const { gridList, totalCount, page } = this.state;

    return (
      <Fragment>
        <GiftList
          gridList={gridList}
          totalCount={totalCount}
          onSearch={this.onSearch}
          handleUpdateReceiver={this.handleUpdateReceiver}
          page={page}
        />
      </Fragment>
    );
  }
}

GiftContainer.propTypes = {
  history: PropTypes.arrayOf(PropTypes.shape({}))
};

GiftContainer.defaultProps = {
  history: []
};

export default withRouter(GiftContainer);
