import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';

import { hot } from 'react-hot-loader';
import Header from 'components/header/Header';
import storage from 'lib/storage';
import { validateCognitoToken } from 'lib/aws/validateCognitoToken';

import DiscoverListContainer from 'containers/discover/DiscoverListContainer';
import DiscoverWaitListContainer from 'containers/discover/DiscoverWaitListContainer';
import MainNavContainer from 'containers/nav/MainNavContainer';
import ProviderContainer from 'containers/provider/ProviderContainer';
// import ProviderListContainer from 'containers/provider/ProviderListContainer';
import UserContainer from 'containers/user/UserContainer';
import UserRegisterContainer from 'containers/user/UserRegisterContainer';
import DeliveryContainer from 'containers/provider/DeliveryContainer';
import DeliveryRegisterContainer from 'containers/provider/DeliveryRegisterContainer';
import DeliveryAdjustMentContainer from 'containers/provider/DeliveryAdjustmentContainer';
import ProviderDeliveryContainer from 'containers/provider/ProviderDeliveryContainer';
import ProviderAdjustmentContainer from 'containers/provider/ProviderAdjustmentContainer';
// import ProviderDetailContainer from 'containers/provider/ProviderDetailContainer';
import ProviderRegisterContainer from 'containers/provider/ProviderRegisterContainer';
import DiscoverRegisterContainer from 'containers/discover/DiscoverRegisterContainer';
import DiscoverDetailContainer from 'containers/discover/DiscoverDetailContainer';
import ProductContainer from 'containers/product/ProductContainer';
import DashboardContainer from 'containers/dashboard/DashboardContainer';
import RegisterProductContainer from 'containers/product/RegisterProductContainer';
import GiftContainer from 'containers/gift/GiftContainer';
import OrderContainer from 'containers/order/OrderContainer';
import OrderRegisterContainer from 'containers/order/OderRegisterContainer';
import LoginContainer from 'containers/login/LoginContainer';
import AuthenticationContainer from 'containers/login/AuthenticationContainer';
import SendDMContainer from 'containers/dm/SendDMContainer';
import EditorComp from 'components/editor/EditorComp';

import ModifyProductContainer from 'containers/product/ModifyProductContainer';
import SendGiftContainer from 'containers/sendgift/SendGiftContainer';
import MarketingContainer from 'containers/marketing/MarketingContainer';
// import SegmentForm from './containers/marketing/SegmentForm';
import './App.scss';

/**
 * @param {*} history
 * @param {*} props
 */
const isValidateToken = async (history) => {
  await validateCognitoToken(history);
};

/**
 * @param {*} props
 */
function App(props) {
  const { location, history } = props;
  const adminInfo = storage.get('adminInfo');

  let isLogin = false;

  if (null !== adminInfo) {
    const { isLoggedIn } = adminInfo;
    isLogin = isLoggedIn;
  }

  // 로그인 페이지가 아니라면 실행
  if (location.pathname !== '/' && location.pathname !== '/authentication') {
    if (null === adminInfo) {
      history.push('/');
      return null;
    }

    isValidateToken(history);
  }

  return (
    <div className="app">
      {location.pathname !== '/' && location.pathname !== '/authentication' ? (
        <>
          <Header />
        </>
      ) : null}

      {location.pathname !== '/' && location.pathname !== '/authentication' ? (
        <>
          {!isLogin ? <Redirect to="/" /> : null}
          <MainNavContainer />
          <section className="container section">
            <div className="site-contents">
              {/* <div className="site-tab-menu" /> */}
              <div className="page-content">
                <Switch>
                  <Route
                    exact
                    path="/dashboard"
                    component={DashboardContainer}
                  />
                  <Route
                    exact
                    path="/discover"
                    component={DiscoverListContainer}
                  />
                  <Route
                    exact
                    path="/discover/:discoverId/register"
                    component={DiscoverRegisterContainer}
                  />
                  <Route
                    exact
                    path="/discover/:discoverId/detail"
                    component={DiscoverDetailContainer}
                  />
                  <Route
                    exact
                    path="/discoverwait"
                    component={DiscoverWaitListContainer}
                  />
                  <Route exact path="/provider" component={ProviderContainer} />
                  <Route exact path="/user" component={UserContainer} />
                  <Route
                    exact
                    path="/provider/:providerId/detail"
                    // component={ProviderDetailContainer}
                    component={ProviderRegisterContainer}
                  />
                  <Route exact path="/product" component={ProductContainer} />
                  {/* <Route
                  exact
                  path="/product/:productId/register"
                  component={RegisterProductContainer}
                /> */}
                  <Route
                    path="/product/:productId"
                    component={ModifyProductContainer}
                  />
                  {/* <Route
                  exact
                  path="/deliverys"
                  component={ProviderDeliveryContainer}
                /> */}
                  <Route
                    exact
                    path="/adjustment"
                    component={ProviderAdjustmentContainer}
                  />
                  <Route exact path="/gift" component={GiftContainer} />
                  <Route
                    exact
                    path="/user/register/:id"
                    component={UserRegisterContainer}
                  />
                  <Route exact path="/delivery" component={DeliveryContainer} />
                  <Route
                    exact
                    path="/delivery/register/:deliveryId"
                    component={DeliveryRegisterContainer}
                  />
                  <Route
                    exact
                    path="/delivery_adj"
                    component={DeliveryAdjustMentContainer}
                  />
                  <Route exact path="/order" component={OrderContainer} />
                  <Route
                    exact
                    path="/order/register"
                    component={OrderRegisterContainer}
                  />
                  <Route exact path="/sendDm" component={SendDMContainer} />
                  <Route exact path="/editor" component={EditorComp} />
                  <Route exact path="/sendgift" component={SendGiftContainer} />
                  <Route
                    exact
                    path="/marketing"
                    render={() => <Redirect to="/marketing/segment" />}
                  />
                  <Route path="/marketing" component={MarketingContainer} />

                  {/* <Route exact path='/discover/:discoverId' component={DiscoverRegisterContainer} /> */}
                </Switch>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          {isLogin ? <Redirect to="/dashboard" /> : null}
          <Switch>
            <Route exact path="/" component={LoginContainer} />
            <Route
              exact
              path="/authentication"
              component={AuthenticationContainer}
            />
          </Switch>
        </>
      )}
    </div>
  );
}
export default hot(module)(withRouter(App));
