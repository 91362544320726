import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { getProducts } from "api/product";
import ProductList from "components/product/ProductList";
import { dayFromNow } from "lib/time";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";

class ProductContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productList: [],
      isLoading: true
    };

    this.pageCount = 100;
    this.lastKey = null;
  }

  componentDidMount() {
    this.initialize();
  }

  getProductList = async () => {
    try {
      const { productList } = this.state;

      const response = await getProducts({
        pageCount: this.pageCount,
        lastKey: this.lastKey
      });

      if (!response || !response.data || !response.data.success) {
        throw new Error("실패");
      }

      const { data } = response.data;

      const newList = data.items.map(k => {
        let dfFlagBest = false;

        if (dayFromNow(k.createDate) <= 14 || k.flagBest) {
          dfFlagBest = true;
        }

        return {
          ...k,
          dfFlagBest
        };
      });

      this.setState({
        productList: productList.concat(newList),
        isLoading: !!data.lastKey
      });

      this.lastKey = data.lastKey;

      if (data.lastKey) {
        this.getProductList();
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleMoveDelivery = id => {
    const { history } = this.props;
    history.push(`/provider/${id}/delivery`);
  };

  handleOpenModal = modalType => {
    this.setState({
      [modalType]: true,
      productList: []
    });
  };

  handleToggleModal = modalType => {
    this.setState({
      [modalType]: false
    });
  };

  initialize = () => {
    const { location } = this.props;
    const { state } = location;

    if (state) {
      const { defaultProviderName } = state;

      this.setState(
        {
          defaultProviderName
        },
        () => {
          this.getProductList();
        }
      );
    } else {
      this.getProductList();
    }
  };

  render() {
    const { defaultProviderName, productList, isLoading } = this.state;

    return (
      <Fragment>
        <ProductList
          defaultProviderName={defaultProviderName}
          productList={productList}
          isLoading={isLoading}
        />
      </Fragment>
    );
  }
}

ProductContainer.propTypes = {
  history: PropTypes.arrayOf(PropTypes.shape({})),
  location: PropTypes.arrayOf(PropTypes.shape({}))
};

ProductContainer.defaultProps = {
  history: [],
  location: []
};

export default withRouter(ProductContainer);
