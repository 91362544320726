import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import PropTypes from 'prop-types';

import { getDiscover, updateDiscover, sendDiscoverSNS, putDiscoverStatus } from 'api/discover';
import { getProvider } from 'api/provider';

import { s3Upload } from 'lib/aws/s3Upload';
import DiscoverDetailForm from 'components/discover/DiscoverDetailForm';
import DiscoverStatusSelect from 'components/discover/DiscoverStatusSelect';

// import TestReduxForm from 'components/test/TestReduxForm';
import MessageModal from 'components/modal/MessageModal';
import SearchProviderModal from 'components/modal/SearchProviderModal';

class DiscoverDetailContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createDateKorStr: '',
      discovererName: '',
      discovererMobile: '',
      discovererEmail: '',
      nowStatusProcess: -1,
      productName: '',
      providerName: '',
      providerTel: '',
      tags: '',
      haveProductImages: 'false',
      recommendation: '',
      isLoading: false,
      isOpenSuccessModal: false,
      isOpenProviderModal: false
    };

    this.selectProviderId = null;
    this.discoverData = {};
  }

  componentDidMount() {
    this.initialize();
  }

  getDiscover = async () => {
    try {
      const { match } = this.props;
      const { discoverId } = match.params;

      const response = await getDiscover(discoverId);

      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }

      const { data } = response.data;

      this.setState({
        id: data.id,
        storeId: data.discovererId,
        discoverId: data.id,
        createDateKorStr: data.createDateKorStr,
        discovererName: data.discovererName,
        discovererMobile: data.discovererMobile,
        discovererEmail: data.discovererEmail,
        nowStatusProcess: data.nowStatusProcess,
        changeStatusProcess: data.nowStatusProcess,
        productName: data.productName,
        providerName: data.providerName,
        providerTel: data.providerTel,
        providerId: data.providerId,
        tags: data.tags,
        profileImgPath: data.profileImgPath,
        productImgPathList: data.productImgPathList,
        recommendation: data.recommendation,
        picName: data.picName,
        picMobile: data.picMobile,
        picEmail: data.picEmail,
        productUrl: data.productUrl,
        productId: data.productId
      });

       // 발굴상태 날짜 가져오는 구문
       let tempCreate = 0;
       let tempUpdate = 0;
       let tempDone = 0;
 
       for (let i = 0; i < data.historyStatusProcess.length; i += 1) {
         const targetDate = Number(
           Object.entries(data.historyStatusProcess[i])[0][0]
         );
         const returnDate = Object.entries(data.historyStatusProcess[i])[0][1];
         if (targetDate === 0) {
           tempCreate = returnDate;
         }
         if (targetDate === 1) {
           tempUpdate = returnDate;
         }
         if (targetDate === 2) {
           tempDone = returnDate;
         }
         if (targetDate === 3) {
           tempDone = returnDate;
         }
       }
       //

      this.discoverData = data;

      const { changeField } = this.props;
      // 발굴정보
      changeField('productName', data.productName);
      changeField('providerTel', data.providerTel);
      changeField('tags', data.tags);
      changeField('providerName', data.providerName);
      changeField('recommendation', data.recommendation);
      // 담당자정보
      changeField('picName', data.picName);
      changeField('picMobile', data.picMobile);
      changeField('picEmail', data.picEmail);

      this.setState({
        createDate: tempCreate,
        updateDate: tempUpdate,
        doneDate: tempDone,
        isLoading: false
      });

    } catch (e) {
      console.error(e);
    }
  };

  handleSubmit = async values => {
    try {
      const { storeId, discoverId } = this.state;
      const { productImgPathList } = this.state;

      const submitData = values;

      submitData.storeId = storeId;
      submitData.discoverId = discoverId;
      // ///// - 공사중 -
      // //
      const uploadProductImages = [];
      const productImgList = [];

      productImgPathList.forEach(image => {
        if (!image) {
          return;
        }

        uploadProductImages.push(
          s3Upload(image.path || image.image, 'productImage'),
        );

        productImgList.push({
          width: image.width,
          height: image.height
        });
      });

      const uploadProductImgPathList = await Promise.all(uploadProductImages);

      for (let i = 0; i < uploadProductImgPathList.length; i += 1) {
        productImgList[i].path = uploadProductImgPathList[i];
      }

      submitData.productImgPathList = productImgList;
      // //
      // ///// - 공사중 -
      this.discoverData = {
        ...this.discoverData,
        ...submitData
      };

      if (this.selectProviderId) {
        this.discoverData = {
          ...this.discoverData,
          providerId: this.selectProviderId
        };
      }

      const response = await updateDiscover(this.discoverData);

      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }

      this.setState({
        contentText: `수정되었습니다.`,
        isOpenSuccessModal: true
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleAddProductImage = productImage => {
    const { productImgPathList } = this.state;

    // react list key 를 설정하기 위해 랜덤으로 id를 만듬
    const uniqueId = Math.random()
      .toString(36)
      .substr(2, 9);

    const image = productImage;
    image.id = uniqueId;

    this.setState({
      productImgPathList: [...productImgPathList, image],
      haveProductImages: true
    });
  };

  // 이미지 제거 함수
  handleRemovePicture = index => {
    const { productImgPathList } = this.state;

    productImgPathList.splice(index, 1);

    this.setState({
      productImgPathList,
      haveProductImages: productImgPathList.length > 0
    });
  };

  handleSendProviderSNS = async () => {
    try {
      const {
        id,
        providerName,
        productName,
        discovererName,
        picMobile
      } = this.discoverData;

      const sendData = {};

      sendData.discoverId = id;
      sendData.providerName = providerName;
      sendData.productName = productName;
      sendData.discovererName = discovererName;
      sendData.picMobile = picMobile;

      const response = await sendDiscoverSNS(sendData);

      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }

      this.setState({
        contentText: `'${providerName}'님에게 '${picMobile}'로\n'${productName}' 발굴요청보냈습니다.`,
        isOpenSuccessModal: true
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChangeProvider = providerName => {
    const { changeField } = this.props;

    changeField('providerName', providerName);

    this.handleCloseModal('isOpenProviderModal');
  };

  handleSelectProvider = async providerId => {
    const { changeField } = this.props;
    try {
      const response = await getProvider(providerId);

      if (!response || !response.data || !response.data.success) {
        throw new Error('ㅇㅇ');
      }

      const { data } = response.data;
      const { id, providerName, mobile } = data;

      this.selectProviderId = id;

      changeField('providerName', providerName);
      changeField('providerTel', mobile);
      changeField('picName', data.picName);
      changeField('picMobile', data.picMobile);
      changeField('picEmail', data.picEmail);
    } catch (e) {
      //
    }
  };

  handleToggleModal = modalType => {
    this.setState({
      [modalType]: false
    });
  };

  handleOpenModal = modalType => {
    this.setState({
      [modalType]: true
    });
  };  

  handleChangeIgnoreStatusMessage = (e) => {
    this.setState({
      ignorStatusMessage: e.target.value
    });
  };

  handleChangeSelect = (e) => {
    this.setState({
      ignorStatusMessage: '',
      changeStatusProcess: e.target.value
    });
  };
  
  handleUpdateDiscoverStatus = async () => {
    const {
      changeStatusProcess,
      ignorStatusMessage,
      isUpdateStatusLoading,
      productId
    } = this.state;
    const IGNORE = 3;
    const numberChangeStatus = parseInt(changeStatusProcess, 10);
    const isIgnore = numberChangeStatus === IGNORE;

    if (isUpdateStatusLoading) {
      return;
    }

    if (isIgnore && ignorStatusMessage === '') {
      this.setState({
        isUpdateStatusLoading: false
      });

      alert('실패 사유를 입력해주세요.');

      return;
    }

    if (changeStatusProcess === '2' && !productId) {
      this.setState({
        isUpdateStatusLoading: false
      });

      alert('상품정보가 없습니다.');

      return;
    }

    try {
      const { discoverId } = this.state;

      const response = await putDiscoverStatus({
        changeStatusProcess,
        message: ignorStatusMessage || null,
        discoverId
      });

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `발굴 상태 업데이트 실패  - ${JSON.stringify(response)}`
        );
      }

      this.setState({
        isUpdateStatusLoading: false,
        contentText: `발굴상태를 업데이트 했습니다.`,
        isOpenSuccessModal: true
      });
    } catch (e) {
      console.error(e);
    }
  };

  initialize = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.getDiscover();
      },
    );
  };

  handleMovePage = () => {
    // const { history } = this.props;
    // const { id } = this.state;
    this.setState({
      isOpenSuccessModal : false
    })
    // history.push(`/discover/${id}/detail`);
    
    window.location.reload();
  };

  render() {
    const {
      id,
      isLoading,
      createDateKorStr,
      discovererName,
      discovererMobile,
      discovererEmail,
      storeId,
      nowStatusProcess,
      productName,
      productId,
      providerName,
      providerTel,
      providerId,
      tags,
      profileImgPath,
      productImgPathList,
      haveProductImages,
      recommendation,
      picName,
      picMobile,
      picEmail,
      productUrl,
      isOpenSuccessModal,
      contentText,
      isOpenProviderModal,
      ignorStatusMessage,
      changeStatusProcess,
      stateHistoryStatusProcess,
      createDate,
      updateDate,
      doneDate
    } = this.state;

    return (
      <Fragment>
        <DiscoverDetailForm
          id={id}
          isLoading={isLoading}
          createDateKorStr={createDateKorStr}
          discovererName={discovererName}
          discovererMobile={discovererMobile}
          discovererEmail={discovererEmail}
          storeId={storeId}
          nowStatusProcess={nowStatusProcess}
          productName={productName}
          providerName={providerName}
          providerTel={providerTel}
          providerId={providerId}
          tags={tags}
          profileImgPath={profileImgPath}
          productImgPathList={productImgPathList}
          haveProductImages={haveProductImages}
          onRemovePicture={this.handleRemovePicture}
          onOpenProviderModal={e => {
            this.handleOpenModal('isOpenProviderModal');

            e.target.blur();
          }}
          onSelectProductImage={this.handleAddProductImage}
          recommendation={recommendation}
          onSubmit={this.handleSubmit}
          onSendProviderSNS={this.handleSendProviderSNS}
          picName={picName}
          picMobile={picMobile}
          picEmail={picEmail}
          productUrl={productUrl}
          productId={productId}
        />
        <DiscoverStatusSelect
          // isUpdateLoading={isUpdateStatusLoading}
          ignoreMessage={ignorStatusMessage}
          changeStatusProcess={changeStatusProcess}
          nowStatusProcess={nowStatusProcess}
          historyStatusProcess={stateHistoryStatusProcess}
          createDate={createDate}
          updateDate={updateDate}
          doneDate={doneDate}
          onChange={this.handleChangeIgnoreStatusMessage}
          onChangeSelect={this.handleChangeSelect}
          onClick={this.handleUpdateDiscoverStatus}
        />
        <SearchProviderModal
          isOpen={isOpenProviderModal}
          onClickNew={this.handleChangeProvider}
          onSelect={this.handleSelectProvider}
          onToggle={() => {
            this.handleToggleModal('isOpenProviderModal');
          }}
        />
        <MessageModal
          isOpen={isOpenSuccessModal}
          contentText={contentText}
          buttonText="확인"
          onClick={() => {
            this.handleMovePage();
          }}
          onToggle={() => {
            this.handleMovePage();
          }}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeField: (field, value) => {
    dispatch(change('discoverDetailForm', field, value));
  }
});

DiscoverDetailContainer.propTypes = {
  changeField: PropTypes.func
  // history: PropTypes.arrayOf(PropTypes.shape({}))
};

DiscoverDetailContainer.defaultProps = {
  changeField: () => {}
  // history: []
};

export default connect(
  null,
  mapDispatchToProps,
)(DiscoverDetailContainer);
