import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Tabs.scss';

export default class Tabs extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ])  
  };

  static defaultProps = {
    className: '',
    children: null || []
  };

  constructor(props) {
    super(props);

    this.state = {
      width: 100
    };
  }

  componentDidMount = () => {
    this.handleChangeWidth();
  };

  handleChangeWidth = () => {
    const { children } = this.props;

    const tabCount = children.length;
    const width = 100 / tabCount;

    this.setState({
      width
    });
  };

  render() {
    const { children, className } = this.props;
    const { selectIndex, width } = this.state;

    const childrenWithProps = Children.map(children, (child, index) =>
      React.cloneElement(child, {
        index,
        selectIndex,
        width
      })
    );

    return (
      <ul className={classNames('tabs', className)}>{childrenWithProps}</ul>
    );
  }
}
