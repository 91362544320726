import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import { signInAdmin, changePasswordAdmin } from 'api/store';

const SIGNIN_ADMIN = 'user/SIGNIN_ADMIN';
const CHANGE_PASSWORD = 'user/CHANGE_PASSWORD';

export const signIn = createAction(SIGNIN_ADMIN, signInAdmin);
export const changePassword = createAction(CHANGE_PASSWORD, changePasswordAdmin);


const initialState = {
    isLoggedIn: false,
    user: {}
};

export default handleActions(
    {
        ...pender({
            type: SIGNIN_ADMIN,
            onSuccess: (state, action) => {
                if (!action.payload.err) {
                    return {
                        ...state,
                        user: action.payload.payload
                    };
                }
                
                return {
                    ...state,
                    user: null
                };
            },
            onFailure : (state, action) => {
                console.log(state);
                console.log(action);
            }
        }),
        ...pender({
            type: CHANGE_PASSWORD
        })
    },
    
    initialState
);
  