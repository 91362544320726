import AWS from 'aws-sdk';

// import sentry from 'lib/sentry'; 센트리 미설치

import cognitoConfig from './cognitoConfig';

export function s3Uploader(file, albumName) {
  const { region } = cognitoConfig;

  return new Promise((resolve, reject) => {
    try {
      AWS.config.update({
        region: cognitoConfig.region,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: cognitoConfig.IdentityPoolId
        })
      });

      AWS.config.credentials.get(() => {
        const {
          accessKeyId,
          secretAccessKey,
          sessionToken
        } = AWS.config.credentials;

        AWS.config.update({
          region: cognitoConfig.region,
          accessKeyId,
          secretAccessKey,
          sessionToken
        });

        if (file.extension === 'image' || file.extension === 'file') {
          const s3 = new AWS.S3({
            region,
            params: { Bucket: '1store.io.web.upload' }
          });

          const randomNumber = Math.floor(Math.random() * 100000);
          const randomNumbers = Math.random()
            .toString()
            .slice(2, randomNumber);

          const fileName = file.name.split('.');
          const fileType =
            fileName.length > 0 ? fileName[fileName.length - 1] : fileName;

          const changeFileName = `${new Date().getTime()}-${randomNumbers}.${fileType}`;
          const albumPhotosKey = `${encodeURIComponent(albumName)}/`;
          const photoKey = albumPhotosKey + changeFileName;

          s3.putObject(
            {
              Key: photoKey,
              Body: file,
              ACL: 'public-read',
              ContentType: file.type
            },
            err => {
              if (err) {
                // sentry.captureException(err); 센트리 미설치
                reject(err);

                return;
              }
              // console.log('Uploading to S3');
              const filePath = `https://s3.ap-northeast-2.amazonaws.com/1store.io.web.upload/${photoKey}`;
              const result = {
                width: file.width ? file.width : 0,
                height: file.height ? file.height : 0,
                path: filePath,
                key: file.key
              };
              // console.log({ result });
              resolve(result);
            }
          );
        } else {
          resolve(file);
        }
      });
    } catch (e) {
      reject(e);
    }
  });
}

export function s3Delete(file, isDelete) {
  const { region } = cognitoConfig;

  return new Promise((resolve, reject) => {
    if (!file) {
      resolve();

      return;
    }

    if (!isDelete) {
      resolve();

      return;
    }

    try {
      AWS.config.update({
        region: cognitoConfig.region,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: cognitoConfig.IdentityPoolId
        })
      });

      AWS.config.credentials.get(() => {
        const {
          accessKeyId,
          secretAccessKey,
          sessionToken
        } = AWS.config.credentials;

        AWS.config.update({
          region: cognitoConfig.region,
          accessKeyId,
          secretAccessKey,
          sessionToken
        });

        const s3 = new AWS.S3({
          region,
          params: { Bucket: '1store.io.web.upload' }
        });

        const renameFile = file.substring(61);

        s3.deleteObject(
          {
            Key: renameFile
          },
          err => {
            if (err) {
              reject(err);

              return;
            }

            resolve();
          }
        );
      });
    } catch (e) {
      reject(e);
    }
  });
}
