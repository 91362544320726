import React from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';

import RenderField from 'components/reduxForm/RenderField';
// import RenderFieldTextArea from 'components/reduxForm/RenderFieldTextArea';
import RenderFieldRadio from 'components/reduxForm/RenderFieldRadio';
import { mendatoryValidation } from 'lib/form/validation';

import './SalesLimitProductForm.scss';

function SalesLimitProductForm({
  handleSubmit,
  isSubmitLoading,
  onSubmit,
  flagSalesLimit
}) {
  return (
    <div className="stop-selling-product-form">
      <h1 className="font-weight-bold mt-4 mb-4">한정수량 관리</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group d-flex align-items-center flex-wrap mt-3">
          <span className="d-inline-block text-left pr-2 ">한정수량 설정</span>
          <br />
          <br />
          <div>
            <Field
              name="flagSalesLimit"
              id="salesLimit"
              component={RenderFieldRadio}
              value="1"
              type="radio"
              label="예"
            />
            <Field
              name="flagSalesLimit"
              id="noSalesLimit"
              component={RenderFieldRadio}
              value="0"
              type="radio"
              label="아니오"
            />
          </div>
        </div>
        <Field
          name="countOfSalesLimit"
          label="한정 수량"
          type="text"
          component={RenderField}
          placeholder="한정 수량"
          readOnly={flagSalesLimit.toString() === '0'}
          validate={
            flagSalesLimit.toString() === '0' ? [] : [mendatoryValidation]
          }
          pattern={/[^0-9.]/g}
        />
        <Field
          name="quantityOnHand"
          label="재고 수량"
          type="text"
          component={RenderField}
          placeholder="재고 수량(초기 값은 한정 수량과 같게 설정)"
          readOnly={flagSalesLimit.toString() === '0'}
          validate={
            flagSalesLimit.toString() === '0' ? [] : [mendatoryValidation]
          }
          pattern={/[^0-9.]/g}
        />

        <div>
          <img src="/images/sales_limit_ex.png" alt="한정수량 예시" />
        </div>
        <div className="product-regist-buttons">
          <button
            type="submit"
            className={`btn btn-primary ${isSubmitLoading ? 'disabled' : ''}`}
            isLoading={isSubmitLoading}
            disabled={isSubmitLoading}
          >
            수정
          </button>
        </div>
      </form>
    </div>
  );
}

SalesLimitProductForm.propTypes = {
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitLoading: PropTypes.bool,
  flagSalesLimit: PropTypes.string
};

SalesLimitProductForm.defaultProps = {
  onSubmit: () => {},
  handleSubmit: () => {},
  isSubmitLoading: false,
  flagSalesLimit: '0'
};

export default reduxForm({
  form: 'salesLimitProductForm',
  initialValues: {
    flagSalesLimit: '0' // string 형식으로 값을 초기화 해줘야 radio에서 초기값 설정 및 값 선택이 됨...
  }
})(SalesLimitProductForm);
