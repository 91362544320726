/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from 'react';
import { NavLink } from 'react-router-dom';

import './NavBar.scss';

function NavBar({ className = 'nav-bar', navLinks = [] }) {
  // console.log({ navLinks });

  return (
    <ul className={className}>
      {navLinks.map((link) => {
        return (
          <li className="nav-link" key={link.path}>
            <NavLink activeClassName="active" to={link.path}>
              <div className="nav-tab">
                <div>{link.label}</div>
                <div className="bottom-dot" />
              </div>
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
}

export default NavBar;
