// import axios from './defaultAxios';
import adminAxios from './adminAxios';

// 회원 상세 조회
// eslint-disable-next-line
export function getUserInfo(id) {
  return adminAxios.get(`/1stores/${id}`)
}

// 회원 상세 수정
// eslint-disable-next-line
export function updateUserInfo(id, params) {
  return adminAxios.put(`/1stores/${id}`, params)
}

// 회원 목록 조회
// eslint-disable-next-line
export function getUserList(query) {
  return adminAxios.get(`/1stores/search${query}`)
}