import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import './ProviderSearchForm.scss';

function ProviderSearchForm({
  onGetSearchData,
  keyword,
  onChangeKeyword,
  // onMoveProductList,
  // onMovePayment,
  providerList
}) {
  const search = e => {
    if (e.keyCode !== 13) {
      // not enter;

      return;
    }

    onGetSearchData();
  };

  const columns = [
    {
      dataField: 'providerName',
      text: '공급자 이름'
    },
    {
      dataField: 'id',
      text: '공급자 ID',
      formatter: (cell, row) => (
        <h5>
          <Link
            className="label label-danger"
            to={`/provider/${row.id}/detail`}
          >
            {row.id}
          </Link>
        </h5>
      )
    },
    {
      dataField: 'df3',
      isDummyField: true,
      text: '상품',
      headerStyle: () => ({
        width: '100px'
      }),
      formatter: (cell, row) => (
        <h5>
          <Link
            className="label label-danger"
            to={{
              pathname: '/product',
              state: { defaultProviderName: row.providerName }
            }}
          >
            상품목록
          </Link>
        </h5>
      )
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: '배송',
      headerStyle: () => ({
        width: '100px'
      }),
      formatter: (cell, row) => (
        <h5>
          <Link
            className="label label-danger"
            to={{
              pathname: '/delivery',
              state: { providerId: row.id }
            }}
          >
            배송목록
          </Link>
        </h5>
      )
    },
    {
      dataField: 'df2',
      isDummyField: true,
      text: '결제',
      headerStyle: () => ({
        width: '100px'
      }),
      formatter: () => (
        <h5>
          <span className="label label-danger"> 결제목록</span>
        </h5>
      )
    }
  ];

  return (
    <Fragment>
      <h4 className="h4">공급자관리</h4>
      <div className="input-group mb-3 col-sm-3">
        <input
          type="text"
          className="form-control"
          placeholder="공급자 찾기"
          value={keyword}
          onChange={onChangeKeyword}
          onKeyUp={search}
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={onGetSearchData}
          >
            검색
          </button>
        </div>
      </div>
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={providerList}
        columns={columns}
        pagination={paginationFactory()}
      />
    </Fragment>
  );
}

ProviderSearchForm.propTypes = {
  onGetSearchData: PropTypes.func,
  onChangeKeyword: PropTypes.func,
  keyword: PropTypes.string,
  providerList: PropTypes.arrayOf(PropTypes.shape({}))
};

ProviderSearchForm.defaultProps = {
  onGetSearchData: () => {},
  onChangeKeyword: () => {},
  keyword: '',
  providerList: []
};

export default ProviderSearchForm;
