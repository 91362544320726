import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import RenderField from '../reduxForm/RenderField';


const style = {
  width : '300px',
  margin : '0 auto'
}

class SendMailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = () => {
  };

  render() {
    const {onSubmit, 
      handleSubmit,
      sendMailmessage,
      pristine,
      submitting } = this.props;

    return (
      <div style={style}>
        <div style={{marginTop : '150px'}} className="p-3 border rounded">
          <p className="text-center h5 p-3"><strong>메일발송</strong></p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div>
                <Field
                  type="username"
                  name="username"
                  label="아이디"
                  className="col-12"
                  placeholder="아이디"
                  component={RenderField}
                />
              </div>
              <p className="pl-3 pt-1 pb-3" style={{color: 'red'}}><small>{sendMailmessage}</small></p>
              <div className="p-3">
                <button
                  type="submit"
                  className="btn btn-outline-secondary btn-block"
                  disabled={pristine || submitting}
                >
                  발송
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

SendMailForm.propTypes = {
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  sendMailmessage : PropTypes.string
};

SendMailForm.defaultProps = {
  onSubmit: () => {},
  handleSubmit: () => {},
  pristine: false,
  submitting: false,
  sendMailmessage : ''
};

export default reduxForm({ form: 'seindMailForm' })(SendMailForm)