import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * 공통 검색
 */
class Title extends Component {
  constructor() {
    super();

    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    const {title} = this.props;

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h4 className="h4">
                {title}
              </h4>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

Title.propTypes = {
    title : PropTypes.string
};

Title.defaultProps = {
    title : ''
};

export default Title;