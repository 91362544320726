
import * as AWSCognito from 'amazon-cognito-identity-js';
import authConfig from 'lib/aws/authConfig';

const poolData = {
    UserPoolId: authConfig.UserPoolId,
    ClientId: authConfig.ClientId
};
  
const userPool = new AWSCognito.CognitoUserPool(poolData);

let cognitoUser = null;

// 로그인
const cognitoSignIn = params =>
  new Promise((resolve) => {
    const { email, password } = params;
    const authenticationDetails = new AWSCognito.AuthenticationDetails({
      Username: email,
      Password: password
    });

    cognitoUser = new AWSCognito.CognitoUser({
      Username: email,
      Pool: userPool
    });
    
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
            const payload = result;
            payload.isLoggedIn = true;
            return resolve({ payload });
        },
        onFailure: err => {
            resolve({ payload: null, err });
        },
        mfaRequired: (challengeName, challengeParam) => {
            const user = {
                challengeName : '',
                challengeParam : {}
            };
            user.challengeName = challengeName;
            user.challengeParam = challengeParam;
            resolve(user);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
            const user = {
                challengeName : '',
                challengeParam : {}
            };
            user.challengeName = 'NEW_PASSWORD_REQUIRED';
            
            delete userAttributes.phone_number_verified // eslint-disable-line no-param-reassign
            delete userAttributes.email_verified // eslint-disable-line no-param-reassign

            user.challengeParam = {
                userAttributes ,
                requiredAttributes
            };
            resolve(user);
        }
    });
});

// cognito 비밀번호 변경
const cognitoChangePassword = (params, userAttributes) =>
  new Promise((resolve) => {
    const { newPassword } = params;

    // cognito 비밀번호 변경
    cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
        onSuccess: (result) => {
            const data = {
                isConfirm : true
            }
            console.log(`success : ${result}`)
            resolve(data);
        },
        onFailure: (err) => {
            const data = {
                isConfirm : false
            }
            console.log(`err : ${err}`)
            resolve(data);
        }
    });
});

// 로그인
/* eslint-disable import/prefer-default-export */
export function signInAdmin(params) {
    return cognitoSignIn(params);
}

// 비밀번호 변경
/* eslint-disable import/prefer-default-export */
export function changePasswordAdmin(params, userAttributes) {
    return cognitoChangePassword(params, userAttributes);
}