import React from 'react';
// import PropTypes from 'prop-types';

import classNames from 'classnames';

// redux-form 에 사용되는 input Component
function RenderFieldDefaultRadio(field) {
  return (
    <div className={classNames('form-group', field.className)}>
      <input
        id={field.id}
        {...field.input}
        type="radio"
        value={field.enum}
        checked={(field.enum).toString() === field.input.value ? 'checked' : ''}
      />&nbsp;&nbsp;
      <label className="form-radio-label" htmlFor={`${field.id}`}>
        {field.label}
      </label>
    </div>
      
  );
}

export default RenderFieldDefaultRadio;
