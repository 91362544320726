import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import SearchProviderModal from "components/modal/SearchProviderModal";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";

import { getProvider } from "api/provider";

/**
 * 공통 검색
 */
class SearchForm extends Component {
  constructor() {
    super();

    this.state = {
      id: "",
      provider: "",
      isOpenProviderModal: false
    };

    this.selectOptionIndex = 0;
    this.historyId = "";
    this.historyName = "";
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const { id } = prevState;
    const { resultId, resultName, searchParam } = this.props;

    // console.log(`${id} / ${resultId} / ${resultName}`);
    // 배송관리
    if (id === "" && resultId && resultName) {
      this.setProps(resultId, resultName);
    }

    if (searchParam) {
      const keys = Object.keys(searchParam);

      /* eslint-disable */
      for (const key of keys) {
        if (prevState[key] === undefined && searchParam[key]) {
          this.setProp(key, searchParam[key]);
        }
      }
    }
  }

  setProps = (resultId, resultName) => {
    const { initFunction } = this.props;
    const { id, keyword } = initFunction;

    this.setState({
      id: resultId || resultName,
      keyword: resultName
    });

    id(resultId);
    keyword(resultName);
  };

  setProp = (key, value) => {
    const { initFunction } = this.props;

    if (value) {
      this.setState({
        [key]: value
      });
      initFunction[key](value);
    }

    // initFunction[key](value);
  };

  drawSearch = values => {
    const { id, provider } = this.state;
    const { initFunction } = this.props;
    const result = values.map(item => {
      let object;
      if (item.type === "I") {
        object = (
          <div key={item.id} className="form-group px-1">
            <input
              className="form-control"
              type="text"
              defaultValue={this.state[item.fnName]}
              onChange={e => initFunction[item.fnName](e.target.value)}
            />
          </div>
        );
      }

      if (item.type === "S") {
        object = (
          <div key={item.id} className="form-group px-1">
            <select
              className="form-control"
              defaultValue={
                this.state[item.fnName]
                  ? this.state[item.fnName]
                  : item.data[0].id
              }
              onChange={e => {
                initFunction[item.fnName](e.target.value);
                this.selectOptionIndex = e.target.selectedIndex;
                if (initFunction["selectedOptionIndex"]) {
                  initFunction["selectedOptionIndex"](e.target.selectedIndex);
                }
                initFunction["keyword"]("");
                this.setState({
                  keyword: ""
                });
              }}
            >
              {item.data.map((option, i) => {
                const res = (
                  <option key={option.id} value={option.id}>
                    {option.text}
                  </option>
                );
                return res;
              })}
            </select>
          </div>
        );
      }

      if (item.type === "IM") {
        object = (
          <div key={item.id} className="form-group px-1">
            {this.selectOptionIndex !== item.openModalIndex ? (
              <input
                className="form-control"
                type="text"
                defaultValue={this.state[item.fnName]}
                onChange={e => {
                  initFunction[item.fnName](e.target.value);
                  this.setState({
                    keyword: e.target.value
                  });
                }}
              />
            ) : (
              <input
                type="text"
                className="form-control"
                placeholder="공급자 찾기"
                onClick={() => {
                  this.handleOpenModal("isOpenProviderModal");
                }}
                readOnly
                value={this.state.keyword}
                data-id={id}
              />
            )}
          </div>
        );
      }

      if (item.type === "M") {
        object = (
          <div key={item.id} className="form-group px-1">
            <input
              type="text"
              className="form-control"
              placeholder="공급자 찾기"
              onClick={() => {
                this.handleOpenModal("isOpenProviderModal");
              }}
              readOnly
              defaultValue={this.state.keyword}
              data-id={id}
            />
          </div>
        );
      }

      if (item.type === "D") {
        const temp = this.props;
        return (
          <div key={item.id} className="form-group px-1">
            {item.start ? (
              <>
                <DatePicker
                  selected={temp[item.start.fnName]}
                  selectsStart
                  startDate={temp[item.start.fnName]}
                  onChange={value => {
                    initFunction[item.start.fnName](value);
                  }}
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  // showTimeSelect
                  // timeFormat="HH:mm"
                  // timeIntervals={15}
                />
              </>
            ) : null}

            {item.start && item.end ? (
              <span className="ml-1 mr-1">~</span>
            ) : null}

            {item.end ? (
              <>
                <DatePicker
                  selected={temp[item.end.fnName]}
                  selectsEnd
                  endDate={temp[item.end.fnName]}
                  onChange={value => {
                    initFunction[item.end.fnName](value);
                  }}
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  // showTimeSelect
                  // timeFormat="HH:mm"
                  // timeIntervals={15}
                />
              </>
            ) : null}
          </div>
        );
      }

      return object;
    });

    return result;
  };

  /**
   * @param providerId
   *
   */
  handleSelectProvider = async providerId => {
    try {
      const { initFunction, onSubmit } = this.props;
      let resultId = "";
      let resultName = "";

      if (providerId) {
        const response = await getProvider(providerId);

        if (!response || !response.data || !response.data.success) {
          throw new Error(`error => handleSelectProvider`);
        }

        const { data } = response.data;
        resultId = data.id;
        resultName = data.providerName;
      }

      this.setState({
        id: resultId,
        keyword: resultName
      });

      const { id, keyword } = initFunction;

      id(resultId);
      keyword(resultName);

      onSubmit();
    } catch (error) {
      console.log(`error : ${error}`);
    }
  };

  handleOpenModal = modalType => {
    this.setState({
      [modalType]: true
    });
  };

  handleToggleModal = modalType => {
    this.setState({
      [modalType]: false
    });
  };

  render() {
    const { condition, onSubmit } = this.props;
    const { isOpenProviderModal } = this.state;
    const result = this.drawSearch(condition);

    return (
      <Fragment>
        <div className="pb-3">
          <form
            className="form-inline"
            onSubmit={e => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <div className="form-row">
              {result}
              {condition.length > 0 ? (
                <div className="form-group pl-1">
                  <button
                    type="button"
                    className="btn btn-info my-1"
                    onClick={onSubmit}
                  >
                    검색
                  </button>
                </div>
              ) : (
                <div className="form-group pl-3" />
              )}
            </div>
          </form>
        </div>
        <SearchProviderModal
          isOpen={isOpenProviderModal}
          onSelect={this.handleSelectProvider}
          onToggle={() => {
            this.handleToggleModal("isOpenProviderModal");
          }}
        />
      </Fragment>
    );
  }
}

SearchForm.propTypes = {
  condition: PropTypes.arrayOf(PropTypes.shape({})),
  initFunction: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onSubmit: PropTypes.func,
  resultId: PropTypes.string,
  resultName: PropTypes.string,
  searchParam: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

SearchForm.defaultProps = {
  condition: [],
  initFunction: null,
  onSubmit: () => {},
  resultId: "",
  resultName: "",
  searchParam: {}
};

export default SearchForm;
