import axios from './defaultAxios';
import adminAxios from './adminAxios';
import getLoginUserEmail from './getLoginUserEmail';
// const userInfo = storage.get('adminInfo');

// adminAxios.defaults.headers.common['admin-user-id'] = userInfo.idToken.payload['cognito:username'];

// 배송 상세 조회
// eslint-disable-next-line
export function getDeliveryDetail(giftId) {
  return adminAxios.get(`/gifts/${giftId}`);
}

// 배송 상세 조회
// eslint-disable-next-line
export function updateRecipient(deliveryId, type, params) {
  if (type === 'get') {
    return adminAxios[type](`/deliveries/${deliveryId}`);
  }

  if (type === 'put') {
    return adminAxios[type](`/deliveries/${deliveryId}`, params);
  }
}

// 운송장 정보 등록
// eslint-disable-next-line
export function updateDeliverer(providerId, deliveryId, params) {
  console.log({ params });
  // return adminAxios.put(`provider/${id}/delivery/${deliveryId}`, params)
  return axios.put(`/provider/${providerId}/delivery/${deliveryId}`, params);
}

// 배송 목록 조회
// eslint-disable-next-line
export function getDeliveryList(params) {
  return adminAxios.get(`/deliveries`, {
    params
  });
}

// 결제취소
// eslint-disable-next-line
export function cancelPayment(storeId, orderId, flagForceCancel) {
  console.log(orderId);
  return axios.post(`/store/${storeId}/payments/cancel`, {
    orderId,
    flagForceCancel
  });
}

// 배송정보 상태 변경
export function updateDeliveryStatus(params) {
  const userEmail = getLoginUserEmail();

  return adminAxios.put(`/deliveries/status`, {
    items: params,
    requestedBy: userEmail
  });
}

// 배송정보 상태 변경
export function updateReceiver(params) {
  const userEmail = getLoginUserEmail();

  return adminAxios.put(`/gift/${params.id}`, {
    items: params,
    requestedBy: userEmail
  });
}
