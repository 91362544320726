// import axios from './defaultAxios';
import adminAxios from './adminAxios';

// product 정보 가지고 옴
export function getDiscoverCount() {
  return adminAxios.get(`/dashboard/discovery`);
}

// 회원수,공급자수, 마지막 1S 번호
export function getUsersCount() {
  return adminAxios.get(`/dashboard/users`);
}

// 1S,1P,P,PP 카운트
export function getMainFeaturesCount() {
  return adminAxios.get(`/dashboard/main4`);
}
