import axios from 'axios';

// const developApi = 'https://akuotb3hfi.execute-api.ap-northeast-2.amazonaws.com/dev';
const developApi = 'https://admin-api.from.kr/dev';
const productApi = 'https://admin-api.from.kr/prod'; // 아직 없음

// axios.defaults.withCredentials = true;

const adminAxios = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? developApi : productApi,
  // withCredentials: true,

  headers: { 'x-api-key': 'GV1WwufoaI3orxnboQO3V23xffq9cslB05wYbv8c' }, // 키값
  responseType: 'json'
});

export default adminAxios;
