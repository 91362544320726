import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getCorrectOrientationFile } from 'lib/utils/imageUtils';

import AlertModal from 'components/modal/AlertModal';

import './PictureInput.scss';

class PictureInput extends Component {
  static getDerivedStateFromProps(props) {
    const { imageFile } = props;

    let imageSrc = '';

    if (!imageFile) {
      return { imageSrc };
    }

    if (typeof imageFile === 'string') {
      imageSrc = imageFile;
    }

    if (typeof imageFile === 'object' && imageFile.name) {
      imageSrc = imageFile.name;
    }

    // object
    return { imageSrc };
  }

  constructor() {
    super();

    this.state = {
      isOpenErrorModal: false,
      imageSrc: '',
      style: {
        backgroundImage: 'url("/images/default-add-image.png")'
      }
    };
  }

  componentDidMount() {
    const { imageFile } = this.props;

    if (!imageFile) {
      this.setImageSrc('/images/default-add-image.png');

      return;
    }

    if (typeof imageFile === 'string') {
      this.setImageSrc(imageFile);

      return;
    }

    this.setImageFile(imageFile);
  }

  componentDidUpdate(prevProps, prevState) {
    const { imageFile } = this.props;
    const { imageSrc } = this.state;
    const { imageSrc: prevImageSrc } = prevState;

    if (prevImageSrc === imageSrc) {
      return;
    }

    if (!imageFile) {
      this.setImageSrc('/images/default-add-image.png');

      return;
    }

    if (typeof imageFile === 'string') {
      this.setImageSrc(imageFile);

      return;
    }

    this.setImageFile(imageFile);
  }

  setImageSrc = imageSrc => {
    this.setState({
      style: {
        backgroundImage: `url("${imageSrc}")`
      }
    });
  };

  setImageFile = imageFile => {
    const { isShownImage } = this.props;

    return new Promise((resolve, reject) => {
      getCorrectOrientationFile(imageFile)
        .then(changedImage => {
          const reader = new FileReader();

          reader.onload = readerEvent => {
            const imageReader = new Image();

            imageReader.src = readerEvent.target.result;

            imageReader.onload = () => {
              const imageObject = {
                image: changedImage,
                width: imageReader.width,
                height: imageReader.height
              };

              if (!isShownImage) {
                resolve(imageObject);

                return;
              }

              this.setState(
                {
                  style: {
                    backgroundImage: `url('${readerEvent.target.result}')`
                  }
                },
                () => {
                  resolve(imageObject);
                },
              );
            };
          };

          reader.readAsDataURL(changedImage);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  changeImage = imageFile => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(imageFile);
    }
  };

  selectImage = imageFile => {
    const { onSelect } = this.props;

    if (onSelect) {
      onSelect(imageFile);
    }
  };

  handleCloseModal = () => {
    this.setState({
      isOpenErrorModal: false
    });
  };

  handleClickFileEl = () => {
    this.fileEl.click();
  };

  handleChangeImage = () => {
    const imageFile =
      this.fileEl && this.fileEl.files.length > 0 ? this.fileEl.files[0] : null;

    if (!imageFile) {
      return;
    }

    this.setImageFile(imageFile)
      .then(imageObject => {
        const { isShownImage } = this.props;

        if (!isShownImage) {
          this.selectImage(imageObject);

          return;
        }

        this.changeImage(imageObject);
      })
      .catch(() => {
        this.setState({
          isOpenErrorModal: true
        });
      });
  };

  render() {
    const { errorMessage, isOpenErrorModal, style } = this.state;
    const { className } = this.props;

    return (
      <Fragment>
        <div className={classNames('picture-input', className)}>
          <div className="picture-input-cover">
            <input
              type="file"
              accept="image/*"
              ref={fileEl => {
                this.fileEl = fileEl;
              }}
              onChange={this.handleChangeImage}
            />
            <div
              className="picture-input-image"
              style={style}
              onClick={this.handleClickFileEl}
              role="button"
              tabIndex="0"
              aria-hidden="true"
            />
          </div>
          <button type="button" onClick={this.handleClickFileEl}>
            <img
              src="/images/recommend-message-modify-icon.svg"
              alt="이미지 수정"
              className="picture-input-edit-btn"
            />
          </button>
        </div>
        <AlertModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          onClick={this.handleCloseModal}
          onToggle={this.handleCloseModal}
        />
      </Fragment>
    );
  }
}

PictureInput.propTypes = {
  imageFile: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  isShownImage: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  className: PropTypes.string
};

PictureInput.defaultProps = {
  imageFile: null || '',
  isShownImage: true,
  onChange: () => {},
  onSelect: () => {},
  className: ''
};
export default PictureInput;
