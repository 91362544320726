import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import PictureView from 'components/commons/PictureView';
import { getImagePath, getCorrectOrientationFile } from 'lib/utils/imageUtils';
import uuid from 'uuid';
import './RenderFile.scss';

const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

class RenderFile extends Component {
  constructor() {
    super();

    this.state = {
      saveFiles: []
    };
  }

  componentDidMount() {
    const { input } = this.props;
    const saveFiles = [];
    // console.log({ input });
    if (input.value && input.value.path) {
      const isArray = Array.isArray(input.value);
      let imageObject = {};

      if (!isArray) {
        imageObject = {
          key: uuid.v4(),
          image: input.value.path,
          file: input.value,
          /* eslint-disable */
          extension: input.value.extension
            ? input.value.extension
            : allowedExtensions.exec(input.value.path)
            ? 'image'
            : 'file'
        };

        saveFiles.push(imageObject);
      }

      if (isArray) {
        const files = input.value;

        /* eslint-disable */
        for (const file of files) {
          imageObject = {};
          imageObject = {
            key: uuid.v4(),
            image: file.path,
            file,
            extension: allowedExtensions.exec(file.path) ? 'image' : 'file'
          };

          saveFiles.push(imageObject);
        }
      }

      this.setFiles(saveFiles, isArray ? input.value : [input.value]);
    } else if (input.value === '') {
      this.setFiles(saveFiles, []);
    }
  }

  componentDidUpdate(prevProps) {
    const { input } = this.props;
    const saveFiles = [];
    // console.log({ input });
    if (
      (prevProps.input.value === '' && input.value !== '') ||
      (input.value && input.value.path)
    ) {
      const isArray = Array.isArray(input.value);
      let imageObject = {};

      if (!isArray) {
        imageObject = {
          key: uuid.v4(),
          image: input.value.path,
          file: input.value,
          /* eslint-disable */
          extension: input.value.extension
            ? input.value.extension
            : allowedExtensions.exec(input.value.path)
            ? 'image'
            : 'file'
        };

        saveFiles.push(imageObject);
      }

      if (isArray) {
        const files = input.value;

        /* eslint-disable */
        for (const file of files) {
          imageObject = {};
          imageObject = {
            key: uuid.v4(),
            image: file.path,
            file,
            extension: allowedExtensions.exec(file.path) ? 'image' : 'file'
          };

          saveFiles.push(imageObject);
        }
      }

      this.setFiles(saveFiles, isArray ? input.value : [input.value]);
    } else if (prevProps.input.value !== '' && input.value === '') {
      this.setFiles(saveFiles, []);
    }
  }

  setFiles = (saveFiles, files) => {
    const { input } = this.props;

    this.setState({
      saveFiles
    });

    input.onChange(files);
  };

  setImageFile = imageFile => {
    const obj = new Promise((resolve, reject) => {
      getCorrectOrientationFile(imageFile)
        .then(changedImage => {
          const reader = new FileReader();

          reader.onload = readerEvent => {
            const imageReader = new Image();

            imageReader.src = readerEvent.target.result;

            imageReader.onload = () => {
              imageFile.encoding = readerEvent.target.result;
              imageFile.width = imageReader.width;
              imageFile.height = imageReader.height;
              imageFile.extension = 'image';
              const imageObject = {
                key: uuid.v4(),
                image: readerEvent.target.result,
                width: imageReader.width,
                height: imageReader.height,
                file: imageFile,
                extension: 'image'
              };

              resolve(imageObject);
            };
          };

          reader.readAsDataURL(changedImage);
        })
        .catch(err => {
          reject(err);
        });
    });
    return obj;
  };

  getExtension = fileName => {
    const name =
      fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
      fileName;
    return name;
  };

  getImages = files => {
    const { saveFiles } = this.state;
    if (files) {
      /* eslint-disable */
      let newFiles = [];
      for (const file of files) {
        if (allowedExtensions.exec(file.name)) {
          this.setImageFile(file)
            .then(imageObject => {
              newFiles.push(imageObject);
              this.setState({
                saveFiles: newFiles
              });
            })
            .catch(() => {
              console.log(`image convert error!`);
            });
        } else {
          if (allowedExtensions.exec(file.path)) {
            file.extension = 'save';
            const imageObject = {
              key: uuid.v4(),
              image: file.path,
              width: file.width,
              height: file.height,
              file,
              extension: 'save'
            };

            newFiles.push(imageObject);
            this.setState({
              saveFiles: newFiles
            });
          } else {
            newFiles.push({
              key: uuid.v4(),
              extension: 'file',
              file
            });
          }
        }
      }

      this.setState({
        saveFiles: newFiles
      });
    }
  };

  onRemovePicture = key => {
    const { input } = this.props;
    const { saveFiles } = this.state;
    let filesToUpload = null;
    const fileFiles = saveFiles.filter(file => {
      if (key !== file.key) {
        if (filesToUpload === null) {
          filesToUpload = [];
        }
        filesToUpload.push(file.file);
        return file;
      }
    });

    if (fileFiles.length === 0) {
      input.onChange(null);
    } else {
      input.onChange(filesToUpload);
    }

    this.setState({
      saveFiles: fileFiles
    });
  };

  render() {
    const {
      input,
      accept,
      meta: { error, touched },
      label,
      classNameLabel,
      multiple
    } = this.props;
    const { saveFiles } = this.state;

    return (
      <Fragment>
        <div
          className={
            /* eslint-disable */
            `form-group col-12 ${
              touched ? (error ? 'is-danger' : 'is-success') : ''
            }`
          }
        >
          {label && <p className={classNameLabel || ''}>{label}</p>}
          <Dropzone
            onDrop={(filesToUpload, e) => {
              if (saveFiles.length > 0) {
                if (!multiple && saveFiles.length === 1) {
                  return;
                }

                saveFiles.map(obj => {
                  filesToUpload.push(obj.file);
                });
              }

              input.onChange(filesToUpload);
              this.getImages(filesToUpload);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="input-group">
                <div {...getRootProps()} className="custom-file">
                  <input
                    {...getInputProps({
                      accept:
                        accept === 'image'
                          ? 'image/jpeg, image/png, image/gif'
                          : '*'
                    })}
                    className={`custom-file-input ${
                      touched ? (error ? 'is-invalid' : '') : ''
                    }`}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="inputGroupFile04"
                  >
                    file select / drag & drop
                  </label>
                </div>
              </div>
            )}
          </Dropzone>
          {touched && error && (
            <div
              className="text-danger mt-1 ml-auto"
              style={{ fontSize: '12.8px' }}
            >
              {error}
            </div>
          )}
        </div>
        <div className="form-group col-12 image-list-form d-inline-block">
          {saveFiles.map((file, index) =>
            file.extension === 'image' || file.extension === 'save' ? (
              <PictureView
                key={file.key}
                imageSrc={file.image || getImagePath(file)}
                onClick={() => {
                  this.onRemovePicture(file.key);
                }}
              />
            ) : null
          )}
        </div>
        <div className="form-group col-12 image-list-form d-inline-block">
          {saveFiles.map((file, index) =>
            file.extension === 'file' ? (
              <p key={file.key}>
                {file.file.name}
                <span className="pl-1">
                  <button
                    type="button"
                    className="text-view-btn"
                    onClick={() => {
                      this.onRemovePicture(file.key);
                    }}
                  >
                    닫기
                  </button>
                </span>
              </p>
            ) : null
          )}
        </div>
      </Fragment>
    );
  }
}

RenderFile.propTypes = {
  dropzoneOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  meta: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string,
  classNameLabel: PropTypes.string,
  input: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  children: PropTypes.node,
  cbFunction: PropTypes.func
};

RenderFile.defaultProps = {
  dropzoneOptions: {},
  meta: {},
  label: '',
  classNameLabel: '',
  input: {},
  className: '',
  children: null,
  cbFunction: () => {}
};

export default RenderFile;
