import React, { Component, Fragment } from 'react';
import { withRouter, Link, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Tabs, Tab } from 'components/commons/Ui/Tabs';
import RegisterProductContainer from 'containers/product/RegisterProductContainer';
import StopSellingContainer from 'containers/product/StopSellingContainer';
import ProductHtmlEditContainer from 'containers/product/ProductHtmlEditContainer';
import SaleLimitConatiner from 'containers/product/SalesLimitContainer';
import OptionPriceListContainer from 'containers/product/OptionPriceListContainer';
import ProductDiscoverStatusContainer from 'containers/product/ProductDiscoverStatusContainer';
import './style.scss';

class ModifyProductContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productName: ''
    };
  }

  componentDidMount() {
    this.handleChangeTab();
  }

  componentDidUpdate = prevProps => {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;

    if (!location) {
      return;
    }

    if (location.pathname !== prevLocation.pathname) {
      this.handleChangeTab();
    }
  };

  handleChangeTab = () => {
    const { location } = this.props;
    let tabIndex = 0;

    if (!location || !location.pathname) {
      return;
    }

    const { pathname } = location;

    if (pathname.indexOf('stopselling') > -1) {
      tabIndex = 1;
    } else if (pathname.indexOf('saleslimit') > -1) {
      tabIndex = 3;
    } else if (pathname.indexOf('htmledit') > -1) {
      tabIndex = 2;
    } else if (pathname.indexOf('optionprice') > -1) {
      tabIndex = 4;
    } else if (pathname.indexOf('discoverstatus') > -1) {
      tabIndex = 5;
    }

    this.setState({
      tabIndex
    });
  };

  getProductName = value => {
    this.setState({
      productName: value
    });
  };

  render() {
    const { match } = this.props;
    const { productId } = match.params;
    const { tabIndex, productName } = this.state;

    return (
      <Fragment>
        <h1 className="font-weight-bold mt-4 mb-4">
          상품 수정{` - ${productName}`}
        </h1>
        <Tabs className="modify-product-tab">
          <Tab isSelected={tabIndex === 0}>
            <Link to={`/product/${productId}/register`}>
              <span className="bg-primary mr-1 dot-icon" />* 기본 정보
            </Link>
          </Tab>
          <Tab isSelected={tabIndex === 4}>
            <Link to={`/product/${productId}/optionprice`}>
              <span className="bg-warning mr-1 dot-icon" />* 옵션 & 가격
            </Link>
          </Tab>
          <Tab isSelected={tabIndex === 2}>
            <Link to={`/product/${productId}/htmledit`}>
              <span className="bg-warning mr-1 dot-icon" />
              상세정보(HTML)
            </Link>
          </Tab>
          <Tab isSelected={tabIndex === 1}>
            <Link to={`/product/${productId}/stopselling`}>
              <span className="bg-primary mr-1 dot-icon" />
              판매중단
            </Link>
          </Tab>
          <Tab isSelected={tabIndex === 3}>
            <Link to={`/product/${productId}/saleslimit`}>
              <span className="bg-warning mr-1 dot-icon" />
              한정판매
            </Link>
          </Tab>

          <Tab isSelected={tabIndex === 5}>
            <Link to={`/product/${productId}/discoverstatus`}>
              <span className="bg-warning mr-1 dot-icon" />
              발굴상태 & 발굴성공
            </Link>
          </Tab>
        </Tabs>
        <Route
          exact
          path={`/product/${productId}/register`}
          render={() => (
            <RegisterProductContainer
              productId={productId}
              productName={productName}
              onSendProductName={this.getProductName}
            />
          )}
        />
        <Route
          exact
          path={`/product/${productId}/stopselling`}
          render={() => (
            <StopSellingContainer
              productId={productId}
              onSendProductName={this.getProductName}
            />
          )}
        />
        <Route
          exact
          path={`/product/${productId}/htmledit`}
          render={() => (
            <ProductHtmlEditContainer
              productId={productId}
              onSendProductName={this.getProductName}
            />
          )}
        />
        <Route
          exact
          path={`/product/${productId}/saleslimit`}
          render={() => (
            <SaleLimitConatiner
              productId={productId}
              onSendProductName={this.getProductName}
            />
          )}
        />
        <Route
          exact
          path={`/product/${productId}/optionprice`}
          render={() => (
            <OptionPriceListContainer
              productId={productId}
              onSendProductName={this.getProductName}
            />
          )}
        />
        <Route
          exact
          path={`/product/${productId}/discoverstatus`}
          render={() => (
            <ProductDiscoverStatusContainer
              productId={productId}
              onSendProductName={this.getProductName}
            />
          )}
        />
      </Fragment>
    );
  }
}

ModifyProductContainer.propTypes = {
  match: PropTypes.arrayOf(PropTypes.shape({})),
  location: PropTypes.arrayOf(PropTypes.shape({}))
};

ModifyProductContainer.defaultProps = {
  match: [],
  location: []
};

export default withRouter(ModifyProductContainer);
