import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from 'components/modal/ModalDialog';
import './AlertModal.scss';

function AlertModal({ contentText, buttonText, isOpen, onToggle }) {
  return (
    <ModalDialog size="sm" isOpen={isOpen} onToggle={onToggle}>
      <p className="text-center text-primary mt-3 alert-modal-text">
        {contentText.split('\n').map(line => (
          <span key={line}>
            {line}
            <br />
          </span>
        ))}
      </p>
      <div className="text-center mt-3">
        <button type="button" className="btn btn-primary" onClick={onToggle}>
          {buttonText}
        </button>
      </div>
    </ModalDialog>
  );
}

AlertModal.propTypes = {
  contentText: PropTypes.string,
  buttonText: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func
};

AlertModal.defaultProps = {
  contentText: '',
  buttonText: '확인',
  isOpen: false,
  onToggle: () => {}
};

export default AlertModal;
