import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import { getDiscovers } from 'api/discover';

import DiscoverList from 'components/discover/DiscoverList';

class DiscoverListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      discoverList: [],
      isLoading: true
    };
  }

  componentDidMount() {
    this.initialize();

    this.pageCount = 100;
    this.prevKeyList = [];
    this.lastKey = null;
  }

  getDiscoverList = async () => {
    try {
      const { discoverList } = this.state;

      const response = await getDiscovers({
        pageCount: this.pageCount,
        lastKey: this.lastKey
      });

      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }

      const { data } = response.data;

      this.setState({
        discoverList: discoverList.concat(data.items),
        isLoading: !!data.lastKey
      });

      this.lastKey = data.lastKey;

      if (data.lastKey) {
        this.getDiscoverList();
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleRedirectDetailPage = discoverId => {
    window.location.href = `http://from.kr:3000/admin/discover/${discoverId}/register`;
  };

  handleMoveDiscoverRegister = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const { history } = this.props;
    history.push(`/discover/${id}/register`);
  };

  handleMoveDiscoverDetail = id => {
    const { history } = this.props;
    history.push(`/discover/${id}/detail`);
  };

  initialize = () => {
    const { location } = this.props;
    const { state } = location;

    if (state) {
      const { discoverStatus } = state;

      this.setState(
        {
          discoverStatus
        },
        () => {
          this.getDiscoverList();
        },
      );
    } else {
      this.getDiscoverList();
    }
  };

  render() {
    const { discoverList, discoverStatus, isLoading } = this.state;
    return (
      <DiscoverList
        discoverStatus={discoverStatus}
        discoverList={discoverList}
        onMoveDiscoverRegister={this.handleMoveDiscoverRegister}
        onMoveDiscoverDetail={this.handleMoveDiscoverDetail}
        onClickRow={this.handleRedirectDetailPage}
        isLoading={isLoading}
      />
    );
  }
}

DiscoverListContainer.propTypes = {
  history: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  location: PropTypes.arrayOf(PropTypes.shape({}))
};

DiscoverListContainer.defaultProps = {
  history: null,
  location: []
};

export default withRouter(DiscoverListContainer);
