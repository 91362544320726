import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import RenderField from '../reduxForm/RenderField';


const style = {
  width : '300px',
  margin : '0 auto'
}

class ResetForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
    };
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = () => {
  };

  render() {
    const {onSubmit, 
      handleSubmit,
      equalsMessage,
      verificationCodeMessage,
      newPasswordMessage,
      pristine,
      submitting} = this.props;

    return (
      <div style={style}>
        <div style={{marginTop : '150px'}} className="p-3 border rounded">
          <p className="text-center h5 p-3"><strong>아이디</strong></p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div>
                <Field
                  type="text"
                  name="verificationCode"
                  label="인증번호"
                  className="col-12"
                  component={RenderField}
                />
              </div>
              <p className="pl-3 pt-1 pb-3" style={{color: 'red'}}><small>{verificationCodeMessage}</small></p>
              <div>
                <Field
                  type="password"
                  name="newPassword"
                  label="비밀번호"
                  className="col-12"
                  component={RenderField}
                />
              </div>
              <p className="pl-3 pt-1 pb-3" style={{color: 'red'}}><small>{newPasswordMessage}</small></p>
              <div>
                <Field
                  type="password"
                  name="newPasswordRe"
                  label="비밀번호 확인"
                  className="col-12"
                  component={RenderField}
                />
              </div>
              <p className="pl-3 pt-1 pb-3" style={{color: 'red'}}><small>{equalsMessage}</small></p>
              <div className="p-3">
                <button
                  type="submit"
                  className="btn btn-outline-secondary btn-block"
                  disabled={pristine || submitting}
                >
                비밀번호변경
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ResetForm.propTypes = {
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  equalsMessage : PropTypes.string,
  verificationCodeMessage : PropTypes.string,
  newPasswordMessage : PropTypes.string,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool
};

ResetForm.defaultProps = {
  onSubmit: () => {},
  handleSubmit: () => {},
  equalsMessage : '',
  verificationCodeMessage : '',
  newPasswordMessage : '',
  pristine: false,
  submitting: false
};

export default reduxForm({ form: 'ResetForm' })(ResetForm)
