import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import ModalDialog from 'components/modal/ModalDialog';
import DeliveryForm from 'components/provider/DeliveryForm/DeliveryForm';
import { updateRecipient, updateDeliverer } from 'api/delivery';
import Delivery from 'lib/delivery/delivery';

class DeliveryRegisterContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      data: {}
    };

    this.values = null;
  }

  componentDidMount() {
    this.initialize();
  }

  /**
   * 초기화
   */
  initialize = async () => {
    const { location } = this.props;
    console.log({ location });
    const { state } = location;
    // eslint-disable-next-line no-unused-vars
    const { id, flag, receiver, recipient, flagDelivery, deliverer } = state;

    console.log({ receiver });
    console.log({ recipient });
    try {
      if (flag === 'edit') {
        // const result = await deliveryInfo(deliveryId, 'get');
        // const { data } = result;

        // if (data && data.success) {
        //   const {
        //     name,
        //     mobile,
        //     zipcode,
        //     addr,
        //     addrSub,
        //     reserveDate
        //   } = data.data;

        if (recipient && flagDelivery) {
          const {
            name,
            mobile,
            zipcode,
            addr,
            addrSub,
            deliveryMessage,
            reserveDate
          } = recipient;

          this.changeField('name', name);
          this.changeField('mobile', mobile);
          this.changeField('zipcode', zipcode);
          this.changeField('addr', addr);
          this.changeField('addrSub', addrSub);
          this.changeField('deliveryMessage', deliveryMessage);
          this.changeField('reserveDate', reserveDate);
        } else {
          this.changeField('name', receiver.name);
          this.changeField('mobile', receiver.mobile);
        }
      }

      if (flag !== 'edit' && deliverer) {
        this.changeField('deliveryCode', deliverer.id);
        this.changeField('invoiceNumber', deliverer.trackingNumber);
      }

      this.setState({
        id,
        isEdit: flag === 'edit'
      });
    } catch (error) {
      console.log(error);
    }
  };

  changeField = (field, value) => {
    const { changeField } = this.props;
    changeField(field, value);
  };

  handleSubmit = (values) => {
    this.values = values;

    this.setState({
      isOpen: true
    });
  };

  onSubmit = async () => {
    const { location } = this.props;
    const { state } = location;
    const { isEdit, id } = this.state;
    const params = this.values;
    console.log({ params });

    if (params) {
      delete params['object Object'];

      if (params.deliveryCode) {
        const deliverer = {
          id: params.deliveryCode,
          name: Delivery[params.deliveryCode],
          trackingNumber: params.invoiceNumber
        };
        // params.deliveryName = Delivery[params.deliveryCode];
        params.deliverer = deliverer;
        params.deliveryId = state.deliveryId;
      }

      params.id = state.deliveryId;

      try {
        let result = null;
        if (isEdit) {
          result = await updateRecipient(state.deliveryId, 'put', params);
        } else {
          result = await updateDeliverer(id, state.deliveryId, params);
        }

        if (result && result.status === 200) {
          this.historyBack();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false
    });
  };

  historyBack = () => {
    const { history } = this.props;

    history.push({
      pathname: `/delivery`
    });
  };

  render() {
    const { isOpen, data, isEdit } = this.state;

    return (
      <Fragment>
        <DeliveryForm
          data={data}
          isEdit={isEdit}
          onSubmit={this.handleSubmit}
          onChange={this.changeField}
          handleBack={this.historyBack}
        />
        <ModalDialog
          className="modal-control discover-register-modal"
          isOpen={isOpen}
          onToggle={this.handleCloseModal}
          size="md"
        >
          <p className="p-3 text-center">수정하시겠습니까?</p>
          <div className="text-center">
            <div>
              <button
                className="btn btn-primary p-2"
                type="button"
                onClick={this.onSubmit}
              >
                수정
              </button>
              &nbsp;
              <button
                className="btn btn-danger p-2"
                type="button"
                onClick={this.handleCloseModal}
              >
                취소
              </button>
            </div>
          </div>
        </ModalDialog>
      </Fragment>
    );
  }
}

DeliveryRegisterContainer.propTypes = {
  changeField: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

DeliveryRegisterContainer.defaultProps = {
  changeField: () => {},
  history: null
};

const mapDispatchToProps = (dispatch) => ({
  changeField: (field, value) => {
    dispatch(change('deliveryEditForm', field, value));
  }
});

export default compose(
  withRouter,
  connect(
    () => ({}),
    mapDispatchToProps
  )
)(DeliveryRegisterContainer);
