import React from 'react';

import './Loader.scss';

function Loader() {
  return (
    <div className="loader">
      <div className="loader-box">
        <img src="/images/1store-loader.gif" className="loader-spinner" alt="로딩 이미지" />
        <div className="loader-backdrop" />
      </div>
    </div>
  );
}

export default Loader;
