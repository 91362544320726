import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import ModalDialog from 'components/modal/ModalDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import SearchForm from 'components/commons/SearchForm/index';
// import { getFormatDate } from 'lib/time';
import './UserList.scss'
import Paging from 'components/commons/Paging/Index'
import { getFormatDate, fromNow, dayFromNow } from 'lib/time';

const options = [
  {
    id: 1,
    type: 'S', // select
    data: [
      { text: '이름', id: 'userName' },
      { text: '전화번호', id: 'mobile' },
      { text: 'email', id: 'email' }
    ],
    fnName: 'type'
  },
  {
    id: 2,
    type: 'I', // input
    fnName: 'keyword'
  }
];

class UserList extends Component {
  // 이벤트 정의
  initFunction = {
    type: (value) => {
      this.setInitValues('type', value);
    },
    keyword: (value) => {
      this.setInitValues('keyword', value);
    }
  }

  constructor() {
    super();

    this.state = {
      isOpen: false,
      type: 'userName'
      // startDate : new Date(),
      // endDate : new Date()
    };

    this.columns = [
      {
        dataField: 'id',
        text: 'ID',
        formatter: (cell, row) => {
          const { id } = row;
          let tempId = id;
          if (process.env.NODE_ENV === 'development') {
            tempId = 's155498931022884';
          }
          const button = (
            <div>
              <button
                type="button"
                onClick={() => this.handlerModify(tempId)}
                style={{ color: '#286ddc' }}
              >
                {row.id}
              </button>
            </div>)
          return button;
        },
        headerStyle: {
          width: '90px'
        }
      },
      {
        dataField: 'storeNo',
        text: '상점번호',
        headerStyle: {
          width: '60px'
        }
      },
      {
        dataField: 'userName',
        text: '이름',
        headerStyle: {
          width: '60px'
        }
      },
      {
        dataField: 'email',
        text: 'email',
        headerStyle: {
          width: '150px'
        }
      },
      {
        dataField: 'mobile',
        text: '휴대폰',
        headerStyle: {
          width: '80px'
        }
      },
      {
        dataField: 'zipcode',
        text: '우편번호',
        headerStyle: {
          width: '60px'
        }
      },
      {
        dataField: 'addr',
        text: '주소',
        headerStyle: {
          width: '70px'
        }
      },
      {
        dataField: 'addrSub',
        text: '상세주소',
        headerStyle: {
          width: '70px'
        }
      },
      {
        dataField: 'createDate',
        text: '가입일',
        formatter: (cell) => (
          <Fragment>
            <div className="mb-2">{getFormatDate(cell, 'YYYY.MM.DD')}</div>
            <div>{fromNow(cell)}</div>
            {dayFromNow(cell) <= 14 && <span className="h2"><span className="badge badge-new">NEW</span></span>}
          </Fragment>
        ),
        headerStyle: {
          width: '60px'
        }
      },
      {
        dataField: 'createType',
        text: '가입경로',
        headerStyle: {
          width: '70px'
        }
      },
      {
        dataField: 'kakaoId',
        text: '카카오계정',
        headerStyle: {
          width: '10px'
        }
      },
      {
        dataField: 'flagAgreeMarketing',
        text: '마케팅 동의',
        headerStyle: {
          width: '40px'
        }
      },
      {
        dataField: 'flagUnsubscribe',
        text: '수신거부',
        headerStyle: {
          width: '40px'
        }
      },
      {
        dataField: 'flagLeave',
        text: '탈퇴',
        headerStyle: {
          width: '40px'
        }
      }
    ];
  }

  componentDidMount() { }

  openPopup = () => {
    this.setState({
      isOpen: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false
    });
  };

  /**
   * 수정 페이지로 이동
   */
  handlerModify = id => {
    const { handleMovePage } = this.props;
    const { type, keyword } = this.state;
    const param = {};

    if (type) {
      param.type = type;
    }

    if (keyword) {
      param.keyword = keyword;
    }

    handleMovePage(id, param);
  };

  setInitValues = (column, value) => {
    this.setState({
      [column]: value
    });
  };

  onSelectCellButton = (row, isSelect, rowIndex, e) => {
    console.log(row);
    console.log(isSelect);
    console.log(rowIndex);
    console.log(e);
  };

  /**
   * 이전 페이지 이동
   */
  handlePrev = page => {
    this.onSearch(page);
  };

  /**
   * 다음 페이지 이동
   */
  handleNext = page => {
    this.onSearch(page);
  };

  /**
   * @param integer, integer
   */
  onSearch = page => {
    const { onSearch } = this.props;

    onSearch({
      page
    });
  };

  onSubmit = () => {
    const { onSearch } = this.props;
    const { type, keyword } = this.state;
    onSearch({ type, keyword });
  };

  sendMessage = (number) => {
    if (number) {
      alert(number);
    }
  }

  render() {
    const { gridList, page, isLast, searchParam } = this.props;
    const { isOpen } = this.state;
    const { ExportCSVButton } = CSVExport;

    return (
      <Fragment>
        <ToolkitProvider
          keyField="id"
          data={gridList}
          columns={this.columns}
          exportCSV={{
            fileName: `회원관리_.csv`
          }}
        >
          {props => (
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <h4 className="h4">회원관리</h4>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-8">
                  <SearchForm
                    condition={options}
                    initFunction={this.initFunction}
                    onSubmit={this.onSubmit}
                    searchParam={searchParam}
                  />
                </div>
                <div className="col-4 text-right gift-list-btn">
                  <ExportCSVButton {...props.csvProps}>
                    Export CSV <FontAwesomeIcon icon={faFileExcel} />
                  </ExportCSVButton>
                </div>
              </div>
              <div className="row">
                <div className="col" style={{ overflow: 'auto' }}>
                  <BootstrapTable
                    {...props.baseProps}
                    // selectRow={{
                    //   mode: 'radio',
                    //   clickToSelect: false,
                    //   onSelect: (row, isSelect, rowIndex, e) => {
                    //     this.onSelectCellButton(row, isSelect, rowIndex, e);
                    //   }
                    // }}
                    options={{ noDataText: '데이터가 없습니다.' }}
                    filter={filterFactory()}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col text-center">
                  <Paging
                    page={page}
                    isLast={isLast}
                    handlePrev={this.handlePrev}
                    handleNext={this.handleNext}
                  />
                </div>
              </div>
            </div>
          )}
        </ToolkitProvider>
        <ModalDialog
          className="modal-control discover-register-modal"
          isOpen={isOpen}
          onToggle={this.handleCloseModal}
          size="md"
        />
      </Fragment>
    );
  }
}

UserList.propTypes = {
  onSearch: PropTypes.func
};

UserList.defaultProps = {
  onSearch: () => { }
};

export default UserList;
