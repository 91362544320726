/**
 * @description 가격 절삭
 * @param {*} price 정가
 * @param {*} cut 절삭 단위
 */
export function priceCutting(price, cut) {
  let cuttingPrice = 0;
  cuttingPrice = Math.ceil(parseInt(price, 10) / cut);

  cuttingPrice *= cut;

  return cuttingPrice;
}

export function getCalcPriceObject(option) {
  const totalRate = 100;
  const calcPriceObject = { ...option };

  if (!option || !option.rate) {
    return calcPriceObject;
  }

  calcPriceObject.rate = option.rate;

  calcPriceObject.rate.provider =
    totalRate -
    parseFloat(option.rate.cnx || 0) -
    parseFloat(option.rate.discount || 0) -
    parseFloat(option.rate.seller || 0);

  calcPriceObject.rate.total =
    parseFloat(option.rate.cnx || 0) +
    parseFloat(option.rate.discount || 0) +
    parseFloat(option.rate.seller || 0) +
    parseFloat(option.rate.provider || 0);

  // cutting
  calcPriceObject.discountPrice = priceCutting(
    option.price * (option.rate.discount / 100),
    10
  );
  calcPriceObject.sellerPrice = priceCutting(
    option.price * (option.rate.seller / 100),
    10
  );
  calcPriceObject.cnxPrice = priceCutting(
    option.price * (option.rate.cnx / 100),
    10
  );
  calcPriceObject.discountedPrice =
    calcPriceObject.price - calcPriceObject.discountPrice;
  calcPriceObject.providerPrice =
    calcPriceObject.price -
    (calcPriceObject.discountPrice +
      calcPriceObject.sellerPrice +
      calcPriceObject.cnxPrice);

  // TODO
  // provider percent
  // total Percent
  // console.log(`calcPriceObject`);
  // console.log(calcPriceObject);

  return calcPriceObject;
}

// 인센티브 퍼신트 구하기
export function getIncentivePercent(incentiveStruct, incentive) {
  if (!incentiveStruct) {
    return 0;
  }

  let percent = parseFloat((100 * incentiveStruct[incentive]).toFixed(10));

  if (incentive === 'provider') {
    const withoutProvider =
      parseFloat(incentiveStruct.cnx) +
      parseFloat(incentiveStruct.seller) +
      parseFloat(incentiveStruct.discount);

    percent = parseFloat((100 * (1 - withoutProvider)).toFixed(10));
  }

  if (Number.isNaN(percent)) {
    percent = 0;
  }

  return percent;
}

// 인센티브 소수 구하기
export function getIncentivePoint(incentiveStruct, incentive) {
  if (!incentiveStruct) {
    return 0;
  }

  let point = parseFloat(
    (parseFloat(incentiveStruct[incentive]) / 100).toFixed(10)
  );

  if (incentive === 'provider') {
    const withoutProvider =
      parseFloat(incentiveStruct.cnx) +
      parseFloat(incentiveStruct.seller) +
      parseFloat(incentiveStruct.discount);

    point = parseFloat((parseFloat(100 - withoutProvider) / 100).toFixed(10));
  }

  if (Number.isNaN(point)) {
    point = 0;
  }

  return point;
}

export function getIncentivePercentObject(pointIncentiveStruct) {
  const incentivePercentObject = {
    cnx: getIncentivePercent(pointIncentiveStruct, 'cnx'),
    seller: getIncentivePercent(pointIncentiveStruct, 'seller'),
    provider: getIncentivePercent(pointIncentiveStruct, 'provider'),
    discount: getIncentivePercent(pointIncentiveStruct, 'discount')
  };

  return incentivePercentObject;
}

export function getIncentivePointObject(percentIncentiveStruct) {
  const incentivePointObject = {
    cnx: getIncentivePoint(percentIncentiveStruct, 'cnx'),
    seller: getIncentivePoint(percentIncentiveStruct, 'seller'),
    provider: getIncentivePoint(percentIncentiveStruct, 'provider'),
    discount: getIncentivePoint(percentIncentiveStruct, 'discount')
  };
  return incentivePointObject;
}
