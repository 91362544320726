import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalDialog from 'components/modal/ModalDialog';
import DaumPostcode from 'react-daum-postcode';

class AddressModal extends Component {
  constructor(props) {
    super(props);

    this.searchAddressEl = null;
  }

  shouldComponentUpdate(nextProps) {
    const { isOpen } = nextProps;
    const { isOpen: prevIsOpen } = this.props;

    return isOpen !== prevIsOpen;
  }

  componentDidUpdate() {
    const { isOpen } = this.props;

    if (isOpen) {
      // this.openSearchAddress();
    }
  }

  handleAddress = (data) => {
    const {onSelect} = this.props;
    let fullAddress = data.address;
    let extraAddress = ''; 
    
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }
 
    onSelect(fullAddress, data.zonecode);
  }

  render() {
    const { isOpen, onToggle } = this.props;

    return (
      <ModalDialog className="search-address" size="md" isOpen={isOpen} onToggle={onToggle}>
        <DaumPostcode
          onComplete={this.handleAddress}
        />
      </ModalDialog>
    );
  }
}

AddressModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  onSelect: PropTypes.func
};

AddressModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  onSelect: () => {}
};

export default AddressModal;
