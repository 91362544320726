import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './TrackingDeliveryView.scss';

function TrakingDeliveryView({
  errorMessage,
  hasDeliveryInfo,
  invoiceNo,
  isError,
  receiverName,
  receiverAddr,
  senderName,
  trackingDetails
}) {
  if (isError) {
    return <div className="tracking-text">{errorMessage}</div>;
  }

  if (!hasDeliveryInfo) {
    return <div className="tracking-text">배송 준비 중 입니다.</div>;
  }

  const hasTrakingDetais = trackingDetails.length > 0;

  return (
    <Fragment>
      <ul>
        <li>
          <span className="order-status-label d-inline-block pr-2">운송장번호</span>
          <span className="delivery-status-text">{invoiceNo}</span>
        </li>
        <li>
          <span className="order-status-label d-inline-block pr-2">받는 사람</span>
          <span className="delivery-status-text">{receiverName}</span>
        </li>
        <li>
          <span className="order-status-label d-inline-block pr-2">보낸 사람</span>
          <span className="delivery-status-text">{senderName}</span>
        </li>
        <li>
          <span className="order-status-label d-inline-block pr-2">수령 주소</span>
          <span className="delivery-status-text">{receiverAddr}</span>
        </li>
      </ul>
      {hasTrakingDetais && (
        <ul className="tracking-detail">
          <li className="tracking-detail-status header">
            <div className="time">처리일시</div>
            <div className="where">현재위치</div>
            <div className="kind">배송상태</div>
          </li>
          {trackingDetails.map((item, index) => {
            const { kind, time, timeString, where } = item;

            return (
              <li
                className={classNames('tracking-detail-status body', {
                  'last-detail': index === 0
                })}
                key={time}
              >
                <div className="time">{timeString}</div>
                <div className="where">{where}</div>
                <div className="kind">{kind}</div>
              </li>
            );
          })}
        </ul>
      )}
    </Fragment>
  );
}

TrakingDeliveryView.propTypes = {
  errorMessage: PropTypes.string,
  hasDeliveryInfo: PropTypes.bool,
  invoiceNo: PropTypes.string,
  isError: PropTypes.bool,
  receiverName: PropTypes.string,
  receiverAddr: PropTypes.string,
  senderName: PropTypes.string,
  trackingDetails: PropTypes.arrayOf(PropTypes.shape({}))
};

TrakingDeliveryView.defaultProps = {
  errorMessage: '',
  hasDeliveryInfo: false,
  invoiceNo: '',
  isError: false,
  receiverName: '',
  receiverAddr: '',
  senderName: '',
  trackingDetails: []
};

export default TrakingDeliveryView;
