/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Badge } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';

import PictureInput from 'components/commons/PictureInput';
import RenderField from 'components/reduxForm/RenderField';
import RenderFieldTextArea from 'components/reduxForm/RenderFieldTextArea';
import RenderFieldRadio from 'components/reduxForm/RenderFieldRadio';
// import RenderCheckbox from 'components/reduxForm/RenderCheckbox';

import { getImagePath } from 'lib/utils/imageUtils';
import { checkRequiredDeliveryFee, emptyValidation } from 'lib/form/validation';

import './RegisterProductForm.scss';

function RegisterProductForm({
  flagDelivery,
  handleSubmit,
  hasOfficialProductImage,
  isSubmitLoading,
  officialProductImage,
  onChangeOfficialProductImage,
  onOpenModal,
  onSubmit,
  productId,
  nowStatusProcess,
  hasAdditionalMessage,
  hasDeliveryMessage
}) {
  const STATUS_TEXT_LIST = ['발굴대기', '발굴심사', '발굴성공', '발굴실패'];
  const STATUS_ENG = ['warning', 'warning', 'success', 'danger'];

  return (
    <div className="register-product-form">
      <h1 className="font-weight-bold mt-4 mb-4">
        {`상품 정보 ${productId ? '수정' : '등록'}`}
        <Badge className="ml-3" color={STATUS_ENG[nowStatusProcess]}>
          {STATUS_TEXT_LIST[nowStatusProcess]}
        </Badge>
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field name="flagNew" type="hidden" component="input" />
        <Field name="id" type="hidden" component="input" />
        <Field
          component={RenderField}
          type="text"
          name="providerName"
          placeholder="공급자명"
          label="공급자명"
          className="mr-1"
          onFocus={e => {
            onOpenModal('isOpenProviderModal');
            e.target.blur();
          }}
          readOnly
        >
          <button
            type="button"
            className="product-search-btn form-button btn-primary"
            onClick={() => {
              onOpenModal('isOpenProviderModal');
            }}
          >
            공급자명검색
          </button>
        </Field>
        <Field
          component={RenderField}
          type="text"
          name="productName"
          placeholder="상품명"
          label="상품명"
          className="mr-1"
          onFocus={e => {
            onOpenModal('isOpenProductModal');
            e.target.blur();
          }}
          readOnly
        >
          <button
            type="button"
            className="product-search-btn form-button btn-primary"
            onClick={() => {
              onOpenModal('isOpenProductModal');
            }}
          >
            상품명검색
          </button>
        </Field>
        <div className="form-group d-flex align-items-center">
          <span className="form-label form-picture-label float-left text-left pr-3">
            공식상품이미지
          </span>
          <PictureInput
            imageFile={
              officialProductImage && officialProductImage.path
                ? getImagePath(officialProductImage)
                : officialProductImage
            }
            className={classNames({
              'border border-danger': !hasOfficialProductImage
            })}
            onChange={imageFile => {
              onChangeOfficialProductImage(imageFile);
            }}
          />
        </div>
        {!hasOfficialProductImage && (
          <span className="d-block invalid-feedback invalid-feedback-with-label">
            ▲ 필수 정보 입니다.
          </span>
        )}
        <Field
          name="tags"
          label="Tags"
          type="text"
          component={RenderField}
          placeholder="#태그1 #태그2"
        />
        <Field
          name="productDetailInfo.author"
          label="크리에이터"
          type="text"
          component={RenderField}
          placeholder="상품제작자"
        />
        <Field
          name="productDetailInfo.publishDate"
          label="생성일"
          type="date"
          placeholder="YYYY-MM-DD"
          component={RenderField}
        />
        <Field
          name="productDetailInfo.introduce"
          label="소개글"
          type="text"
          component={RenderFieldTextArea}
        />
        <div className="form-group d-flex align-items-center mt-3">
          <span className="form-label d-inline-block text-left pr-2 ">
            면세 상품
          </span>
          <Field
            name="flagTaxFree"
            id="yesTaxFree"
            type="radio"
            component={RenderFieldRadio}
            value="1"
            label="예"
          />
          <Field
            name="flagTaxFree"
            id="noTaxFree"
            type="radio"
            component={RenderFieldRadio}
            value="0"
            label="아니오"
          />
        </div>
        <div className="form-group d-flex align-items-center mt-3">
          <span className="form-label d-inline-block text-left pr-2 ">
            배송 상품
          </span>
          <Field
            name="flagDelivery"
            id="yesDelivery"
            component={RenderFieldRadio}
            value="1"
            type="radio"
            label="예"
          />
          <Field
            name="flagDelivery"
            id="noDelivery"
            component={RenderFieldRadio}
            value="0"
            type="radio"
            label="아니오"
          />
        </div>
        <div className="form-group d-flex align-items-center mt-3">
          <span className="form-label d-inline-block text-left pr-2 ">
            예약 상품
          </span>
          <Field
            name="flagReservation"
            id="yesReservation"
            component={RenderFieldRadio}
            value="true"
            type="radio"
            label="예"
          />
          <Field
            name="flagReservation"
            id="noReservation"
            component={RenderFieldRadio}
            value="false"
            type="radio"
            label="아니요"
          />
        </div>
        <div className="form-group d-flex align-items-center mt-3">
          <span className="form-label d-inline-block text-left pr-2 ">
            오늘 배송
          </span>
          <Field
            name="isSamedayDelivery"
            id="yesSamedayDelivery"
            component={RenderFieldRadio}
            value="true"
            type="radio"
            label="예"
          />
          <Field
            name="isSamedayDelivery"
            id="noSamedayDelivery"
            component={RenderFieldRadio}
            value="false"
            type="radio"
            label="아니요"
          />
        </div>
        <div className="form-group d-flex align-items-center mt-3">
          <span className="form-label d-inline-block text-left pr-2 ">
            이용권
          </span>
          <Field
            name="isTicket"
            id="yesTicket"
            component={RenderFieldRadio}
            value="true"
            type="radio"
            label="예"
          />
          <Field
            name="isTicket"
            id="noTicket"
            component={RenderFieldRadio}
            value="false"
            type="radio"
            label="아니오"
          />
        </div>
        <div className="form-group d-flex align-items-center mt-3">
          <span className="form-label d-inline-block text-left pr-2 ">
            성인용
          </span>
          <Field
            name="flagAdultAuth"
            id="yesAdultAuth"
            component={RenderFieldRadio}
            value="1"
            type="radio"
            label="예"
          />
          <Field
            name="flagAdultAuth"
            id="noAdultAuth"
            component={RenderFieldRadio}
            value="0"
            type="radio"
            label="아니요"
          />
        </div>
        <div className="form-group d-flex align-items-center mt-3">
          <span className="form-label d-inline-block text-left pr-2 ">
            프롬스픽
          </span>
          <Field
            name="flagBest"
            id="yesBest"
            component={RenderFieldRadio}
            value="true"
            type="radio"
            label="예"
          />
          <Field
            name="flagBest"
            id="noBest"
            component={RenderFieldRadio}
            value="false"
            type="radio"
            label="아니오"
          />
        </div>
        <div className="form-group d-flex align-items-center mt-3">
          <span className="form-label d-inline-block text-left pr-2 ">
            프롬마차
          </span>
          <Field
            name="isCarrige"
            id="yesCarrige"
            component={RenderFieldRadio}
            value="true"
            type="radio"
            label="예"
          />
          <Field
            name="isCarrige"
            id="noCarrige"
            component={RenderFieldRadio}
            value="false"
            type="radio"
            label="아니오"
          />
        </div>
        <div className="form-group d-flex align-items-center mt-3">
          <span className="form-label d-inline-block text-left pr-2 ">
            추가메시지
          </span>
          <Field
            name="hasAdditionalMessage"
            id="yesAdditionalMessage"
            component={RenderFieldRadio}
            value="true"
            type="radio"
            label="예"
          />
          <Field
            name="hasAdditionalMessage"
            id="noAdditionalMessage"
            component={RenderFieldRadio}
            value="false"
            type="radio"
            label="아니오"
          />
        </div>
        <Field
          name="additionalMessage"
          label="추가 메시지 내용"
          type="text"
          component={RenderFieldTextArea}
          placeholder="예) {{receiverName}}님 구매해 주셔서 감사합니다 "
          readOnly={hasAdditionalMessage === 'false'}
        />
        <div className="form-group d-flex align-items-center mt-3">
          <span className="form-label d-inline-block text-left pr-2 ">
            배송메시지
          </span>
          <Field
            name="hasDeliveryMessage"
            id="yesDeliveryMessage"
            component={RenderFieldRadio}
            value="true"
            type="radio"
            label="예"
          />
          <Field
            name="hasDeliveryMessage"
            id="noDeliveryMessage"
            component={RenderFieldRadio}
            value="false"
            type="radio"
            label="아니오"
          />
        </div>
        <Field
          name="deliveryMessage"
          label="배송 메시지 내용"
          type="text"
          component={RenderFieldTextArea}
          placeholder="예) {{receiverName}}님 오래 기다리셨죠. 배송이 시작되었습니다."
          readOnly={hasDeliveryMessage === 'false'}
        />
        <Field
          name="basicDeliveryFee"
          label="기본 배송료"
          type="text"
          component={RenderField}
          placeholder="기본 배송료"
          readOnly={!parseInt(flagDelivery, 10)} // parseInt 한 값이 1이면 배송 유, 0이면 배송 무
          validate={[checkRequiredDeliveryFee]}
          pattern={/[^0-9]/g}
        />
        <ul className="incentive-struc-form">
          <span>기본 인센티브 구조</span>
          <li>
            <span className="incentive-struc-form-label">구매 할인율</span>
            <Field
              className="mb-0"
              name="defaultRate.discount"
              type="text"
              component={RenderField}
              validate={[emptyValidation]}
              pattern={/[^0-9.]/g}
            />
            <span className="ml-1">%</span>
          </li>
          <li>
            <span className="incentive-struc-form-label">판매자 적립율</span>
            <Field
              className="mb-0"
              name="defaultRate.seller"
              type="text"
              component={RenderField}
              validate={[emptyValidation]}
              pattern={/[^0-9.]/g}
            />
            <span className="ml-1">%</span>
          </li>
          <li>
            <span className="incentive-struc-form-label">커넥서스 컴퍼니</span>
            <Field
              className="mb-0"
              name="defaultRate.cnx"
              type="text"
              component={RenderField}
              validate={[emptyValidation]}
              pattern={/[^0-9.]/g}
            />
            <span className="ml-1">%</span>
          </li>
        </ul>
        <div className="product-regist-buttons">
          <button
            type="submit"
            className={`btn btn-primary ${isSubmitLoading ? 'disabled' : ''}`}
            isLoading={isSubmitLoading}
            disabled={isSubmitLoading}
          >
            {`상품 정보 ${productId ? '수정' : '등록'}`}
          </button>
        </div>
      </form>
    </div>
  );
}

RegisterProductForm.propTypes = {
  nowStatusProcess: PropTypes.number,
  // defaultPrice: PropTypes.number,
  // defaultPriceList: PropTypes.arrayOf(PropTypes.number),
  flagDelivery: PropTypes.string,
  hasAdditionalMessage: PropTypes.string,
  hasDeliveryMessage: PropTypes.string,
  productId: PropTypes.string,
  hasOfficialProductImage: PropTypes.bool,
  isSubmitLoading: PropTypes.bool,
  officialProductImage: PropTypes.shape({}),
  // incentiveStruct: PropTypes.arrayOf(PropTypes.object),
  onChangeOfficialProductImage: PropTypes.func,
  onOpenModal: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func
};

RegisterProductForm.defaultProps = {
  nowStatusProcess: -1,
  // defaultPrice: "0",
  // defaultPriceList: [],
  flagDelivery: '1',
  hasAdditionalMessage: false,
  hasDeliveryMessage: false,
  productId: '',
  hasOfficialProductImage: false,
  isSubmitLoading: false,
  officialProductImage: null,
  // incentiveStruct: [],
  onChangeOfficialProductImage: () => {},
  onOpenModal: () => {},
  onSubmit: () => {},
  handleSubmit: () => {}
};

export default reduxForm({
  form: 'registerProductForm',
  initialValues: {
    basicDeliveryFee: '0',
    flagNew: true,
    flagDelivery: '1', // string 형식으로 값을 초기화 해줘야 radio에서 초기값 설정 및 값 선택이 됨...
    flagTaxFree: '0', // string 형식으로 값을 초기화 해줘야 radio에서 초기값 설정 및 값 선택이 됨...
    flagReservation: 'false', // string 형식으로 값을 초기화 해줘야 radio에서 초기값 설정 및 값 선택이 됨...
    isSamedayDelivery: 'false', // string 형식으로 값을 초기화 해줘야 radio에서 초기값 설정 및 값 선택이 됨...
    isTicket: 'false', // string 형식으로 값을 초기화 해줘야 radio에서 초기값 설정 및 값 선택이 됨...
    flagAdultAuth: '0', // string 형식으로 값을 초기화 해줘야 radio에서 초기값 설정 및 값 선택이 됨...
    flagBest: 'false', // string 형식으로 값을 초기화 해줘야 radio에서 초기값 설정 및 값 선택이 됨...
    hasAdditionalMessage: 'false', // string 형식으로 값을 초기화 해줘야 radio에서 초기값 설정 및 값 선택이 됨...
    hasDeliveryMessage: 'false',
    defaultRate: {
      cnx: '5',
      discount: '5',
      seller: '5',
      provider: '85'
    }
  }
})(RegisterProductForm);
