import React, { Component } from 'react';
import PropTypes from 'prop-types'

import sweetTracker from 'api/sweetTracker';

import TrakingDeliveryView from './TrakingDeliveryView';

export default class TrackingDelivery extends Component {
  // static propTypes = {
  //   prop: PropTypes
  // }

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      invoiceNo: '',
      isError: false,
      receiverName: '',
      receiverAddr: '',
      senderName: '',
      trackingDetails: []
    };
  }

  componentDidMount = async () => {
    const { deliveryCode, hasDeliveryInfo, invoiceNumber } = this.props;

    if (!hasDeliveryInfo) {
      return;
    }

    const response = await sweetTracker(deliveryCode, invoiceNumber);

    if (!response || !response.data || response.data.status === false) {
      let errorMessage = '';

      if (response && response.data) {
        errorMessage = response.data.msg;
      }

      this.setState({
        errorMessage:
          errorMessage ||
          '오류가 발생하여 배송지 정보를 불러오지 못하였습니다.',
        isError: true
      });

      return;
    }

    const { data } = response;
    const {
      invoiceNo,
      receiverName,
      receiverAddr,
      senderName,
      trackingDetails
    } = data;

    this.setState({
      invoiceNo,
      receiverName,
      receiverAddr,
      senderName,
      trackingDetails: trackingDetails.reverse()
    });
  };

  render() {
    const { hasDeliveryInfo } = this.props;
    const {
      errorMessage,
      invoiceNo,
      isError,
      receiverName,
      receiverAddr,
      senderName,
      trackingDetails
    } = this.state;

    return (
      <TrakingDeliveryView
        errorMessage={errorMessage}
        hasDeliveryInfo={hasDeliveryInfo}
        invoiceNo={invoiceNo}
        isError={isError}
        receiverName={receiverName}
        receiverAddr={receiverAddr}
        senderName={senderName}
        trackingDetails={trackingDetails}
      />
    );
  }
}

TrackingDelivery.propTypes = {
  hasDeliveryInfo: PropTypes.bool
};

TrackingDelivery.defaultProps = {
  hasDeliveryInfo: false
};
