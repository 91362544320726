import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'redux-form';

import RenderField from 'components/reduxForm/RenderField';
import RenderFieldRadio from 'components/reduxForm/RenderFieldRadio';
import RenderSelect from 'components/reduxForm/RenderSelect';
import RenderFile from 'components/reduxForm/RenderFile';

import {
  reduxRequired,
  numericValidation,
  mendatoryValidation,
  emptyValidation
} from 'lib/form/validation';
import { getPriceFormat } from 'lib/utils/numberUtils';
import { getCalcPriceObject } from 'lib/utils/priceUtils';

import './IncentiveStructField.scss';
import './RenderPriceListField.scss';
import './RenderOptionPriceListField.scss';

function RenderOptionPriceListField({
  fields,
  onChangePrice,
  defaultRate,
  onSwapIndex,
  onClickDeleteOption
}) {
  let lastIndex = -1;

  return (
    <div className="form-group">
      <span className="d-inline-block text-left pr-2 mb-3">상품옵션</span>
      <ul className="render-option-pricelist-field">
        {fields.map((priceOption, index) => {
          const calcPriceObject = getCalcPriceObject(fields.get(index));
          const priceOptionId = fields.get(index).id;
          const isToggleShowRate = fields.get(index).isShowRate;
          const previousNo = fields.get(index).seqNo;
          lastIndex = index;

          return (
            <Fragment>
              <li key={priceOptionId || `new_${index}`}>
                <span>
                  옵션{index + 1} - {priceOptionId}
                </span>
                {index >= 0 && (
                  <button
                    type="button"
                    className="product-regist-price-button remove"
                    // onClick={() => {
                    //   fields.remove(index);
                    // }}
                    onClick={() => {
                      onClickDeleteOption(fields, index);
                    }}
                  >
                    -
                  </button>
                )}
                <Field
                  component={RenderSelect}
                  type="text"
                  name={`${priceOption}.seqNo`}
                  label="옵션 순서"
                  className="mt-3 mr-1"
                  validate={emptyValidation}
                  onChange={e => {
                    onSwapIndex(e, previousNo);
                  }}
                >
                  {fields.map((field, changeIndex) => (
                    <option key={field.value} value={changeIndex}>
                      {changeIndex + 1}
                    </option>
                  ))}
                </Field>
                <Field
                  component={RenderField}
                  type="text"
                  label="옵션 이름"
                  name={`${priceOption}.name`}
                  validate={[reduxRequired, mendatoryValidation]}
                  placeholder="종류"
                  className="mt-3 mr-1"
                />
                <Field
                  name={`${priceOption}.imagePath`}
                  label="옵션 이미지 (미 입력시 공식 상품이미지가 사용됩니다)"
                  component={RenderFile}
                  accept="*"
                  multiple={false}
                  className="mt-3 mr-1"
                />
                <div className="form-group d-flex align-items-center mt-3">
                  <span className="form-label d-inline-block text-left pr-2 ">
                    공개함
                  </span>
                  <Field
                    name={`${priceOption}.isOnDisplay`}
                    id={`yesOnDisplay${index}`}
                    component={RenderFieldRadio}
                    value="true"
                    type="radio"
                    label="예"
                  />
                  <Field
                    name={`${priceOption}.isOnDisplay`}
                    id={`noOnDisplay${index}`}
                    component={RenderFieldRadio}
                    value="false"
                    type="radio"
                    label="아니오"
                  />
                </div>
                <div className="form-group d-flex align-items-center mt-3">
                  <span className="form-label d-inline-block text-left pr-2 ">
                    재고있음
                  </span>
                  <Field
                    name={`${priceOption}.isInStock`}
                    id={`yesInStock${index}`}
                    component={RenderFieldRadio}
                    value="true"
                    type="radio"
                    label="예"
                  />
                  <Field
                    name={`${priceOption}.isInStock`}
                    id={`noInStock${index}`}
                    component={RenderFieldRadio}
                    value="false"
                    type="radio"
                    label="아니오"
                  />
                </div>
                <div className="incentive-price-form">
                  <div className="incentive-price-field-set">
                    <Field
                      component={RenderField}
                      type="text"
                      name={`${priceOption}.price`}
                      onBlur={onChangePrice}
                      label="판매 가격"
                      placeholder="가격"
                      validate={[reduxRequired, numericValidation]}
                      className="mb-0"
                      pattern={/[^0-9]/g}
                    />
                    <span className="ml-1">원</span>
                    <Field
                      className={classNames(
                        'product-regist-price-button toggle'
                      )}
                      name={`${priceOption}.isShowRate`}
                      component="input"
                      type="checkBox"
                    />
                  </div>
                  {isToggleShowRate && (
                    <div className="rate-group">
                      <div className="incentive-price-field-set">
                        {/* <span className="incentive-struc-form-label">구매 할인율</span> */}
                        <Field
                          className="mb-0"
                          name={`${priceOption}.rate.discount`}
                          type="text"
                          component={RenderField}
                          validate={[mendatoryValidation]}
                          pattern={/[^0-9.]/g}
                          label="구매 할인율"
                        />
                        <span className="ml-1">%</span>
                        <span className="incentive-struc-form-preview">
                          {`${getPriceFormat(
                            Math.floor(calcPriceObject.discountPrice)
                          )}원`}
                        </span>
                      </div>
                      <div className="incentive-price-field-set">
                        {/* <span className="incentive-struc-form-label">판매자 적립율</span> */}
                        <Field
                          className="mb-0"
                          name={`${priceOption}.rate.seller`}
                          type="text"
                          component={RenderField}
                          validate={[mendatoryValidation]}
                          pattern={/[^0-9.]/g}
                          label="소개자 적립율"
                        />
                        <span className="ml-1">%</span>
                        <span className="incentive-struc-form-preview">
                          {`${getPriceFormat(
                            Math.floor(calcPriceObject.sellerPrice)
                          )}원`}
                        </span>
                      </div>
                      <div className="incentive-price-field-set">
                        {/* <span className="incentive-struc-form-label">커넥서스 컴퍼니</span> */}
                        <Field
                          className="mb-0"
                          name={`${priceOption}.rate.cnx`}
                          type="text"
                          component={RenderField}
                          validate={[mendatoryValidation]}
                          pattern={/[^0-9.]/g}
                          label="프롬"
                        />
                        <span className="ml-1">%</span>
                        <span className="incentive-struc-form-preview">
                          {`${getPriceFormat(
                            Math.floor(calcPriceObject.cnxPrice)
                          )}원`}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="incentive-price-field-set">
                    <span className="incentive-struc-form-label">판매가</span>
                    <span className="form-incentive-input form-incentive-provider mb-0">
                      {`${
                        Number.isNaN(calcPriceObject.rate.discount)
                          ? 0
                          : (100 - calcPriceObject.rate.discount).toFixed(2)
                      }`}
                    </span>
                    <span className="ml-1">%</span>
                    <span className="incentive-struc-form-preview">
                      {`${getPriceFormat(
                        Math.floor(calcPriceObject.discountedPrice)
                      )}원`}
                    </span>
                  </div>
                  <div className="incentive-price-field-set">
                    <span className="incentive-struc-form-label">공급율</span>
                    <span className="form-incentive-input form-incentive-provider mb-0">
                      {`${
                        Number.isNaN(calcPriceObject.rate.provider)
                          ? 0
                          : calcPriceObject.rate.provider.toFixed(2)
                      }`}
                    </span>
                    <span className="ml-1">%</span>
                    <span className="incentive-struc-form-preview">
                      {`${getPriceFormat(
                        Math.floor(calcPriceObject.providerPrice)
                      )}원`}
                    </span>
                  </div>
                  <div className="incentive-price-field-set">
                    <span className="incentive-struc-form-label">전체</span>
                    <span className="form-incentive-input form-incentive mb-0">
                      {`${
                        Number.isNaN(calcPriceObject.rate.total)
                          ? 0
                          : calcPriceObject.rate.total.toFixed(2)
                      }`}
                    </span>
                    <span className="ml-1">%</span>
                  </div>
                </div>
              </li>
            </Fragment>
          );
        })}
      </ul>
      <button
        type="button"
        className="product-regist-price-button btn btn-primary add"
        onClick={() => {
          fields.push({
            name: '',
            price: '',
            seqNo: lastIndex + 1,
            rate: defaultRate,
            isOnDisplay: 'true',
            isInStock: 'true'
          });
        }}
      >
        상품 옵션 추가 +
      </button>
    </div>
  );
}

RenderOptionPriceListField.propTypes = {
  onChangePrice: PropTypes.func,
  onSwapIndex: PropTypes.func,
  onClickDeleteOption: PropTypes.func,
  fields: PropTypes.arrayOf(PropTypes.object),
  // options: PropTypes.arrayOf(PropTypes.object),
  defaultRate: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

RenderOptionPriceListField.defaultProps = {
  onChangePrice: () => {},
  onSwapIndex: () => {},
  onClickDeleteOption: () => {},
  fields: null,
  // options: null,
  defaultRate: {}
};

export default RenderOptionPriceListField;
