// mapFn함수로 새로운 object생성
export function objectMap(obj, mapFn) {
  return Object.keys(obj).reduce((result, key) => {
    result[key] = mapFn(obj[key]);
    return result;
  }, {});
}

export function isEmpty(value) {
  if (
    value === "" ||
    value === null ||
    value === undefined ||
    (value !== null && typeof value === "object" && !Object.keys(value).length)
  ) {
    return true;
  }
  return false;
}

/**
 * 'a.b.c' 와 같이 path string으로 nested object field 값 가져옴 
 * @param {*} obj 
 * @param {*} path 
 * @param {*} defaultValue 
 */
export function resolvePath(obj, path, defaultValue) {
  return path
      .split('.')
      .reduce((o, p) => o ? o[p] : defaultValue, obj);
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}