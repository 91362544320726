/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { createSegment } from 'api/marketing';
import {
  Select,
  Input,
  SubmitButton
} from '../../components/shared/components';
import {
  StyledForm,
  StyledFormTitle,
  FormBodyWrapper,
  FormButtonWrapper
} from '../../components/shared/styled/componentStyle';
import {
  nameRequired,
  stringRequired
} from '../../components/rules/validationRules';

const schema = yup.object().shape({
  channelType: stringRequired,
  messageType: stringRequired,
  directory: nameRequired
});

const defaultValues = { channelType: '', messageType: '', directory: 'test' };

function SegmentForm() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    control,
    errors,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues
  });

  const onSubmit = async (values) => {
    // console.log({ values });
    setIsSubmitting(true);

    // setTimeout(() => {
    //   alert(JSON.stringify(values, null, 2));
    //   reset(defaultValues);
    //   setIsSubmitting(false);
    // }, 2000);

    const { data } = await createSegment(values);
    // console.log({ data });
    const message =
      data && data.success
        ? `세그멘트 주소: ${data.data.url}`
        : '세그멘트 생성에 실패했습니다!';
    alert(message);
    reset(defaultValues);
    setIsSubmitting(false);
  };
  // console.log({ errors });
  // console.log({ isSubmitting });

  return (
    <Fragment>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledFormTitle>핀포인트를 위한 세그멘트 생성</StyledFormTitle>
        <FormBodyWrapper>
          <Select
            name="channelType"
            label="채널 유형: "
            options={{ '': '선택', EMAIL: '이메일', SMS: '문자' }}
            {...{ register, errors }}
          />
          <Select
            name="messageType"
            label="메시지 유형: "
            options={{ '': '선택', ADS: '마케팅/광고', INFO: '공지/정보' }}
            {...{ register, errors }}
          />

          <Input
            type="text"
            name="directory"
            label="디렉토리: "
            placeholder="S3 디렉토리 이름을 입력해주세요."
            {...{ register, errors }}
          />
        </FormBodyWrapper>
        <FormButtonWrapper>
          <SubmitButton isSubmitting={isSubmitting}>세그멘트 생성</SubmitButton>
        </FormButtonWrapper>
      </StyledForm>
    </Fragment>
  );
}
export default withRouter(SegmentForm);
