import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import OrderList from 'components/order/OrderList/OrderList'
// 임시 데이터
import { getProviderDeliveryList } from 'api/provider';

class OrderContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: 'organicmedia',
      gridList : []
    };

    this.pageCount = 20;
    this.lastKey = null;
  }

  componentDidMount() {
    this.initialize();
  }

  /**
   * 초기화
   */
  initialize = () => {
    this.handleGetGridList();
  };

  handleGetGridList = async () => {
    const { keyword, gridList } = this.state;

    try {
      const response = await getProviderDeliveryList({
        pageCount: this.pageCount,
        providerId: keyword,
        lastKey: this.lastKey
      });

      console.log(response);
      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }

      const { data } = response.data;

      this.setState({
        gridList: gridList.concat(data.items)
      });

      // this.lastKey = data.lastKey;

      // if (data.lastKey) {
      //   this.handleGetProviderDeliveryList();
      // }
    } catch (e) {
      console.error(e);
    }
  };

  // 쿼리 조회
  onSearch = (params) => {
    console.log(params);
  }

  render() {
    const {gridList} = this.state;

    return (
      <Fragment>  
        <OrderList gridList={gridList} onSearch={this.onSearch} />
      </Fragment>
    );
  }
}

export default withRouter(OrderContainer);
