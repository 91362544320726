import axios from './defaultAxios';
import adminAxios from './adminAxios';

export function getProvider(providerId) {
  return axios.get(`/provider/${providerId}`);
}

export function getSearchProvider(keyword) {
  return axios.get('/provider', {
    params: {
      keyword
    }
  });
}

export function getProviderProducts(params) {
  return axios.get(`/provider/${params.providerId}/products`, {
    params
  });
}

export function getProviderDeliveryList(params) {
  return axios.get(`/provider/${params.providerId}/delivery`, {
    params
  });
}

export function getProviderSalesList(params) {
  return axios.get(`/provider/${params.providerId}/sales`, {
    params
  });
}

// 공급자별 & 기간별 정산리스트
export function getProviderDateSalesList(params) {
  return adminAxios.get(`/provider/${params.providerId}/sales`, {
    params
  });
}

export function getProviderDetail(id) {
  return adminAxios.get(`/provider/${id}`);
}

export function updateProviderDetail(id, param) {
  return adminAxios.put(`/provider/${id}`, param);
}