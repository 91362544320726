/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import styled, { css } from 'styled-components';
// import ReactDatePicker from 'react-datepicker';

const defaultControlStyle = css`
  grid-area: input-control;
  box-sizing: border-box;
  padding: 5px;
  width: 90%;
  border-radius: 4px;
  border: 1px solid darkgray;
  font-size: 14px;

  &:focus {
    border: 2px solid black;
  }

  &:disabled {
    opacity: 0.4;
  }
`;

export const StyledForm = styled.form`
  display: grid;
  max-width: 500px;
  grid-template-columns: 2fr 8fr 2fr;
  grid-template-rows: repeat(3, auto);
  column-gap: 0px;
  row-gap: 5px;
  align-items: center;
  justify-items: center;
  margin: 30px auto;

  grid-template-areas:
    ' . form-title . '
    'form-body form-body form-body'
    ' . form-button . ';
`;

export const StyledFormTitle = styled.div`
  grid-area: form-title;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

export const FormBodyWrapper = styled.div`
  grid-area: form-body;
  width: 100%;
`;

export const FormButtonWrapper = styled.div`
  grid-area: form-button;
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const FormControlGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: repeat(2, auto);
  column-gap: 0px;
  row-gap: 5px;
  align-items: center;
  justify-items: start;
  margin: 20px auto;

  grid-template-areas:
    'input-label input-control'
    '.           error-message';

  .react-datepicker-wrapper {
    grid-area: input-control;
    display: block;
    width: 100%;
    .react-datepicker__input-container input {
      ${defaultControlStyle}
      grid-area: none;
    }
  }
`;

export const StyledLabel = styled.label`
  grid-area: input-label;
  display: block;
  width: 90%;
  text-align: right;
  padding-right: 10px;
  color: darkgrey;
  font-size: 14px;
`;

export const StyledError = styled.div`
  grid-area: error-message;

  color: #bf1650;
  font-size: 10px;
  margin-left: 5px;

  &::before {
    display: inline;
    content: '⚠ ';
  }
`;

export const StyledInput = styled.input`
  ${defaultControlStyle}
`;

export const StyledTextArea = styled.textarea`
  ${defaultControlStyle}
`;

export const StyledSelect = styled.select`
  ${defaultControlStyle}
`;

export const ControlWrapper = styled.div`
  grid-area: input-control;
  box-sizing: border-box;
  width: 90%;
`;

// const StyledDatePicker = styled(ReactDatePicker)`
//   .react-datepicker-wrapper .react-datepicker__input-container input {
//     ${defaultControlStyle}
//   }
// `;

export const StyledButton = styled.button`
  display: block;
  background: ${({ theme }) => theme.colors.from};
  color: white;
  border: none;
  min-width: 150px;
  padding: 10px 20px;
  border-radius: 4px;
  margin: 10px auto;
  font-size: 16px;
  letter-spacing: 5px;

  &:hover {
    background: white;
    color: ${({ theme }) => theme.colors.from};
    border: solid 2px ${({ theme }) => theme.colors.from};
  }

  &:disabled {
    background: darkgrey;
  }

  img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    animation: loading-roller 3s infinite;
  }

  @keyframes loading-roller {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
