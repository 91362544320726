import React from "react";
import PropTypes from "prop-types";
import ModalDialog from "components/modal/ModalDialog";
import "./ConfirmModal.scss";

function ConfirmModal({ contentText, buttonText, onClick, isOpen, onToggle }) {
  return (
    <ModalDialog size="sm" isOpen={isOpen} onToggle={onToggle}>
      <p className="text-center text-primary mt-3 alert-modal-text">
        {contentText.split("\n").map(line => (
          <span key={line}>
            {line}
            <br />
          </span>
        ))}
      </p>
      <div className="row p-2">
        <div className="col text-center">
          <span className="px-1">
            <button className="btn btn-info" type="button" onClick={onClick}>
              {buttonText}
            </button>
          </span>
          <span className="px-1">
            <button className="btn btn-danger" type="button" onClick={onToggle}>
              닫기
            </button>
          </span>
        </div>
      </div>
    </ModalDialog>
  );
}

ConfirmModal.propTypes = {
  contentText: PropTypes.string,
  buttonText: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  onClick: PropTypes.func
};

ConfirmModal.defaultProps = {
  contentText: "",
  buttonText: "확인",
  isOpen: false,
  onToggle: () => {},
  onClick: () => {}
};

export default ConfirmModal;
