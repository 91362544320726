import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { getFormatDate } from 'lib/time';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';

function ProviderDeliveryList({
  providerDeliveryList,
  onGetSearchData,
  keyword,
  onChangeKeyword,
  onOpenProviderModal,
  providerName
}) {
  const search = e => {
    if (e.keyCode !== 13) {
      // not enter;

      return;
    }

    onGetSearchData();
  };
  const { ExportCSVButton } = CSVExport;
  // function deliveryFormatter(cell) {
  //   return (
  //     <div>
  //       <div>수령인: {cell.name}</div>
  //       <div>연락처: {cell.mobile}</div>
  //       <div>
  //         배송지: {cell.addr} {cell.addrSub}
  //       </div>
  //     </div>
  //   );
  // }
  const columns = [
    {
      dataField: 'createDate',
      text: '구매/선물확인날짜',
      sort: true,
      formatter: cell => getFormatDate(cell, 'YYYY.MM.DD HH:mm')
      // eslint-disable-next-line
      // ,csvFormatter: cell => getFormatDate(cell, 'YYYY.MM.DD HH:mm')
    },
    {
      dataField: 'productName',
      text: '상품명',
      formatter: (cell, row) => {
        const { detailInfo } = row;
        return <div>{`${cell} - ${detailInfo.selectOpt}`}</div>;
      },
      csvFormatter: (cell, row) => `${cell} - ${row.detailInfo.selectOpt}`
    },
    {
      dataField: 'amount',
      text: '수량',
      headerStyle: {
        width: '55px'
      }
    },
    // {
    //   dataField: 'deliveryInfo',
    //   text: '받는사람',
    //   headerStyle: {
    //     width: '200px'
    //   },
    //   formatter: deliveryFormatter,
    //   csvFormatter: cell =>
    //     `수령인: ${cell.name}\n연락처: ${cell.mobile}\n배송지: ${cell.addr} ${
    //       cell.addrSub
    //     }`
    // },
    {
      dataField: 'deliveryInfo.name',
      text: '수령인'
    },
    {
      dataField: 'deliveryInfo.mobile',
      text: '연락처'
    },
    {
      dataField: 'deliveryInfo.addr',
      text: '배송지',
      filter: textFilter({
        placeholder: '주소 검색'
      }),
      formatter: (cell, row) => {
        const { deliveryInfo } = row;
        return (
          <div>
            {`${deliveryInfo.addr} ${
              deliveryInfo.addrSub ? deliveryInfo.addrSub : ''
            }(${deliveryInfo.zipcode})`}
          </div>
        );
      },
      csvFormatter: (cell, row) =>
        `${row.deliveryInfo.addr} ${
          row.deliveryInfo.addrSub ? row.deliveryInfo.addrSub : ''
        }(${row.deliveryInfo.zipcode})`
    },
    {
      dataField: 'paymentLogsId',
      text: '주문번호',
      style: {
        tableLayout: 'fixed',
        wordBreak: 'break-all'
      }
    },
    {
      dataField: 'deliveryName',
      text: '택배사',
      csvFormatter: cell => {
        if (!cell) {
          return '';
        }
        return cell;
      }
    },
    {
      dataField: 'invoiceNumber',
      text: '운송장번호',
      csvFormatter: cell => {
        if (!cell) {
          return '';
        }
        return cell;
      }
    }
  ];

  return (
    <Fragment>
      <ToolkitProvider
        keyField="id"
        data={providerDeliveryList}
        columns={columns}
        exportCSV={{
          fileName: `${providerName}_${keyword}_배송.csv`
        }}
      >
        {props => (
          <div className="discoverlist">
            <h4 className="h4">배송관리</h4>
            <ExportCSVButton {...props.csvProps}>Export CSV!!</ExportCSVButton>
            <hr />
            <div className="input-group mb-3 col-sm-3">
              <input
                type="text"
                className="form-control"
                placeholder="공급자 찾기"
                value={keyword}
                onChange={onChangeKeyword}
                onFocus={onOpenProviderModal}
                onKeyUp={search}
              />
            </div>
            <BootstrapTable
              {...props.baseProps}
              pagination={paginationFactory()}
              filter={filterFactory()}
            />
          </div>
        )}
      </ToolkitProvider>
    </Fragment>
  );
}

// ProviderDeliveryList.propTypes = {
//   discoverList: PropTypes.arrayOf(PropTypes.shape({}))
//   // onClickRow: PropTypes.func
// };

// ProviderDeliveryList.defaultProps = {
//   discoverList: []
//   // onClickRow: () => {}
// };

export default ProviderDeliveryList;
