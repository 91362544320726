// export default {
//     region: 'ap-northeast-2',
//     IdentityPoolId : 'ap-northeast-2:288e10c1-7e46-47ac-8b4d-e9e164ec6c3d',
//     UserPoolId: 'ap-northeast-2_iwvjpNdfL',
//     ClientId: '2jtdkavhjc4447vdv8dlbnpuac'
// };

export default {
    region: 'ap-northeast-2',
    UserPoolId: 'ap-northeast-2_PHLYn6EkU',
    ClientId: '6iig208e6b5ccunon2ngl0i8ab'
};
  