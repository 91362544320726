import { isEmpty } from "lib/utils/commonUtils";

const REG_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REG_PASSWORD = /^.*(?=^.{8,}$)(?=.*\d)(?=.*[a-zA-Z])/;
const REG_NUMBERIC = /^\d+$/;
const REG_ALPHANUMERIC = /^[A-Z0-9_-]+/i;
const REG_WEBSITE = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i;
const REG_TEL = /^\d{2,3}(-|\s+)?\d{3,4}(-|\s+)?\d{4}$/;

export const emptyValidation = value =>
  isEmpty(value) ? "필수 입력 항목 입니다." : undefined;

export const mendatoryValidation = value =>
  !value ? "필수 입력 항목 입니다." : undefined;

export const emailValidation = value =>
  !REG_EMAIL.test(value) ? "올바른 이메일 주소가 아닙니다." : undefined;

export const passwordValidation = value =>
  !REG_PASSWORD.test(value)
    ? "8자 이상, 영문숫자 조합의 비밀번호를 만들어주세요."
    : undefined;

export const numericValidation = value =>
  !REG_NUMBERIC.test(value) ? "숫자만 사용 가능합니다" : undefined;

export const alphanumericValidation = value =>
  !REG_ALPHANUMERIC.test(value)
    ? "영문 대/소문자, 숫자와 (-), (_)만 사용 가능합니다"
    : undefined;

export const websiteValidation = value =>
  !REG_WEBSITE.test(value) ? "웹사이트 주소 형식이 아닙니다" : undefined;

export const telValidation = value => {
  if (!value || value.trim().length == 0) {
    return undefined;
  }
  return !REG_TEL.test(value) ? "전화번호 형식이 아닙니다" : undefined;
};

export const confirmPasswordValidation = (value, values) => {
  if (!value) {
    return "비밀번호 확인은 필수 입니다.";
  }
  if (value && value != values.password) {
    return "비밀번호가 일치하지 않습니다";
  }
  return undefined;
};

// 배송유무 유일때 배송비 필수체크
export function checkRequiredDeliveryFee(value, values) {
  if (parseInt(values.flagDelivery, 10) === 0) {
    return undefined;
  }

  return value ? undefined : "필수입니다.";
}

export const currentPasswordValidation = value =>
  "현재 비밀번호가 일치하지 않습니다.";

export const reduxRequired = value =>
  value !== "" || value !== null || value !== undefined
    ? undefined
    : "필수입니다.";
export const reduxNumber = value =>
  value && !/^\d{3}\d{3,4}\d{4}$/.test(value)
    ? "모바일 번호가 아닙니다."
    : undefined;
