import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { dayFromNow } from 'lib/time';
import { getPriceFormat } from 'lib/utils/numberUtils';
import PropTypes from 'prop-types';
import Loader from 'components/commons/Loader';

import './Dashboard.scss';

function Dashboard({
  countsSuccess,
  countsReady,
  countsProcessing,
  counts1Stores,
  counts1Product,
  countsProduct,
  countsProviders,
  last1StoreNo,
  isLoading
}) {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <div className="h3 mb-4">대시보드</div>
      <div className="mb-3">
        <h4 className="h4">상점현황</h4>
        <div>
          <Link
            className="text-dark"
            to={{
              pathname: '/discover'
            }}
          >
            <div className="dashboard-card">
              <div className="card-title green">1S - 회원수</div>
              <div className="mt-4 count">
                {counts1Stores > 0 ? getPriceFormat(counts1Stores) : '?'}
              </div>
            </div>
          </Link>
          <Link
            className="text-dark"
            to={{
              pathname: '/discover'
            }}
          >
            <div className="dashboard-card">
              <div className="card-title green">1P - 상점수</div>
              <div className="mt-4 count">
                {counts1Product > 0 ? getPriceFormat(counts1Product) : '?'}
              </div>
            </div>
          </Link>
          <Link
            className="text-dark"
            to={{
              pathname: '/discover'
            }}
          >
            <div className="dashboard-card">
              <div className="card-title orange">P - 상품수</div>
              <div className="mt-4 count">
                {countsProduct > 0 ? getPriceFormat(countsProduct) : '?'}
              </div>
            </div>
          </Link>
          <Link
            className="text-dark"
            to={{
              pathname: '/discover'
            }}
          >
            <div className="dashboard-card">
              <div className="card-title orange">PP - 공급자</div>
              <div className="mt-4 count">
                {countsProviders > 0 ? getPriceFormat(countsProviders) : '?'}
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="mb-3">
        <h4 className="h4">발굴현황</h4>
        <div>
          <Link
            className="text-dark"
            to={{
              pathname: '/discover',
              state: { discoverStatus: 0 }
            }}
          >
            <div className="dashboard-card">
              <div className="card-title yellow">발굴대기</div>
              <div className="mt-4 count">
                {countsReady > 0 ? getPriceFormat(countsReady) : '?'}
              </div>
            </div>
          </Link>
          <Link
            className="text-dark"
            to={{
              pathname: '/discover',
              state: { discoverStatus: 1 }
            }}
          >
            <div className="dashboard-card">
              <div className="card-title yellow">발굴심사</div>
              <div className="mt-4 count">
                {countsProcessing > 0 ? getPriceFormat(countsProcessing) : '?'}
              </div>
            </div>
          </Link>
          <Link
            className="text-dark"
            to={{
              pathname: '/discover',
              state: { discoverStatus: 2 }
            }}
          >
            <div className="dashboard-card">
              <div className="card-title green">발굴성공</div>
              <div className="mt-4 count">
                {countsSuccess > 0 ? getPriceFormat(countsSuccess) : '?'}
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="mb-3">
        <h4 className="h4">회원현황</h4>
        <div>
          <Link
            className="text-dark"
            to={{
              pathname: '/provider',
              state: { providerId: 'hi' }
            }}
          >
            <div className="dashboard-card">
              <div className="card-title green">마지막상점번호</div>
              <div className="mt-4 count">
                {last1StoreNo > 0 ? getPriceFormat(last1StoreNo) : '?'}
              </div>
            </div>
          </Link>
          <Link
            className="text-dark"
            to={{
              pathname: '/provider',
              state: { providerId: 'hi' }
            }}
          >
            <div className="dashboard-card">
              <div className="card-title green">회원수</div>
              <div className="mt-4 count">
                {counts1Stores > 0 ? getPriceFormat(counts1Stores) : '?'}
              </div>
            </div>
          </Link>
          <Link
            className="text-dark"
            to={{
              pathname: '/provider',
              state: { providerId: 'hi' }
            }}
          >
            <div className="dashboard-card">
              <div className="card-title orange">공급자수</div>
              <div className="mt-4 count">
                {countsProviders > 0 ? getPriceFormat(countsProviders) : '?'}
              </div>
            </div>
          </Link>
        </div>
      </div>
      {/* <div className="mb-3">
        <h4 className="h4">상점현황</h4>
        <div>
          <Link
            className="text-dark"
            to={{
              pathname: '/provider',
              state: { providerId: 'hi' }
            }}
          >
            <div className="dashboard-card">
              <div className="card-title green">1P 개수</div>
              <div className="mt-4 count">0</div>
            </div>
          </Link>
          <Link
            className="text-dark"
            to={{
              pathname: '/product',
              state: { providerId: '' }
            }}
          >
            <div className="dashboard-card">
              <div className="card-title green">상품 개수</div>
              <div className="mt-4 count">0</div>
            </div>
          </Link>
        </div>
      </div> */}
      <div>
        <h4 className="h4">서비스현황</h4>
        <div>
          <div className="dashboard-card">
            {/* 2018.10.03 start */}
            <div className="card-title green">서비스기간</div>
            <div className="mt-4 count">{`${dayFromNow(1538492400000)}일`}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

Dashboard.propTypes = {
  countsReady: PropTypes.number,
  countsSuccess: PropTypes.number,
  countsProcessing: PropTypes.number,
  counts1Stores: PropTypes.number,
  counts1Product: PropTypes.number,
  countsProduct: PropTypes.number,
  countsProviders: PropTypes.number,
  last1StoreNo: PropTypes.number,
  isLoading: PropTypes.bool
};

Dashboard.defaultProps = {
  countsReady: 0,
  countsSuccess: 0,
  countsProcessing: 0,
  counts1Stores: 0,
  counts1Product: 0,
  countsProduct: 0,
  countsProviders: 0,
  last1StoreNo: 0,
  isLoading: false
};

export default Dashboard;
