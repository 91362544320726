import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  getProvider,
  getProviderSalesList,
  getProviderDateSalesList
} from 'api/provider';

import SearchProviderModal from 'components/modal/SearchProviderModal';
import ProviderAdjustmentList from 'components/provider/ProviderAdjustmentList';

import isAfter from 'date-fns/is_after';
import { presentTime, presentSubtractTime, getTimeStampDate } from 'lib/time';
import AlertModal from 'components/modal/AlertModal';

class ProviderDeliveryContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: '',
      providerDeliveryList: [],
      startDate: presentSubtractTime('day', 7, 'YYYY-MM-DD'),
      endDate: presentTime(),
      isOpenErrorModal: false
    };

    this.pageCount = 100;
    this.lastKey = null;
  }

  componentDidMount() {
    this.initialize();
  }

  /* 입력 함수 */
  handleChangeInputText = (e, text) => {
    this.setState({
      [text]: e.target.value,
      providerDeliveryList: []
    });
  };

  handleGetProviderDeliveryList = async () => {
    const { keyword, providerDeliveryList } = this.state;

    try {
      const response = await getProviderSalesList({
        pageCount: this.pageCount,
        providerId: keyword,
        lastKey: this.lastKey
      });

      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }

      const { data } = response.data;

      this.setState({
        providerDeliveryList: providerDeliveryList.concat(data.items)
      });

      this.lastKey = data.lastKey;

      if (data.lastKey) {
        this.handleGetProviderDeliveryList();
      }
    } catch (e) {
      console.error(e);
    }
  };

  initialize = () => {
    const { location } = this.props;
    const { state } = location;

    if (state) {
      const { providerId } = state;

      this.setState(
        {
          keyword: providerId
        },
        () => {
          this.handleGetProviderDeliveryList();
        },
      );
    }
  };

  handleSelectProvider = async providerId => {
    try {
      const response = await getProvider(providerId);

      if (!response || !response.data || !response.data.success) {
        throw new Error('ㅇㅇ');
      }

      const { data } = response.data;
      const { id, providerName } = data;

      this.setState({
        keyword: id,
        providerName
      });
    } catch (e) {
      //
    }
  };

  handleOpenModal = modalType => {
    this.setState({
      [modalType]: true,
      providerDeliveryList: []
    });
  };

  handleToggleModal = modalType => {
    this.setState({
      [modalType]: false
    });
  };

  handleDateChange = (_startDate, _endDate) => {
    const { startDate, endDate } = this.state;
    let tempStartDate = _startDate || startDate;
    let tempEndDate = _endDate || endDate;
    let tempDate = 0;

    if (isAfter(tempStartDate, tempEndDate)) {
      tempDate = tempEndDate;
      tempEndDate = tempStartDate;
      tempStartDate = tempDate;
    }

    this.setState({
      startDate: tempStartDate,
      endDate: tempEndDate,
      providerDeliveryList: []
    });
  };

  handleStartDateChange = startDate => {
    const { endDate } = this.state;
    this.handleDateChange(startDate, endDate);
  };

  handleEndDateChange = endDate => {
    const { startDate } = this.state;
    this.handleDateChange(startDate, endDate);
  };

  handleGetAdjustment = async () => {
    const { keyword, providerDeliveryList, startDate, endDate } = this.state;

    try {
      const response = await getProviderDateSalesList({
        pageCount: this.pageCount,
        providerId: keyword,
        lastKey: this.lastKey,
        startDate: getTimeStampDate(startDate),
        endDate: getTimeStampDate(endDate)
      });

      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }

      const { data } = response.data;

      this.setState({
        providerDeliveryList: providerDeliveryList.concat(data.items)
      });

      this.lastKey = data.lastKey;

      if (data.lastKey) {
        this.handleGetAdjustment();
      }
    } catch (e) {
      this.setState({
        isOpenErrorModal: true,
        errorMessage:
          '데이터 호출에 실패하였습니다.\n관리자에게 문의 부탁드립니다.'
      });
    }
  };

  handleCloseModal = modalType => {
    this.setState({
      [modalType]: false
    });
  };

  render() {
    const {
      keyword,
      providerDeliveryList,
      isOpenProviderModal,
      providerName,
      startDate,
      endDate,
      isOpenErrorModal,
      errorMessage
    } = this.state;

    return (
      <Fragment>
        <ProviderAdjustmentList
          providerDeliveryList={providerDeliveryList}
          onGetSearchData={this.handleGetProviderDeliveryList}
          keyword={keyword}
          onChangeKeyword={e => {
            this.handleChangeInputText(e, 'keyword');
          }}
          onOpenProviderModal={e => {
            this.handleOpenModal('isOpenProviderModal');

            e.target.blur();
          }}
          providerName={providerName}
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={this.handleStartDateChange}
          onEndDateChange={this.handleEndDateChange}
          onGetAdjustment={this.handleGetAdjustment}
        />
        <SearchProviderModal
          isOpen={isOpenProviderModal}
          onSelect={this.handleSelectProvider}
          onToggle={() => {
            this.handleToggleModal('isOpenProviderModal');
          }}
        />
        <AlertModal
          contentText={errorMessage}
          isOpen={isOpenErrorModal}
          onClick={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
        />
      </Fragment>
    );
  }
}

ProviderDeliveryContainer.propTypes = {
  location: PropTypes.arrayOf(PropTypes.shape({}))
};

ProviderDeliveryContainer.defaultProps = {
  location: []
};

export default withRouter(ProviderDeliveryContainer);
