import axios from './defaultAxios';
import adminAxios from './adminAxios';

// api url
const searchUrl = {
    // 1p
    'oneProduct' : '/product/search?',
    // 1s
    'oneStore' : '/1stores/search?',
    // P
    'provider' : '/provider/search'
}

// query string maker
const queryStringMaker = (param) => {
    let encResult = '';
    let result = `s=`;

    // 정렬
    if (param.s) {
       result = `${result}${encodeURIComponent(`${param.s.field}=${param.s.value}`)}`;
    }
    
    // query 생성
    if (param.q) {
        const keys = Object.keys(param.q);
        // eslint-disable-next-line
        for (const key of keys) {
            if (encResult === '') {
                encResult = `${key}=${param.q[key]}`;
            } else {
                encResult += `&${key}=${param.q[key]}`;
            } 
        }
        result = `${result}&q=${encodeURIComponent(encResult)}`;
    }

    // 페이징 키 생성
    if (param.k) {
        if (param.k.lastKey !== null && param.k.lastKey !== undefined) {
            result = `${result}&k=${encodeURIComponent(param.k.lastKey)}`;
        }
    }
    
    return result;
}

// 목록 조회
// eslint-disable-next-line
export function getList(param, selector) {
    const query = queryStringMaker(param);
    const url = searchUrl[selector];
    if (selector === 'provider') {
        return axios.get(`${url}${query}`);
    }
    return adminAxios.get(`${url}${query}`);
}

// 이미지 업로드
// eslint-disable-next-line
export function setImage(param) {
    return adminAxios.post('/mms/imageupload', param);
}

// dm 발송
// eslint-disable-next-line
export function sendDm(param) {
    return adminAxios.post('/mms/dm', param);
}

// TODO 
export function sendDmV2(params) {
    return adminAxios.post('/mms/dmv2', params);
}

// template 목록 조회
export function getTemplateList(param) {
    return adminAxios.get(`/templates?pageCount=${param.pageCount}&lastKey=${param.lastKey}`);
}

// template 상세 조회
export function getTemplate(id) {
    return adminAxios.get(`/templates/${id}`);
}

// template 등록
export function setTemplate(param) {
    return adminAxios.post(`/templates`, param);
}

// template 등록
export function editTemplate(id, param) {
    return adminAxios.put(`/templates/${id}`, param);
}

// template 삭제
export function removeTemplate(id) {
    return adminAxios.delete(`/templates/${id}`);
}
