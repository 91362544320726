/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { submit, reset, change } from 'redux-form'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Modal, ModalBody } from 'reactstrap';
import AlertModal from 'components/modal/AlertModal';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
// import GridList from 'components/gridList/GridList';
import SelectGridList from 'components/commons/selectGridList/SelectGridList';
import DynamicSearchForm from 'components/commons/DynamicSearchForm/DynamicSearchForm';
import DynamicForm from 'components/commons/DynamicForm/DynamicForm';
import Template from 'components/commons/Template/Template';
import { getFormatDate } from 'lib/time';
import { mendatoryValidation } from 'lib/form/validation';
import {
  getList, setImage, sendDm, sendDmV2, 
  getTemplateList, getTemplate, setTemplate, editTemplate, removeTemplate
} from 'api/sendDM'
import { schema as dynamicSchema } from 'lib/form/schemaHelper'
import { resolvePath, sleep } from 'lib/utils/commonUtils'
import handlebars from 'handlebars'

const DEFAULT_SELECT = 'oneProduct';      // 기본 선택 유형 
const MAX_RECIPIENTS_PER_REQUEST = 100;  // 단일 요청으로 허용 가능한 수신자 수 
const BATCH_REQUEST_DELAY = 100;          // batch mode 에서 서버 요청 delay (단위: ms)

const selectSetRow = {
  mode: 'checkbox',
  clickToSelect: true,
  bgColor: '#97e4f1'
};

// 치환자 정보 추출 (csvText 값 있을 때는 우선 적용)
const getReplaceInfo = recipientColumns => {
  return recipientColumns.map(item => (
    {
      title: item.text,
      key: item.csvText ? item.csvText : item.dataField
    }
  ))
  .map(item => (
    <span
      key={item.key} 
      style={{marginRight: '10px'}}>{item.title}: {"{{" + item.key + "}}"}</span>
  ))
};

class SendDMContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      // 검색 목록
      searchResultList : [],
      // 검색 결과에서 선정된 수신자 목록 
      selectionList : [],
      // 팝업 상태
      isOpen : false,
      // error modal 상태
      isOpenErrorModal : false,
      // 에러메세지
      errorMessage : '',
      // 모달 구분 (R : 수신자 구분, T : 템플릿 관리) 
      modalType : 'R',
      // 선택자
      selector : DEFAULT_SELECT,
      // 검색여부
      isSubmit : false,
      // 발송 중 상태 
      isLoading: false
    };

    // 수신자 목록 컬럼
    this.columns = [
      {
        dataField: 'userName',
        text: '수신자',
        headerStyle: {
          width: '80px'
        }
      },
      {
        dataField: 'mobile',
        text: '수신자전화번호',
        headerStyle: {
          width: '80px'
        }
      },
      {
        dataField: '',
        text: '삭제',
        headerStyle: {
          width: '40px'
        }
      }
    ]

    // 템플릿 동적 폼 스키마
    this.schema = {
      "properties": {
        "messageSubject": {
          "type": "text",
          "label": "제목",
          "readonly" : false,
          "validate" : mendatoryValidation
        },
        "attachFileList": {
          "type": "file",
          "label": "첨부파일",
          "accept" : "*", // * 전체, image 이미지
          "readonly" : false,
          "multiple" : false
        },
        "flagAD": {
          "type": "radio-a",
          "enum" : [true, false],
          "label": "광고여부",
          "readonly" : false,
          "validate" : mendatoryValidation
        },
        "messageContent": {
          "type": "textarea",
          "label": "내용",
          "readonly" : false,
          "validate" : mendatoryValidation
        }
      }
    }

    // 동적으로 생성할 검색 item 및 컬럼
    this.dynamicSchema = dynamicSchema;

  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps, prevState) {
    const {selector} = this.state;
    // selector 가 변경될 때마다 해당 selector에 맞도록 목록 초기화
    if (prevState.selector !== selector) {
      this.onSearchSubmit({});
    }
  }

  // 초기화
  initialize = () => {
    const {dispatch} = this.props;
    const keys = Object.keys(this.schema.properties);
    
    // radio가 존재하면 redux-form에 첫번째 값이 적용되도록 설정
    // eslint-disable-next-line
    for (const key of keys) {
      if (this.schema.properties[key].type.indexOf('radio') > -1) {
        dispatch(change('dynamicEditForm', key, this.schema.properties[key].enum[0].toString()));
      }
    }
  }

  // 서브밋 된 검색 조건 생성 
  onSearchSubmit = (values) => {
    const keys = Object.keys(values);
    const {selector} = this.state;
    let searchParams = null;
    let sortParams = null;

    // eslint-disable-next-line
    for (const key of keys) {
      // 검색어
      if (this.dynamicSchema[selector].fields.q) {
        // eslint-disable-next-line
        for (const field of this.dynamicSchema[selector].fields.q.items) {
          if (field.name === key) {
            if (searchParams === null) {
              searchParams = {};
            }
            searchParams[field.original] = values[key];
          }
        }
      }
      
      // 정렬
      if (this.dynamicSchema[selector].fields.s) {
        if (sortParams === null) {
          sortParams = {}
        }
        sortParams.field = values.op_order;
        sortParams.value = values.orderBy;
      }
    }

    // date의 경우에는 자동으로 검색 파라미터를 추가할 수가 없기 때문에 수동으로 작성해주어야 함.
    if (values) {
      if (values.from) {
        if (!searchParams) {
          searchParams = {};
        }

        if (values.from) {
          const fromDate = new Date(values.from)
          fromDate.setHours(fromDate.getHours()-9)
          searchParams.from = fromDate.toISOString();
        }
        
        if (values.to) {
          const toDate = new Date(values.to)
          toDate.setHours(toDate.getHours()-9)
          searchParams.to = toDate.toISOString();
        }
        
      }
    }
    
    // 검색 구분 (검색 버튼을 누룰 시 무조건 1페이지로)
    const query = {
      q : searchParams,
      s : sortParams,
      k : {lastKey:null}
    }

    // 페이징 시 검색 파라미터가 필요함
    this.setState({
      searchParams,
      sortParams
    })
    
    this.onSearch(query);
  }

  // 검색 수행 
  onSearch = async (param) => {
    const {selector} = this.state;
    try {
      this.setState({
        loading: true
      });
      
      const result = await getList(param, selector);

      if (result.status === 200) {
        const {data} = result.data;
        if (data.items) {
          this.setState({
            searchResultList : data.items,
            lastKey : data.lastKey || null,
            loading : false
          })
        } 
        else {
          // 로직 확인 필요 
          this.setState({
            loading: false
          });
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({
        loading: false
      });
    }
  }

  // 다음 페이지 검색 요청 
  handlePaging = (lastKey) => {
    const {searchParams, sortParams} = this.state;

    const query = {
      q : searchParams,
      s : sortParams, 
      k : {lastKey}
    }
    
    this.onSearch(query);
  }

  // 재귀적으로 검색 수행 
  recursiveSearch = async (recipientList, lastKey) => {

    // request 
    const {
      selector, searchParams, sortParams,
    } = this.state;

    let param = {
      q: searchParams,
      s: sortParams,
      k: lastKey ? { lastKey } : null
    };

    try {
      const result = await getList(param, selector);

      if (result.status !== 200) {
        throw "Request error occurs.";
      }

      // add to a recipient list 
      const { data } = result.data;
      if (data && data.items) {

        // 중복 제거 
        // NOTE: 전화번호 기준으로 비교. 비교 항목 추가는 여기서 처리.
        const key = this.dynamicSchema[selector].keyMobile;
        if (recipientList.length == 0) {

          const tmp = data.items.filter((srcItem, i) => {
            const srcMobile = resolvePath(srcItem, key);
            return data.items.findIndex((trgItem) => {
              const trgMobile = resolvePath(trgItem, key);
              return srcMobile === trgMobile;
            }) === i;
          });

          Array.prototype.push.apply(recipientList, tmp);
        }
        else {

          let targetList = data.items;
          recipientList.every((srcItem) => {
            if (targetList.length == 0) {
              // break 
              return false;
            }

            const srcMobile = resolvePath(srcItem, key);
            targetList = targetList.filter((trgItem) => {
              const trgMobile = resolvePath(trgItem, key);
              
              // DEBUG
              /*if (srcMobile === trgMobile) {
                console.log(`== duplicate:
storeId: ${srcItem.storeId} / mobile: ${srcMobile}
storeIdProductId: ${srcItem.storeIdProductId} - ${trgItem.storeIdProductId}
`);
              }*/
              // end of DEBUG
              
              return srcMobile !== trgMobile;
            });
            return true;
          });   // end of recipientList.every(...)

          Array.prototype.push.apply(recipientList, targetList);
        }

        console.log(`== search results: ${recipientList.length}, lastKey: ${data.lastKey}`)
      }

      // check lastKey 
      if (data && data.lastKey) {
        if (BATCH_REQUEST_DELAY > 0) {
          // 딜레이는 추후에 서버 사양 따라 수정 
          await sleep(BATCH_REQUEST_DELAY);
        }
        // request to search from the last key
        await this.recursiveSearch(recipientList, data.lastKey);
      }

    } catch (err) {
      console.error(err);
      throw "Fail to search";
    }

  }

  // reset all
  // page reload 방식으로 초기화 (LATER: state 만 clear 처리)
  resetData = () => {
    window.location.reload();
  }

  // template 목록 조회
  onTemplateSubmit = async (searchParams) => {
    const pageCount = 10;
    const params = {
      pageCount,
      lastKey : encodeURIComponent((searchParams && searchParams.lastKey ? searchParams.lastKey : ''))
    };

    try {
      const result = await getTemplateList(params);
      const {data} = result.data;

      if (data) {
        this.setState({
          templateResult : data
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  // dm 발송 submit
  send = () => {
    const {dispatch} = this.props;
    // redux form 에 접근하여 생성된 폼 중에 필요한 폼을 submit 함
    dispatch(submit('dynamicEditForm'));
  }

  // '발송' submit 처리 
  handleSubmit = (values) => {
    return new Promise(async (resolve, reject) => {

      this.setState({
        isLoading: true
      });

      try {
        const { selectionList } = this.state;
        if (selectionList.length === 0) {
          throw '수신자를 선택하세요.';
        }

        // 이미지 업로드 
        let fileIdList = null;
        if (values.attachFileList && values.attachFileList.length > 0) {
          fileIdList = await this.uploadFiles(values.attachFileList);
        }

        // 파라미터 구성 
        let params = this.buildParams(values, fileIdList, selectionList);

        // DM 발송 요청 
        let result;
        if (params.selection.length > MAX_RECIPIENTS_PER_REQUEST) {
          result = await this.recursiveSendDem(params, -1);
        }
        else {
          result = await this.reqSendDm(params);
        }

        if (result.success) {
          this.setState({
            isOpenErrorModal: true,
            errorMessage: '발송했습니다.',
            isLoading: false
          });

          return resolve();
        }
        else {
          throw result.data
        }

      } catch (err) {
        console.error(err);
        this.setState({
          isOpenErrorModal : true,
          errorMessage : err,
          isLoading: false
        });
        reject();
      }

    });
  }

  recursiveSendDem = async (params, lastIndexSent) => {

    // copy all params except selection list.
    const { selection, ...paramsToSend } = params;

    if (lastIndexSent >= selection.length - 1) {
      console.error(`lastIndexSent can't be bigger than length of selection list. lastIndexSent: ${lastIndexSent}, selection.length: ${selection.length}`);
      throw "lastIndexSent can't be bigger than length of selection list"
    }

    // slice selection list with max recipients count.
    const lastIndexToSend = Math.min(lastIndexSent + MAX_RECIPIENTS_PER_REQUEST, selection.length - 1);
    paramsToSend.selection = selection.slice(lastIndexSent + 1, lastIndexToSend + 1);
  
    try {
      
      const result = await this.reqSendDm(paramsToSend);
      
      // DEBUG
      /* console.log(`from ${lastIndexSent + 1} to ${lastIndexToSend}`);
      const result = {
        success: true
      }; */
      // end of DEBUG

      if (!result.success) {
        throw result.data
      }

      if (lastIndexToSend < selection.length - 1) {

        if (BATCH_REQUEST_DELAY > 0) {
          // 딜레이는 추후에 서버 사양 따라 수정 
          await sleep(BATCH_REQUEST_DELAY);
        }

        return await this.recursiveSendDem(params, lastIndexToSend);
      }
      else {
        return result;
      }

    } catch (err) {
      console.error(err);
      throw "Fail to send DM"
    }

  }

  // 파일 등록 후 file ID list 반환. 
  // NOTE: 현재 첫 번 째 파일만 업로드 
  uploadFiles = async (files) => {
    const changedImage = files[0];
    
    let imageName = '';
    let imageEncoding = '';
    
    // size가 존재한다면
    if (changedImage.size && changedImage.size > 300000) {
        throw '이미지 크기는 300KB를 넘을 수 없습니다.';
    }

    // file일 경우에는 name 값이 존재
    if (changedImage.name) {
      if (changedImage.name.length > 45) {
        throw '이미지명은 45자가 넘을 수 없습니다.';
      }
      imageName = changedImage.name;
    } else {
      // 파일 객체가 아닌 템플릿을 선택한 값일 경우
      imageName = 'templateSelectedImage.jpg';
    }

    imageEncoding = changedImage.encoding;
    
    try {
      const result = await setImage({
          fileName: imageName, 
          fileBody: imageEncoding.split('base64,')[1]
        });
      if (result.data && result.data.success) {
        const resultData = result.data.data;
        const fileId = resultData.body ? resultData.body.data.fileId : null;
        return [ fileId ];
      }
      else {
        throw result.data.data;
      }
    } catch (err) {
      console.error(err)
      throw '이미지 업로드에 실패했습니다.'
    }
  }

  // API 요청 파라미터 생성 
  buildParams = (values, fileIds, recipientList) => {

    /*
      {
        title: '',
        body: '',
        attachments: null,
        isAd: false,
        isBatch: false,
        source: '',
        selection: [],
        searchParams: null,
      }
     */

    const { selector } = this.state;

    const params = {
      title: values.messageSubject,
      body: values.messageContent,
      isAd: values.flagAD === 'true',
      source: selector,
    };

    if (fileIds && fileIds.length > 0) {
      params.attachments = fileIds;
    }

    if (recipientList.length > 0) {
      params.selection = recipientList.map(item => {
        // some conversions here 
        return item;
      });
    }
    
    return params;
  }

  // API 요청 
  reqSendDm = async (params) => {
    const result = await sendDmV2(params);
    return result.data; 
    
    /* 
    // DEBUG
    console.log(`reqSendDm] ${params.selection.length} requested`)
    return {
      success: true,
      data: {
        lastItem: params.selection[params.selection.length - 1]
      }
    } 
    */
  };

  // 모달 오픈
  openModal = (e, modalType) => {
    const {isOpen, selectionList, selector} = this.state;

    this.setState({
      isOpen : !isOpen,
      modalType,
      selector : selectionList.length === 0 ? DEFAULT_SELECT :  selector// 모달을 받으면 selector 초기화 필요
    })
    
    // 수신자 목록 조회
    if (modalType === 'R') {
      this.onSearchSubmit({});
    }

    if (modalType === 'T') {
      this.onTemplateSubmit({lastKey : null});
    }
    
  }

  // 모달 닫기
  closeModal = () => {
    const {isOpen} = this.state;

    this.setState({
      isOpen : !isOpen
    })
  }

  // alert modal control
  handleModal = () => {
    const {isOpenErrorModal} = this.state;

    this.setState({
      isOpenErrorModal : !isOpenErrorModal
    })
  }

  // 수신자 선택완료 시 처리 
  handleFinishSelect = async (isBatch, selectedItems) => {
    
    const { selector, selectionList } = this.state;

    let newSelectionList;

    if (isBatch) {      // 일괄 선택한 경우 

      this.setState({
        loading: true
      })

      // CHECK: performance 
      newSelectionList = Array.from(selectionList);
      
      await this.recursiveSearch(newSelectionList);

      this.setState({
        loading: false
      })

    }
    else {              // 부분 선택한 경우 
      
      // 기존 목록에 추가하기 

      let tmp = [];
      if (selectionList.length > 0) {
        const list = selectionList.concat(selectedItems);
        
        // 중복 filtering 
        // eslint-disable-next-line
        tmp = list.filter((item, i) => {
          const serialized = JSON.stringify(item);
          // eslint-disable-next-line
          return i === list.findIndex(target => {
            return serialized === JSON.stringify(target);
          });
        });
      } else {
        tmp = selectedItems
      }

      const key = this.dynamicSchema[selector].keyMobile;
      // eslint-disable-next-line
      newSelectionList = tmp.filter((item, i) => {
        // eslint-disable-next-line
        return tmp.findIndex((target) => {
          const mobile1 = resolvePath(item, key);
          const mobile2 = resolvePath(target, key);
          return mobile1 === mobile2;
        }) === i;
      });

    }

    this.setState({
      selectionList : newSelectionList
    })

    this.closeModal();
  }

  // 검색 ui 변경을 위한 이벤트 및 데이터 조회
  onChangeSearch = (selector) => {
    const {dispatch} = this.props;

    // redux-form 리셋
    dispatch(reset('dynamicSearchForm'));

    if (this.dynamicSchema[selector].fields.s) {
      this.onChangeField('op_order', this.dynamicSchema[selector].fields.s.items[0].original);
      this.onChangeField('orderBy', 'desc');
    }
    
    this.setState({
      selector
    })
  }

  // change redux field data
  onChangeField = (field, value) => {
    const {dispatch} = this.props;
    // changeField(field, value);
    dispatch(change('dynamicSearchForm', field, value));
  }
  
  // 템플릿 등록/수정
  handleSaveTemplate = async (values) => {
    try {
      const {template} = this.state;
      const params = {
        'flagAD' : values.flagAD === 'true',
        'templateSubject' : values.templateSubject,
        'templateDesc' :  values.templateDesc,
        'messageSubject' : values.messageSubject,
        'messageContent' : values.messageContent
      };
      
      if (values.attachFileList && values.attachFileList.length > 0) {
        if (values.attachFileList[0].encoding) {
          params.attachFileList = [values.attachFileList[0].encoding.split('base64,')[1]]
        }
      }

      let result;
      
      if (values.status === 2) {
        params.templateType = 'dm';
        result = await setTemplate(params);
      } else if(values.status === 1) {
        result = await editTemplate(template.id, params);
      }
      
      const {success} = result.data;
      if (success) {
        this.setState({
          isOpenErrorModal : true,
          errorMessage : (values.status === 2 ? '저장하였습니다.' : '수정하였습니다.')
        })

        this.onTemplateSubmit({lastKey : null});
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 템플릿 삭제/수정
  handleChangeStatusTemplate = async(values, status) => {
    try {
      if (status === 0) {
        const result = await removeTemplate(values);
        const {success} = result.data;
        if (success) {
          this.setState({
            isOpenErrorModal : true,
            errorMessage : '삭제하였습니다.'
          })
        }
      } else {
        // 
      }

      this.onTemplateSubmit({lastKey : null});
    } catch (error) {
      console.log(error);
    }
  }
  
  handleGetTemplate = async (id) => {
    try {
      const result = await getTemplate(id);
      const {success,data} = result.data;
      if (success) {
        if (!data.items[0].attachFileList) {
          data.items[0].attachFileList = {};
        }
        this.setState({
          template : data.items[0]
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleResetTemplate = () => {
    this.setState({
      template : null
    })
  }

  // template 정보 가져오기
  handleSelectTemplate = async (values) => {
    const {dispatch} = this.props;
    if (values[0]) {
      const result = await getTemplate(values[0].id);
      
      if (result.data) {
        const {success, data} = result.data
        if (success) {
          const {items} = data;
          const keys = Object.keys(items[0]);
          // eslint-disable-next-line
          for (const key of keys) {
            if (key !== 'attachFileList') {
              dispatch(change('dynamicEditForm', key, items[0][key].toString()));
            } else {
              const images = {
                path : (items[0][key][0] ? `data:application/octet-stream;base64,${items[0][key][0]}` : ''), 
                extension : 'image', 
                encoding : (items[0][key][0] ? `data:application/octet-stream;base64,${items[0][key][0]}` : '')
              }

              dispatch(change('dynamicEditForm', key, images));
            }
          }
        }
      }
    }
    this.closeModal();
  }

  // template paging
  handleTemplatePaging = lastKey => {
    this.onTemplateSubmit({lastKey});
  } 

  // 데이터 삭제
  handleRemoveData = () => {
    const { 
      selector, selectionList
    } = this.state; 
    
    const {selected} = this.left.selectionContext;

    const tempList = selectionList.filter(obj => {
      const isExist = selected.indexOf(obj[this.dynamicSchema[selector].keyField]) === -1;
      return isExist;
    })

    this.setState({
      selectionList : tempList
    })
  }

  // 검색 팝업에서 일괄선택 클릭 
  handleChangeBatch = (newBatchState) => {
    this.setState(newBatchState);
  }

  render() {
    const {
      selectionList, 
      searchResultList, 
      lastKey, 
      isOpen, 
      isOpenErrorModal, 
      errorMessage,
      modalType, 
      selector, 
      isSubmit, 
      loading,            // 검색 중 상태 위한 변수로 보임. 사용하는 것 확인 후 isLoading과 통합 처리할 것.
      templateResult,
      template,
      searchParams,
      sortParams,
      isLoading           // 발송 요청 중 처리 위해 추가. 
    } = this.state;

    // TODO convert columns.
    const { columns, recipientColumns } = this.dynamicSchema[selector];
    const { ExportCSVButton } = CSVExport;
    
    return (
      <Fragment>
        <div className="container-fruit">
          <div className="row">
            <div className="col">
              <h4 className="h4">DM발송</h4>
            </div>
          </div>

          <hr />
          <div className="row">
            <div className="col">
              <input 
                className="btn btn-primary px-5" 
                type="button" 
                value={isLoading ? "발송 중..." : "발송"}
                onClick={this.send}
                disabled={isLoading}
              />&nbsp;
              <input 
                className="btn btn-primary px-2" 
                type="button" 
                value="재입력" 
                onClick={this.resetData}
              />
            </div>
          </div>

          <div className="row pt-3">

            <div className="col-6">
              <div className="container-fruit">
                <ToolkitProvider
                  keyField={this.dynamicSchema[selector].keyField}
                  data={selectionList}
                  columns={recipientColumns}
                  exportCSV={{
                    fileName: `미수신선물관리_.csv`
                  }}
                >
                  {props => (
                    <Fragment>
                      <div className="row">
                        <div className="col-4">
                          <span>수신자 목록 - ({selectionList.length})건</span>
                        </div>

                        <div className="col-8 text-right gift-list-btn">
                          <ExportCSVButton {...props.csvProps}>Export CSV <FontAwesomeIcon icon={faFileExcel} /></ExportCSVButton>&nbsp;
                          <input 
                            className="btn btn-primary px-2" 
                            type="button" 
                            value="수신자 선택" 
                            onClick={e => {
                              this.openModal(e, 'R');
                            }}
                          />&nbsp;
                          <input 
                            className="btn btn-primary px-2" 
                            type="button" 
                            value="선택삭제" 
                            onClick={this.handleRemoveData}
                          />
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col px-0">
                          <BootstrapTable
                            {...props.baseProps}
                            noDataIndication="데이터가 없습니다."
                            ref={arg => {this.left = arg;}}
                            selectRow={selectSetRow}
                          />
                        </div>
                      </div>
                    </Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </div>

            <div className="col-6">
              <div className="container-fruit">
                <div className="row">
                  <div className="col-6">
                    <span>발송 내용</span>
                  </div>
                  <div className="col-6 text-right">
                    <input 
                      className="btn btn-primary px-2" 
                      type="button" 
                      value="템플릿 선택" 
                      onClick={e => {
                        this.openModal(e, 'T');
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col pt-3">
                    <div className="border p-2 pt-3">
                      <DynamicForm 
                        schema={this.schema} 
                        onSubmit={this.handleSubmit}
                        onChange={this.changeField}
                        handleBack={this.historyBack}
                        isButton={false}
                      />
                      <div className="container-fluid">
                        <div className="mb-1">치환자 정보</div>
                        <div style={{overflowX: 'scroll', whiteSpace: 'nowrap', minHeight: '50px'}}> 
                          {getReplaceInfo(recipientColumns)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            size='lg'
            isOpen={isOpen}
            toggle={this.closeModal}
            style={{maxWidth: '1200px', width: '80%'}}
          >
            <ModalBody>
              {modalType === 'R'
              ? (
                <Fragment>
                  <div className="container-fluid">
                    <div className="row mb-3">
                      <div className="col">
                        <DynamicSearchForm 
                          types={Object.keys(this.dynamicSchema)}
                          dynamicSchema={this.dynamicSchema}
                          selector={selector}
                          isRadio={selectionList.length > 0}
                          onChangeSearch={this.onChangeSearch}
                          onChangeField={this.onChangeField}
                          onSubmit={this.onSearchSubmit}
                          loading={loading}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <SelectGridList 
                          gridList={searchResultList}
                          columns={columns}
                          keyField={this.dynamicSchema[selector].keyField}
                          keyMobile={this.dynamicSchema[selector].keyMobile}
                          lastKey={lastKey}
                          isSubmit={isSubmit}
                          onFinishSelect={this.handleFinishSelect}
                          onPaging={this.handlePaging}
                          loading={loading}
                          closeModal={this.closeModal}
                        />
                      </div>
                    </div>
                  </div>
                </Fragment>
              )
              : (
                <Fragment>
                  <Template 
                    templateResult={templateResult}
                    template={template}
                    closeModal={this.closeModal}
                    onSubmit={this.handleSaveTemplate}
                    onChange={this.handleChangeStatusTemplate}
                    onSelect={this.handleGetTemplate}
                    onResetTemplate={this.handleResetTemplate}
                    onFinishSelect={this.handleSelectTemplate}
                    onPaging={this.handleTemplatePaging}
                  />
                </Fragment>
              )
              
              }
            </ModalBody>
          </Modal>
          <AlertModal
            isOpen={isOpenErrorModal}
            contentText={errorMessage}
            onClick={this.handleModal}
            onToggle={this.handleModal}
          />
        </div>
      </Fragment>
    );
  }
}

SendDMContainer.propTypes = {
  dispatch : PropTypes.func
};

SendDMContainer.defaultProps = {
  dispatch : () => {}
};

export default compose(
  withRouter,
  connect()
)(SendDMContainer);
