import React, { Component, Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';

import RenderField from 'components/reduxForm/RenderField';
import RenderFieldDefaultRadio from 'components/reduxForm/RenderFieldDefaultRadio';
import RenderSelect from 'components/reduxForm/RenderSelect';
import RenderFile from 'components/reduxForm/RenderFile';
import RenderTextArea from 'components/reduxForm/RenderTextArea';

import AddressModal from 'components/modal/AddressModal';

import './DynamicSubForm.scss'

class DynamicSubForm extends Component {

  constructor() {
    super();

    this.state = {
      isOpen :false
    };
  }

  componentDidMount() {
  }

  // eslint-disable-next-line
  componentDidUpdate (prevProps, prevState) {
  }

  drawFields = (schema) => {
    const {properties} = schema;
    const keys = Object.keys(properties);
    
    return keys.map(key => {
      if (properties[key].type === 'text' && key !== 'zipcode') {
        return (
          <div className="row" key={key}>
            <Field
              type={properties[key].type}
              name={key}
              label={properties[key].label}
              className="col-12"
              component={RenderField}
              validate={properties[key].validate}
              readOnly={properties[key].readonly ? 'readonly' : ''}
            />
          </div>
          )
      }

      if (properties[key].type === 'text' && key === 'zipcode') {
        return (
          <div className="row" key={key}>
            <Field
              name={key}
              type={properties[key].type}
              className="col-12 form-with-button"
              component={RenderField}
              validate={properties[key].validate}
              label={properties[key].label}
              placeholder="클릭해서 우편번호 입력"
              onFocus={this.onToggle}
              readOnly={properties[key].readonly ? 'readonly' : ''}
            >
              <button
                type="button"
                className="form-button-address btn-primary"
                onClick={this.onToggle}
              >
                찾기
              </button>
            </Field>
          </div>
        )
      }

      if (properties[key].type === 'textarea') {
        return (
          <div className="row" key={key}>
            <Field
              type={properties[key].type}
              name={key}
              label={properties[key].label}
              className="col-12"
              component={RenderTextArea}
              validate={properties[key].validate}
              readOnly={properties[key].readonly ? '' : ''}
            />
          </div>
          )
      }

      if (properties[key].type === 'radio-a') {
        return (
          <Fragment key={key}>
            <div className="row">
              <div className="col-12">
                <span className="d-inline-block text-left mb-1 pr-2 ">{properties[key].label}</span>
              </div>
            </div>
            {// eslint-disable-next-line
              properties[key].enum.map(val => {
              
              let label = val;

              if (val === true) {
                label = '예';
              }

              if (val === false) {
                label = '아니오';
              }

              return (
                <div className="row" keys={`${key}${val}`}>
                  <Field 
                    name={key} 
                    className="col-12 input-radio"
                    component={RenderFieldDefaultRadio} 
                    validate={properties[key].validate}
                    label={label}
                    type={properties[key].type}
                    enum={val}
                  />
                </div>
              )
            })
            }
          </Fragment>
        )
      }

      if (properties[key].type === 'select') {
        return (
          <div className="row" key={key}>
            <Field
              name={key} 
              type='text'
              label={properties[key].label}
              className="col-12"
              component={RenderSelect}
              readOnly={properties[key].readonly ? 'readonly' : ''}
              validate={properties[key].validate}
            >
              {properties[key] 
                ? properties[key].options.map(option => <option key={option.value} value={option.value}>{option.text}</option>)
                : null
              }
            </Field>
          </div>
        )
      }

      if (properties[key].type === 'file') {
        return (
          <div className="row" key={key}>
            <Field
              name={key}
              accept={properties[key].accept} 
              label={properties[key].label}
              component={RenderFile}
              validate={properties[key].validate}
              multiple={properties[key].multiple}
            >
              <span>Add more</span>
            </Field>
          </div>
        )
      }

      return null;
    });
  }

  onToggle = (e) => {
    if (e) {
      e.target.blur();
    }

    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen
    });
  }

  // eslint-disable-next-line
  onChangeAddress = (address, zonecode) => {
    const {onChange} = this.props;
    
    onChange('zipcode', zonecode);
    onChange('addr', address);
    onChange('addrSub', '');


    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen
    });
  }

  historyBack = () => {
    const {handleBack} = this.props;
    handleBack();
  }

  render() {
    const {schema, handleSubmit, onSubmit, isButton, className} = this.props;
    const {isOpen} = this.state;
    const fields = this.drawFields(schema);

    return (
      <Fragment>
        <div className={className}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid">
              {fields}
              {!isButton 
              ? null 
              : (
                <div className="row">
                  <div className="col-12 text-right">
                    <span className="p-1"><button type="submit" className="btn btn-primary">수정</button></span>
                    <span className="p-1">
                      <button 
                        type="button" 
                        className="btn btn-danger"
                        onClick={this.historyBack}
                      >
                        취소
                      </button>
                    </span>
                  </div>
                </div>
                )
              }
              
            </div>
          </form>
          <AddressModal
            isOpen={isOpen}
            onToggle={this.onToggle}
            onSelect={this.onChangeAddress}
          />
        </div>
      </Fragment>
    );
  }
}

DynamicSubForm.propTypes = {
  // data: PropTypes.oneOfType([
  //   PropTypes.object
  // ]),
  schema : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  onChange : PropTypes.func,
  handleBack : PropTypes.func,
  isButton : PropTypes.bool,
  className : PropTypes.string
};

DynamicSubForm.defaultProps = {
  // data: {},
  schema : null,
  onSubmit: () => {},
  handleSubmit: () => {},
  onChange: () => {},
  handleBack: () => {},
  isButton : true,
  className : ''
};

export default reduxForm({form: 'dynamicSubEditForm'})(DynamicSubForm);

