import * as AWSCognito from 'amazon-cognito-identity-js';
import authConfig from 'lib/aws/authConfig';
import { logout } from 'lib/form/logout';

const poolData = {
    UserPoolId: authConfig.UserPoolId,
    ClientId: authConfig.ClientId
};
  
const userPool = new AWSCognito.CognitoUserPool(poolData);

let cognitoUser = null;

/**
 * 토근 유효성 체크
 */
export async function validateCognitoToken(history) {
    cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
        await cognitoUser.getSession(function(err, session) {
            
            const currentTime = new Date().getTime() / 1000;
            // console.log('---------------------------------');
            // 맨처음 인증이 발생한 시간
            // console.log(session.getAccessToken().payload.auth_time)

            // getSession 호출 시 access token 유효성 검사를 실행 후 유효하지 않을 시 자동으로 refresh token을 사용하여 갱신한다고 나옴.
            // 아래는 만약 갱신이 되지 않았을 시에 대비하여 구현 
            // console.log(`${session.getAccessToken().payload.exp} ===> ${currentTime}` )
            // console.log(session.getAccessToken().payload.exp < currentTime);
            // console.log('isValid ===> ' + session.isValid());
            // console.log('---------------------------------');

            // 갱신 (exp가 현재 시간보다 크거나 같으면)
            if (session.getAccessToken().payload.exp >= currentTime) {
                refreshSessionUpdate(session, history);
            } else {
                console.log('Authentication Expiration');
                logout(history);
            }

            if (err) {
                console.log(err);
                logout(history);
            }
        })
        
    } else {
        console.log('cognitoUser IS NULL');
        //logout(history);
    }
    
}

/**
 * 토큰 업데이트 (token 정상 업데이트 확인 완료)
 */
function refreshSessionUpdate(session, history) {
    const refresh_token = session.getRefreshToken(); // receive session from calling cognitoUser.getSession()
    
    cognitoUser.refreshSession(refresh_token, (err, result) => {
        if(err) {
            console.log(err);
            logout(history);
        }
    });
}

/**
 * 이메일로 verification code 발송
 * @param {*} email 
 */
export async function forgotSendMail (email) {
    const userData = {
        Username : email,
        Pool : userPool
    };

    cognitoUser = new AWSCognito.CognitoUser(userData);

    const promise = new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
          onSuccess: function (result) {
            resolve(result);
          },
          onFailure: function (err) {
            resolve(err);
          }
        });
    });

    return promise;

}

/**
 * change password
 */
export async function forgotPassword (verificationCode, password) {
    const promise = new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(verificationCode, password, {
            onSuccess() {
                resolve({result : 'success'});
            },
            onFailure(err) {
                resolve(err);
            }
        });
    });

    return promise;
}
