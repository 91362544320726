import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, change, reset } from 'redux-form';
import PropTypes from 'prop-types';

import AlertModal from 'components/modal/AlertModal';
import MessageModal from 'components/modal/MessageModal';
import RegisterProductForm from 'components/product/RegisterProductForm';
import SearchProductModal from 'components/modal/SearchProductModal';
import SearchProviderModal from 'components/modal/SearchProviderModal';

import {
  getDiscover,
  putDiscoverStatus,
  updateDiscoverResult
} from 'api/discover';
import {
  getProductAndStatus,
  registerProduct,
  updateProduct
} from 'api/product';
import { getProvider } from 'api/provider';
import {
  getIncentivePercentObject,
  getIncentivePointObject
} from 'lib/utils/priceUtils';
import { s3Upload } from 'lib/aws/s3Upload';

class RegisterProductContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ui
      errorMessage: '',
      changeStatusProcess: 2,
      hasOfficialProductImage: true,
      isOpenErrorModal: false,
      isOpenSuccessModal: false,
      isOpenProductModal: false,
      isOpenProviderModal: false,
      isSubmitLoading: false,
      officialProductImage: null
    };

    this.discoverData = null;
    this.providerData = null;
    this.productData = null;
    this.responseData = null; // 등록후 리턴 받는 상품 id 데이터
    this.userInfo = {
      id: ''
    };
  }

  componentDidMount = () => {
    this.initialize();
  };

  componentDidUpdate(prevProps) {
    const { productId, resetForm } = this.props;
    const oldID = prevProps.productId;
    const newID = productId;

    if (oldID !== newID) {
      resetForm();
      this.initializeState();
    }
  }

  // util 함수
  getImage = image => {
    if (!image) {
      return null;
    }

    if (typeof image === 'object') {
      if (Object.prototype.hasOwnProperty.call(image, 'path')) {
        return image.path;
      }
      return image.image;
    }

    return image;
  };

  // checkRadioValue = value => {
  //   if(value === null) {
  //     return null;
  //   }
  //   if (value <= 1) {
  //     return value.toString();
  //   }

  //   return null;
  // }

  // 이벤트 함수
  handleChangeOfficialProductImage = image => {
    this.setState({
      officialProductImage: image,
      hasOfficialProductImage: true
    });
  };

  handleChangeDiscover = async () => {
    const { changeField, onSendProductName } = this.props;
    const { discoverId } = this.state;

    if (!discoverId) {
      return;
    }

    try {
      const response = await getDiscover(discoverId);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`상품등록 검색 실패 - ${response}`);
      }

      const { data } = response.data;

      if (data.providerId === 'NOT_ASSIGN' || !data.providerId) {
        this.providerData = {
          providerId: 'NOT_ASSIGN'
        };
      } else {
        this.handleSelectProvider(data.providerId);
      }

      this.discoverData = data;
      this.productData = {
        category: data.category,
        createDate: data.createDate,
        createDateKorStr: data.createDateKorStr,
        discoverId: data.id,
        discovererEmail: data.discovererEmail,
        discovererId: data.discovererId,
        discovererMobile: data.discovererMobile,
        discovererName: data.discovererName,
        discovererProfileImgPath: data.profileImgPath,
        recommendation: data.recommendation,
        tags: data.tags,
        updateDate: data.updateDate
      };

      const { productName } = data;

      // 상품이름 부모컨테이너로 전달
      onSendProductName(productName);
      changeField('productName', productName);
      changeField('tags', data.tags);

      if (data.providerId !== 'NOT_ASSIGN') {
        changeField('providerName', data.providerName);
      }
    } catch (e) {
      console.error(e.message);
      this.setState({
        errorMessage:
          '상품 정보 데이터를 가져오던 도중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.',
        isOpenErrorModal: true
      });
    }
  };

  // productId 있을때 - product정보 api호출해서 폼 채워주는 함수
  handleChangeProduct = async (type, productId) => {
    const { changeField, onSendProductName } = this.props;

    if (type === 'name') {
      changeField('productName', productId);

      return;
    }

    if (productId === 'NOT_ASSIGN') {
      return;
    }

    try {
      const response = await getProductAndStatus(productId);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`상품등록 검색 실패 - ${response}`);
      }

      const { data, discoverStatus } = response.data;
      const { nowStatusProcess } = discoverStatus || {};
      this.productData = data;
      this.providerData = {
        providerEmail: data.providerEmail,
        providerId: data.providerId,
        providerMobile: data.providerMobile,
        providerName: data.providerName,
        providerProfileImgPath: data.providerProfileImgPath
      };
      Object.keys(this.providerData).forEach(
        key => !this.providerData[key] && delete this.providerData[key]
      );

      const {
        flagDelivery,
        flagTaxFree,
        flagReservation,
        isSamedayDelivery,
        flagAdultAuth,
        flagBest,
        isCarrige,
        isTicket,
        hasAdditionalMessage,
        additionalMessage,
        hasDeliveryMessage,
        deliveryMessage,
        officialProductImgPath,
        productDetailInfo,
        productName,
        providerName,
        basicDeliveryFee,
        tags
      } = data;

      let { defaultRate } = data;

      // 상품이름 부모컨테이너로 전달
      onSendProductName(productName);

      this.setState({
        hasOfficialProductImage: !!officialProductImgPath,
        officialProductImage: officialProductImgPath,
        stateNowStatusProcess: nowStatusProcess
      });

      // defaultRate 소숫점 변환
      defaultRate = getIncentivePercentObject(defaultRate);
      //
      //

      let stringBasicDeliveryFee = '0';

      if (basicDeliveryFee) {
        stringBasicDeliveryFee = basicDeliveryFee.toString();
      }

      changeField('flagNew', false);
      changeField('id', productId);
      changeField('providerName', providerName);
      changeField('productName', productName);
      changeField('basicDeliveryFee', stringBasicDeliveryFee);
      changeField('productDetailInfo', productDetailInfo);
      changeField('flagDelivery', flagDelivery.toString());
      changeField('flagTaxFree', flagTaxFree ? flagTaxFree.toString() : '0');
      changeField('flagBest', flagBest ? flagBest.toString() : 'false');
      changeField('isCarrige', isCarrige ? isCarrige.toString() : 'false');
      changeField(
        'flagAdultAuth',
        flagAdultAuth ? flagAdultAuth.toString() : '0'
      );
      changeField(
        'flagReservation',
        flagReservation ? flagReservation.toString() : 'false'
      );
      changeField(
        'isSamedayDelivery',
        isSamedayDelivery ? isSamedayDelivery.toString() : 'false'
      );
      changeField('isTicket', isTicket ? isTicket.toString() : 'false');
      changeField(
        'hasAdditionalMessage',
        hasAdditionalMessage ? hasAdditionalMessage.toString() : 'false'
      );
      changeField('additionalMessage', additionalMessage);
      changeField(
        'hasDeliveryMessage',
        hasDeliveryMessage ? hasDeliveryMessage.toString() : 'false'
      );
      changeField('deliveryMessage', deliveryMessage);
      changeField('tags', tags);
      changeField('defaultRate', defaultRate);
    } catch (e) {
      console.error(e);
      this.setState({
        errorMessage:
          '상품 정보 데이터를 가져오던 도중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.',
        isOpenErrorModal: true
      });
    }
  };

  handleClickNew = (fieldName, name) => {
    const { changeField } = this.props;

    changeField(fieldName, name);
  };

  handleCloseModal = modalType => {
    this.setState({
      [modalType]: false
    });
  };

  handleOpenModal = modalType => {
    this.setState({
      [modalType]: true
    });
  };

  // 지금은 상품수정용
  handleRegisterProduct = async values => {
    const {
      hasOfficialProductImage,
      isSubmitLoading,
      officialProductImage
    } = this.state;

    if (isSubmitLoading || !hasOfficialProductImage) {
      return;
    }

    const hasImage = !!officialProductImage;

    if (!hasImage) {
      this.setState({
        hasOfficialProductImage: hasImage
      });

      return;
    }

    this.setState({
      isSubmitLoading: true
    });

    try {
      if (!this.productData) {
        throw new Error(`error - 상품데이터가 없습니다.`);
      }

      if (this.discoverData && !this.providerData) {
        throw new Error(`error - 공급자데이터가 없습니다.`);
      }

      const copyValue = {
        ...this.productData,
        ...this.providerData,
        ...values
      };

      const {
        basicDeliveryFee,
        flagDelivery,
        flagTaxFree,
        flagReservation,
        isSamedayDelivery,
        isTicket,
        flagAdultAuth,
        flagBest,
        isCarrige,
        hasAdditionalMessage,
        hasDeliveryMessage,
        defaultRate
      } = values;

      // eslint-disable-next-line
      console.log(`values`);
      // eslint-disable-next-line
      console.log(values);

      // const { cnx, discount, toSave } = incentiveStruct;
      const officialProductImgPath = await s3Upload(
        this.getImage(officialProductImage),
        'officialProductImage'
      );

      /* 상품 등록을 위한 데이터 가공 */

      // 필수값
      copyValue.category = 1;
      copyValue.flagFromProvider = this.discoverData ? 0 : 1;

      // 인센티브 구조 설정
      // const numberCnx = parseFloat(cnx);
      // const numberDiscount = parseFloat(discount);
      // const numberToSave = parseFloat(toSave);
      // const numberProivder = 100 - discount - numberCnx - numberToSave;
      const numberFlagDelivery = parseInt(flagDelivery, 10);

      // string 형식 int로 형변환
      copyValue.flagDelivery = numberFlagDelivery;
      copyValue.basicDeliveryFee =
        numberFlagDelivery === 1 ? parseInt(basicDeliveryFee, 10) : 0;
      copyValue.flagTaxFree = parseInt(flagTaxFree, 10);
      copyValue.flagAdultAuth = parseInt(flagAdultAuth, 10);

      // string 형식 bool로 형변환
      copyValue.flagReservation = flagReservation === 'true';
      copyValue.isSamedayDelivery = isSamedayDelivery === 'true';
      copyValue.isTicket = isTicket === 'true';

      copyValue.flagBest = flagBest === 'true';
      copyValue.isCarrige = isCarrige === 'true';
      copyValue.hasAdditionalMessage = hasAdditionalMessage === 'true';

      // if (hasAdditionalMessage !== 'true') {
      //   copyValue.additionalMessage = null;
      // }
      copyValue.hasDeliveryMessage = hasDeliveryMessage === 'true';

      // if (hasDeliveryMessage !== 'true') {
      //   copyValue.deliveryMessage = null;
      // }

      // copyValue.priceList = priceList.map(item => {
      //   const tempValue = item;
      //   const { price } = item;

      //   tempValue.price = parseInt(price, 10);

      //   return tempValue;
      // });

      // // string 형식을 float로 형변환
      // copyValue.incentiveStruct = {
      //   cnx: parseFloat((numberCnx / 100).toFixed(10)),
      //   discount: parseFloat((numberDiscount / 100).toFixed(10)),
      //   fromSave: 0, // 기본 값
      //   saveSum: parseFloat((numberToSave / 100).toFixed(10)),
      //   toSave: parseFloat((numberToSave / 100).toFixed(10)),
      //   provider: parseFloat((numberProivder / 100).toFixed(10))
      // };

      // 공식 상품 이미지 정보 생성
      copyValue.officialProductImgPath = {
        path: officialProductImgPath,
        width: officialProductImage.width,
        height: officialProductImage.height
      };

      if (copyValue.providerId === 'NOT_ASSIGN') {
        copyValue.providerMobile = null;
        copyValue.providerName = null;
        copyValue.providerProfileImgPath = null;
        copyValue.providerEmail = null;
      }

      copyValue.defaultRate = getIncentivePointObject(defaultRate);
      copyValue.incentiveStruct = {
        cnx: copyValue.defaultRate.cnx,
        discount: copyValue.defaultRate.discount,
        fromSave: 0, // 기본 값
        saveSum: copyValue.defaultRate.seller,
        toSave: copyValue.defaultRate.seller,
        provider: copyValue.defaultRate.provider
      };

      // if (copyValue) {
      //   // eslint-disable-next-line
      //   console.log(`copyValue`);
      //   // eslint-disable-next-line
      //   console.log(copyValue);
      //   return;
      // }

      if (this.discoverData) {
        // 상품등록
        const response = await registerProduct(copyValue);

        if (!response || !response.data || !response.data.success) {
          throw new Error();
        }

        this.responseData = response.data.data;
        this.productData = copyValue;

        this.handleChangeDiscoverStatus();
      } else {
        // 상품수정
        const response = await updateProduct(copyValue);

        if (!response || !response.data || !response.data.success) {
          throw new Error();
        }

        this.responseData = response.data.data;
        this.productData = copyValue;
      }

      this.setState({
        isOpenSuccessModal: true,
        successMessage: this.discoverData
          ? '상품 등록 되었습니다'
          : '상품 수정 되었습니다.',
        isSubmitLoading: false,
        id: this.responseData.id
      });
    } catch (e) {
      this.setState({
        errorMessage:
          e.message ||
          '통신이 불안정하여 상품수정에 실패하였습니다.\n잠시 후 다시 시도해주세요.',
        isOpenErrorModal: true,
        isSubmitLoading: false
      });
    }
  };

  // 발굴대기인걸 상품등록하면 발굴심사로 바꿔줌
  handleChangeDiscoverStatus = async () => {
    const {
      discoverId,
      ignorStatusMessage,
      stateNowStatusProcess
    } = this.state;

    if (stateNowStatusProcess > 0) {
      return;
    }

    const response = await putDiscoverStatus({
      changeStatusProcess: 1,
      message: ignorStatusMessage || null,
      discoverId
    });

    if (!response || !response.data || !response.data.success) {
      throw new Error(`발굴 상태 업데이트 실패  - ${JSON.stringify(response)}`);
    }

    this.discoverData = null;

    this.setState({
      stateNowStatusProcess: 1,
      changeStatusProcess: 1
    });
  };

  // 발굴성공으로 바꿀때
  handleUpdateDiscoverStatus = async () => {
    const { ignorStatusMessage, changeStatusProcess, id } = this.state;
    const copyValue = this.productData;
    const { incentiveStruct, providerId } = copyValue;
    const { cnx, discount, toSave } = incentiveStruct;

    const numberCnx = parseFloat(cnx);
    const numberDiscount = parseFloat(discount);
    const numberToSave = parseFloat(toSave);
    const numberProivder = 100 - discount - numberCnx - numberToSave;

    if (numberCnx > 1) {
      // string 형식을 float로 형변환
      copyValue.incentiveStruct = {
        cnx: parseFloat((numberCnx / 100).toFixed(10)),
        discount: parseFloat((numberDiscount / 100).toFixed(10)),
        fromSave: 0, // 기본 값
        saveSum: parseFloat((numberToSave / 100).toFixed(10)),
        toSave: parseFloat((numberToSave / 100).toFixed(10)),
        provider: parseFloat((numberProivder / 100).toFixed(10))
      };
    }

    copyValue.id = id;

    const nextStatus = changeStatusProcess;

    try {
      if (nextStatus < 2) {
        throw new Error(`발굴성공 또는 발굴실패만 가능합니다.`);
      }

      if (providerId === 'NOT_ASSIGN') {
        throw new Error('연결된 공급자 정보가 없습니다.');
      }

      const response = await updateDiscoverResult({
        changeStatusProcess: nextStatus,
        message: ignorStatusMessage || null,
        ...copyValue
      });

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `발굴 상태 업데이트 실패하였습니다.\n관리자에게 문의 부탁드립니다. `
        );
      }

      this.setState({
        isOpenSuccessModal: true,
        successMessage: '발굴 상태가 업데이트되었습니다.',
        isSubmitLoading: false
      });
    } catch (e) {
      this.setState({
        isOpenErrorModal: true,
        isSubmitLoading: false,
        errorMessage:
          e.message ||
          '발굴 상태 업데이트에 실패하였습니다.\n관리자에게 문의 부탁드립니다.'
      });
    }
  };

  handleChangeIgnoreStatusMessage = e => {
    this.setState({
      ignorStatusMessage: e.target.value
    });
  };

  handleChangeSelect = e => {
    this.setState({
      ignorStatusMessage: '',
      changeStatusProcess: e.target.value
    });
  };

  handleChangeProvider = () => {
    const { changeField } = this.props;

    changeField('providerName', '');

    this.providerData = {
      providerId: 'NOT_ASSIGN'
    };

    this.handleCloseModal('isOpenProviderModal');
  };

  handleSelectProvider = async providerId => {
    const { changeField } = this.props;
    try {
      const response = await getProvider(providerId);

      if (!response || !response.data || !response.data.success) {
        throw new Error('ㅇㅇ');
      }

      const { data } = response.data;
      const { id, providerName, picMobile } = data;

      this.selectProviderId = id;
      this.providerData = {
        providerId: id,
        providerMobile: picMobile,
        providerName,
        providerProfileImgPath: data.providerProfileImgPath,
        providerEmail: data.picEmail
      };

      Object.keys(this.providerData).forEach(
        key => !this.providerData[key] && delete this.providerData[key]
      );
      changeField('providerName', providerName);
    } catch (e) {
      //
    }
  };

  initialize = () => {
    const { productId } = this.props;
    const isHaveProductId = productId.charAt(0) === 'p';
    const isHaveDiscoverId = productId.charAt(0) === 'd';

    if (productId) {
      // pid, did 둘중 하나
      this.setState(
        {
          id: isHaveProductId ? productId : null,
          discoverId: isHaveDiscoverId ? productId : null
        },
        () => {
          const { id } = this.state;

          if (id) {
            this.handleChangeProduct('id', id);
          } else {
            this.handleChangeDiscover();
          }
        }
      );
    }
  };

  // initialize = () => {
  //   const { location } = this.props;
  //   const { id } = location.state;

  //   if (id) {
  //     this.setState(
  //       {
  //         id: productId
  //       },
  //       () => {
  //         const { id } = this.state;
  //         this.handleChangeProduct('id', id);
  //       },,,
  //     );
  //   }
  // };

  initializeState = () => {
    this.setState({
      errorMessage: '',
      hasOfficialProductImage: true,
      officialProductImage: null,
      changeStatusProcess: -1,
      stateNowStatusProcess: -1,
      successMessage: '',
      id: '',
      discoverId: ''
    });
  };

  handleMoveProductPage = () => {
    const { history } = this.props;

    this.setState({
      isOpenSuccessModal: false
    });

    if (this.discoverData) {
      history.push(`/product`);

      return;
    }

    window.location.reload();
  };

  render() {
    const {
      flagDelivery,
      hasAdditionalMessage,
      hasDeliveryMessage,
      formProductName,
      formProviderName
    } = this.props;
    const {
      errorMessage,
      hasOfficialProductImage,
      officialProductImage,
      isOpenErrorModal,
      isOpenProductModal,
      isOpenProviderModal,
      isOpenSuccessModal,
      isSubmitLoading,
      stateNowStatusProcess,
      successMessage,
      id
    } = this.state;

    return (
      <Fragment>
        <RegisterProductForm
          flagDelivery={flagDelivery}
          isSubmitLoading={isSubmitLoading}
          hasOfficialProductImage={hasOfficialProductImage}
          officialProductImage={officialProductImage}
          onChangeOfficialProductImage={this.handleChangeOfficialProductImage}
          onOpenModal={this.handleOpenModal}
          onSubmit={this.handleRegisterProduct}
          productId={id}
          nowStatusProcess={stateNowStatusProcess}
          hasAdditionalMessage={hasAdditionalMessage}
          hasDeliveryMessage={hasDeliveryMessage}
        />
        <AlertModal
          contentText={errorMessage}
          isOpen={isOpenErrorModal}
          onClick={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
        />
        <SearchProviderModal
          isOpen={isOpenProviderModal}
          formProviderName={formProviderName}
          onClickNew={this.handleChangeProvider}
          onSelect={this.handleSelectProvider}
          onToggle={() => {
            this.handleCloseModal('isOpenProviderModal');
          }}
        />
        <SearchProductModal
          isOpen={isOpenProductModal}
          formProductName={formProductName}
          modalType="button"
          onSelect={this.handleChangeProduct}
          onClickNew={productName => {
            this.handleClickNew('productName', productName);
          }}
          onToggle={() => {
            this.handleCloseModal('isOpenProductModal');
          }}
          isEdit
        />
        <MessageModal
          buttonText="확인"
          contentText={successMessage}
          isOpen={isOpenSuccessModal}
          onClick={() => {
            this.handleMoveProductPage();
          }}
          onToggle={() => {
            this.handleMoveProductPage();
          }}
        />
      </Fragment>
    );
  }
}

const selector = formValueSelector('registerProductForm');

const mapStateToProps = state => ({
  flagDelivery: selector(state, 'flagDelivery'),
  hasAdditionalMessage: selector(state, 'hasAdditionalMessage'),
  hasDeliveryMessage: selector(state, 'hasDeliveryMessage'),

  incentiveStruct: selector(state, 'incentiveStruct'),
  priceList: selector(state, 'priceList'),
  options: selector(state, 'options'),
  defaultRate: selector(state, 'defaultRate'),
  formProductName: selector(state, 'productName'),
  formProviderName: selector(state, 'providerName')
});

const mapDispatchToProps = dispatch => ({
  changeField: (field, value) => {
    dispatch(change('registerProductForm', field, value));
  },
  resetForm: () => {
    dispatch(reset('registerProductForm'));
  }
});

RegisterProductContainer.propTypes = {
  // priceList: PropTypes.arrayOf(PropTypes.object),
  changeField: PropTypes.func,
  resetForm: PropTypes.func,
  history: PropTypes.arrayOf(PropTypes.shape({})),
  formProductName: PropTypes.string,
  formProviderName: PropTypes.string,
  productId: PropTypes.string,
  flagDelivery: PropTypes.string,
  hasAdditionalMessage: PropTypes.string,
  hasDeliveryMessage: PropTypes.string
};

RegisterProductContainer.defaultProps = {
  // priceList: [],
  changeField: () => {},
  resetForm: () => {},
  history: [],
  formProductName: '',
  formProviderName: '',
  productId: '',
  flagDelivery: '1',
  hasAdditionalMessage: 'false',
  hasDeliveryMessage: 'false'
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RegisterProductContainer);
