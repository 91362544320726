import * as AWSCognito from 'amazon-cognito-identity-js';
import storage from 'lib/storage';
import authConfig from 'lib/aws/authConfig';

export function logout(history) {
    const data = {
        UserPoolId : authConfig.UserPoolId,
        ClientId : authConfig.ClientId
    };

    const userPool = new AWSCognito.CognitoUserPool(data);
    const cognitoUser = userPool.getCurrentUser();
    
    // local storage에서 삭제
    storage.remove('adminInfo');
    // cognito에서 로그아웃
    if (cognitoUser !== null) {
        cognitoUser.signOut();
    }
    
    history.push('/');
}