import React from 'react';
import classNames from 'classnames';

import TextInput from 'components/commons/TextInput';

import '../RenderField/RenderField.scss';
import './RenderFieldTextArea.scss';

// redux-form 에 사용되는 textarea -> 기존 TextInput 컴포넌트를 redux-form에서 사용할 수 있게 만들어 놓은 컴포넌트
function RenderFieldTextArea(field) {
  return (
    <div className={classNames('form-group', field.className)}>
      {field.label && (
        <span className="d-inline-block text-left pr-2 ">
          {field.label}
          {field.isRequired && <span className="text-danger">*</span>}
        </span>
      )}
      <TextInput
        className={`form-control ${
          field.label ? 'form-input form-textarea' : ''
        } ${field.meta.touched && field.meta.error ? 'is-invalid' : ''} ${
          field.readOnly ? 'form-readonly' : ''
        }`}
        placeholder={field.placeholder}
        maxLength={field.maxLength}
        minHeight={105}
        readOnly={field.readOnly}
        {...field.input}
      />
      {field.meta.touched && field.meta.error && (
        <div
          className={`invalid-feedback ml-auto ${
            field.label ? 'invalid-feedback-with-label' : ''
          }`}
        >
          {field.meta.error}
        </div>
      )}
    </div>
  );
}

export default RenderFieldTextArea;
