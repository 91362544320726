import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getProvider, getProviderDeliveryList } from 'api/provider';

import SearchProviderModal from 'components/modal/SearchProviderModal';
import ProviderDeliveryList from 'components/provider/ProviderDeliveryList';

class ProviderDeliveryContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: '',
      providerDeliveryList: []
    };

    this.pageCount = 20;
    this.lastKey = null;
  }

  componentDidMount() {
    this.initialize();
  }

  /* 입력 함수 */
  handleChangeInputText = (e, text) => {
    this.setState({
      [text]: e.target.value,
      providerDeliveryList: []
    });
  };

  handleGetProviderDeliveryList = async () => {
    const { keyword, providerDeliveryList } = this.state;

    try {
      const response = await getProviderDeliveryList({
        pageCount: this.pageCount,
        providerId: keyword,
        lastKey: this.lastKey
      });

      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }

      const { data } = response.data;

      this.setState({
        providerDeliveryList: providerDeliveryList.concat(data.items)
      });

      this.lastKey = data.lastKey;

      if (data.lastKey) {
        this.handleGetProviderDeliveryList();
      }
    } catch (e) {
      console.error(e);
    }
  };

  initialize = () => {
    const { location } = this.props;
    const { state } = location;

    if (state) {
      const { providerId } = state;

      this.setState(
        {
          keyword: providerId
        },
        () => {
          this.handleGetProviderDeliveryList();
        },
      );
    }
  };

  handleSelectProvider = async providerId => {
    try {
      const response = await getProvider(providerId);

      if (!response || !response.data || !response.data.success) {
        throw new Error('ㅇㅇ');
      }

      const { data } = response.data;
      const { id, providerName } = data;

      this.setState({
        keyword: id,
        providerName
      });

      this.handleGetProviderDeliveryList();
    } catch (e) {
      //
    }
  };

  handleOpenModal = modalType => {
    this.setState({
      [modalType]: true,
      providerDeliveryList: []
    });
  };

  handleToggleModal = modalType => {
    this.setState({
      [modalType]: false
    });
  };

  render() {
    const {
      keyword,
      providerDeliveryList,
      isOpenProviderModal,
      providerName
    } = this.state;
    return (
      <Fragment>
        <ProviderDeliveryList
          providerDeliveryList={providerDeliveryList}
          onGetSearchData={this.handleGetProviderDeliveryList}
          keyword={keyword}
          onChangeKeyword={e => {
            this.handleChangeInputText(e, 'keyword');
          }}
          onOpenProviderModal={e => {
            this.handleOpenModal('isOpenProviderModal');

            e.target.blur();
          }}
          providerName={providerName}
        />
        <SearchProviderModal
          isOpen={isOpenProviderModal}
          onSelect={this.handleSelectProvider}
          onToggle={() => {
            this.handleToggleModal('isOpenProviderModal');
          }}
        />
      </Fragment>
    );
  }
}

ProviderDeliveryContainer.propTypes = {
  location: PropTypes.arrayOf(PropTypes.shape({}))
};

ProviderDeliveryContainer.defaultProps = {
  location: []
};

export default withRouter(ProviderDeliveryContainer);
