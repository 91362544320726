import * as yup from 'yup';

const requiredMessage = '필수항목';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const nameRequired = yup.string().trim().required(requiredMessage);

export const stringRequired = yup.string().required(requiredMessage);

export const quantityRequired = yup
  .number()
  .typeError('숫자가 아닙니다')
  .positive('양수가 아닙니다')
  .integer('정수가 아닙니다')
  .required(requiredMessage);

export const mobileRequired = yup
  .string()
  .matches(
    /.*(82|0)(1[01689])([ -.]?)(\d+)([ -.]?)(\d{4})/,
    '휴대폰번호가 아닙니다'
  )
  .trim()
  .required();

export const emailRequired = yup
  .string()
  .email('이메일 형식이 아닙니다')
  .required(requiredMessage);

export const emailNewRequired = yup
  .string()
  .test('checkDuplicateEmail', '이메일이 존재합니다', async (value) => {
    console.log('running async validations');
    if (
      !value ||
      !value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    )
      return true;
    await sleep(1000);
    console.log({ value });
    return value !== 'srho77@gmail.com';
  })
  .email('이메일 형식이 아닙니다')
  .required(requiredMessage);

export const passwordRequired = yup
  .string()
  .min(5, '6자 이상')
  .required('필수항목');

export const dateRequired = yup.date().required(requiredMessage).nullable();
