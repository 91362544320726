import React from 'react';
import { withRouter } from 'react-router-dom';
import { logout } from 'lib/form/logout';
import PropTypes from 'prop-types';

import './Header.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  signOut = () => {
    const { history } = this.props;
    logout(history);
  };

  render() {
    const isDevelop = process.env.NODE_ENV === 'development';

    return (
      <>
        <header className="header">
          {isDevelop ? '개발 - 프롬 어드민' : '프롬 어드민'}
          <span className="float-right inline-block pr-2">
            <button type="button" onClick={this.signOut}>
              <small>로그아웃</small>
            </button>
          </span>
        </header>
        ;
      </>
    );
  }
}

Header.propTypes = {
  history: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

Header.defaultProps = {
  history: null
};

export default withRouter(Header);
