import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';

import { reduxForm, Field } from 'redux-form';
import RenderField from 'components/reduxForm/RenderField';
import RenderFieldTextArea from 'components/reduxForm/RenderFieldTextArea';
import PictureInput from 'components/commons/PictureInput';
import PictureView from 'components/commons/PictureView';
import { getImagePath } from 'lib/utils/imageUtils';
import Loader from 'components/commons/Loader';

import './DiscoverDetailForm.scss';

function DiscoverDetailForm({
  createDateKorStr,
  discovererName,
  discovererMobile,
  discovererEmail,
  nowStatusProcess,
  // productName,
  providerName,
  providerTel,
  tags,
  profileImgPath,
  productImgPathList,
  recommendation,
  onRemovePicture,
  onSelectProductImage,
  onSendProviderSNS,
  onSubmit,
  handleSubmit,
  picName,
  picMobile,
  picEmail,
  providerId,
  storeId,
  id,
  productId,
  isLoading,
  onOpenProviderModal
}) {
  // 발굴날짜, 발굴자 이메일, 전화번호, 상품명, 공급자명, 추천사
  const STATUS_TEXT_LIST = ['발굴대기', '발굴심사', '발굴성공', '발굴실패'];
  const STATUS_ENG = ['warning', 'warning', 'success', 'danger'];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <div className="h3">발굴뷰</div>
      <div className="d-inline-block mr-5">
        <div className="mb-2">
          <div>{createDateKorStr}</div>
          <div>DID - {id}</div>
          <div>SID - {storeId}</div>
          {productId ? <div>PID - {productId}</div> : <div>PID - 상품정보미확인</div>}
          {providerId ? <div>PPID - {providerId}</div> : <div>PPID - 공급자정보미확인</div>}
        </div>
        <div className="mb-2 h5">발굴자정보</div>
        <div className="mt-2">
          <div>{discovererName}</div>
          <div>{discovererMobile}</div>
          <div>{discovererEmail}</div>
        </div>
      </div>
      <div className="d-inline-block mt-2">
        <div
          className="view-form-image d-inline-block mt-3"
          style={{
            backgroundImage: `url("${getImagePath(profileImgPath) ||
              '/images/default-profile.svg'}")`
          }}
        />
      </div>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          {!picName && !picMobile && !picEmail && (
            <div className="d-inline-block alert alert-danger col-sm-3 mr-4">
              담당자정보가 부족합니다.
            </div>
          )}
          {!providerId && (
            <div className="d-inline-block alert alert-danger col-sm-3">
              공급자를 연결해주세요.
            </div>
          )}
        </div>
        <div className="mb-2 h5">
          발굴정보{' '}
          <Badge className="ml-3" color={STATUS_ENG[nowStatusProcess]}>
            {STATUS_TEXT_LIST[nowStatusProcess]}
          </Badge>
        </div>
        <div className="form-row">
          <Field
            type="text"
            name="productName"
            label="발굴명"
            className="col-sm-3"
            component={RenderField}
          />
          <Field
            type="text"
            name="tags"
            label="태그"
            className="col-sm-3"
            component={RenderField}
            value={tags}
            readOnly={nowStatusProcess >= 2}
          />
        </div>
        <div className="form-row">
          <Field
            type="text"
            name="providerName"
            label="공급자명"
            className="col-sm-3"
            component={RenderField}
            value={providerName}
            readOnly={nowStatusProcess >= 2}
          />
          <Field
            type="text"
            name="providerTel"
            label="공급자 전화번호"
            className="col-sm-3"
            component={RenderField}
            value={providerTel}
            readOnly={nowStatusProcess >= 2}
            pattern={/[^0-9]/g}
          />
          <div className="form-groub col-sm-3">
            <button
              type="button"
              className="btn btn-info mt-3"
              onClick={onOpenProviderModal}
            >
              공급자연결
            </button>
          </div>
        </div>
        <div className="form-row">
          <Field
            type="text"
            name="picName"
            label="담당자명"
            className="col-sm-3"
            value={picName}
            component={RenderField}
            readOnly={nowStatusProcess >= 2}
          />
          <Field
            type="text"
            name="picMobile"
            label="담당자연락처(*이 번호로 발굴등록 알림)"
            className="col-sm-3"
            component={RenderField}
            value={picMobile}
            readOnly={nowStatusProcess >= 2}
            pattern={/[^0-9]/g}
          />
          <Field
            type="email"
            name="picEmail"
            label="담당자이메일"
            className="col-sm-3"
            component={RenderField}
            value={picEmail}
            readOnly={nowStatusProcess >= 2}
          />
        </div>
        <div className="form-row">
          <Field
            name="recommendation"
            label="추천사"
            type="text"
            className="col-sm-6"
            component={RenderFieldTextArea}
            value={recommendation}
            readOnly={nowStatusProcess >= 2}
          />
        </div>
        <div className="image-list-form d-inline-block">
          <div>이미지</div>
          {productImgPathList.map((image, index) => (
            <PictureView
              key={image.id}
              imageSrc={image.image || getImagePath(image)}
              onClick={() => {
                onRemovePicture(index);
              }}
            />
          ))}
          {productImgPathList && productImgPathList.length < 5 && (
            <PictureInput
              className="discover-field-image-input d-inline-block"
              onSelect={onSelectProductImage}
              isShownImage={false}
            />
          )}
        </div>
        <div className="mt-3 d-block">
          {/* {nowStatusProcess < 2 && (
            <button
              className="btn btn-primary d-inline-block mr-3"
              type="submit"
            >
              수정
            </button>
          )} */}
          <div className="p-2 discoverBtn">
            <button className="btn btn-primary d-inline-block mr-3" type="submit">
              발굴정보 수정
            </button>
            <button className="btn btn-danger d-inline-block" type="button">
              발굴정보 삭제
            </button>
          </div>
          <div className="px-2 discoverBtn">
            {nowStatusProcess < 2 && (
              <button
                className={`btn btn-primary d-inline-block mr-3 ${
                  !picMobile ? 'disabled' : ''
                }`}
                type="button"
                disabled={!picMobile}
                onClick={onSendProviderSNS}
              >
                공급자 발굴 알림
              </button>
            )}
            <Link
              className="label label-danger"
              to={`/product/${productId || id}/register`}
            >
              <button className="btn btn-primary d-inline-block" type="button">
                상품정보 등록/수정
              </button>
            </Link>
          </div>
          {/* <Fragment>
            {/* productid 분기처리 
            {productId ? (
              <Link
                className="label label-danger"
                to={{
                  pathname: `/product/${productId}/register`,
                  state: { id: productId }
                }}
              >
                <button
                  className="btn btn-primary d-inline-block"
                  type="button"
                >
                  상품 정보 확인
                </button>
              </Link>
            ) : (
              <button
                className="btn btn-primary d-inline-block disabled"
                type="button"
              >
                상품 정보 확인
              </button>
            )}
          </Fragment> */}
          
          
          {/* 앱등록을 위해 임시 생성 */}
        </div>
      </form>
    </Fragment>
  );
}

DiscoverDetailForm.propTypes = {
  id: PropTypes.string,
  createDateKorStr: PropTypes.string,
  discovererName: PropTypes.string,
  discovererMobile: PropTypes.string,
  discovererEmail: PropTypes.string,
  storeId: PropTypes.string,
  productId: PropTypes.string,
  picName: PropTypes.string,
  picMobile: PropTypes.string,
  picEmail: PropTypes.string,
  nowStatusProcess: PropTypes.number,
  // productName: PropTypes.string,
  providerName: PropTypes.string,
  providerTel: PropTypes.string,
  tags: PropTypes.string,
  recommendation: PropTypes.string,
  providerId: PropTypes.string,
  profileImgPath: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  productImgPathList: PropTypes.arrayOf(PropTypes.shape({})),
  onRemovePicture: PropTypes.func,
  onSelectProductImage: PropTypes.func,
  onSubmit: PropTypes.func,
  onSendProviderSNS: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  onOpenProviderModal: PropTypes.func
  //   discoverList: PropTypes.arrayOf(PropTypes.shape({})),
  //   onMoveDiscoverEdit: PropTypes.func
};

DiscoverDetailForm.defaultProps = {
  createDateKorStr: '',
  discovererName: '',
  discovererMobile: '',
  discovererEmail: '',
  id: '',
  storeId: '',
  productId: '',
  picName: '',
  picMobile: '',
  picEmail: '',
  nowStatusProcess: -1,
  // productName: '',
  providerName: '',
  providerTel: '',
  tags: '',
  recommendation: '',
  providerId: '',
  profileImgPath: {} || '',
  productImgPathList: [],
  onRemovePicture: () => {},
  onSelectProductImage: () => {},
  onSubmit: () => {},
  onSendProviderSNS: () => {},
  handleSubmit: () => {},
  isLoading: false,
  onOpenProviderModal: () => {}
  //   discoverList: [],
  //   onMoveDiscoverEdit: () => {}
};

export default reduxForm({ form: 'discoverDetailForm' })(DiscoverDetailForm);
