import React from 'react';

import '../RenderField/RenderField.scss';
import './RenderTextArea.scss';

// redux-form 에 사용되는 textarea -> 기존 TextInput 컴포넌트를 redux-form에서 사용할 수 있게 만들어 놓은 컴포넌트
function RenderTextArea({
  input,
  readOnly,
  label,
  meta: {touched, error}
}) {
  return (
    <div className="form-group col-12">
      {label && (
        <span className="d-inline-block text-left mb-1 pr-2 ">
          {label}
        </span>
      )}
      <div className="control">
        <div className={
          /* eslint-disable */
          `${touched ? (error ? 'is-danger' : 'is-success') : ''}`}
        >
          <textarea
            className={`form-control render-textarea px-2 ${touched ? (error ? 'is-invalid' : 'is-success') : ''}`}
            row="3"
            readOnly={readOnly}
            {...input}
          >

          </textarea>
          {touched && (error && <div className="text-danger mt-1 ml-auto" style={{fontSize : '12.8px'}}>{error}</div>)}
        </div>
      </div>
    </div>
  );
}

export default RenderTextArea;
