import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { forgotSendMail, forgotPassword } from 'lib/aws/validateCognitoToken';
import AlertModal from 'components/modal/AlertModal';
import { mendatoryValidation } from '../../lib/form/validation';
import SendMailForm from '../../components/login/SendMailForm';
import ResetForm from '../../components/login/ResetForm';


class SendMailContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendMail : false,
      isOpen : false,
      errorMessage : ''
    };
  }

  componentDidMount() {
    //  console.log(this.props);
  }

  handleSubmit = async values => {
    const {username} = values;
    const result = await forgotSendMail(username);
    const {CodeDeliveryDetails, code} = result;

    if (CodeDeliveryDetails) {
      this.setState({
        sendMailmessage : '',
        // sendMail : true,
        isOpen : true,
        errorMessage : '메일로 인증번호를 발송하였습니다.'
      })
    }

    if (code && code === 'UserNotFoundException') {
      this.setState({
        sendMailmessage : '등록된 사용자가 아닙니다.'
      })
    }
  }

  handleAuthentication = async values => {
    const {verificationCode, newPassword, newPasswordRe} = values;

    if (newPassword !== newPasswordRe) {
      this.setState({
        equalsMessage: '비밀번호가 일치하지 않습니다'
      });
      return;
    }
    
    if (verificationCode === undefined || verificationCode === '') {
      this.setState({
        equalsMessage : '',
        verificationCodeMessage : mendatoryValidation(undefined)
      })
      return;
    }

    if (newPassword === undefined || newPassword === '') {
      this.setState({
        equalsMessage : '',
        newPasswordMessage : mendatoryValidation(undefined)
      })
      return;
    }

    const result = await forgotPassword(verificationCode,newPassword);
    const {code, message} = result;
    
    if (code && code === 'InvalidParameterException') {
      if (message.indexOf('1 validation') > -1 && message.indexOf('password')) {
        this.setState({
          equalsMessage : '',
          verificationCodeMessage : '',
          newPasswordMessage : '적합한 비밀번호가 아닙니다.'
        })
      }
      
      if (message.indexOf('2 validation') > -1) {
        this.setState({
          equalsMessage : '',
          verificationCodeMessage : '올바른 인증번호가 아닙니다.',
          newPasswordMessage : '적합한 비밀번호가 아닙니다.'
        })
      }

      return;
    }

    if (code && code === 'CodeMismatchException') {
      this.setState({
        equalsMessage : '',
        verificationCodeMessage : '올바른 인증번호가 아닙니다.',
        newPasswordMessage : ''
      })

      return;
    }

    this.setState({
      isOpen : true,
      errorMessage : '비밀번호를 변경하였습니다.'
    })
  }

  handleCloseModal = () => {
    const {history} = this.props;
    const {sendMail} = this.state;
    
    if (sendMail) {
      history.push(`/`);
      return;
    } 

    this.setState({
      isOpen : false,
      sendMail : true,
      errorMessage : ''
    })
  }

  render() {
    const {sendMail, 
      isOpen, 
      errorMessage, 
      sendMailmessage,
      verificationCodeMessage,
      newPasswordMessage,
      equalsMessage} = this.state;
    return (
      <Fragment>
        <>
          {!sendMail
          ? <SendMailForm onSubmit={this.handleSubmit} sendMailmessage={sendMailmessage} />
          : <>
            <ResetForm 
              onSubmit={this.handleAuthentication} 
              verificationCodeMessage={verificationCodeMessage} 
              newPasswordMessage={newPasswordMessage}
              equalsMessage={equalsMessage} 
            />
            </>
        }
        </>
        <AlertModal
          isOpen={isOpen}
          contentText={errorMessage}
          onClick={this.handleCloseModal}
          onToggle={this.handleCloseModal}
        />
      </Fragment>
    );
  }
}

SendMailContainer.propTypes = {
  history: PropTypes.arrayOf(PropTypes.shape({}))
};

SendMailContainer.defaultProps = {
  history: []
};

export default withRouter(SendMailContainer)
