import React from "react";
import { reduxForm, Field } from "redux-form";
import PropTypes from "prop-types";

import RenderFieldRadio from "components/reduxForm/RenderFieldRadio";
import EditorField from "components/editor/EditorField";
import initTemplate from "components/editor/EditorField/initTemplate";

// import { mendatoryValidation } from "lib/form/validation";

import "./ProductHtmlEditForm.scss";

function ProductHtmlEditForm({
  handleSubmit,
  isSubmitLoading,
  onSubmit,
  onModelChange,
  htmlProductInfo
  // flagStopSelling
}) {
  return (
    <div className="stop-selling-product-form">
      <h1 className="font-weight-bold mt-4 mb-4">상품상세정보</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group d-flex align-items-center flex-wrap mt-3">
          <span className="d-inline-block text-left pr-2 ">
            상세정보 공개여부
          </span>
          <div>
            <Field
              name="flagHtmlProductInfo"
              id="yesHtmlProductInfo"
              component={RenderFieldRadio}
              value="true"
              type="radio"
              label="공개"
            />
            <Field
              name="flagHtmlProductInfo"
              id="noHtmlProductInfo"
              component={RenderFieldRadio}
              value="false"
              type="radio"
              label="비공개"
            />
          </div>
        </div>
        <div className="form-group">
          <span className="d-inline-block text-left pr-2 ">상품상세정보</span>
          <EditorField
            onModelChange={onModelChange}
            htmlProductInfo={htmlProductInfo || initTemplate}
          />
        </div>
        <div className="product-regist-buttons">
          <button
            type="submit"
            className={`btn btn-primary ${isSubmitLoading ? "disabled" : ""}`}
            isLoading={isSubmitLoading}
            disabled={isSubmitLoading}
          >
            수정
          </button>
        </div>
      </form>
    </div>
  );
}

ProductHtmlEditForm.propTypes = {
  onSubmit: PropTypes.func,
  onModelChange: PropTypes.func,
  htmlProductInfo: PropTypes.string,
  handleSubmit: PropTypes.func,
  isSubmitLoading: PropTypes.bool
};

ProductHtmlEditForm.defaultProps = {
  onSubmit: () => {},
  onModelChange: () => {},
  htmlProductInfo: "",
  handleSubmit: () => {},
  isSubmitLoading: false
};

export default reduxForm({
  form: "productHtmlEditForm",
  initialValues: {
    flagHtmlProductInfo: "false" // string 형식으로 값을 초기화 해줘야 radio에서 초기값 설정 및 값 선택이 됨...
  }
})(ProductHtmlEditForm);
