/* eslint-disable react/no-unused-state */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import storage from 'lib/storage';

import { getDeliveryList, cancelPayment } from 'api/delivery';
import DeliveryAdjList from 'components/provider/DeliveryAdjList';

export const handleMovePage = id => {
  const url = `/delivery/register/${id}`;
  return url;
};

class DeliveryAdjustmentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gridList: [],
      page: 1,
      selectListCount: 100,
      selectToStaus: 'clearing',
      isLast: false,
      isPageBack: false
    };

    this.pageCount = 100;
    this.lastKeys = new Map();
  }

  componentDidMount() {
    this.initialize();
  }

  /**
   * 초기화
   * 배송관리는 초기 목록 없이 시작하기로 함(기존 것 그대로)
   * 추후에 변경될 여지는 있음
   */
  initialize = () => {
    const param = storage.get('delivaryHistory');

    // 세팅 후 검색
    if (param) {
      this.setState({
        page: param.page,
        isLast: param.isLast,
        type: param.type,
        keyword: param.keyword,
        resultId: param.id,
        resultName: param.keyword,
        isPageBack: true
      });

      const { keys } = param;

      if (keys && keys.length > 0) {
        const lastKeys = new Map();
        keys.map(obj => lastKeys.set(obj.key, obj.value));
        this.lastKeys = lastKeys;
      }

      param.status = 'history';
      this.onSearch(param);

      storage.remove('delivaryHistory');
    } else {
      this.onSearch();
    }
  };

  handleMovePage = (deliveryId, providerId, param, flag) => {
    const { history } = this.props;
    const { page, isLast, lastSearchParams } = this.state;

    /* eslint no-param-reassign: ["error", { "props": false }] */
    param.page = page;
    /* eslint no-param-reassign: ["error", { "props": false }] */
    param.isLast = isLast;

    param.type = lastSearchParams.type;
    param.keyword = lastSearchParams.keyword;

    const keys = [];
    const keyList = [...this.lastKeys.keys()];

    keyList.map(number =>
      keys.push({ key: number, value: this.lastKeys.get(number) })
    );
    // eslint-disable-next-line
    param.keys = keys;

    console.log(`movepage`);
    console.log('final');

    console.log(param);
    storage.set('delivaryHistory', param);

    history.push({
      pathname: `/delivery/register/${deliveryId}`,
      state: { deliveryId, id: providerId, flag }
    });
  };

  // moveInvoice = (deliveryId, id) => {
  //   const { history } = this.props;
  //   console.log(deliveryId);
  //   console.log(id);
  //   console.log(history);
  //   console.log(this.state);
  // }

  onSearch = async searchKeywords => {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        console.log(searchKeywords);
        // 검색 조건
        const params = {};
        // 검색 페이지 번호
        let searchPage = 1;
        // let searchId = '';
        const { selectListCount } = this.state;

        // 검색 조건이 있음
        // if (searchKeywords && searchKeywords.id !== '' && searchKeywords.provider !== '') {
        if (
          searchKeywords &&
          searchKeywords.type !== '' &&
          searchKeywords.keyword !== '' &&
          !searchKeywords.status
        ) {
          const { lastSearchParams } = this.state;

          const { page, type, keyword } = searchKeywords;
          searchPage = undefined === page ? 1 : page;
          // searchId = providerId;

          // if (id && providerId !== id) {
          //   searchId = id;
          //   // 상태가 히스토리라면 초기화 하지 않음, 검색이며 페이징이 아닐 경우에만 적용
          //   if (!status) {
          //     this.lastKeys = new Map()
          //     this.lastKeys.set(1, null);
          //   }
          // }

          if (page) {
            console.log(`paging`);
            if (lastSearchParams.type === 'buyerName') {
              params.keyword = lastSearchParams.keyword;
            } else {
              params.keyword = lastSearchParams.keyword;
            }
            params.type = lastSearchParams.type;
          } else {
            console.log(`isModalSearch - ${searchKeywords.isModalSearch}`);
            if (!searchKeywords.isModalSearch) {
              params.keyword = keyword;
            } else {
              params.keyword = searchKeywords.id;
            }
            params.type = type;

            if (
              lastSearchParams.type !== type ||
              lastSearchParams.keyword !== keyword
            ) {
              this.lastKeys = new Map();
              this.lastKeys.set(1, null);
            }
          }

          params.pageCount = selectListCount;
          params.lastKey = this.lastKeys.get(searchPage);
        } else if (searchKeywords && searchKeywords.status) {
          if (searchKeywords.status === 'history') {
            searchPage =
              undefined === searchKeywords.page ? 1 : searchKeywords.page;

            console.log(`history`);
            console.log(this.lastKeys);
            params.type = searchKeywords.type;
            params.keyword = searchKeywords.keyword;
            params.pageCount = selectListCount;
            params.lastKey = this.lastKeys.get(searchPage);
          } else {
            console.log('reset');

            const { lastSearchParams, page } = this.state;
            searchPage = page;

            params.type = lastSearchParams.type;
            params.keyword = lastSearchParams.keyword;
            params.pageCount = lastSearchParams.pageCount;
            params.lastKey = this.lastKeys.get(searchPage);
          }
        } else {
          // 전체조회
          this.lastKeys = new Map();
          this.lastKeys.set(1, null);
          params.type = 'all';
          params.keyword = '';
          params.pageCount = selectListCount;
          params.lastKey = this.lastKeys.get(searchPage);
        }

        try {
          // eslint-disable-next-line
          console.log(`search params : ${JSON.stringify(params)}`);
          console.log(this.lastKeys);
          console.log(`searchPage - ${searchPage}`);
          const response = await getDeliveryList(params);

          if (!response || !response.data || !response.data.success) {
            throw new Error('실패');
          }

          const { data } = response.data;
          let isLast = false;

          if (data.lastKey) {
            if (this.lastKeys.get(searchPage + 1) === undefined) {
              this.lastKeys.set(searchPage + 1, data.lastKey);
            }
          } else {
            isLast = true;
          }

          if (data.items) {
            this.setState({
              gridList: data.items,
              isLast,
              page: searchPage,
              lastSearchParams: params,
              // providerId : searchId
              // type : type,
              // keyword : keyword
              isLoading: false
            });
          }
        } catch (error) {
          console.error(error);
          this.setState({
            isLoading: false
          });
        }
      }
    );
  };

  // original
  // onSearch = async (searchKeywords) => {
  //   // 검색 조건
  //   const params = {};
  //   // 검색 페이지 번호
  //   let searchPage = 1;
  //   let searchId = '';

  //   // 검색 조건이 있음
  //   // if (searchKeywords && searchKeywords.id !== '' && searchKeywords.provider !== '') {
  //   if (searchKeywords && searchKeywords.type !== '' && searchKeywords.keyword !== '') {
  //     const {providerId} = this.state;
  //     const {page, id, status} = searchKeywords;
  //     searchPage = (undefined === page ? 1 : page);
  //     searchId = providerId;

  //     if (id && providerId !== id) {
  //       searchId = id;
  //       // 상태가 히스토리라면 초기화 하지 않음, 검색이며 페이징이 아닐 경우에만 적용
  //       if (!status) {
  //         this.lastKeys = new Map()
  //         this.lastKeys.set(1, null);
  //       }
  //     }

  //     params.providerId = searchId;
  //     params.pageCount = this.pageCount;
  //     params.lastKey = this.lastKeys.get(searchPage);
  //   } else {// 전체조회
  //     this.lastKeys = new Map()
  //     this.lastKeys.set(1, null);
  //     params.providerId = '';
  //     params.pageCount = this.pageCount;
  //     params.lastKey = this.lastKeys.get(searchPage);
  //   }

  //   try {
  //     const response = await getDeliveryList(params);

  //     if (!response || !response.data || !response.data.success) {
  //       throw new Error('실패');
  //     }

  //     const { data } = response.data;
  //     let isLast = false;

  //     if (data.lastKey) {
  //       if (this.lastKeys.get(searchPage+1) === undefined) {
  //         this.lastKeys.set((searchPage+1), data.lastKey);
  //       }
  //     } else {
  //       isLast = true;
  //     }

  //     if (data.items) {
  //       this.setState({
  //         gridList: data.items,
  //         isLast,
  //         page : searchPage,
  //         providerId : searchId
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  onChangeSearchForm = (key, value) => {
    if (key === 'id') {
      this.setState({
        resultId: value
      });
    }

    if (key === 'provider') {
      this.setState({
        resultName: value
      });
    }
  };

  // 결제 취소
  handleCancelPayment = async (storeId, paymentUid, flagForceCancel) => {
    try {
      const result = await cancelPayment(storeId, paymentUid, flagForceCancel);
      if (result.data.success) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleChangeSelect = e => {
    const targetValue = e.target.value;
    const { lastSearchParams } = this.state;

    this.setState(
      {
        selectListCount: targetValue
      },
      () => {
        this.onSearch({
          type: lastSearchParams.type,
          keyword: lastSearchParams.keyword,
          id: lastSearchParams.id
        });
      }
    );

    e.stopPropagation();
  };

  handleChangeStatusSelect = e => {
    const targetValue = e.target.value;

    this.setState({
      selectToStaus: targetValue
    });

    e.stopPropagation();
  };

  render() {
    const {
      gridList,
      page,
      isLast,
      resultId,
      resultName,
      selectListCount,
      selectToStaus,
      isLoading
    } = this.state;

    return (
      <Fragment>
        <DeliveryAdjList
          gridList={gridList}
          onSearch={this.onSearch}
          handleMovePage={this.handleMovePage}
          // moveInvoice={this.moveInvoice}
          onChangeSearchForm={this.onChangeSearchForm}
          handleCancelPayment={this.handleCancelPayment}
          page={page}
          isLast={isLast}
          resultId={resultId}
          resultName={resultName}
          selectListCount={selectListCount}
          selectToStaus={selectToStaus}
          onChangeSelect={this.handleChangeSelect}
          onChangeStatusSelect={this.handleChangeStatusSelect}
          isLoading={isLoading}
        />
      </Fragment>
    );
  }
}

DeliveryAdjustmentContainer.propTypes = {
  history: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

DeliveryAdjustmentContainer.defaultProps = {
  history: null
};

export default withRouter(DeliveryAdjustmentContainer);
