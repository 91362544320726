import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getSearchProvider } from 'api/provider';

import ProviderSearchForm from 'components/provider/ProviderSearchForm';

class ProviderContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: '',
      providerList: []
    };
  }

  /* 입력 함수 */
  handleChangeInputText = (e, text) => {
    this.setState({
      [text]: e.target.value
    });
  };

  handleGetSearchProvider = async () => {
    const { keyword } = this.state;

    try {
      const response = await getSearchProvider(keyword);

      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }

      const { data } = response.data;

      this.setState({
        providerList: data
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleMoveDelivery = id => {
    const { history } = this.props;
    history.push(`/provider/${id}/delivery`);
  };

  render() {
    const { keyword, providerList } = this.state;

    return (
      <Fragment>
        <ProviderSearchForm
          keyword={keyword}
          providerList={providerList}
          onChangeKeyword={e => {
            this.handleChangeInputText(e, 'keyword');
          }}
          onGetSearchData={this.handleGetSearchProvider}
          onMoveDelivery={this.handleMoveDelivery}
        />
      </Fragment>
    );
  }
}

ProviderContainer.propTypes = {
  history: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ProviderContainer.defaultProps = {
  history: null
};

export default withRouter(ProviderContainer);
