import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { reduxForm, Field, change } from 'redux-form';
import RenderField from '../reduxForm/RenderField';

const style = {
  width: '300px',
  margin: '0 auto'
};

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    const { email, dispatch } = this.props;
    if (prevProps.email !== email) {
      // redux-form, set data
      dispatch(change('adminLoginForm', 'email', email));
    }
  }

  initialize = () => {};

  render() {
    const {
      onSubmit,
      handleSubmit,
      onChangePassword,
      isInitial,
      isConfirm,
      validMessageId,
      validMessagePassword,
      onToggle,
      isChecked,
      isEquals,
      pristine,
      submitting
    } = this.props;

    return (
      <div style={style}>
        <div style={{ marginTop: '150px' }} className="p-3 border rounded">
          <p className="text-center h5 p-3">
            <strong>프롬 관리자</strong>
          </p>
          {isInitial ? (
            <>
              <form onSubmit={handleSubmit(onChangePassword)}>
                <div>
                  <div>
                    <Field
                      type="password"
                      name="newPassword"
                      label="비밀번호"
                      className="col-12"
                      component={RenderField}
                    />
                  </div>
                  {!isConfirm ? (
                    <p className="pl-3 pt-1 pb-3" style={{ color: 'red' }}>
                      <small>적합한 비밀번호가 아닙니다.</small>
                    </p>
                  ) : null}
                  <div>
                    <Field
                      type="password"
                      name="newPasswordRe"
                      label="비밀번호 확인"
                      className="col-12"
                      component={RenderField}
                    />
                  </div>
                  {!isEquals ? (
                    <p className="pl-3 pt-1 pb-3" style={{ color: 'red' }}>
                      <small>비밀번호가 일치하지 않습니다.</small>
                    </p>
                  ) : null}
                  <div className="p-3">
                    <button
                      type="submit"
                      className="btn btn-outline-secondary btn-block"
                    >
                      비밀번호변경
                    </button>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div>
                    <Field
                      type="text"
                      name="email"
                      label="아이디"
                      className="col-12"
                      component={RenderField}
                    />
                  </div>
                  <p className="pl-3 pt-1 pb-3" style={{ color: 'red' }}>
                    <small>{validMessageId}</small>
                  </p>
                  <div>
                    <Field
                      type="password"
                      name="password"
                      label="비밀번호"
                      className="col-12"
                      component={RenderField}
                    />
                  </div>
                  <p className="pl-3 pt-1 pb-3" style={{ color: 'red' }}>
                    <small>{validMessagePassword}</small>
                  </p>
                  <div
                    className="form-check form-check-inline"
                    style={{ width: '100%', paddingLeft: '1rem' }}
                  >
                    <label className="form-check-label" htmlFor="checkbox">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkbox"
                        name="saveId"
                        checked={isChecked}
                        onChange={onToggle}
                        value="0"
                        style={{ WebkitAppearance: 'checkbox' }}
                      />
                      <small>아이디 저장</small>
                    </label>
                    <span
                      className="position-absolute"
                      style={{ right: '15px' }}
                    >
                      <small>
                        <Link to={{ pathname: `/authentication` }}>
                          <button type="button">비밀번호 찾기</button>
                        </Link>
                      </small>
                    </span>
                  </div>
                  <div className="p-3">
                    <button
                      type="submit"
                      className="btn btn-outline-secondary btn-block"
                      disabled={pristine || submitting}
                    >
                      로그인
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  onChangePassword: PropTypes.func,
  onToggle: PropTypes.func,
  isInitial: PropTypes.bool,
  isConfirm: PropTypes.bool,
  validMessageId: PropTypes.string,
  validMessagePassword: PropTypes.string,
  isChecked: PropTypes.bool,
  isEquals: PropTypes.bool,
  email: PropTypes.string,
  dispatch: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool
};

LoginForm.defaultProps = {
  onSubmit: () => {},
  handleSubmit: () => {},
  onChangePassword: () => {},
  onToggle: () => {},
  isInitial: false,
  isConfirm: true,
  validMessageId: '',
  validMessagePassword: '',
  isChecked: false,
  isEquals: false,
  email: '',
  dispatch: () => {},
  pristine: false,
  submitting: false
};

export default reduxForm({ form: 'adminLoginForm' })(LoginForm);
