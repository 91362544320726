import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import RenderField from 'components/reduxForm/RenderField';
import RenderSelect from 'components/reduxForm/RenderSelect';
import RenderFieldDefaultRadio from 'components/reduxForm/RenderFieldDefaultRadio';

import './DynamicSearchForm.scss';

class DynamicSearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      q: null,
      s: null
    };
  }

  componentDidMount() {
    const { dynamicSchema } = this.props;
    
    this.setState({
      dynamicSchema
    });

    this.changeForm();
  }

  componentDidUpdate(prevProps) {
    const { selector } = this.props;

    if (prevProps.selector !== selector) {
      this.changeForm();
    }
  }

  // form 변경
  changeForm = () => {
    const { dynamicSchema, selector } = this.props;

    if (dynamicSchema && dynamicSchema[selector]) {
      let q = null;
      let s = null;

      if (dynamicSchema[selector].fields.q) {
        q = this.drawFields(dynamicSchema[selector].fields.q, 'q');
      }

      if (dynamicSchema[selector].fields.s) {
        s = this.drawFields(dynamicSchema[selector].fields.s, 's');
      }

      this.setState({
        q,
        s
      });
    }
  };

  drawFields = (fields, type) => {
    let resultFields;
    
    // 검색조건
    if (type === 'q') {
      // eslint-disable-next-line
      resultFields = fields.items.map(item => {
        let field;
        
        if (item.type === 'text') {
          field = (
            <Field
              key={`${item.type}${item.name}`}
              type={item.type}
              name={item.name}
              label={item.label}
              component={RenderField}
            />
          );
        } else if (item.type === 'select') {
          if (item.options) {
            field = (
              <Field
                name={item.name}
                type={item.type}
                label={item.label}
                component={RenderSelect}
              >
                {
                  item.options.map((option, i) => {
                    const opt = (
                      <option
                        key={`${option.value}${option.text}`}
                        value={option.value}
                        selected={i === 0}
                      >
                        {option.text}
                      </option>
                    )
                    return opt;
                  })
                }
              </Field>
            )
          }
        } else if (item.type === 'date') {
          if (item.options) {
            const d = item.options.map((option, i) => {
              const date = (
                <div className={`col-6 p-0 ${i === 0 ? 'pr-1' : 'pl-1'}`}>
                  <Field
                    key={`${option.name}`}
                    type={item.type}
                    name={option.name}
                    label={option.label}
                    component={RenderField}
                  />
                </div>
              )
              return date;
            })

            field = (
              <div className="container-fluid">
                <div className="row">
                  {d}
                </div>
              </div>
            )
          }
        }

        return field;
      });
    }

    // 정렬
    if (type === 's') {
      const { onChangeField } = this.props;
      let fieldName = '';

      resultFields = (
        <Fragment>
          <Field
            name="op_order"
            type="select"
            label=""
            component={RenderSelect}
            onChange={e => {
              const obj = fields.items.filter(item => {
                if (item.original === e.target.value) {
                  return true;
                }
                return false;
              });

              onChangeField('op_order', obj.name);
            }}
          >
            {fields.items
              ? fields.items.map((item, i) => {
                  if (i === 0) {
                    fieldName = item.original;
                  }
                  const option = (
                    <option
                      key={`${item.type}${item.name}`}
                      value={item.original}
                    >
                      {item.label}
                    </option>
                  );
                  return option;
                })
              : null}
          </Field>
          <div className="form-group input-radio">
            <Field
              name="orderBy"
              className="col-12 input-radio"
              component={RenderFieldDefaultRadio}
              label="desc"
              type="radio-a"
              enum="desc"
            />
            <Field
              name="orderBy"
              className="col-12 input-radio"
              component={RenderFieldDefaultRadio}
              label="asc"
              type="radio-a"
              enum="asc"
            />
          </div>
        </Fragment>
      );

      onChangeField('op_order', fieldName);
      onChangeField('orderBy', 'desc');
    }

    const result = (
      <Fragment>
        <div>
          <strong>{fields.label}</strong>
        </div>
        <br />
        <div>{resultFields}</div>
      </Fragment>
    );
    return result;
  };

  render() {
    const {
      types,
      selector,
      onChangeSearch,
      handleSubmit,
      onSubmit,
      isRadio,
      loading
    } = this.props;
    const { dynamicSchema, q, s} = this.state;

    return (
      <Fragment>
        <div className="container-fluid border">
          <div className="row p-2">
            <div className="col-2 border p-2">
              {types.map(type => {
                const searchType = (
                  <div key={`${type}`} className="form-group input-radio">
                    <input
                      id={`search_${type}`}
                      type="radio"
                      value={type}
                      name="searchType"
                      defaultChecked={selector === type ? 'checked' : ''}
                      disabled={isRadio}
                      onChange={() => {
                        if (onChangeSearch) {
                          onChangeSearch(type);
                        }
                      }}
                    />
                    &nbsp;&nbsp;
                    <label
                      className="form-radio-label"
                      htmlFor={`search_${type}`}
                    >
                      {dynamicSchema ? dynamicSchema[type].alias : null}
                    </label>
                  </div>
                );
                return searchType;
              })}
            </div>
            <div className="col-10 p-3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-10">
                      {q}
                      {s}
                    </div>
                    <div className="col-2">
                      {dynamicSchema ? (
                        <div className="row">
                          <div className="col-12 text-right">
                            <span className="p-1">
                              <button type="submit" className="btn btn-primary" disabled={loading}>
                                검색
                              </button>
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

DynamicSearchForm.propTypes = {
  types: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  dynamicSchema: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selector: PropTypes.string,
  onChangeSearch: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  onChangeField: PropTypes.func,
  isRadio: PropTypes.bool,
  loading: PropTypes.bool
};

DynamicSearchForm.defaultProps = {
  types: [],
  dynamicSchema: {},
  selector: '',
  onChangeSearch: () => {},
  handleSubmit: () => {},
  onSubmit: () => {},
  onChangeField: () => {},
  isRadio: false,
  loading: false
};

export default reduxForm({ form: 'dynamicSearchForm' })(DynamicSearchForm);
