import axios from './defaultAxios';
import adminAxios from './adminAxios';

export function getDiscovers(params) {
  return axios.get('/admin/discover', {
    params
  });
}

export function getDiscover(discoverId) {
  return axios.get(`/discover/${discoverId}`);
}

export function updateDiscover(values) {
  return axios.put(
    `/store/${values.storeId}/discovery/${values.discoverId}`,
    values,
  );
}

export function sendDiscoverSNS(values) {
  return adminAxios.post(`/discovery/provider/notify`, values);
}

// 발굴 상태 업데이트
export function putDiscoverStatus(values) {
  return axios.put(`/discover/${values.discoverId}/discoverstatus`, values);
}

// 중복된 데이터가 있는지 확인하기 위해 검색한 정보를 가지고 온다.
export function getSearchedDiscover(keyword) {
  return axios.get(`/discover/search`, {
    params: {
      keyword
    }
  });
}

// 발굴성공 또는 실패처리 api: 1Store-admin-1
// values: changeStatusProcess, message, product
export function updateDiscoverResult(values) {
  return adminAxios.post(`/discovery/${values.discoverId}/result`, values);
}
