import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import storage from 'lib/storage';

import { getUserList } from 'api/user';

import UserList from 'components/user/UserList'

class UserContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gridList : [],
      page : 1,
      isLast : false,
      resultType : '',
      resultKeyword : ''
    };

    this.pageCount = 20;
    this.lastKeys = new Map();
  }

  componentDidMount() {
    this.initialize();
  }

  /**
   * 초기화
   * 배송관리는 초기 목록 없이 시작하기로 함(기존 것 그대로)
   * 추후에 변경될 여지는 있음
   */
  initialize = () => {
    const param = storage.get('userHistory');
    // 세팅 후 검색
    if (param) {
      this.setState({
        page : param.page,
        isLast : param.isLast,
        resultType : param.type,
        resultKeyword : param.keyword
      })

      const {keys} = param;
      
      if (keys && keys.length > 0) {
        const lastKeys = new Map();
        keys.map(obj => lastKeys.set(obj.key, obj.value));
        this.lastKeys = lastKeys;
      }

      // param.status = 'history';
      this.onSearch(param);

      storage.remove('userHistory');
    } else {
      this.onSearch();
    }
  };

  
  handleMovePage = (id, param) => {
    const {history} = this.props;
    const {page , isLast} = this.state;    

    /* eslint no-param-reassign: ["error", { "props": false }] */
    param.page = page;
    /* eslint no-param-reassign: ["error", { "props": false }] */
    param.isLast = isLast;

    const keys = [];
    const keyList = [ ...this.lastKeys.keys() ];
    
    keyList.map(number => keys.push({key: number, value: this.lastKeys.get(number)}));
    param.keys = keys;
    storage.set('userHistory', param);
    
    history.push({
      pathname : `/user/register/${id}`,
      state : {id}
    })
  }

  onSearch = async (searchKeywords) => {
    // 검색 페이지 번호
    let searchPage = 1;
    // 검색엔진 검색조건
    let query = `?s=${encodeURIComponent('createDate=desc')}`;

    // 검색 조건이 있음
    if (searchKeywords) {
      const {page, type, keyword} = searchKeywords;
      searchPage = (undefined === page ? 1 : page);
      
      let searchKeyword;
      
      // 페이징이 있으면 기존 검색 조건으로 페이징
      if (page) {
        const {resultType, resultKeyword} = this.state;

        if (type && keyword) {
          searchKeyword = encodeURIComponent(`${type}=${keyword}`);
        } else {
          searchKeyword = encodeURIComponent(`${resultType}=${resultKeyword}`);
        }
        
        query = `${query}&q=${searchKeyword}`;
        if (this.lastKeys.get((searchPage)) !== null) {
          query = `${query}&k=${encodeURIComponent(this.lastKeys.get((searchPage)))}`;
        }
      } else {
        // 페이징이 없으면 신규로 조회
        if (keyword !== undefined && keyword !== '') {
          // searchKeyword = encodeURIComponent(`${type}=${keyword}`);
          searchKeyword = `${type}=${keyword}`;
          query = `${query}&q=${searchKeyword}`;
          this.setState({
            resultType : type,
            resultKeyword : keyword
          })
        } else {
          this.setState({
            resultType : '',
            resultKeyword : ''
          })
        }

        this.lastKeys = new Map()
        this.lastKeys.set(1, null);
      }
    } else {
      this.lastKeys = new Map()
      this.lastKeys.set(1, null);
    }
    
    try {
      const response = await getUserList(query);
      
      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }
      
      const { data } = response.data;
      let isLast = false;
      
      if (data.lastKey) {
        if (this.lastKeys.get(searchPage + 1) === undefined) {
          this.lastKeys.set((searchPage + 1), data.lastKey);
        }
      } else {
        isLast = true;
      }

      if (data.items) {
        this.setState({
          gridList: data.items,
          isLast,
          page : searchPage
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {gridList, page, isLast, resultType, resultKeyword} = this.state;
    const searchParam = {
      'type' : resultType,
      'keyword' : resultKeyword
    }

    return (
      <Fragment>  
        <UserList
          gridList={gridList} 
          onSearch={this.onSearch} 
          handleMovePage={this.handleMovePage}
          page={page}
          isLast={isLast}
          searchParam={searchParam}
        />
      </Fragment>
    );
  }
}

UserContainer.propTypes = {
  history: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

UserContainer.defaultProps = {
  history: null
};

export default withRouter(UserContainer);