import React, { Component } from 'react';

import { getDiscovers } from 'api/discover';

import DiscoverWaitList from 'components/discover/DiscoverWaitList';

class DiscoverWaitListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      discoverList: []
    };
  }

  componentDidMount() {
    this.getDiscoverList();

    this.pageCount = 20;
    this.prevKeyList = [];
    this.lastKey = null;
  }

  getDiscoverList = async () => {
    try {
      const { discoverList } = this.state;

      const response = await getDiscovers({
        pageCount: this.pageCount,
        lastKey: this.lastKey
      });

      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }

      const { data } = response.data;

      this.setState({
        discoverList: discoverList.concat(data.items)
      });

      this.lastKey = data.lastKey;

      if (data.lastKey) {
        this.getDiscoverList();
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { discoverList } = this.state;

    return (
      <DiscoverWaitList
        discoverWaitList={discoverList.filter(
          item => item.nowStatusProcess === 0 || item.nowStatusProcess === 1,
        )}
        onMoveTempAdmin={this.handleMoveTempAdmin}
      />
    );
  }
}

export default DiscoverWaitListContainer;
