import React from 'react';
import { reduxForm, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';

// import { mendatoryValidation } from "lib/form/validation";
import RenderOptionPriceListField from 'components/product/RegisterProductForm/RenderOptionPriceListField';
import './OptionPriceListForm.scss';

function OptionPriceListForm({
  handleSubmit,
  isSubmitLoading,
  onSubmit,
  options,
  onChangePrice,
  // eslint-disable-next-line react/prop-types
  onChangeOptionImage,
  onSwapIndex,
  onClickDeleteOption,
  defaultRate
}) {
  return (
    <div className="stop-selling-product-form">
      <h1 className="font-weight-bold mt-4 mb-4">옵션 가격 관리</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray
          name="options"
          component={RenderOptionPriceListField}
          onChangePrice={onChangePrice}
          onChangeOptionImage={onChangeOptionImage}
          onSwapIndex={onSwapIndex}
          onClickDeleteOption={onClickDeleteOption}
          options={options}
          defaultRate={defaultRate}
        />
        <div className="product-regist-buttons">
          <button
            type="submit"
            className={`btn btn-primary ${isSubmitLoading ? 'disabled' : ''}`}
            isLoading={isSubmitLoading}
            disabled={isSubmitLoading}
          >
            수정
          </button>
        </div>
      </form>
    </div>
  );
}

OptionPriceListForm.propTypes = {
  onSubmit: PropTypes.func,
  onSwapIndex: PropTypes.func,
  onClickDeleteOption: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitLoading: PropTypes.bool,
  onChangePrice: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  defaultRate: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

OptionPriceListForm.defaultProps = {
  onSubmit: () => {},
  onSwapIndex: () => {},
  onClickDeleteOption: () => {},
  handleSubmit: () => {},
  isSubmitLoading: false,
  onChangePrice: () => {},
  options: [],
  defaultRate: {}
};

export default reduxForm({
  form: 'optionPriceListForm',
  initialValues: {}
})(OptionPriceListForm);
