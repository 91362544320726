import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from 'components/modal/ModalDialog';

import './MessageModal.scss';

function MessageModal({
  contentText,
  buttonText,
  isOpen,
  onToggle,
  onClick,
  isShownProcessImg
}) {
  return (
    <ModalDialog
      className="text-center text-primary"
      size="sm"
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <div className="mt-3">
        <div className="mb-3 logo-modal-content">
          {contentText.split('\n').map(line => (
            <span key={line}>
              {line}
              <br />
            </span>
          ))}
        </div>
        {isShownProcessImg ? ( // 발굴요청 성공시 팝업에서 발굴 과정 이미지 출력
          <img src="/images/discover-status-process.svg" alt="발굴과정" />
        ) : (
          ''
        )}
      </div>
      <div className="text-center mt-3">
        <button type="button" className="btn btn-primary" onClick={onClick}>
          {buttonText}
        </button>
      </div>
    </ModalDialog>
  );
}

MessageModal.propTypes = {
  contentText: PropTypes.string,
  buttonText: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  onClick: PropTypes.func,
  isShownProcessImg: PropTypes.bool
};

MessageModal.defaultProps = {
  contentText: '',
  buttonText: '확인',
  isOpen: false,
  onToggle: () => {},
  onClick: () => {},
  isShownProcessImg: false
};

export default MessageModal;
