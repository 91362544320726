import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Cookies from 'js-cookie';
import * as userActions from 'modules/user';
import storage from 'lib/storage';
import { mendatoryValidation } from '../../lib/form/validation';
import LoginForm from '../../components/login/LoginForm';


class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInitial : false,
      userAttributes : null,
      isConfirm : true,
      validMessageId : '',
      validMessagePassword : '',
      isChecked : false,
      isEquals : true,
      email : ''
    };
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = () => {
    // 아이디 저장 여부 확인
    if (Cookies.get('admin_id')) {
      this.setState({
        isChecked : true,
        email : Cookies.get('admin_id')
      })
    }
  };

  // 체크박스 핸들러
  handleChangeCheckBox = () => {
    const {isChecked} = this.state;

    this.setState({
      isChecked: !isChecked
    });
  }

  // 로그인
  handleSubmit = async values => {
    const { UserActions, history } = this.props;
    const { userAttributes, isChecked } = this.state;

    try {
      let response = null;

      let validMessageId = this.vaildate(values.email);
      let validMessagePassword = this.vaildate(values.password);

      this.setState({
        validMessageId,
        validMessagePassword
      });

      if (validMessageId !== undefined || validMessagePassword !== undefined) {
        return;
      }

      response = await UserActions.signIn(values);

      const {challengeName, challengeParam, payload, err} = response;

      // 비밀번호 초기화
      if (undefined !== challengeName) {
        this.setState({
          isInitial : true,
          userAttributes : (userAttributes === null ? challengeParam.userAttributes : null )
        });
      } else {
        // 로그인 성공
        if (payload) {
          // 로컬 스토리지에 로그인한 유저 정보 저장
          storage.set('adminInfo', payload);

          if (isChecked) {
            Cookies.set('admin_id', values.email, { expires: 7, path: '' });
          } else {
            Cookies.remove('admin_id');
          }
          
          history.push(`/`);
        }

        if (err) {
          const {code} = err;
          // 사용자 없음
          if (code === 'UserNotFoundException') {
            validMessageId = '등록되지 않은 사용자입니다.';
          }

          // 아이디 or 비밀번호가 틀림
          if (code === 'NotAuthorizedException') {
            validMessagePassword = '아이디 혹은 비밀번호가 틀렸습니다.';
          }

          this.setState({
            validMessageId,
            validMessagePassword
          });
        }
      }
    } catch (error) {
      console.log(`error : ${error}`)
    }
  }

  // 비밀번호 변경
  handleChangePassword  = async values => {
    const { UserActions } = this.props;
    const { userAttributes } = this.state;

    const {newPassword, newPasswordRe} = values;
    
    if (newPassword !== newPasswordRe) {
      this.setState({
        isEquals: false
      });
      return;
    }

    try {
      let response = null;

      response = await UserActions.changePassword(values, userAttributes);

      const { isConfirm } = response;

      if (isConfirm) {
        // 변경완료
        this.setState({
          isInitial : false,
          userAttributes : null
        });
      }

      this.setState({
        isConfirm
      });
      
    } catch (error) {
      console.log(`error : ${error}`)
    }
  }
  
  // validation
  vaildate = (attr) => {
    const message = mendatoryValidation(attr);
    return message;
  }

  render() {
    const {isInitial,
        isConfirm,
        validMessageId,
        validMessagePassword,
        isChecked,
        isEquals,
        email} = this.state;

    return (
      <Fragment>
        <LoginForm 
          isInitial={isInitial}
          onSubmit={this.handleSubmit}
          onChangePassword={this.handleChangePassword}
          isConfirm={isConfirm}
          isEquals={isEquals}
          validMessageId={validMessageId}
          validMessagePassword={validMessagePassword}
          isChecked={isChecked}
          onToggle={this.handleChangeCheckBox}
          email={email}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  UserActions: bindActionCreators(userActions, dispatch)
});

export default compose(
  withRouter,
  connect(
    () => ({}),
    mapDispatchToProps
  )
)(LoginContainer);
