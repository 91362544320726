import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getCorrectOrientationFile } from 'lib/utils/imageUtils';

import './PictureView.scss';

class PictureView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      style: {}
    };
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate = prevProps => {
    const { imageSrc } = this.props;
    const { imageSrc: prevImageSrc } = prevProps;

    let imageName = '';
    let prevImageName = '';

    if (imageSrc === '' || imageSrc === null) {
      return;
    }

    imageName = typeof imageSrc === 'object' ? imageSrc.name : imageSrc;
    prevImageName =
      prevImageSrc && typeof prevImageSrc === 'object'
        ? prevImageSrc.name
        : prevImageSrc;

    if (imageName !== prevImageName) {
      this.initialize();
    }
  };

  setImageSrc = imageSrc => {
    this.setState({
      style: {
        backgroundImage: `url("${encodeURI(imageSrc)}")`
      }
    });
  };

  setImageFile = imageSrc => {
    const reader = new FileReader();

    getCorrectOrientationFile(imageSrc).then(newFile => {
      const changedImage = newFile;

      reader.onload = loadEvent => {
        this.setState({
          style: { backgroundImage: `url('${loadEvent.target.result}')` }
        });
      };

      reader.readAsDataURL(changedImage);
    });
  };

  initialize = () => {
    const { imageSrc } = this.props;

    if (imageSrc === '' || imageSrc === null) {
      return;
    }
    
    if (typeof imageSrc === 'string') {
      this.setImageSrc(imageSrc);

      return;
    }

    this.setImageFile(imageSrc);
  };

  render() {
    const { style } = this.state;
    const { onClick } = this.props;

    return (
      <div className="picture-view" style={style}>
        <button type="button" className="picture-view-btn" onClick={onClick}>
          닫기
        </button>
      </div>
    );
  }
}

PictureView.propTypes = {
  imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func
};

PictureView.defaultProps = {
  imageSrc: null,
  onClick: () => {}
};
export default PictureView;
