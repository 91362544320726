import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, change } from 'redux-form';

import SalesLimitProductForm from 'components/product/SalesLimitProductForm';
import AlertModal from 'components/modal/AlertModal';
import MessageModal from 'components/modal/MessageModal';

import { getProductAndStatus, updateProductSetting } from 'api/product';

class SalesLimitContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenErrorModal: false,
      isOpenSuccessModal: false,
      errorMessage: '',
      successMessage: ''
    };
  }

  componentDidMount = () => {
    this.initialize();
  };

  // productId 있을때 - product정보 api호출해서 폼 채워주는 함수
  getProductInfo = async productId => {
    try {
      const response = await getProductAndStatus(productId);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`상품정보 조히 실패 - ${response}`);
      }

      const { changeField, onSendProductName } = this.props;
      const { data } = response.data;

      this.productData = data;

      const { countOfSalesLimit, quantityOnHand, productName } = data;

      // check value - countOfSalesLimit
      const flagSalesLimit = countOfSalesLimit ? '1' : '0';

      onSendProductName(productName);
      changeField('flagSalesLimit', flagSalesLimit);
      changeField('countOfSalesLimit', countOfSalesLimit);
      changeField('quantityOnHand', quantityOnHand);
    } catch (e) {
      console.error(e);
      this.setState({
        errorMessage:
          '상품 정보 데이터를 가져오던 도중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.',
        isOpenErrorModal: true
      });
    }
  };

  initialize = () => {
    const { productId } = this.props;
    console.log(productId);
    const isHaveProductId = productId.charAt(0) === 'p';

    if (productId) {
      this.setState(
        {
          id: isHaveProductId ? productId : null
        },
        () => {
          const { id } = this.state;

          if (id) {
            this.getProductInfo(id);
          } else {
            this.setState({
              isOpenErrorModal: true,
              errorMessage: '발굴상태입니다',
              isDiscover: true
            });
          }
        }
      );
    }
  };

  handleSubmit = values => {
    this.setState(
      {
        isSubmitLoading: true
      },
      async () => {
        console.log({ values });

        const params = {};

        const { productId, flagSalesLimit } = this.props;
        const { countOfSalesLimit, quantityOnHand } = values;

        params.productId = productId;
        // string -> int형변환
        params.countOfSalesLimit =
          flagSalesLimit === '1' ? parseInt(countOfSalesLimit, 10) : 0;
        params.quantityOnHand = quantityOnHand
          ? parseInt(quantityOnHand, 10)
          : 0;

        console.log({ params });
        try {
          const updateType = 'SALES_LIMIT';
          const response = await updateProductSetting(params, updateType);

          if (!response || !response.data || !response.data.success) {
            throw new Error('한정수량 변경 실패');
          }

          this.setState({
            isOpenSuccessModal: true,
            successMessage: '수정 되었습니다.',
            isSubmitLoading: false
          });
        } catch {
          this.setState({
            isOpenErrorModal: true,
            errorMessage: '수정에 실패했습니다.\n잠시 후 다시 시도해주세요.',
            isSubmitLoading: false
          });
        }
      }
    );
  };

  handleMoveProductPage = () => {
    this.setState({
      isOpenSuccessModal: false
    });

    window.location.reload();
  };

  handleCloseModal = modalType => {
    // 발굴상태일시 기본정보로 이동
    const { isDiscover } = this.state;

    if (isDiscover) {
      const { history, productId } = this.props;
      history.push(`/product/${productId}/register`);
    }

    this.setState({
      [modalType]: false
    });
  };

  render() {
    const {
      isOpenErrorModal,
      isOpenSuccessModal,
      errorMessage,
      successMessage,
      isSubmitLoading
    } = this.state;
    const { flagSalesLimit } = this.props;
    return (
      <Fragment>
        <SalesLimitProductForm
          flagSalesLimit={flagSalesLimit}
          onSubmit={this.handleSubmit}
          isSubmitLoading={isSubmitLoading}
        />
        <AlertModal
          contentText={errorMessage}
          isOpen={isOpenErrorModal}
          onClick={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
        />
        <MessageModal
          buttonText="확인"
          contentText={successMessage}
          isOpen={isOpenSuccessModal}
          onClick={() => {
            this.handleMoveProductPage();
          }}
          onToggle={() => {
            this.handleMoveProductPage();
          }}
        />
      </Fragment>
    );
  }
}

const selector = formValueSelector('salesLimitProductForm');

const mapStateToProps = state => ({
  flagSalesLimit: selector(state, 'flagSalesLimit')
});

const mapDispatchToProps = dispatch => ({
  changeField: (field, value) => {
    dispatch(change('salesLimitProductForm', field, value));
  }
});

SalesLimitContainer.propTypes = {
  productId: PropTypes.string,
  flagSalesLimit: PropTypes.string,
  history: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

SalesLimitContainer.defaultProps = {
  productId: '',
  flagSalesLimit: '',
  history: null
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SalesLimitContainer);
