import axios from 'axios';

// const developApi = 'https://akuotb3hfi.execute-api.ap-northeast-2.amazonaws.com/dev';
const developApi = 'https://api.from.kr/dev';
const productApi = 'https://api.from.kr/prod'; // 아직 없음

// axios.defaults.withCredentials = true;

const defaultAxios = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? developApi : productApi,
  // withCredentials: true,

  responseType: 'json'
});

export default defaultAxios;
