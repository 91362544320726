import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

/**
 * 이전 페이지
 * @param {*} page 
 */
export const prev = (page) => {
    if ((page - 1) === 0) {
        return 1;
    }
    return (page - 1);
}

/**
 * 다음 페이지
 * @param {*} page 
 */
export const next = (page, isLast) => {
    if (isLast) {
        return page;
    }
    return (page + 1)
}

class Paging extends Component { 
    constructor (props) {
        super(props)

        this.state = {
        }
    }

  /**
   * 이전 페이지 이동
   */
  handlePrev = (page) => {
    const { handlePrev } = this.props;
    if ((page - 1) === 0) {
        return;
    }
    handlePrev((page - 1))
    window.scrollTo(0, 0);
  }

  /**
   * 다음 페이지 이동
   */
  handleNext = (page) => {
    const { isLast, handleNext } = this.props;
    
    if (isLast) {
        return;
    }

    handleNext((page + 1))
    window.scrollTo(0, 0);
  }

    render () {
        const {page} = this.props;
        return (
          <Fragment>
            <div className="btn-group" role="group">
              <button 
                className="btn btn-primary" 
                type="button" 
                onClick={() => {
                    this.handlePrev(page);
                }}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </button>
              <button className="btn btn-primary" type="button">{page}</button>
              <button 
                className="btn btn-primary" 
                type="button" 
                onClick={() => {
                    this.handleNext(page);
                }}
              >
                <FontAwesomeIcon icon={faAngleRight} />
              </button>
            </div>
          </Fragment>
        )
    }
}

Paging.propTypes = {
    handlePrev : PropTypes.func,
    handleNext : PropTypes.func,
    page : PropTypes.number,
    isLast : PropTypes.bool
};

Paging.defaultProps = {
    handlePrev : () => {},
    handleNext : () => {},
    page : 1,
    isLast : false
};

export default Paging;