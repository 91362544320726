import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {
  textFilter,
  selectFilter,
  dateFilter
} from 'react-bootstrap-table2-filter';

import { getFormatDate, fromNow } from 'lib/time';
import Loader from 'components/commons/Loader';

import './DiscoverList.scss';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';

function DiscoverList({ discoverList, discoverStatus, isLoading }) {
  const { ExportCSVButton } = CSVExport;
  const bgColor = ['bg-warning', 'bg-warning', 'bg-success', 'bg-danger'];
  const statusText = ['대기중', '심사중', '성공', '실패'];
  const statusText2 = {
    0: '대기중',
    1: '심사중',
    2: '성공',
    3: '실패'
  };
  const columns = [
    {
      dataField: 'nowStatusProcess',
      text: '발굴상태',
      formatter: cell => statusText[cell],
      classes: cell => bgColor[cell],
      style: {
        fontWeight: 'bold'
      },
      headerStyle: {
        width: '100px'
      },
      filter: selectFilter({
        options: statusText2,
        defaultValue: discoverStatus,
        placeholder: '전체'
      })
    },
    {
      dataField: 'createDate',
      text: '발굴날짜',
      sort: true,
      headerStyle: {
        width: '195px'
      },
      formatter: (cell, row) => (
        <Fragment>
          <div className="mb-2">{getFormatDate(cell, 'YYYY.MM.DD')}</div>
          <div>{fromNow(row.createDate)}</div>
        </Fragment>
      ),
      filter: dateFilter()
    },
    {
      dataField: 'productName',
      text: '발굴명',
      filter: textFilter({
        placeholder: '발굴명 입력'
      }),
      formatter: (cell, row) => {
        const { id, productName } = row;
        return (
          <Link className="label label-danger" to={`/discover/${id}/detail`}>
            <div className="mb-2">{productName}</div>
          </Link>
        );
      }
    },
    {
      dataField: 'discovererName',
      text: '발굴자명',
      headerStyle: {
        width: '150px'
      },
      filter: textFilter({
        placeholder: '발굴자명 입력'
      })
    },
    {
      dataField: 'providerName',
      text: '공급자명',
      filter: textFilter({
        placeholder: '공급자명 입력'
      }),
      formatter: (cell, row) => {
        const { providerName, providerTel, providerId } = row;
        if (providerId !== 'NOT_ASSIGN') {
          return (
            <Link
              className="label label-danger"
              to={`/provider/${providerId}/detail`}
            >
              <div className="mb-2">
                {providerName} - {providerId}
              </div>
              <div>{providerTel}</div>
            </Link>
          );
        }
        return (
          <div>
            <div className="mb-2">{providerName}</div>
            <div>{providerTel}</div>
          </div>
        );
      }
    },
    {
      dataField: 'df2',
      isDummyField: true,
      text: '담당자',
      formatter: (cellContent, row) => {
        const { picName, picMobile, picEmail } = row;
        if (picName || picMobile || picEmail) {
          return (
            <div>
              <div>{picName}</div>
              <div>{picMobile}</div>
              <div>{picEmail}</div>
            </div>
          );
        }
        return null;
      }
    }
  ];

  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     console.log(row, rowIndex);
  //     onMoveDiscoverDetail(row.id);
  //   }
  // };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ToolkitProvider
      keyField="id"
      data={discoverList}
      columns={columns}
      exportCSV
    >
      {props => (
        <Fragment>
          <div className="h3">발굴조회</div>
          <ExportCSVButton {...props.csvProps}>Export CSV!!</ExportCSVButton>
          <hr />
          <BootstrapTable
            {...props.baseProps}
            pagination={paginationFactory()}
            filter={filterFactory()}
            headerClasses="header-class"
          />
        </Fragment>
      )}
    </ToolkitProvider>
  );
}

DiscoverList.propTypes = {
  discoverList: PropTypes.arrayOf(PropTypes.shape({})),
  onMoveDiscoverEdit: PropTypes.func
};

DiscoverList.defaultProps = {
  discoverList: [],
  onMoveDiscoverEdit: () => {}
};

export default DiscoverList;
