import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DatePicker from 'react-datepicker';

import { getFormatDate } from 'lib/time';

import 'react-datepicker/dist/react-datepicker.css';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';

function ProviderAdjustmentList({
  providerDeliveryList,
  onGetSearchData,
  keyword,
  onChangeKeyword,
  onOpenProviderModal,
  providerName,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onGetAdjustment
}) {
  const search = e => {
    if (e.keyCode !== 13) {
      // not enter;

      return;
    }

    onGetSearchData();
  };
  const { ExportCSVButton } = CSVExport;

  const columns = [
    {
      dataField: 'paymentAfterDate',
      text: '결제 날짜',
      sort: true,
      formatter: cell => getFormatDate(cell, 'YYYY.MM.DD HH:mm'),
      csvFormatter: cell => getFormatDate(cell, 'YYYY.MM.DD HH:mm')
    },
    {
      dataField: 'payType',
      text: '구매/선물',
      formatter: cell => {
        if (cell === 'gift') {
          return <div>선물</div>;
        }
        return <div>구매</div>;
      },
      csvFormatter: cell => {
        if (cell === 'gift') {
          return `선물`;
        }
        return `구매`;
      },
      headerStyle: {
        width: '90px'
      }
    },
    {
      dataField: 'flagDelivery',
      text: '배송유/무',
      formatter: cell => {
        if (cell === 1) {
          return <div>유</div>;
        }
        return <div>무</div>;
      },
      csvFormatter: cell => {
        if (cell === 1) {
          return `유`;
        }
        return `무`;
      },
      headerStyle: {
        width: '90px'
      }
    },
    {
      dataField: 'paymentUid',
      text: 'id',
      headerStyle: {
        width: '190px'
      }
    },
    {
      dataField: 'productName',
      text: '상품명'
    },
    {
      dataField: 'paidCount',
      text: '수량',
      headerStyle: {
        width: '55px'
      }
    },
    {
      dataField: 'dfPrice',
      text: '가격',
      isDummyField: true,
      formatter: (cellContent, row) => {
        const { selectOptList } = row;
        return selectOptList.map(value => (
          <div>
            {value.option} - {value.price} x {value.amount}
          </div>
        ));
      },
      csvFormatter: (cellContent, row) => {
        const { selectOptList } = row;
        return selectOptList.map(
          value => `${value.option} - ${value.price} x ${value.amount}\n`,
        );
      }
    },
    {
      dataField: 'dfProviderPrice',
      text: '공급자정산가',
      isDummyField: true,
      formatter: (cellContent, row) => {
        const { paidOptList } = row;
        return paidOptList.map(value => <div>{value.provider}</div>);
      },
      csvFormatter: (cellContent, row) => {
        const { paidOptList } = row;
        return paidOptList.map(value => `${value.provider}\n`);
      }
    },
    {
      dataField: 'dfdeliveyprice',
      text: '배송비',
      isDummyField: true,
      formatter: (cellContent, row) => {
        const { paidAmount, paidOptList } = row;
        const arr = paidOptList.map(
          value => value.provider + value.cnx + value.saveSum,
        );
        let sum = 0;
        arr.forEach(each => {
          sum += each;
        });
        return <div>{paidAmount - sum + paidOptList[0].paidSaveMoney}</div>;
      },
      csvFormatter: (cellContent, row) => {
        const { paidAmount, paidOptList } = row;
        const arr = paidOptList.map(
          value => value.provider + value.cnx + value.saveSum,
        );
        let sum = 0;
        arr.forEach(each => {
          sum += each;
        });
        return `${paidAmount - sum + paidOptList[0].paidSaveMoney}`;
      }
    }
  ];

  return (
    <Fragment>
      <ToolkitProvider
        keyField="id"
        data={providerDeliveryList}
        columns={columns}
        exportCSV={{
          fileName: `${providerName}_${keyword}_정산.csv`
        }}
      >
        {props => (
          <div className="discoverlist">
            <h4 className="h4">정산관리</h4>
            <ExportCSVButton {...props.csvProps}>Export CSV!!</ExportCSVButton>
            <hr />
            <div>
              <div className="input-group mb-3 col-sm-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="공급자 찾기"
                  value={keyword}
                  onFocus={onOpenProviderModal}
                  onChange={onChangeKeyword}
                  onKeyUp={search}
                />
              </div>
              <div className="d-inline-block mb-3">
                <DatePicker
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  onChange={onStartDateChange}
                  className="form-control"
                  dateFormat="yyyy-MM-dd HH:mm"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                />
                <span className="ml-3 mr-3">~</span>
                <DatePicker
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  onChange={onEndDateChange}
                  className="form-control"
                  dateFormat="yyyy-MM-dd HH:mm"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                />
              </div>
              <div className="d-inline-block form-groub col-sm-3">
                <button
                  type="button"
                  className="btn btn-info mb-2"
                  onClick={onGetAdjustment}
                >
                  정산검색
                </button>
              </div>
            </div>
            {/* <div className="form-groub col-sm-3">
              <button
                type="button"
                className="btn btn-info mb-2"
                onClick={onOpenProviderModal}
              >
                공급자검색해보기
              </button>
            </div> */}
            <BootstrapTable
              {...props.baseProps}
              pagination={paginationFactory()}
            />
          </div>
        )}
      </ToolkitProvider>
    </Fragment>
  );
}

// ProviderDeliveryList.propTypes = {
//   discoverList: PropTypes.arrayOf(PropTypes.shape({}))
//   // onClickRow: PropTypes.func
// };

// ProviderDeliveryList.defaultProps = {
//   discoverList: []
//   // onClickRow: () => {}
// };

export default ProviderAdjustmentList;
