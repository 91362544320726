import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ModalDialog from 'components/modal/ModalDialog';
import renderHTML from 'react-render-html';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import SearchForm from 'components/commons/SearchForm/index';
import { changeFormatDate, getFormatTimeStempDate } from 'lib/time';
import './GiftList.scss';
import { sendNotice } from 'api/sms';
import { getHost } from 'lib/utils/envUtils';

// import { updateRecipient /*, updateReceiver */ } from 'api/delivery';
import Paging from 'components/commons/Paging/Index';

const options = [
  {
    id: 1,
    type: 'S', // select
    data: [
      { text: '전체', id: 1 },
      { text: '수신', id: 2 },
      { text: '미수신', id: 3 }
    ],
    fnName: 'receive'
  },
  {
    id: 2,
    type: 'M', // modal
    data: { value: 'P' },
    fnName: 'buyer'
  }
];

/**
 * 메세지 출력 테스트
 * @param {*} productName
 * @param {*} option
 * @param {*} amount
 * @param {*} name
 * @param {*} buyerNumber
 * @param {*} id
 * @param {*} target
 */
// eslint-disable-next-line
export const makerMessage = (
  productName,
  option,
  amount,
  name,
  buyerNumber,
  id,
  target
) => {
  const number =
    buyerNumber !== undefined
      ? `${buyerNumber.substring(0, 3)}-${buyerNumber.substring(
          3,
          7
        )}-${buyerNumber.substring(7, 11)}`
      : '0';

  const host = getHost();
  const giftbox = `${host}/store/${id}/setting/giftbox/send`;
  const gift = `${host}/gift/${id}`;

  // 수신자명, 상품, 옵션
  const resorce = {
    buyer: `[선물 미확인]

‘${name}’님이 아직 ‘${productName}’-‘${option}’x${amount}의 배송지를 입력하지 않으셨습니다. 다시 한번 알려드리세요.

▶카카오톡으로 직접 전해주기
${giftbox}`,
    resReceiver: `[선물 미확인]

‘${name}’님(${number})이 선물하신 ‘${productName}’-‘${option}’x${amount}의 배송지를 아직 입력하지 않으셨습니다.

▶선물메시지 확인하고 배송 정보 입력하기
${gift}`
  };
  return resorce[target];
};

class GiftList extends Component {
  // 이벤트 정의
  initFunction = {
    // 수신
    receive: (value) => {
      this.setInitValues('receive', value);
    },
    // 구매자 id
    provider: (value) => {
      this.setInitValues('provider', value);
    },
    // 구매자 id
    id: (value) => {
      this.setInitValues('id', value);
    },
    // 구매자
    buyer: (value) => {
      this.setInitValues('buyer', value);
    }
  };

  constructor() {
    super();

    this.state = {
      // startDate : new Date(),
      // endDate : new Date()
      isLast: false,
      // 알림 모달
      isOpen: false,
      // 확인 모달
      isOpenConfirm: false,
      message: '',
      rowData: null
    };

    this.columns = [
      {
        dataField: 'createDate',
        text: '구매일',
        sort: true,
        formatter: (cell) => getFormatTimeStempDate(cell, 'YYYY.MM.DD HH:mm'),
        csvFormatter: (cell) =>
          getFormatTimeStempDate(cell, 'YYYY.MM.DD HH:mm'),
        headerStyle: {
          width: '80px'
        }
      },
      {
        dataField: 'product.name',
        text: '상품',
        headerStyle: {
          width: '150px'
        },
        filter: textFilter({
          placeholder: '상품명'
        })
      },
      {
        dataField: 'order.optionName',
        text: '옵션',
        headerStyle: {
          width: '150px'
        },
        formatter: (cell, row) => {
          const { order } = row;
          return <div>{order.optionName}</div>;
        },
        csvFormatter: (cell, row) => `${row.order.optionName}`,
        filter: textFilter({
          placeholder: '상품옵션'
        })
      },
      {
        dataField: 'confirmedDate',
        text: '수신일/알림',
        formatter: (cell, row) => {
          const { confirmedDate } = row;
          let obj;
          if (confirmedDate) {
            obj = (
              <div>{changeFormatDate(confirmedDate, 'YYYY.MM.DD HH:mm')}</div>
            );
          } else {
            obj = (
              <div>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-id={row.id}
                  onClick={this.handlerNotice}
                >
                  알림
                </button>
              </div>
            );
          }

          return obj;
        },
        headerStyle: {
          width: '70px'
        },
        editable: false
      },
      {
        dataField: 'buyer.name',
        text: '구매자',
        headerStyle: {
          width: '100px'
        },
        formatter: (cell, row) => {
          const { buyer } = row;
          return <div>{buyer.name}</div>;
        },
        csvFormatter: (cell, row) => `${row.buyer.name}`,
        filter: textFilter({
          placeholder: '구매자'
        })
      },
      {
        dataField: 'buyer.mobile',
        text: '연락처',
        formatter: (cell, row) => {
          const { buyer } = row;
          return <div>{buyer.mobile}</div>;
        },
        csvFormatter: (cell, row) => `${row.buyer.mobile}`,
        headerStyle: {
          width: '100px'
        }
      },
      {
        dataField: 'receiver.name',
        text: '수신자',
        formatter: (cell, row) => {
          const { receiver } = row;
          return <div>{receiver.name}</div>;
        },
        headerStyle: {
          width: '100px'
        }
      },
      {
        dataField: 'receiver.mobile',
        text: '연락처',
        formatter: (cell, row) => {
          const { receiver } = row;
          return <div>{receiver.mobile}</div>;
        },
        headerStyle: {
          width: '100px'
        }
      },
      {
        dataField: 'recipient.addr',
        text: '배송지',
        formatter: (cell, row) => {
          const { recipient } = row;

          if (recipient) {
            return (
              <div>
                <p>{recipient.zipcode}</p>
                <p>{recipient.addr}</p>
                <p>{recipient.addrSub}</p>
              </div>
            );
          }

          return null;
        }
      },
      {
        dataField: 'modify',
        text: '선물 대신 받기',
        // eslint-disable-next-line
        formatter: (cell, row) => {
          const { status } = row;

          if (status === 'ordered') {
            return (
              <div>
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => {
                    this.handleModify(row);
                  }}
                >
                  주소입력 및 확인
                </button>
              </div>
            );
          }

          return null;
        },

        editable: false
      }
    ];
  }

  componentDidMount() {}

  /**
   * 알림 모달 활성화
   */
  handlerNotice = (e) => {
    const { gridList } = this.props;
    const id = e.target.getAttribute('data-id');
    const row = gridList.filter((obj) => {
      if (obj.id === id) {
        return true;
      }
      return false;
    });

    this.setState({
      isOpen: true,
      rowData: row
    });
  };

  /**
   * 수정 페이지로 이동
   */
  handleModify = (row) => {
    const { handleUpdateReceiver } = this.props;
    const { id, status, flagDelivery, receiver, recipient } = row;
    handleUpdateReceiver(id, status, flagDelivery, receiver, recipient);
  };

  /**
   * 알림 모달 취소
   */
  handleCloseModal = () => {
    this.setState({
      isOpen: false
    });
  };

  /**
   * 확인 모달 취소
   */
  handleConfirmCloseModal = () => {
    this.setState({
      isOpenConfirm: false,
      isOpen: false
    });
  };

  /**
   * init value
   */
  setInitValues = (column, value) => {
    this.setState({
      [column]: value
    });
  };

  /**
   * bootstrap talbe row, button click
   */
  onSelectCellButton = (row, isSelect, rowIndex, e) => {
    console.log(row);
    console.log(isSelect);
    console.log(rowIndex);
    console.log(e);
  };

  /**
   * 이전 페이지 이동
   */
  handlePrev = (page) => {
    this.setState({
      isLast: false
    });

    this.onSearch(page);
  };

  /**
   * 다음 페이지 이동
   */
  handleNext = (page) => {
    const { totalCount } = this.props;
    const isLast = page === Math.ceil(totalCount / 100);

    this.setState({
      isLast
    });

    this.onSearch(page);
  };

  /**
   * @param integer, integer
   */
  onSearch = (page) => {
    const { onSearch } = this.props;

    onSearch({
      page
    });
  };

  /**
   * @param integer, integer
   */
  onSubmit = () => {
    const { onSearch } = this.props;
    const stat = this.state;
    onSearch(stat);
  };

  /**
   * 메세지 전송
   */
  handlerSend = async () => {
    try {
      const { rowData } = this.state;

      let buyerNumber = '';
      let receiverNumber = '';
      let message = '';

      if (rowData) {
        const { buyer, receiver } = rowData[0];

        if (buyer) {
          buyerNumber = buyer.mobile;
        }

        if (receiver) {
          receiverNumber = receiver.mobile;
        }
      }

      // 구매자 메세지(상품명, 옵션, 수신자)
      message = this.makerMessage(
        rowData[0].product.name,
        rowData[0].order.optionName,
        rowData[0].order.quantity,
        rowData[0].receiver.name,
        undefined,
        rowData[0].buyer.id,
        'buyer'
      );

      let params = {
        sendNumber: '0269511234',
        subject: '프롬',
        msgBody: message,
        recvNumber: buyerNumber
      };

      let returnMessage = '';
      if (buyerNumber !== '') {
        const resBuyer = await sendNotice(params);

        if (resBuyer.status === 200) {
          returnMessage = `<p><span>*</span> 구매자 ${buyerNumber} 님에게 알림을 발송하였습니다.<p><br><p className="work-break">${message}</p><br><br>`;
        }
      }
      // 수신자 메세지
      message = this.makerMessage(
        rowData[0].product.name,
        rowData[0].order.optionName,
        rowData[0].order.quantity,
        rowData[0].buyer.name,
        buyerNumber,
        rowData[0].id,
        'resReceiver'
      );

      params = {
        sendNumber: '0269511234',
        subject: '프롬',
        msgBody: message,
        recvNumber: receiverNumber
      };

      if (receiverNumber !== '') {
        const resReceiver = await sendNotice(params);

        if (resReceiver.status === 200) {
          returnMessage += `<p><span>*</span> 수신자 ${receiverNumber} 님에게 알림을 발송하였습니다.<p><br><p className="work-break">${message}</p>`;
        }
      }

      if (message !== '') {
        this.setState({
          isOpenConfirm: true,
          message: returnMessage
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 메세지 만들기
   */
  makerMessage = (
    productName,
    option,
    amount,
    name,
    buyerNumber,
    id,
    target
  ) => {
    const number =
      buyerNumber !== undefined
        ? `${buyerNumber.substring(0, 3)}-${buyerNumber.substring(
            3,
            7
          )}-${buyerNumber.substring(7, 11)}`
        : '0';

    const host = getHost();
    const giftbox = `${host}/store/${id}/setting/giftbox/send`;
    const gift = `${host}/gift/${id}`;

    // 수신자명, 상품, 옵션
    const resorce = {
      buyer: `[선물 미확인]

‘${name}’님이 아직 ‘${productName}’-‘${option}’x${amount}의 배송지를 입력하지 않으셨습니다. 다시 한번 알려드리세요.

▶카카오톡으로 직접 전해주기
${giftbox}`,
      resReceiver: `[선물 미확인]

‘${name}’님(${number})이 선물하신 ‘${productName}’-‘${option}’x${amount}의 배송지를 아직 입력하지 않으셨습니다.

▶선물메시지 확인하고 배송 정보 입력하기
${gift}`
    };

    return resorce[target];
  };

  /**
   * @param params
   * @returns string
   * 문자 발송
   */
  sendMessage = async (params) => {
    let result;
    try {
      result = await sendNotice(params);
    } catch (error) {
      console.log(error);
    }
    return result;
  };

  render() {
    const { isLast, isOpen, rowData, message, isOpenConfirm } = this.state;
    const { gridList, page, totalCount } = this.props;
    // const { startDate, endDate } = this.state;
    const { ExportCSVButton } = CSVExport;
    const condition = options;

    let buyerName = '';
    let buyerNumber = '';
    let receiverName = '';
    let receiverNumber = '';
    if (rowData) {
      const { buyer, receiver } = rowData[0];

      if (buyer) {
        buyerName = buyer.name;
        buyerNumber = buyer.mobile;
      }

      if (receiver) {
        receiverName = receiver.name;
        receiverNumber = receiver.mobile;
      }
    }

    return (
      <Fragment>
        <ToolkitProvider
          keyField="id"
          data={gridList}
          columns={this.columns}
          exportCSV={{
            fileName: `미수신선물관리_.csv`
          }}
        >
          {(props) => (
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <h4 className="h4">미수신선물관리 - {totalCount}개</h4>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-8">
                  <SearchForm
                    condition={condition}
                    initFunction={this.initFunction}
                    // startDate={startDate}
                    // endDate={endDate}
                    onSubmit={this.onSubmit}
                  />
                </div>
                <div className="col-4 text-right gift-list-btn">
                  <ExportCSVButton {...props.csvProps}>
                    Export CSV <FontAwesomeIcon icon={faFileExcel} />
                  </ExportCSVButton>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <BootstrapTable
                    {...props.baseProps}
                    selectRow={{
                      mode: 'radio',
                      clickToSelect: false,
                      onSelect: (row, isSelect, rowIndex, e) => {
                        this.onSelectCellButton(row, isSelect, rowIndex, e);
                      }
                    }}
                    options={{ noDataText: '데이터가 없습니다.' }}
                    filter={filterFactory()}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col text-center">
                  <Paging
                    page={page}
                    isLast={isLast}
                    handlePrev={this.handlePrev}
                    handleNext={this.handleNext}
                  />
                </div>
              </div>
            </div>
          )}
        </ToolkitProvider>
        <ModalDialog
          className="modal-control discover-register-modal"
          isOpen={isOpen}
          onToggle={this.handleCloseModal}
          size="md"
        >
          <div className="container-fluid">
            <div className="row p-2">
              <div className="col">
                <p className="h5">알림발송</p>
              </div>
            </div>
            <div className="row p-2">
              <div className="col-6">
                <span>구매자명 : {buyerName}</span>
              </div>
              <div className="col-6">
                <span>전화번호 : {buyerNumber}</span>
              </div>
            </div>
            <div className="row p-2">
              <div className="col-6">
                <span>수신자명 : {receiverName}</span>
              </div>
              <div className="col-6">
                <span>전화번호 : {receiverNumber}</span>
              </div>
            </div>
            <div className="row p-2">
              <div className="col">
                <p>알림을 발송하시겠습니까?</p>
              </div>
            </div>
            <div className="row p-2">
              <div className="col text-center">
                <span className="px-1">
                  <button
                    className="btn btn-info"
                    type="button"
                    onClick={this.handlerSend}
                  >
                    발송
                  </button>
                </span>
                <span className="px-1">
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={this.handleCloseModal}
                  >
                    취소
                  </button>
                </span>
              </div>
            </div>
          </div>
        </ModalDialog>
        <ModalDialog
          isOpen={isOpenConfirm}
          onToggle={this.handleConfirmCloseModal}
          size="md"
        >
          <div>{renderHTML(message)}</div>
        </ModalDialog>
      </Fragment>
    );
  }
}

GiftList.propTypes = {
  onSearch: PropTypes.func,
  handleUpdateReceiver: PropTypes.func
};

GiftList.defaultProps = {
  onSearch: () => {},
  handleUpdateReceiver: () => {}
};

export default GiftList;
