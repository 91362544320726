import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  dateFilter,
  textFilter,
  selectFilter
} from "react-bootstrap-table2-filter";

import { getFormatDate, dayFromNow } from "lib/time";
import Loader from "components/commons/Loader";

import "./ProductList.scss";

function ProductList({ defaultProviderName, productList, isLoading }) {
  const deliveryText = ["미배송", "배송"];
  const deliverySelect = {
    0: "미배송",
    1: "배송"
  };
  const taxFreeText = ["과세", "면세"];
  const taxFreeSelect = {
    0: "과세",
    1: "면세"
  };
  const stopSellingText = {
    0: "판매중",
    1: "판매중 & 안내 팝업(구매가능)",
    11: "판매종료 & 안내 팝업",
    12: "판매종료 & 안내 팝업 & Redirect URL"
  };
  const stopSellingSelect = {
    1: "판매중 & 안내 팝업(구매가능)",
    11: "판매종료 & 안내 팝업",
    12: "판매종료 & 안내 팝업 & Redirect URL"
  };

  const bestSelect = { true: "프롬스픽" };

  const columns = [
    {
      dataField: "createDate",
      text: "생성날짜",
      formatter: cell => getFormatDate(cell, "YY.MM.DD h:mm"),
      filter: dateFilter()
    },
    {
      dataField: "productName",
      text: "상품명",
      filter: textFilter({
        placeholder: "상품명 입력"
      }),
      formatter: (cell, row) => {
        const { id, productName } = row;
        return (
          <Link className="label label-danger" to={`/product/${id}/register`}>
            <div className="mb-2">{productName}</div>
            <div className="mb-2 table-id-text">{id}</div>
          </Link>
        );
      }
    },
    {
      dataField: "discovererName",
      text: "발굴자명",
      filter: textFilter({
        placeholder: "발굴자명 입력"
      }),
      formatter: (cell, row) => {
        const { discovererId, discovererName } = row;
        return (
          <Fragment>
            <div className="mb-2">{discovererName}</div>
            <div className="mb-2 table-id-text">{discovererId}</div>
          </Fragment>
        );
      }
    },
    {
      dataField: "providerName",
      text: "공급자명",
      filter: textFilter({
        placeholder: "공급자명 입력",
        defaultValue: defaultProviderName
      }),
      formatter: (cell, row) => {
        const { providerId, providerName } = row;
        return (
          <Link
            className="label label-danger"
            to={`/provider/${providerId}/detail`}
          >
            <div className="mb-2">{providerName}</div>
            <div className="mb-2 table-id-text">{providerId}</div>
          </Link>
        );
      }
    },
    {
      dataField: "flagTaxFree",
      text: "부가세",
      formatter: cell => taxFreeText[cell],
      filter: selectFilter({
        options: taxFreeSelect,
        placeholder: "전체"
      })
    },
    {
      dataField: "flagDelivery",
      text: "배송여부",
      formatter: cell => deliveryText[cell],
      filter: selectFilter({
        options: deliverySelect,
        placeholder: "전체"
      })
    },
    {
      dataField: "flagStopSelling",
      text: "판매중단여부",
      formatter: cell => {
        if (!cell) {
          return "판매중";
        }
        return stopSellingText[cell];
      },
      filter: selectFilter({
        options: stopSellingSelect,
        placeholder: "전체"
      })
    },
    {
      dataField: "dfFlagBest",
      text: "프롬스픽상품",
      formatter: (cell, row) => {
        if (!cell) {
          return "";
        }

        if (dayFromNow(row.createDate) <= 14) {
          return "프롬스픽 - 신규상품";
        }

        return "프롬스픽";
      },
      filter: selectFilter({
        options: bestSelect,
        placeholder: "전체"
      })
    },
    {
      dataField: "1pCount",
      text: "1P수",
      formatter: cell => (
        <Fragment>
          {cell && cell > 0 ? (
            <div>{cell}</div>
          ) : (
            <div className="btn btn-danger">발굴전</div>
          )}
        </Fragment>
      ),
      sort: true
    }
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <h4 className="h4">상품관리</h4>
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={productList}
        columns={columns}
        pagination={paginationFactory()}
        filter={filterFactory()}
        headerClasses="header-class"
      />
    </Fragment>
  );
}

ProductList.propTypes = {
  isLoading: PropTypes.bool,
  defaultProviderName: PropTypes.string,
  productList: PropTypes.arrayOf(PropTypes.shape({}))
};

ProductList.defaultProps = {
  isLoading: true,
  defaultProviderName: "",
  productList: []
};

export default ProductList;
