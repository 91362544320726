import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import SearchForm from 'components/commons/SearchForm/index';
import { getFormatDate } from 'lib/time';
import './OrderList.scss'

const options = [
  // {
  //     id : 1,
  //     type : 'S', // select
  //     data : [
  //         {text : '전체', id : 1},
  //         {text : '입력', id : 2},
  //         {text : '미입력', id : 3}
  //     ],
  //     fnName : 'serial'
  // }
  // ,{
  //     id : 2,
  //     type : 'M', // modal
  //     data : {value : 'P'},
  //     fnName : 'provider'
  // }
  {
      id : 3,
      type : 'D', // datepicker
      start : {
          data : new Date(),
          fnName : 'startDate'
      },
      // end 미적용 시 date input 은 한개만 나타남
      end : {
          data : new Date(),
          fnName : 'endDate'
      }
  }
];

class OrderList extends Component {
  // 이벤트 정의
  initFunction = {
    // // 송장입력여부
    // serial : (e) => {
    //     this.setInitValues('serial', e.target.value);
    // },
    // // 공급자 id
    // id : (value) => {
    //     this.setInitValues('id', value);
    // },
    // // 공급자
    // provider : (value) => {
    //     this.setInitValues('provider', value);
    // }
    // 시작시간
    startDate : (value) => {
        this.setInitValues('startDate', value);
    },
    // // 종료시간
    endDate : (value) => {
        this.setInitValues('endDate', value);
    }
  }

  constructor() {
    super();

    this.state = {
      startDate : new Date(),
      endDate : new Date()
    };

    this.columns = [
      {
        dataField: 'createDate',
        text: '구매일',
        sort: true,
        formatter: cell => getFormatDate(cell, 'YYYY.MM.DD HH:mm'),
        csvFormatter: cell => getFormatDate(cell, 'YYYY.MM.DD HH:mm')
      },
      {
        dataField: '',
        text: '공급자명'
      },
      {
        dataField: '',
        text: '상품명'
      },
      {
        dataField: '',
        text: '구매유형'
      },
      {
        dataField: '',
        text: '구매자명'
      },
      {
        dataField: '',
        text: '구매자연락처'
      },
      {
        dataField: '',
        text: '결제금액'
      }
    ]
  }

  componentDidMount() {    
  }

  handlerNotice = () => {
    alert('handlerNotice');
  }

  handlerModify = () => {
    alert('handlerModify');
  }

  setInitValues = (column, value) => {
    this.setState({
      [column] : value
    })
  }

  onSelectCellButton = (row, isSelect, rowIndex, e) => {
    console.log(row);
    console.log(isSelect);
    console.log(rowIndex);
    console.log(e);
  }

  onSubmit = () => {
    const { onSearch } = this.props;
    const temp = this.state;
    onSearch(temp);
  }

  render() {
    const { gridList } = this.props;
    const { startDate, endDate } = this.state;
    const { ExportCSVButton } = CSVExport;
    const condition = options;

    return (
      <Fragment>
        <ToolkitProvider
          keyField="id"
          data={gridList}
          columns={this.columns}
          exportCSV={{
            fileName: `이름미정.csv`
          }}
        >
          {props => (
            <div>
              <h4 className="h4">주문관리</h4>
              <ExportCSVButton>Export CSV!!</ExportCSVButton>
              <hr />
              <SearchForm 
                condition={condition}
                initFunction={this.initFunction}
                startDate={startDate}
                endDate={endDate}
                onSubmit={this.onSubmit}
              />
              <BootstrapTable
                {...props.baseProps}
                selectRow={{
                  mode: 'radio',
                  clickToSelect: false,
                  onSelect: (row, isSelect, rowIndex, e) => {
                    this.onSelectCellButton(row, isSelect, rowIndex, e);
                }}}
                pagination={paginationFactory()}
                filter={filterFactory()}
              />
            </div>
          )}
        </ToolkitProvider>
      </Fragment>
    );
  }
}

OrderList.propTypes = {
  onSearch : PropTypes.func
};

OrderList.defaultProps = {
  onSearch : () => {}
};

export default OrderList;
