import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import AlertModal from "components/modal/AlertModal";
import MessageModal from "components/modal/MessageModal";
import DiscoverStatusSelect from "components/discover/DiscoverStatusSelect";

import { isEmpty } from "lib/utils/commonUtils";
import { getProductAndStatus } from "api/product";
import { getDiscover, updateDiscoverResult } from "api/discover";

class ProductDiscoverStatusContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenErrorModal: false,
      isOpenSuccessModal: false,
      errorMessage: "",
      successMessage: "",
      changeStatusProcess: 2
    };
  }

  componentDidMount = () => {
    this.initialize();
  };

  getDiscoverInfo = async () => {
    const { discoverId } = this.state;

    if (!discoverId) {
      return;
    }

    try {
      const response = await getDiscover(discoverId);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`발굴등록 검색 실패 - ${response}`);
      }

      const { data } = response.data;
      const { createDate, nowStatusProcess } = data;

      this.setState({
        createDate,
        stateNowStatusProcess: nowStatusProcess
      });
    } catch (e) {
      console.error(e.message);
      this.setState({
        errorMessage:
          "발굴 정보 데이터를 가져오던 도중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
        isOpenErrorModal: true
      });
    }
  };

  // productId 있을때 - product정보 api호출해서 폼 채워주는 함수
  getProductInfo = async productId => {
    try {
      const response = await getProductAndStatus(productId);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`상품정보 조히 실패 - ${response}`);
      }

      const { onSendProductName } = this.props;
      const { data, discoverStatus } = response.data;

      this.productData = data;

      const { productName } = data;

      onSendProductName(productName);

      if (discoverStatus) {
        const { nowStatusProcess, historyStatusProcess } = discoverStatus;

        // 발굴상태 날짜 가져오는 구문
        let tempCreate = 0;
        let tempUpdate = 0;
        let tempDone = 0;

        for (let i = 0; i < historyStatusProcess.length; i += 1) {
          const targetDate = Number(
            Object.entries(historyStatusProcess[i])[0][0]
          );
          const returnDate = Object.entries(historyStatusProcess[i])[0][1];
          if (targetDate === 0) {
            tempCreate = returnDate;
          }
          if (targetDate === 1) {
            tempUpdate = returnDate;
          }
          if (targetDate === 2) {
            tempDone = returnDate;
          }
          if (targetDate === 3) {
            tempDone = returnDate;
          }
        }

        this.setState({
          stateNowStatusProcess: nowStatusProcess,
          createDate: tempCreate,
          updateDate: tempUpdate,
          doneDate: tempDone
        });
      }
    } catch (e) {
      console.error(e);
      this.setState({
        errorMessage:
          "발굴 정보 데이터를 가져오던 도중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
        isOpenErrorModal: true
      });
    }
  };

  initialize = () => {
    const { productId } = this.props;
    const isHaveProductId = productId.charAt(0) === "p";
    const isHaveDiscoverId = productId.charAt(0) === "d";

    if (productId) {
      // pid, did 둘중 하나
      this.setState(
        {
          id: isHaveProductId ? productId : null,
          discoverId: isHaveDiscoverId ? productId : null
        },
        () => {
          const { id } = this.state;

          if (id) {
            this.getProductInfo(id);
          } else {
            this.getDiscoverInfo();
          }
        }
      );
    }
  };

  handleMoveProductPage = () => {
    this.setState({
      isOpenSuccessModal: false
    });

    const { history, productId } = this.props;
    history.push(`/product/${productId}/register`);
  };

  handleCloseModal = modalType => {
    this.setState({
      [modalType]: false
    });
  };

  // 발굴성공으로 바꿀때
  handleUpdateDiscoverStatus = async () => {
    try {
      const { ignorStatusMessage, changeStatusProcess, id } = this.state;
      const copyValue = this.productData;

      if (!id) {
        throw new Error(`발굴대기 상태에서는 할수 없습니다`);
      }

      const { providerId } = copyValue;

      copyValue.id = id;

      const nextStatus = changeStatusProcess;

      if (nextStatus < 2) {
        throw new Error(`발굴성공 또는 발굴실패만 가능합니다.`);
      }

      if (providerId === "NOT_ASSIGN") {
        throw new Error("연결된 공급자 정보가 없습니다.");
      }

      if (isEmpty(copyValue.options)) {
        throw new Error(`옵션과 가격정보가 없습니다.`);
      }

      const response = await updateDiscoverResult({
        changeStatusProcess: nextStatus,
        message: ignorStatusMessage || null,
        ...copyValue
      });

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `발굴 상태 업데이트 실패하였습니다.\n관리자에게 문의 부탁드립니다. `
        );
      }

      this.setState({
        isOpenSuccessModal: true,
        successMessage: "발굴 상태가 업데이트되었습니다.",
        isUpdateStatusLoading: false
      });
    } catch (e) {
      this.setState({
        isOpenErrorModal: true,
        isUpdateStatusLoading: false,
        errorMessage:
          e.message ||
          "발굴 상태 업데이트에 실패하였습니다.\n관리자에게 문의 부탁드립니다."
      });
    }
  };

  handleChangeIgnoreStatusMessage = e => {
    this.setState({
      ignorStatusMessage: e.target.value
    });
  };

  handleChangeSelect = e => {
    this.setState({
      ignorStatusMessage: "",
      changeStatusProcess: e.target.value
    });
  };

  render() {
    const {
      isOpenErrorModal,
      isOpenSuccessModal,
      errorMessage,
      createDate,
      updateDate,
      doneDate,
      successMessage,
      changeStatusProcess,
      stateNowStatusProcess,
      isUpdateStatusLoading,
      ignorStatusMessage,
      stateHistoryStatusProcess
    } = this.state;
    return (
      <Fragment>
        <DiscoverStatusSelect
          isUpdateLoading={isUpdateStatusLoading}
          ignoreMessage={ignorStatusMessage}
          changeStatusProcess={changeStatusProcess}
          nowStatusProcess={stateNowStatusProcess}
          historyStatusProcess={stateHistoryStatusProcess}
          createDate={createDate}
          updateDate={updateDate}
          doneDate={doneDate}
          onChange={this.handleChangeIgnoreStatusMessage}
          onChangeSelect={this.handleChangeSelect}
          onClick={this.handleUpdateDiscoverStatus}
          isProductView
        />
        <AlertModal
          contentText={errorMessage}
          isOpen={isOpenErrorModal}
          onClick={() => {
            this.handleCloseModal("isOpenErrorModal");
          }}
          onToggle={() => {
            this.handleCloseModal("isOpenErrorModal");
          }}
        />
        <MessageModal
          buttonText="확인"
          contentText={successMessage}
          isOpen={isOpenSuccessModal}
          onClick={() => {
            this.handleMoveProductPage();
          }}
          onToggle={() => {
            this.handleMoveProductPage();
          }}
        />
      </Fragment>
    );
  }
}

ProductDiscoverStatusContainer.propTypes = {
  productId: PropTypes.string,
  history: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ProductDiscoverStatusContainer.defaultProps = {
  productId: "",
  history: null
};

export default withRouter(ProductDiscoverStatusContainer);
