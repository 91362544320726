import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import "./RenderField.scss";

// redux-form 에 사용되는 input Component
function RenderField({
  autoComplete,
  children,
  className,
  input,
  isRequired,
  label,
  maxLength,
  pattern,
  placeholder,
  readOnly,
  redirectUrl,
  type,
  meta: { asyncValidating, error, touched }
}) {
  const hasRedirectUrl = !!redirectUrl;

  return (
    <div className={classNames("form-group", className)}>
      {asyncValidating && (
        <img
          src="/images/button-loader.svg"
          className="async-validating loading-spinner"
          alt="input 로딩 중"
        />
      )}
      {label && (
        <span className="d-inline-block text-left mb-1 pr-2 ">
          {label}
          {isRequired && <span className="text-danger">*</span>}
        </span>
      )}
      <input
        {...input}
        className={`form-control ${label ? "form-input" : ""} ${
          touched && error ? "is-invalid" : ""
        }`}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete || "nope"}
        readOnly={readOnly}
        maxLength={maxLength || null}
        value={
          input.value && pattern
            ? String(input.value).replace(pattern, "")
            : input.value
        }
      />
      {children || null}
      {touched && error && (
        <div className={`invalid-feedback mt-1 ml-auto ${label ? "" : ""}`}>
          {error}
          {hasRedirectUrl && error.indexOf("사용 중") > -1 && (
            <Link
              to={{
                pathname: "/store/signin",
                state: {
                  redirectUrl,
                  isGiftType: redirectUrl.indexOf("gift") > -1
                }
              }}
              className="button primary render-field-login-btn d-block mt-1"
            >
              로그인 하러 가기
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

export default RenderField;
