import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faCircleNotch } from "@fortawesome/free-solid-svg-icons";

/**
 * 이전 페이지
 * @param {*} page 
 */
export const prev = (page) => {
    if ((page - 1) === 0) {
        return 1;
    }
    return (page - 1);
}

/**
 * 다음 페이지
 * @param {*} page 
 */
export const next = (page, isLast) => {
    if (isLast) {
        return page;
    }
    return (page + 1)
}

class DynamicPaging extends Component { 
    constructor (props) {
        super(props)

        this.state = {
          isLoading : false
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
      const {loading} = this.props;

      // 통신이 완료되었으며 현재 상태가 로딩중이라면
      if (!loading && prevState.isLoading) {
        this.handleLoading(false);
      }
    }

    /**
     * 로딩 on, off
     */
    handleLoading = (isLoading) => {
      this.setState({
        isLoading
      })
    }

    /**
     * 이전 페이지 이동
     */
    handlePrev = (page) => {
      const { handlePrev } = this.props;
      if ((page - 1) === 0) {
          return;
      }
      handlePrev((page - 1));

      this.handleLoading(true);
    }

    /**
     * 다음 페이지 이동
     */
    handleNext = (page) => {
      const { isLast, handleNext } = this.props;
      
      if (isLast) {
          return;
      }

      handleNext((page + 1))

      this.handleLoading(true);
    }

    render () {
        const {page} = this.props;
        const {isLoading} = this.state;

        return (
          <Fragment>
            <div className="btn-group" role="group">
              <button 
                className="btn btn-primary" 
                type="button"
                disabled={isLoading}
                onClick={() => {
                    this.handlePrev(page);
                }}
              >
                {isLoading 
                ? <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
                : <FontAwesomeIcon icon={faAngleLeft} />
                }
              </button>
              <button className="btn btn-primary" type="button">
                {page}
              </button>
              <button 
                className="btn btn-primary" 
                type="button" 
                disabled={isLoading}
                onClick={() => {
                    this.handleNext(page);
                }}
              >
                {isLoading 
                ? <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
                : <FontAwesomeIcon icon={faAngleRight} />
                }
              </button>
            </div>
          </Fragment>
        )
    }
}

DynamicPaging.propTypes = {
    handlePrev : PropTypes.func,
    handleNext : PropTypes.func,
    page : PropTypes.number,
    isLast : PropTypes.bool,
    loading : PropTypes.bool
};

DynamicPaging.defaultProps = {
    handlePrev : () => {},
    handleNext : () => {},
    page : 1,
    isLast : false,
    loading : false
};

export default DynamicPaging;