import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { getFormatDate } from 'lib/time';
import './DiscoverStatusSelect.scss';

function DiscoverStatusSelect({
  isUpdateLoading,
  changeStatusProcess,
  nowStatusProcess,
  createDate,
  updateDate,
  doneDate,
  onChangeSelect,
  onClick,
  onChange,
  ignoreMessage,
  isProductView
}) {
  const isShownIgnoreMessageInput = changeStatusProcess === '3';

  return (
    <div className="discover-status-form p-3">
      <hr />
      <h2 className="font-weight-bold mt-3 mb-4">발굴 상태</h2>
      <div className="clearfix mb-3">
        <div className="form-group clearfix">
          <div className="form-control form-input form-text d-block float-left">
            <span className="mr-auto">
              {'발굴대기'} -{' '}
              {getFormatDate(createDate, 'YYYY.MM.DD HH:mm:ss a')}
            </span>
          </div>
          <div className="form-control form-input form-text d-block float-left">
            <span className="mr-auto">
              {'발굴심사'} -{' '}
              {nowStatusProcess >= 1 &&
                getFormatDate(updateDate, 'YYYY.MM.DD HH:mm:ss a')}
            </span>
          </div>
          <div className="form-control form-input form-text d-block float-left">
            <span className="mr-auto">
              {'발굴완료'} -{' '}
              {nowStatusProcess > 1 &&
                getFormatDate(doneDate, 'YYYY.MM.DD HH:mm:ss a')}
            </span>
          </div>
        </div>
        <div className="form-group clearfix">
          <span className="form-label d-inline-block float-left text-left pr-3">
            업데이트
          </span>
          <select
            className="form-control form-input form-select d-block float-left d-flex"
            onChange={onChangeSelect}
            value={changeStatusProcess}
          >
            {isProductView ? (
              // 발굴뷰
              <Fragment>
                <option value={2}>발굴성공</option>
                <option value={3}>발굴실패</option>
              </Fragment>
            ) : (
              // 상품뷰
              <Fragment>
                <option value={0}>발굴대기</option>
                <option value={1}>발굴심사</option>
                <option value={3}>발굴실패</option>
              </Fragment>
            )}
          </select>
          <button
            type="button"
            className="btn btn-primary float-left ml-3"
            disabled={isUpdateLoading || nowStatusProcess > 1}
            onClick={onClick}
          >
            {isUpdateLoading ? (
              <img
                src="/images/button-loader.svg"
                className="loading-spinner"
                alt="로딩 이미지"
              />
            ) : (
              '업데이트'
            )}
          </button>
        </div>
        {isProductView && <div>※ 발굴성공,발굴실패만 가능</div>}
      </div>
      {isShownIgnoreMessageInput && (
        <div className="clearfix mb-3">
          <div className="form-group clearfix">
            <span className="form-label d-inline-block float-left text-left pr-3">
              실패 사유
            </span>
            <input
              type="text"
              autoComplete="nope"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              className="form-control form-input float-left"
              placeholder="실패 사유"
              value={ignoreMessage}
              onChange={onChange}
            />
          </div>
        </div>
      )}
    </div>
  );
}

DiscoverStatusSelect.propTypes = {
  isUpdateLoading: PropTypes.bool,
  changeStatusProcess: PropTypes.number,
  nowStatusProcess: PropTypes.number,
  createDate: PropTypes.string,
  updateDate: PropTypes.string,
  doneDate: PropTypes.string,
  ignoreMessage: PropTypes.string,
  onChange: PropTypes.func,
  onChangeSelect: PropTypes.func,
  onClick: PropTypes.func,
  isProductView: PropTypes.bool
};

DiscoverStatusSelect.defaultProps = {
  isUpdateLoading: false,
  changeStatusProcess: -1,
  nowStatusProcess: -1,
  createDate: '',
  updateDate: '',
  doneDate: '',
  ignoreMessage: '',
  onChange: () => {},
  onChangeSelect: () => {},
  onClick: () => {},
  isProductView: false
};

export default DiscoverStatusSelect;
