import React from 'react';
import PropTypes from 'prop-types';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import './DiscoverWaitList.scss';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';

function DiscoverWaitList({ discoverWaitList, onMoveTempAdmin }) {
  function statusFormatter(cell, row, rowIndex, formatExtraData) {
    return <span>{formatExtraData[cell]}</span>;
  }
  const { ExportCSVButton } = CSVExport;
  const bgColor = ['bg-warning', 'bg-warning', 'bg-success', 'bg-danger'];
  const columns = [
    {
      dataField: 'nowStatusProcess',
      text: '발굴상태',
      formatter: statusFormatter,
      formatExtraData: {
        0: '대기중',
        1: '심사중',
        2: '성공',
        3: '실패'
      },
      classes: cell => bgColor[cell],
      style: {
        fontSize: '20px',
        fontWeight: 'bold'
      },
      headerStyle: {
        width: '100px'
      }
    },
    {
      dataField: 'createDate',
      text: '발굴날짜',
      sort: true
    },
    {
      dataField: 'productName',
      text: '발굴명'
    },
    {
      dataField: 'discovererName',
      text: '발굴자명'
    },
    {
      dataField: 'providerName',
      text: '공급자명'
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: '수정하기',
      // events: {
      //   onClick: (e, column, columnIndex, row) => {
      //     const { id } = row;
      //     onMoveDelivery(id);
      //   }
      // },
      headerStyle: () => ({
        width: '100px'
      }),
      formatter: () => (
        <h5>
          <span className="label label-danger"> 수정</span>
        </h5>
      )
    },
    {
      dataField: 'df2',
      isDummyField: true,
      text: '등록하기',
      headerStyle: () => ({
        width: '100px'
      }),
      events: {
        onClick: (e, column, columnIndex, row) => {
          onMoveTempAdmin(row);
        }
      },
      formatter: () => (
        <h5>
          <span className="label label-danger"> 등록</span>
        </h5>
      )
    }
  ];

  return (
    <ToolkitProvider
      keyField="id"
      data={discoverWaitList}
      columns={columns}
      exportCSV
    >
      {props => (
        <div className="page-content">
          <div>발굴 대기 리스트</div>
          <ExportCSVButton {...props.csvProps}>Export CSV!!</ExportCSVButton>
          <hr />
          <BootstrapTable
            {...props.baseProps}
            pagination={paginationFactory()}
            headerClasses="header-class"
          />
        </div>
      )}
    </ToolkitProvider>
  );
}

DiscoverWaitList.propTypes = {
  discoverList: PropTypes.arrayOf(PropTypes.shape({}))
};

DiscoverWaitList.defaultProps = {
  discoverList: []
};

export default DiscoverWaitList;
