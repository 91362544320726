import React, { Component, Fragment } from 'react';
import Dashboard from 'components/dashboard/Dashboard';
import {
  getDiscoverCount,
  getUsersCount,
  getMainFeaturesCount
} from 'api/dashboard';

class DashboardContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countsSuccess: 0,
      countsReady: 0,
      countsProcessing: 0,
      counts1Stores: 0,
      counts1Product: 0,
      countsProduct: 0,
      countsProviders: 0,
      last1StoreNo: 0
    };
  }

  componentDidMount() {
    this.initialize();
  }

  getDashboardCount = () => {};

  getDiscoverCnt = async () => {
    try {
      const response = await getDiscoverCount();

      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }

      const { data } = response.data;

      this.setState({
        countsSuccess: data.countsSuccess,
        countsReady: data.countsReady,
        countsProcessing: data.countsProcessing
      });
    } catch (e) {
      console.error(e);
    }
  };

  getUsersCnt = async () => {
    try {
      const response = await getUsersCount();

      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }

      const { data } = response.data;

      this.setState({
        counts1Stores: data.counts1Stores,
        countsProviders: data.countsProviders,
        last1StoreNo: data.last1StoreNo
      });
    } catch (e) {
      console.error(e);
    }
  };

  getMainFeaturesCnt = async () => {
    try {
      const response = await getMainFeaturesCount();

      if (!response || !response.data || !response.data.success) {
        throw new Error('실패');
      }

      const { data } = response.data;

      this.setState({
        counts1Product: data.counts1Product,
        counts1Stores: data.counts1Stores,
        countsProduct: data.countsProduct,
        countsProviders: data.countsProviders,
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  initialize = () => {
    this.getUsersCnt();
    this.getDiscoverCnt();
    this.getMainFeaturesCnt();
  };

  render() {
    const {
      countsSuccess,
      countsReady,
      countsProcessing,
      counts1Stores,
      counts1Product,
      countsProduct,
      countsProviders,
      last1StoreNo,
      isLoading
    } = this.state;

    return (
      <Fragment>
        <Dashboard
          countsSuccess={countsSuccess}
          countsReady={countsReady}
          countsProcessing={countsProcessing}
          counts1Stores={counts1Stores}
          counts1Product={counts1Product}
          countsProduct={countsProduct}
          countsProviders={countsProviders}
          last1StoreNo={last1StoreNo}
          isLoading={isLoading}
        />
      </Fragment>
    );
  }
}

export default DashboardContainer;
