/* eslint-disable react/prop-types */
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ImageInputWithPreview from './ImageInput/ImageInputWithPreview';

import {
  FormControlGrid,
  StyledInput,
  StyledSelect,
  StyledTextArea,
  StyledLabel,
  StyledError,
  StyledButton,
  ControlWrapper
} from './styled/componentStyle';

export function Form({
  className,
  resolver,
  defaultValues,
  onSubmit,
  children
}) {
  const methods = useForm({ defaultValues, resolver, mode: 'onBlur' });
  const { handleSubmit } = methods;

  console.log({ children });
  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      {Array.isArray(children)
        ? children.map((child) =>
            child.props.name
              ? React.createElement(child.type, {
                  ...{
                    ...child.props,
                    register: methods.register,
                    control: methods.control,
                    errors: methods.errors,
                    key: child.props.name
                  }
                })
              : child
          )
        : children}
    </form>
  );
}

export function Input({ register, errors, rules, name, label, ...rest }) {
  // console.log({rest})
  return (
    <FormControlGrid>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <StyledInput id={name} name={name} ref={register(rules)} {...rest} />
      <ErrorMessage errors={errors} name={name} as={StyledError} />
    </FormControlGrid>
  );
}

export function TextArea({ register, errors, rules, name, label, ...rest }) {
  // console.log({rest})
  return (
    <FormControlGrid>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <StyledTextArea id={name} name={name} ref={register(rules)} {...rest} />
      <ErrorMessage errors={errors} name={name} as={StyledError} />
    </FormControlGrid>
  );
}

export function Select({
  register,
  errors,
  rules,
  name,
  label,
  options,
  ...rest
}) {
  //   console.log(Object.entries(options));
  return (
    <FormControlGrid>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <StyledSelect id={name} name={name} ref={register(rules)} {...rest}>
        {Object.entries(options).map((option, index) => (
          <option
            key={option[1]}
            value={option[0]}
            disabled={index === 0 && rules && rules.required}
          >
            {option[1]}
          </option>
        ))}
      </StyledSelect>
      <ErrorMessage errors={errors} name={name} as={StyledError} />
    </FormControlGrid>
  );
}

export function DatePicker({ control, errors, rules, name, label, ...rest }) {
  return (
    <FormControlGrid>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ onChange, onBlur, value }) => (
          <ReactDatePicker
            id={name}
            placeholderText={rest ? rest.placeholder : ''}
            onChange={onChange}
            onBlur={onBlur}
            selected={value}
            {...rest}
          />
        )}
      />
      <ErrorMessage errors={errors} name={name} as={StyledError} />
    </FormControlGrid>
  );
}

export function ImageInput({ name, label, imageUrls, ...rest }) {
  // console.log({ rest });

  return (
    <FormControlGrid>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <ControlWrapper>
        <ImageInputWithPreview name={name} images={imageUrls} {...rest} />
      </ControlWrapper>
    </FormControlGrid>
  );
}

export function SubmitButton({ isSubmitting = false, children }) {
  return (
    <StyledButton type="submit" disabled={isSubmitting}>
      {isSubmitting ? (
        <img
          src="/images/button-loader.svg"
          className="loading-spinner"
          alt="..."
        />
      ) : (
        children
      )}
    </StyledButton>
  );
}
