import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import DynamicForm from 'components/commons/DynamicForm';
import ModalDialog from 'components/modal/ModalDialog';
import Title from 'components/commons/Title';
import {getUserInfo, updateUserInfo} from 'api/user';

import { reduxRequired, reduxNumber } from '../../lib/form/validation';

class UserRegisterContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
    //   isOpen : false,
    //   data : {}
        schema : {
            "properties": {
              "id": {
                "type": "text",
                "label": "아이디",
                "readonly" : true
              },
              "storeNo": {
                "type": "text",
                "label": "상점번호",
                "readonly" : true
              },
              "userName": {
                "type": "text",
                "label": "이름",
                "readonly" : false,
                "validate" : reduxRequired
              },
              "email": {
                "type": "text",
                "label": "이메일",
                "readonly" : false,
                "validate" : reduxRequired
              },
              "mobile": {
                "type": "text",
                "label": "휴대폰",
                "readonly" : false,
                "validate" : [reduxRequired , reduxNumber]
              },
              "kakaoId": {
                "type": "text",
                "label": "카카오계정",
                "readonly" : true
              },
              "zipcode": {
                "type": "text",
                "label": "우편번호",
                "readonly" : true,
                "validate" : reduxRequired
              },
              "addr": {
                "type": "text",
                "label": "주소",
                "readonly" : true,
                "validate" : reduxRequired
              },
              "addrSub": {
                "type": "text",
                "label": "상세주소",
                "readonly" : false
              },
              "createDateKorStr": {
                "type": "text",
                "label": "가입일",
                "readonly" : true
              },
              "createType": {
                "type": "text",
                "label": "가입경로",
                "readonly" : true
              },
              "flagAgreeMarketing": {
                "type": "radio-a",
                "enum" : [true, false],
                "label": "마케팅 동의",
                "readonly" : false,
                "validate" : reduxRequired
              },
              "flagUnsubscribe": {
                "type": "radio-a",
                "enum" : [true, false],
                "label": "수신거부",
                "readonly" : false,
                "validate" : reduxRequired
              }
            }
        }
    };

    this.values = null;
  }

  componentDidMount() {
    this.initialize();
  }

  /**
   * 초기화
   */
  initialize = async () => {
    const {location} = this.props;
    const {state} = location;
    const {id} = state;
    const {schema} = this.state;

    try {
      const result = await getUserInfo(id);
      const {data} = result;
      console.log(data);
      if (data && data.success) {
        const info = data.data;
        const keys = Object.keys(schema.properties);
        
        keys.reduce((obj, field) => {
            if (schema.properties[field]) {
                schema.properties[field].default = info[field];
                if (schema.properties[field].type === 'radio-a' && info[field] === undefined) {
                    this.changeField(field, schema.properties[field].enum[1].toString());
                } else if (schema.properties[field].type === 'radio-a' && info[field] !== undefined) {
                    this.changeField(field, info[field].toString());
                } else {
                    this.changeField(field, schema.properties[field].default);
                }
            }
            return field;
        }, {});

        this.setState({
            schema
        })
      }

    } catch (error) {
      console.log(error);
    }
    
  };

  changeField = (field, value) => {
    const {changeField} = this.props;
    changeField(field, value);
  }

  handleSubmit = values => {
    this.values = values;

    this.setState({
      isOpen : true
    })
  }

  onSubmit = async () => {
    const {location} = this.props;
    const {state} = location;
    const params = this.values;

    if (params) {
        delete params['object Object'];

        try {
            params.flagAgreeMarketing = (params.flagAgreeMarketing === 'true');
            params.flagUnsubscribe = (params.flagUnsubscribe === 'true');

            const result = await updateUserInfo(state.id, params);
            
            if (result && result.status === 200) {
                this.historyBack();
            }

        } catch (error) {
            console.log(error);
        }
    }
  }

  handleCloseModal = () => {
    this.setState({
      isOpen : false
    })
  }

  historyBack = () => {
    const {history} = this.props;

    history.push({
      pathname : `/user`
    })
  }

  render() {
    const {schema, isOpen} = this.state;

    return (
      <Fragment>
        <div>
          <Title title="회원정보 수정" />
          <hr />
          <DynamicForm 
            schema={schema} 
            onSubmit={this.handleSubmit}
            onChange={this.changeField}
            handleBack={this.historyBack}
          />
        </div>
        <ModalDialog
          className="modal-control discover-register-modal"
          isOpen={isOpen}
          onToggle={this.handleCloseModal}
          size="md"
        >
          <p className="p-3 text-center">수정하시겠습니까?</p>
          <div className="text-center">
            <div>
              <button 
                className="btn btn-primary p-2" 
                type="button" 
                onClick={this.onSubmit}
              >
                수정
              </button>&nbsp;
              <button 
                className="btn btn-danger p-2" 
                type="button" 
                onClick={this.handleCloseModal}
              >
                취소
              </button>
            </div>
          </div>
        </ModalDialog>
      </Fragment>
    );
  }
}

UserRegisterContainer.propTypes = {
  changeField: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

UserRegisterContainer.defaultProps = {
  changeField: () => {},
  history: null
};

const mapDispatchToProps = dispatch => ({
  changeField: (field, value) => {
    dispatch(change('dynamicEditForm', field, value));
  }
});

export default compose(
  withRouter,
  connect(
    () => ({}),
    mapDispatchToProps
  )
)(UserRegisterContainer);
