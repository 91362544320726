import React from 'react';
// import PropTypes from 'prop-types';

// import classNames from 'classnames';

// redux-form 에 사용되는 input Component
function RenderFieldRadio(field) {
  return (
    <div className="form-control form-input form-radio float-left mr-2">
      <input
        id={field.id}
        {...field.input}
        type="radio"
        value={field.input.value}
      />
      <label className="form-radio-label" htmlFor={`${field.id}`}>
        {field.label}
      </label>
    </div>
  );
}

export default RenderFieldRadio;

