import loadImage from 'blueimp-load-image';
import { getRemovedEmptyString } from 'lib/utils/stringUtils';

/**
 * @param {file} file
 * 파일을 받아 읽어 orientation 방향을 수정한 blob 객체를 반환한다.
 */
export function fixRotationOfFile(file) {
  const fileType = file.type;

  return new Promise((resolve, reject) => {
    loadImage(
      file,
      img => {
        if (!img) {
          resolve(null);
        }

        if (img.type === 'error') {
          reject(img);
        }

        img.toBlob(blob => {
          resolve(blob);
        }, fileType);
      },
      { orientation: true },
    );
  });
}

/**
 * @param {blob} blob
 * @param {string} fileName
 * blob객체를 사용해 fileName의 이름으로 파일을 만든 후 반환한다.
 */
export function changeFileFromBlob(blob, fileName) {
  const createdFile = new File([blob], fileName);

  return createdFile;
}

/**
 * @param {file} file
 * 파일을 받아서 orientation을 수정한 파일을 반환해준다.
 */
export function getCorrectOrientationFile(file) {
  return new Promise((resolve, reject) => {
    fixRotationOfFile(file)
      .then(blob => {
        const newFile = changeFileFromBlob(blob, file.name);

        resolve(newFile);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function getImagePath(image) {
  if (!image) {
    return '';
  }

  if (typeof image === 'string') {
    const path = getRemovedEmptyString(image);

    return path.replace(/\s/g, '%20');
  }

  // FIXME: API 오류에 따른 예외처리 -> API 수정되면 삭제 할 것
  if (image.path) {
    if (typeof image.path === 'object') {
      return '';
    }
  }

  const path = image.path ? getRemovedEmptyString(image.path) : '';

  return path.replace(/\s/g, '%20');
}

export function getOfficialProductImgPath(imagePath) {
  const path = getImagePath(imagePath) || '/images/default-image.svg';

  return path;
}
