import React from 'react';
import PropTypes from 'prop-types';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';

function DiscoverRegisterForm({ discovererName, productName }) {
  return (
    <div className="page-content">
      <h1>발굴등록</h1>
      <h2 className="h4">발굴명 - {productName}</h2>
      <h2 className="h4">발굴자 - {discovererName}</h2>
      <hr />
      <form>
        <div className="form-row">
          <div className="form-group  col-sm-3 mr-4">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  상품명
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                aria-label="productName"
                aria-describedby="basic-addon1"
                value={productName}
                // 수정기능 추가
              />
            </div>
          </div>
          <div className="form-group  col-sm-3 mr-4 ">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon2">
                  저자
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                aria-label="productName"
                aria-describedby="basic-addon2"
              />
            </div>
          </div>
          <div className="form-group  col-sm-3 ">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon3">
                  출간일
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                aria-label="productName"
                aria-describedby="basic-addon3"
              />
            </div>
          </div>
        </div>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">소개글</span>
          </div>
          <textarea
            className="form-control"
            aria-label="With textarea"
            rows="15"
          />
        </div>
      </form>
    </div>
  );
}

DiscoverRegisterForm.propTypes = {
  discovererName: PropTypes.string,
  productName: PropTypes.string
  //   discoverList: PropTypes.arrayOf(PropTypes.shape({})),
  //   onMoveDiscoverEdit: PropTypes.func
};

DiscoverRegisterForm.defaultProps = {
  discovererName: '',
  productName: ''

  //   discoverList: [],
  //   onMoveDiscoverEdit: () => {}
};

export default DiscoverRegisterForm;
