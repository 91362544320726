import axios from './defaultAxios';
import adminAxios from './adminAxios';
import getLoginUserEmail from './getLoginUserEmail';

// product 정보 가지고 옴
export function getProduct(params) {
  return axios.get(`/product/${params}`);
}

// product 정보 가지고 옴2 + 발굴상태
export function getProductAndStatus(params) {
  return adminAxios.get(`/product/${params}`);
}

// product 등록 - productId 없을때
export function registerProduct(values) {
  return axios.post(`/product`, values);
}

// product 수정 - productId 있을때
// product - 11
export function updateProduct(values) {
  return axios.put(`/product/productid`, values);
}

// product명 검색
export function getSearchedProduct(keyword) {
  return axios.get(`/product`, {
    params: {
      keyword
    }
  });
}

// product명 검색
export function getProducts(params) {
  return adminAxios.get(`/product`, { params });
}

// product 핸들링 값 변경 (초기: STOP_SELLING)
export function updateProductSetting(params, pUpdateType) {
  const userEmail = getLoginUserEmail();
  const updateType = pUpdateType || 'STOP_SELLING';
  return adminAxios.put(`/product/${params.productId}`, {
    params,
    requestedBy: userEmail,
    updateType
  });
}
