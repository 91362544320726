import React, { Component, Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';

import RenderField from 'components/reduxForm/RenderField';
import RenderSelect from 'components/reduxForm/RenderSelect';

import AddressModal from 'components/modal/AddressModal';

import './DeliveryForm.scss';
import {
  mendatoryValidation,
  telValidation,
  numericValidation
} from 'lib/form/validation';

class DeliveryForm extends Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
      isValidation: true
    };

    this.onChangeDeliveryCode = this.onChangeDeliveryCode.bind(this);
  }

  componentDidMount() {}

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState) {
    if (prevState.data === undefined) {
      // this.onSetData();
    }
  }

  onSetData = () => {
    const { data, onChange } = this.props;
    let gift;

    if (data) {
      const { flagReceive, deliveryInfo, giftInfo } = data;

      if (flagReceive === 1) {
        gift = deliveryInfo;
      }

      if (flagReceive === 2) {
        gift = giftInfo;
      }
      console.log({ gift });
      const {
        name,
        mobile,
        zipcode,
        addr,
        addrSub,
        reserveDate,
        deliveryMessage
      } = gift;

      onChange('name', name);
      onChange('mobile', mobile);
      onChange('zipcode', zipcode);
      onChange('addr', addr);
      onChange('addrSub', addrSub);
      onChange('deliveryMessage', deliveryMessage);

      onChange('reserveDate', reserveDate);
    }
  };

  onChangeSelect = () => {};

  onToggle = (e) => {
    if (e) {
      e.target.blur();
    }

    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen
    });
  };

  // eslint-disable-next-line
  onChangeAddress = (address, zonecode) => {
    const { onChange } = this.props;

    onChange('zipcode', zonecode);
    onChange('addr', address);
    onChange('addrSub', '');

    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen
    });
  };

  onChangeDeliveryCode = (e) => {
    const { onChange } = this.props;
    let isValidation = true;
    if (e.target.value && e.target.value === '000') {
      isValidation = false;
      onChange('invoiceNumber', '');
    } else {
      isValidation = true;
    }

    this.setState({
      isValidation
    });
  };

  historyBack = () => {
    const { handleBack } = this.props;
    handleBack();
  };

  render() {
    const { handleSubmit, onSubmit, isEdit } = this.props;
    const { isOpen, isValidation } = this.state;

    return (
      <Fragment>
        <div>
          <h4 className="h4">
            {isEdit ? '배송정보 수정' : '운송장 번호 입력'}
          </h4>
          <hr />
          <div className="delivery-container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container-fluid">
                {isEdit ? (
                  <Fragment>
                    <div className="row">
                      <Field
                        type="text"
                        name="name"
                        label="이름"
                        className="col-6"
                        component={RenderField}
                        validate={mendatoryValidation}
                      />
                      <Field
                        type="number"
                        name="mobile"
                        label="전화번호"
                        className="col-6"
                        component={RenderField}
                        validate={[mendatoryValidation, telValidation]}
                      />
                    </div>
                    <div className="row">
                      <Field
                        name="zipcode"
                        type="text"
                        className="col-12 form-with-button"
                        component={RenderField}
                        label="우편번호"
                        placeholder="클릭해서 우편번호 입력"
                        onFocus={this.onToggle}
                        readOnly
                      >
                        <button
                          type="button"
                          className="form-button-address btn-primary"
                          onClick={this.onToggle}
                        >
                          찾기
                        </button>
                      </Field>
                    </div>
                    <div className="row">
                      <Field
                        type="text"
                        name="addr"
                        label="주소"
                        className="col-12"
                        component={RenderField}
                        validate={mendatoryValidation}
                      />
                    </div>
                    <div className="row">
                      <Field
                        type="text"
                        name="addrSub"
                        label="상세주소"
                        className="col-12"
                        component={RenderField}
                        // validate={required}
                      />
                    </div>
                    <div className="row">
                      <Field
                        type="text"
                        name="deliveryMessage"
                        label="배송 메시지"
                        className="col-12"
                        component={RenderField}
                        // validate={required}
                      />
                    </div>
                    <div className="row">
                      <Field
                        type="text"
                        name="reserveDate"
                        label="예약 발송일"
                        className="col-12"
                        component={RenderField}
                        // validate={required}
                      />
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="row">
                      <Field
                        type="text"
                        name="deliveryCode"
                        label="택배사"
                        className="col-12"
                        component={RenderSelect}
                        onChange={this.onChangeDeliveryCode}
                        validate={mendatoryValidation}
                      >
                        <option value="">택배사선택</option>
                        <option value="000">직접전달</option>
                        <option value="01">우체국택배</option>
                        <option value="04">CJ대한통운</option>
                        <option value="06">로젠택배</option>
                        <option value="05">한진택배</option>
                        <option value="11">일양로지스</option>
                        <option value="08">롯데택배</option>
                        <option value="46">CU편의점택배</option>
                        <option value="23">경동택배</option>
                        <option value="17">천일택배</option>
                        <option value="56">KGB택배</option>
                        <option value="22">대신택배</option>
                        <option value="32">합동택배</option>
                        <option value="18">건영택배</option>
                        <option value="40">굿투럭</option>
                        <option value="54">홈픽택배</option>
                        <option value="53">농협택배</option>
                        <option value="52">세방</option>
                        <option value="43">애니트랙</option>
                        <option value="20">한덱스</option>
                        <option value="16">한의사랑택배</option>
                        <option value="45">호남택배</option>
                        <option value="24">CVSnet편의점택배</option>
                        <option value="30">KGL네트웍스</option>
                        <option value="44">SLX</option>
                      </Field>
                    </div>
                    <div className="row">
                      <Field
                        type="text"
                        name="invoiceNumber"
                        label="운송장번호"
                        className="col-12"
                        component={RenderField}
                        validate={
                          isValidation
                            ? [mendatoryValidation, numericValidation]
                            : null
                        }
                      />
                    </div>
                  </Fragment>
                )}

                <div className="row">
                  <div className="col-12 text-right">
                    <span className="p-1">
                      <button type="submit" className="btn btn-primary">
                        수정
                      </button>
                    </span>
                    <span className="p-1">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={this.historyBack}
                      >
                        취소
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </form>
            <AddressModal
              isOpen={isOpen}
              onToggle={this.onToggle}
              onSelect={this.onChangeAddress}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

DeliveryForm.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  onChange: PropTypes.func,
  handleBack: PropTypes.func,
  isEdit: PropTypes.bool
};

DeliveryForm.defaultProps = {
  data: {},
  onSubmit: () => {},
  handleSubmit: () => {},
  onChange: () => {},
  handleBack: () => {},
  isEdit: true
};

export default reduxForm({ form: 'deliveryEditForm' })(DeliveryForm);
