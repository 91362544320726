/* eslint-disable no-unused-vars */
import React, { useState, Fragment } from 'react';
// import './ReceiverList.scss';

function ReceiverList() {
  console.log('Hello');
  return <div>Test</div>;
}

export default ReceiverList;
