/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';

require('react-dom');

window.React2 = require('react');

console.log(window.React1 === window.React2);

function GiftForm() {
  const [buyerId, setBuyerId] = useState('');
  // const [productId, setProductId] = useState('');
  // const [optionId, setOptionId] = useState('');

  // useEffect(() => {
  //   console.log({ buyerId });
  // }, [buyerId]);

  const handleSubmit = e => {
    e.preventDefault();
    console.log(buyerId);
    setBuyerId('');
    // setProductId('');
    // setOptionId('');
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="buyerId" className="form-label">
          구매자:{' '}
        </label>
        <input
          type="text"
          name="buyerId"
          className="form-control"
          onChange={e => {
            setBuyerId(e.target.value);
          }}
        />
        {/* <label htmlFor="productId">상품: </label>
        <input
          type="text"
          name="productId"
          value={productId}
          onChanage={e => {
            setProductId(e.target.value);
          }}
        />
        <label htmlFor="optionId">옵션: </label>
        <input
          type="text"
          name="optionId"
          value={optionId}
          onChanage={e => {
            setOptionId(e.target.value);
          }}
        /> */}
        <input type="submit" value="확인" />
      </form>
    </div>
  );
}

export default GiftForm;
