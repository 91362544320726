/* eslint-disable no-await-in-loop */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, change } from 'redux-form';

import OptionPriceListForm from 'components/product/OptionPriceListForm';
import AlertModal from 'components/modal/AlertModal';
import ConfrimModal from 'components/modal/ConfirmModal';
import MessageModal from 'components/modal/MessageModal';
import { s3Uploader } from 'lib/aws/s3Uploader';
import uuid from 'uuid';

import { getProductAndStatus, updateProduct } from 'api/product';
import {
  getIncentivePercentObject,
  getIncentivePointObject
} from 'lib/utils/priceUtils';
import { isEmpty } from 'lib/utils/commonUtils';

const getOptionImagePath = async option => {
  const { imagePath } = option;
  // console.log({ imagPath });

  const uploadImages = [];
  if (imagePath) {
    imagePath.forEach(file => {
      // 파일과 오브젝트 구분
      // 파일인 경우 업로드 진행
      /* eslint no-param-reassign: ["error", { "props": false }] */
      file.key = uuid.v4();

      uploadImages.push(s3Uploader(file, 'providerUpload'));
    });
  }
  const uploadImgPathList = await Promise.all(uploadImages);

  if (uploadImgPathList.length > 0) {
    const { key, ...newImagePath } = uploadImgPathList[0];
    return newImagePath;
  }
  return null;
};

class OptionPriceListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenErrorModal: false,
      isOpenSuccessModal: false,
      errorMessage: '',
      successMessage: ''
    };
  }

  componentDidMount = () => {
    this.initialize();
  };

  // productId 있을때 - product정보 api호출해서 폼 채워주는 함수
  getProductInfo = async productId => {
    try {
      const response = await getProductAndStatus(productId);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`상품정보 조히 실패 - ${response}`);
      }

      const { changeField, onSendProductName } = this.props;
      const { data } = response.data;

      this.productData = data;

      const { productName, options } = data;
      let { defaultRate } = data;

      // defaultRate 소숫점 변환
      defaultRate = getIncentivePercentObject(defaultRate);

      // 옵션 변환
      const newOptions =
        options &&
        options.map(op => ({
          ...op,
          rate: getIncentivePercentObject(op.rate),
          isOnDisplay: isEmpty(op.isOnDisplay)
            ? 'true'
            : op.isOnDisplay.toString(),
          isInStock: isEmpty(op.isInStock) ? 'true' : op.isInStock.toString(),
          isShowRate: false
        }));

      onSendProductName(productName);
      changeField('defaultRate', defaultRate);
      changeField('options', newOptions);
    } catch (e) {
      console.error(e);
      this.setState({
        errorMessage:
          '상품 정보 데이터를 가져오던 도중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.',
        isOpenErrorModal: true
      });
    }
  };

  initialize = () => {
    const { productId } = this.props;
    // console.log(productId);
    const isHaveProductId = productId.charAt(0) === 'p';

    if (productId) {
      this.setState(
        {
          id: isHaveProductId ? productId : null
        },
        () => {
          const { id } = this.state;

          if (id) {
            this.getProductInfo(id);
          } else {
            this.setState({
              isOpenErrorModal: true,
              errorMessage: '발굴상태입니다',
              isDiscover: true
            });
          }
        }
      );
    }
  };

  handleSwapIndex = (e, previousNo) => {
    const { options, changeField } = this.props;

    const swapIndex = options.findIndex(
      op => parseInt(op.seqNo, 10) === parseInt(e.target.value, 10)
    );

    if (swapIndex < 0) {
      return;
    }

    changeField(`options[${swapIndex}].seqNo`, previousNo);
  };

  handleClickDeleteOption = (fields, index) => {
    this.setState({
      isOpenConfrimModal: true
    });

    this.deleteData = { fields, index };
  };

  deleteOption = () => {
    const { fields, index } = this.deleteData;
    const { options, changeField } = this.props;

    if (fields.length <= 1) {
      this.setState({
        isOpenConfrimModal: false,
        isOpenErrorModal: true,
        errorMessage: '옵션이 적어도 하나이상 있어야 합니다.'
      });

      return;
    }

    const previousNo = options[index].seqNo;

    let tempOptions = JSON.parse(JSON.stringify(options));

    fields.remove(index);
    tempOptions.splice(index, 1);

    this.setState({
      isOpenConfrimModal: false
    });

    // 옵션 변환
    tempOptions = tempOptions.map(op => ({
      ...op,
      seqNo: op.seqNo > previousNo ? op.seqNo - 1 : op.seqNo
    }));

    changeField('options', tempOptions);
  };

  handleSubmit = values => {
    this.setState(
      {
        isSubmitLoading: true
      },
      async () => {
        const params = { ...this.productData };
        // console.log({ params });
        // console.log({ values });
        const { productId } = this.props;
        const { options } = values;

        params.id = productId;

        // 공개한 옵션수
        const isOnDisplayTrueCount = options.filter(
          op => op.isOnDisplay === 'true'
        ).length;

        if (isOnDisplayTrueCount === 0) {
          this.setState({
            isOpenErrorModal: true,
            errorMessage: '옵션이 적어도 하나이상 공개해야 합니다.',
            isSubmitLoading: false
          });

          return;
        }

        // options 변환
        const newOptions = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < options.length; i++) {
          const element = {
            ...options[i],
            price: parseInt(options[i].price, 10),
            rate: getIncentivePointObject(options[i].rate),
            // string 형식 bool로 형변환
            isOnDisplay: options[i].isOnDisplay === 'true',
            isInStock: options[i].isInStock === 'true',
            // string 형식 int 형변환
            seqNo: parseInt(options[i].seqNo, 10),
            imagePath: await getOptionImagePath(options[i])
          };

          delete element.isShowRate;
          // console.log({ element });
          newOptions.push(element);
        }

        params.options = newOptions.sort((a, b) => {
          if (a.seqNo < b.seqNo) {
            return -1;
          }

          if (a.seqNo > b.seqNo) {
            return 1;
          }

          return 0;
        });

        try {
          // console.log('before update');
          // console.log({ params });
          const response = await updateProduct(params);

          if (!response || !response.data || !response.data.success) {
            throw new Error('옵션 변경 실패');
          }

          this.setState({
            isOpenSuccessModal: true,
            successMessage: '수정 되었습니다.',
            isSubmitLoading: false
          });
        } catch {
          this.setState({
            isOpenErrorModal: true,
            errorMessage: '수정에 실패했습니다.\n잠시 후 다시 시도해주세요.',
            isSubmitLoading: false
          });
        }
      }
    );
  };

  handleMoveProductPage = () => {
    this.setState({
      isOpenSuccessModal: false
    });

    window.location.reload();
  };

  handleCloseModal = modalType => {
    // 발굴상태일시 기본정보로 이동
    const { isDiscover } = this.state;

    if (isDiscover) {
      const { history, productId } = this.props;
      history.push(`/product/${productId}/register`);
    }

    this.setState({
      [modalType]: false
    });
  };

  render() {
    const {
      isOpenErrorModal,
      isOpenSuccessModal,
      isOpenConfrimModal,
      errorMessage,
      successMessage,
      isSubmitLoading
    } = this.state;
    const { options, defaultRate } = this.props;
    return (
      <Fragment>
        <OptionPriceListForm
          onSubmit={this.handleSubmit}
          isSubmitLoading={isSubmitLoading}
          options={options}
          defaultRate={defaultRate}
          onChangePrice={this.handleChangePrice}
          onChangeOptionImage={this.handleChangeOptionImage}
          onSwapIndex={this.handleSwapIndex}
          onClickDeleteOption={this.handleClickDeleteOption}
        />
        <AlertModal
          contentText={errorMessage}
          isOpen={isOpenErrorModal}
          onClick={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
        />
        <ConfrimModal
          contentText="옵션을 삭제 하시겠습니까?"
          isOpen={isOpenConfrimModal}
          onClick={this.deleteOption}
          onToggle={() => {
            this.handleCloseModal('isOpenConfrimModal');
          }}
        />
        <MessageModal
          buttonText="확인"
          contentText={successMessage}
          isOpen={isOpenSuccessModal}
          onClick={() => {
            this.handleMoveProductPage();
          }}
          onToggle={() => {
            this.handleMoveProductPage();
          }}
        />
      </Fragment>
    );
  }
}

const selector = formValueSelector('optionPriceListForm');

const mapStateToProps = state => ({
  options: selector(state, 'options'),
  defaultRate: selector(state, 'defaultRate')
});

const mapDispatchToProps = dispatch => ({
  changeField: (field, value) => {
    dispatch(change('optionPriceListForm', field, value));
  }
});

OptionPriceListContainer.propTypes = {
  productId: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  defaultRate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  history: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  changeField: PropTypes.func
};

OptionPriceListContainer.defaultProps = {
  productId: '',
  options: [],
  defaultRate: {},
  history: null,
  changeField: () => {}
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OptionPriceListContainer);
