import styled, { css } from 'styled-components';

export const ImageInputWithPreviewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0px 0px;

  button {
    display: block;
    position: relative;
    width: 100px;
    height: 100px;
    margin: 5px 5px;
  }
`;

export const ImagePreviewWrapper = styled.div`
  display: block;
  position: relative;
  margin: 5px 5px;
  width: 100px;
  height: 100px;

  ${(props) =>
    props.isLarge &&
    css`
      width: 100%;
      height: 100%;
    `}

  img {
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
  }
`;

export const InvisibleImageInput = styled.input`
  display: none;
`;
