/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';

import NavBar from 'containers/nav/NavBar';
import SegmentForm from './SegmentForm';

function MarketingContainer() {
  const navLinks = [
    {
      label: '세그멘트 생성',
      path: '/marketing/segment'
    },
    {
      label: '응원글 작성',
      path: '/marketing/post'
    }
  ];
  return (
    <Fragment>
      <NavBar className="sub-nav-bar" navLinks={navLinks} />
      {/* <Route
        exact
        key="marketing"
        path="/marketing"
        render={() => <Redirect to="/marketing/segment" />}
      /> */}
      <Route
        exact
        key="marketing-segment"
        path="/marketing/segment"
        component={SegmentForm}
      />
      <Route
        exact
        key="marketing-post"
        path="/marketing/post"
        component={SegmentForm}
      />
    </Fragment>
  );
}
export default withRouter(MarketingContainer);
