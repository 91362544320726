import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import { resolvePath } from 'lib/utils/commonUtils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import './SelectGridList.scss'
import Paging from '../DynamicPaging/DynamicPaging';


const selectSetRow = {
  mode: 'checkbox',
  clickToSelect: true,
  bgColor: '#97e4f1'
};

class SelectGridList extends Component {
  constructor() {
    super();

    this.state = {
      // 페이징 번호
      page : 1,
      // 우측 선택된 목록
      selectedList : [],
      // 마지막 여부
      isLast : false,
      // 일괄선택 여부 
      isBatch: false
    };

    // query next keys
    this.lastKeys = [];
    // 검색 여부
    // 검색 시에 페이징을 원래대로 변경해야해서 필요함.
    this.isSubmut = false;
  }

  componentDidMount() {
    // keyField를 저장해두어야 변경되었는지 알 수 있음.
    const {keyField} = this.props;
    this.setState({
      keyField
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const {keyField, lastKey} = this.props;
    const {isLast} = this.state;

    // 검색 구분 변경 시 reset
    if (prevState.keyField !== keyField) {
      this.handleResetData();
    }

    // lastKey가 없으면 마지막
    if (lastKey) {
      const orderBy = lastKey.indexOf('desc') > -1 ? 'desc' : 'asc';
      const pLastKey = prevProps.lastKey || '';
      const prevOrderBy = pLastKey.indexOf('desc') > -1 ? 'desc' : 'asc';

      if (this.lastKeys.indexOf(lastKey) === -1) {
        // 이전 키와 동일한지 확인
        if (orderBy !== prevOrderBy) {
          this.lastKeys = [lastKey];
        } else {
          this.lastKeys.push(lastKey);
        }
      }

      if (orderBy !== prevOrderBy) {
        this.handleResetData();
      }
    } else if (!lastKey && !isLast){
      this.handleIsLast(true);
    }
  }

  // reset
  handleResetData = () => {
    const {keyField} = this.props;
    this.setState({
      selectedList : [],
      keyField,
      page : 1,
      isLast : false
    })
    this.lastKeys = [];
  }

  // 페이징 마지막 여부 수정
  handleIsLast = (isLast) => {
    this.setState({
      isLast
    })
  }

  /**
   * 페이지 이동
   */
  handlePaging = page => {
    this.setState({page})
    this.onSearch(this.lastKeys[page-2]);
  };

  /**
   * @param String
   */
  onSearch = lastKey => {
    const {onPaging} = this.props;
    if (onPaging) {
      onPaging(lastKey);
    }
  };

  onSubmit = () => {
  };

 /** 데이터 추가
  * 1. selectedList 와 선택된 row와 비교 후 동일하지 않은 오브젝트만 추가
  * 2. 추가된 오브젝트 key로 gridList 에서 데이터 추출
  * 3. 중복된 번호 제거 
  * 4. bootstrap-table2 selected 배열 초기화
  */
  handleSetData = () => {
    const {keyField, keyMobile, gridList} = this.props
    const {selectedList} = this.state; 
    
    const {selected} = this.left.selectionContext;
    
    // 1
    let keys = [];    // 체크한 것 중 실제 추가할 item들의 key array 
    if (selectedList.length > 0) {
      keys = selected;
      // eslint-disable-next-line
      selectedList.map(obj => {
        const index = keys.indexOf(obj[keyField]);
        if (index > -1) {
          keys.splice(index,1)
        }
      })
    } else {
      keys = selected;
    }

    // 2
    const tempList = gridList.filter(obj => (
      keys.indexOf(obj[keyField]) > -1 && 
        resolvePath(obj, keyMobile)    // 전화번호 값 있는 항목만 추가 
    ));

    // 3 
    // eslint-disable-next-line
    const resultList = tempList.filter((item1, i) => {
      // eslint-disable-next-line
      return tempList.findIndex((item2, j) => {
        const mobile1 = resolvePath(item1, keyMobile);
        const mobile2 = resolvePath(item2, keyMobile);
        return mobile1 === mobile2;
      }) === i;
    });

    this.setState({
      selectedList : selectedList.concat(resultList)
    })

    // 4
    this.left.selectionContext.selected = []
  }

  // 데이터 삭제
  handleRemoveData = () => {
    const {keyField} = this.props
    const {selectedList} = this.state; 
    const {selected} = this.right.selectionContext;

    const tempList = selectedList.filter(obj => {
      const isExist = selected.indexOf(obj[keyField]) === -1;
      return isExist;
    })

    this.setState({
      selectedList : tempList
    })
  }

  // 데이터 선택
  handleOnSelect = (row, isSelect) => {
    const {keyField} = this.props;
    const tempLength = this.tempList.length;

    // 선택
    if (isSelect) {
      if (tempLength > 0) {
        const isExist = this.tempList.find(temp => {
          const result = temp[keyField] === row[keyField];
          return result; 
        })
        if (!isExist) {
          this.tempList.push(row);
        }
      } else {
        this.tempList.push(row);
      }
    }

    // 선택 해제
    if (!isSelect) {
      if (tempLength > 0) {
        const list = this.tempList.filter(temp => {
          const result = temp[keyField] !== row[keyField];
          return result;
        });
        this.tempList = list;
      }
    }
  }

  // 일괄선택 모드 토글 
  handleClickBatch = () => {
    const { isBatch } = this.state;
    this.setState({
      isBatch: !isBatch
    });
  }

  // callback parent
  handleFinishSelect = () => {
    const {onFinishSelect} = this.props;
    const { isBatch, selectedList } = this.state; 
    if (onFinishSelect) {
      onFinishSelect(isBatch, selectedList);
    }
  }

  render() {
    const { 
      keyField, gridList, columns, loading, closeModal
    } = this.props;
    const {
      page, isLast, selectedList, isBatch
    } = this.state;

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row pb-3">
            <div className="col text-right">
              <button
                className="btn btn-primary float-left" 
                type="button" 
                onClick={this.handleClickBatch}
                disabled={loading}
              >
                {isBatch ? "일괄선택 취소" : "검색결과 일괄선택"}
              </button>
              {isBatch && loading && <span>검색 추가 중 ... </span>}
              <button 
                className="btn btn-primary" 
                type="button" 
                onClick={this.handleFinishSelect}
                disabled={loading}
              >
                선택완료
              </button>&nbsp;
              <button 
                className="btn btn-primary" 
                type="button" 
                onClick={closeModal}
                disabled={loading}
              >
                닫기
              </button>
            </div>
          </div>
          {isBatch ? (
            <div>
              검색결과 일괄선택됨
            </div>
          ) : (
            <div className="row">
              <div className="col-5 px-0">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col text-right">
                    총 ({gridList.length})건
                    </div>
                  </div>
                  <div className="row">
                    <div className="col px-0" style={{overflow : 'auto'}}>
                      <BootstrapTable
                        keyField={keyField}
                        data={gridList}
                        columns={columns}
                        noDataIndication="데이터가 없습니다."
                        filter={filterFactory()}
                        ref={arg => {this.left = arg;}}
                        selectRow={selectSetRow}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-center">
                      <Paging
                        page={page}
                        isLast={isLast}
                        handlePrev={this.handlePaging}
                        handleNext={this.handlePaging}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="text-center p-2">
                  <button 
                    className="btn btn-primary" 
                    type="button" 
                    onClick={this.handleSetData}
                    disabled={isBatch}
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                </div>
                <div className="text-center p-2">
                  <button 
                    className="btn btn-primary" 
                    type="button" 
                    onClick={this.handleRemoveData}
                    disabled={isBatch}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </button>
                </div>
              </div>
              <div className="col-5 px-0">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col text-right">
                    총 ({selectedList.length})건
                    </div>
                  </div>
                  <div className="row">
                    <div className="col px-0" style={{overflow : 'auto'}}>
                      <BootstrapTable
                        keyField={keyField}
                        data={selectedList}
                        columns={columns}
                        noDataIndication="데이터가 없습니다."
                        filter={filterFactory()}
                        ref={arg => {this.right = arg;}}
                        selectRow={selectSetRow}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

SelectGridList.propTypes = {
  keyField : PropTypes.string,
  keyMobile : PropTypes.string,
  gridList : PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  lastKey : PropTypes.string,
  columns : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onFinishSelect : PropTypes.func,
  onPaging : PropTypes.func,
  closeModal : PropTypes.func,
  loading : PropTypes.bool
};

SelectGridList.defaultProps = {
  keyField : '',
  keyMobile : '',
  gridList: [],
  columns : {},
  lastKey : '',
  onFinishSelect : () => {},
  onPaging : () => {},
  closeModal : () => {},
  loading : false
};

export default SelectGridList;
