import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import OrderForm from 'components/order/OrderForm/OrderForm'

class OrderRegisterContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    this.initialize();
  }

  /**
   * 초기화
   */
  initialize = () => {
  };

  render() {
    return (
      <Fragment>  
        <OrderForm />
      </Fragment>
    );
  }
}

export default withRouter(OrderRegisterContainer);
