import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import DynamicForm from 'components/commons/DynamicForm';
import ModalDialog from 'components/modal/ModalDialog';
import Title from 'components/commons/Title';
import { getProviderDetail, updateProviderDetail } from 'api/provider';
import { s3Uploader } from 'lib/aws/s3Uploader';
import {
  mendatoryValidation,
  emailValidation,
  telValidation,
  numericValidation
} from 'lib/form/validation';
import uuid from 'uuid';

class ProviderRegisterContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //   isOpen : false,
      //   data : {}
      schema: {
        properties: {
          providerId: {
            type: 'text',
            label: '공급자ID',
            readonly: true,
            validate: mendatoryValidation
          },
          providerName: {
            type: 'text',
            label: '공급자명',
            readonly: false,
            validate: mendatoryValidation
          },
          ceoName: {
            type: 'text',
            label: '대표명',
            readonly: false,
            validate: mendatoryValidation
          },
          mobile: {
            type: 'text',
            label: '전화번호',
            readonly: false,
            validate: [telValidation]
          },
          email: {
            type: 'text',
            label: '이메일',
            readonly: false,
            validate: [emailValidation]
          },
          picName: {
            type: 'text',
            label: '담당자명',
            readonly: false,
            validate: mendatoryValidation
          },
          picMobile: {
            type: 'text',
            label: '담당자전화번호',
            readonly: false,
            validate: [mendatoryValidation, telValidation]
          },
          picEmail: {
            type: 'text',
            label: '담당자이메일',
            readonly: false,
            validate: [mendatoryValidation, emailValidation]
          },
          providerProfileImgPath: {
            type: 'file', // 타입
            label: '프로필', // 라벨
            accept: '*', // * 전체, image 이미지
            readonly: false, // readonly 여부
            multiple: false // 다중 파일 여부
          },
          zipcode: {
            // 주소는 3개 한쌍.
            type: 'text',
            label: '우편번호',
            readonly: true
          },
          addr: {
            type: 'text',
            label: '주소',
            readonly: true
          },
          addrSub: {
            type: 'text',
            label: '상세주소',
            readonly: false
          },
          providerType: {
            type: 'select', // 타입이 셀렉트 일 때 옵션 필수(없을 시 [] 빈 배열 제공)
            label: '공급자타입',
            readonly: false,
            validate: mendatoryValidation,
            options: [
              { text: '개인', value: '1' },
              { text: '개인사업자', value: '2' },
              { text: '법인사업자', value: '3' }
            ]
          },
          businessLicense: {
            type: 'text',
            label: '사업자등록번호',
            readonly: false
          },
          businessLicenseImgPath: {
            type: 'file',
            label: '사업자등록증',
            accept: '*', // * 전체, image 이미지
            readonly: false,
            multiple: false
          },
          passbook: {
            type: 'text',
            label: '통장번호',
            readonly: false
          },
          bank: {
            type: 'text',
            label: '은행',
            readonly: false
          },
          passbookImgPath: {
            type: 'file',
            label: '정산통장사본',
            accept: '*', // * 전체, image 이미지
            readonly: false,
            multiple: false
          },
          freeDeliveryAmount: {
            type: 'number',
            label:
              '무료배송 주문액(미입력시 5만원 이상이면 무료배송 처리됩니다)',
            readonly: false,
            validate: numericValidation
          }
        }
      }
    };

    this.values = null;
  }

  componentDidMount() {
    this.initialize();
  }

  /**
   * 초기화
   */
  initialize = async () => {
    const { match } = this.props;
    const { providerId } = match.params;
    // const {id} = state;
    const { schema } = this.state;

    try {
      const result = await getProviderDetail(providerId);
      const { data } = result;

      // console.log({ data });

      if (data && data.success) {
        const info = data.data;

        if (info) {
          info.providerId = providerId;
        }

        const { businessLicenseImgPath, passbookImgPath } = info;

        if (businessLicenseImgPath || passbookImgPath) {
          if (businessLicenseImgPath) {
            const businessLicenseImgPathTemp = businessLicenseImgPath;
            info.businessLicenseImgPath = { path: businessLicenseImgPathTemp };
          }

          if (passbookImgPath) {
            const passbookImgPathTemp = passbookImgPath;
            info.passbookImgPath = { path: passbookImgPathTemp };
          }
        }
        // console.log({ info });
        const keys = Object.keys(schema.properties);

        keys.reduce((obj, field) => {
          if (schema.properties[field]) {
            schema.properties[field].default = info[field];
            if (
              schema.properties[field].type === 'radio' &&
              info[field] === undefined
            ) {
              this.changeField(
                field,
                schema.properties[field].enum[0].toString()
              );
            } else if (
              schema.properties[field].type === 'radio' &&
              info[field] !== undefined
            ) {
              this.changeField(field, info[field].toString());
            } else {
              this.changeField(field, schema.properties[field].default);
            }
          }
          return field;
        }, {});

        // console.log({ schema });

        this.setState({
          schema
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  changeField = (field, value) => {
    const { changeField } = this.props;
    changeField(field, value);
  };

  handleSubmit = values => {
    // console.log({ values });
    this.values = values;
    this.setState({
      isOpen: true
    });
  };

  onSubmit = async () => {
    const { match } = this.props;
    const { providerId } = match.params;
    const params = this.values;
    // e.preventDefault();
    // console.log({ params });

    if (params) {
      const {
        providerProfileImgPath,
        passbookImgPath,
        businessLicenseImgPath
      } = params;
      let uploadImages = [];

      params.providerId = providerId;

      if (providerProfileImgPath) {
        providerProfileImgPath.forEach(file => {
          // 파일과 오브젝트 구분
          // 파일인 경우 업로드 진행
          /* eslint no-param-reassign: ["error", { "props": false }] */
          file.key = uuid.v4();

          uploadImages.push(s3Uploader(file, 'providerUpload'));
        });
      }

      let uploadImgPathList = await Promise.all(uploadImages);

      if (uploadImgPathList.length > 0) {
        params.providerProfileImgPath = {};

        params.providerProfileImgPath.path = uploadImgPathList[0].path;
        params.providerProfileImgPath.width = uploadImgPathList[0].width;
        params.providerProfileImgPath.height = uploadImgPathList[0].height;
      } else {
        params.providerProfileImgPath = null;

        // params.providerProfileImgPath.path = '';
        // params.providerProfileImgPath.width = 0;
        // params.providerProfileImgPath.height = 0;
      }

      uploadImages = [];
      if (passbookImgPath) {
        passbookImgPath.forEach(file => {
          // 파일과 오브젝트 구분
          // 파일인 경우 업로드 진행
          /* eslint no-param-reassign: ["error", { "props": false }] */
          file.key = uuid.v4();

          uploadImages.push(s3Uploader(file, 'providerUpload'));
          // console.log({ uploadImages });
        });
      }

      uploadImgPathList = [];
      uploadImgPathList = await Promise.all(uploadImages);

      if (uploadImgPathList.length > 0) {
        params.passbookImgPath = '';
        params.passbookImgPath = uploadImgPathList[0].path;
      } else {
        params.passbookImgPath = '';
      }

      uploadImages = [];
      if (businessLicenseImgPath) {
        businessLicenseImgPath.forEach(file => {
          // 파일과 오브젝트 구분
          // 파일인 경우 업로드 진행
          /* eslint no-param-reassign: ["error", { "props": false }] */
          file.key = uuid.v4();

          uploadImages.push(s3Uploader(file, 'providerUpload'));
        });
      }
      uploadImgPathList = [];
      uploadImgPathList = await Promise.all(uploadImages);

      if (uploadImgPathList.length > 0) {
        params.businessLicenseImgPath = '';
        params.businessLicenseImgPath = uploadImgPathList[0].path;
      } else {
        params.businessLicenseImgPath = '';
      }
    }

    params.providerType = parseInt(params.providerType, 10);
    params.freeDeliveryAmount = parseInt(params.freeDeliveryAmount, 10);

    if (!params.email) {
      params.email = '';
    }

    if (!params.mobile) {
      params.mobile = '';
    }

    if (!params.providerProfileImgPath) {
      params.providerProfileImgPath = {};
    }

    if (!params.zipcode) {
      params.zipcode = '';
    }

    if (!params.addr) {
      params.addr = '';
    }

    if (!params.addrSub) {
      params.addrSub = '';
    }

    if (!params.picEmail) {
      params.picEmail = '';
    }

    if (!params.businessLicense) {
      params.businessLicense = '';
    }

    if (!params.bank) {
      params.bank = '';
    }

    if (!params.passbook) {
      params.passbook = '';
    }

    if (!params.businessLicenseImgPath) {
      params.businessLicenseImgPath = '';
    }

    if (!params.passbookImgPath) {
      params.passbookImgPath = '';
    }

    if (!params.freeDeliveryAmount) {
      params.freeDeliveryAmount = 50000;
    }

    if (params) {
      delete params['object Object'];
      try {
        const result = await updateProviderDetail(providerId, params);

        if (result && result.status === 200) {
          this.setState({
            isOpen: false
          });
          // console.log({ result });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false
    });
  };

  historyBack = () => {
    const { history } = this.props;

    history.push({
      pathname: `/provider`
    });
  };

  render() {
    // const {isOpen,data, isEdit} = this.state;
    const { schema, isOpen } = this.state;

    return (
      <Fragment>
        <div>
          <Title title="공급자 정보 수정" />
          <hr />
          <DynamicForm
            schema={schema}
            onSubmit={this.handleSubmit}
            onChange={this.changeField}
            handleBack={this.historyBack}
            className="dynamic-container"
          />
        </div>
        <ModalDialog
          className="modal-control discover-register-modal"
          isOpen={isOpen}
          onToggle={this.handleCloseModal}
          size="md"
        >
          <p className="p-3 text-center">수정하시겠습니까?</p>
          <div className="text-center">
            <div>
              <button
                className="btn btn-primary p-2"
                type="button"
                onClick={this.onSubmit}
              >
                수정
              </button>
              &nbsp;
              <button
                className="btn btn-danger p-2"
                type="button"
                onClick={this.handleCloseModal}
              >
                취소
              </button>
            </div>
          </div>
        </ModalDialog>
      </Fragment>
    );
  }
}

ProviderRegisterContainer.propTypes = {
  changeField: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ProviderRegisterContainer.defaultProps = {
  changeField: () => {},
  history: null
};

const mapDispatchToProps = dispatch => ({
  changeField: (field, value) => {
    dispatch(change('dynamicEditForm', field, value));
  }
});

export default compose(
  withRouter,
  connect(() => ({}), mapDispatchToProps)
)(ProviderRegisterContainer);
