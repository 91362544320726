import React, { Component, Fragment } from 'react';
import PropTypes, { bool } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, submit, reset, change } from 'redux-form';
import AlertModal from 'components/modal/AlertModal';
import BootstrapTable from 'react-bootstrap-table-next';

import ModalDialog from 'components/modal/ModalDialog';
import DynamicSubForm from 'components/commons/DynamicSubForm/DynamicSubForm';
import Paging from 'components/commons/DynamicPaging/DynamicPaging';

// import RenderField from 'components/reduxForm/RenderField';
import { mendatoryValidation } from 'lib/form/validation';
import './Template.scss'

class Template extends Component {

  constructor() {
    super();

    this.state = {
      isOpen : false,
      isOpenErrorModal : false,
      message : '',
      status : 2,
      page : 1,
      isLast : false
    };

    // query next keys
    this.lastKeys = [];

    this.columns = [
      {
        dataField: 'templateSubject',
        text: '템플릿명',
        headerStyle: {
            width: '100%'
        }
      }
    ]

    // 템플릿 동적 폼 스키마
    this.schema = {
        "properties": {
          "templateSubject": {
            "type": "text",
            "label": "템플릿명",
            "readonly" : false,
            "validate" : mendatoryValidation
          },
          "templateDesc": {
            "type": "text",
            "label": "템플릿설명",
            "readonly" : false,
            "validate" : mendatoryValidation
          },
          "attachFileList": {
            "type": "file",
            "label": "첨부파일",
            "accept" : "*", // * 전체, image 이미지
            "readonly" : false,
            "multiple" : false
          },
          "flagAD": {
            "type": "radio-a",
            "enum" : [true, false],
            "label": "광고여부",
            "readonly" : false,
            "validate" : mendatoryValidation
          },
          "messageSubject": {
            "type": "text",
            "label": "제목",
            "readonly" : false,
            "validate" : mendatoryValidation
          },
          "messageContent": {
            "type": "textarea",
            "label": "내용",
            "readonly" : false,
            "validate" : mendatoryValidation
          }
        }
    }
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps, prevState) {
    const {templateResult, template} = this.props;
    const {isLast} = this.state;

    // lastkey가 없으면 첫 목록
    if (!prevProps.lastKey) {
      this.setTemplateState();
    }

    if (prevProps.lastKey !== templateResult.lastKey) {
      if (templateResult.lastKey === null && !isLast) {
        this.setIsLast();
      }

      if (templateResult.lastKey !== null && isLast) {
        this.setIsLast();
      }
    }

    // 상세 데이터 밀어넣기
    if (template !== null) {
      if (prevProps.id !== template.id) {
        const keys = Object.keys(template);
        // eslint-disable-next-line
        for (const key of keys) {
          this.changeField(key, template[key]);
        }
        // this.changeField(template);

        // 수정이 아니라면
        if (prevState.status !== 1) {
          this.handleStatus(1);
        }
      }
    } else {
      // 그리드 선택된 모든 row clear
      this.template.selectionContext.handleAllRowsSelect(Event, true);
    }
  }

  // template state 저장
  setTemplateState = () => {
    const {templateResult} = this.props;

    if (this.lastKeys.indexOf(templateResult.lastKey) === -1) {
      if (templateResult.lastKey) {
        this.lastKeys.push(templateResult.lastKey);
      }
    }

  }

  setIsLast = () => {
    const {isLast} = this.state;
    this.setState({
      isLast : !isLast
    })
  }

  // 초기화
  initialize = () => {
    const {dispatch} = this.props;
    const keys = Object.keys(this.schema.properties);
    
    // radio가 존재하면 redux-form에 첫번째 값이 적용되도록 설정
    // eslint-disable-next-line
    for (const key of keys) {
      if (this.schema.properties[key].type.indexOf('radio') > -1) {
        dispatch(change('dynamicSubEditForm', key, this.schema.properties[key].enum[0].toString()));
      }
    }
  };

  onChangeField = (values) => {
    const keys = Object.keys(values);
    // eslint-disable-next-line
    for (const key of keys) {
      this.changeField(key, values[key]);
    }
  }

  // change redux-form field value
  changeField = (key, value) => {
    const {dispatch} = this.props;
    let data;

    if (key === 'attachFileList') {
      data = value;
      let src = '';
      if (Array.isArray(value)) {
        // eslint-disable-next-line
        src = value[0];
      } else {
        // eslint-disable-next-line
        if (value.encoding) {
          // eslint-disable-next-line
          src = value.encoding.split('base64,')[1];
        } else {
          src = '';
        }
      }
      
      if (src) {
        data = {
          path : (src ? `data:application/octet-stream;base64,${src}` : ''), 
          extension : 'image', 
          encoding : (src ? `data:application/octet-stream;base64,${src}` : '')
        }
      } else {
        data = '';
      }
    } else {
      data = value.toString();
    }
    dispatch(change('dynamicSubEditForm', key, data));
  }

  // 선택완료
  finishSelect = () => {
    const {templateResult, onFinishSelect} = this.props;
    const {items} = templateResult;
    const {selected} = this.template.selectionContext;
    
    const result = items.filter(template => {
      let isTemplate = false;
      if (template.id === selected[0]) {
        isTemplate = true;
      }
      return isTemplate;
    });

    if (onFinishSelect) {
      onFinishSelect(result);
    }
    
  }

  // save
  saveTemplate = () => {
    const {dispatch} = this.props;
    // redux form 에 접근하여 생성된 폼 중에 필요한 폼을 submit 함
    dispatch(submit('dynamicSubEditForm'));
  }

  // form submit
  handleSubmit = (vlaues) => {
    const {onSubmit} = this.props;
    const {status} = this.state;
    
    if (onSubmit) {
      const data = vlaues;
      data.status = status;
      onSubmit(data);
      this.reset();
    }
  }

  // 모달 오픈
  openModal = () => {
    const {selected} = this.template.selectionContext;

    if (selected.length > 0) {
      this.setState({
        message : '삭제하시겠습니까?'
      })
  
      this.handleStatus(0);
      this.handleModal();
    } else {
      this.handleAlertModal();
      this.setState({
        errorMessage : '선택된 목록이 없습니다.'
      })
    }

  }

  // 알럿 모달
  handleAlertModal = () => {
    const {isOpenErrorModal} = this.state;
    this.setState({
      isOpenErrorModal : !isOpenErrorModal
    })
  }

  // 모달 컨트롤
  handleModal = () => {
    const {isOpen} = this.state;
    this.setState({
      isOpen : !isOpen
    })
  }

  // 0: 삭제, 1: 수정
  handleTemplateSubmit = (status) => {
    const {onChange} = this.props;
    if (status === 0) {
      const {selected} = this.template.selectionContext;
    
      if (onChange) {
        onChange(selected[0], status);
        this.handleModal();
      }
    } else {
      // 
    }
  }

  // form 초기화
  reset = () => {
    const {dispatch, onResetTemplate} = this.props;
    
    dispatch(reset('dynamicSubEditForm'));
    this.changeField('flagAD', true);

    onResetTemplate();
    
    this.setState({
      status : 2
    })
    
  }

  // row 선택 
  handleSelectedRow = (row) => {
    const {onSelect} = this.props;
    if (onSelect) {
      onSelect(row.id);
    }
  }

  // status 변경
  handleStatus = (status) => {
    this.setState({
      status
    })
  }

  /**
   * 페이지 이동
   */
  handlePaging = page => {
    this.setState({
      page
    })
    this.onSearch(this.lastKeys[page-2]);
  };

  /**
   * @param String
   */
  onSearch = lastKey => {
    const {onPaging} = this.props;
    if (onPaging) {
      onPaging(lastKey);
    }
  };

  render() {
    const {loading, templateResult, closeModal} = this.props;
    const {page, isLast, message, status, isOpen, isOpenErrorModal, errorMessage} = this.state;
    
    const selectSetRow = {
      mode: 'radio',
      clickToSelect: true,
      bgColor: '#97e4f1',
      onSelect : (row) => {
        this.handleSelectedRow(row);
      }
    };

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row p-2">
            <div className="col-6">
              <BootstrapTable
                keyField="id"
                data={templateResult.items || []}
                columns={this.columns}
                noDataIndication="데이터가 없습니다."
                ref={arg => {this.template = arg;}}
                selectRow={selectSetRow}
              />
              <div className="text-center">
                <Paging
                  page={page}
                  isLast={isLast}
                  handlePrev={this.handlePaging}
                  handleNext={this.handlePaging}
                  loading={loading}
                />
              </div>
              <div className="text-right pt-3">
                <input 
                  className="btn btn-primary px-2" 
                  type="button" 
                  value="선택완료" 
                  onClick={this.finishSelect}
                />&nbsp;
                <input 
                  className="btn btn-primary px-2" 
                  type="button" 
                  value="삭제" 
                  onClick={this.openModal}
                />
              </div>
            </div>
            <div className="col-6">
              <DynamicSubForm 
                schema={this.schema} 
                onSubmit={this.handleSubmit}
                // onChange={this.onChangeField}
                handleBack={this.historyBack}
                isButton={false}
              />
              <div className="text-right px-2">
                <input 
                  className="btn btn-primary px-2" 
                  type="button" 
                  value={status === 2 ? '저장' : '수정'} 
                  onClick={this.saveTemplate}
                />&nbsp;
                <input 
                  className="btn btn-primary px-2" 
                  type="button" 
                  value="초기화" 
                  onClick={this.reset}
                />&nbsp;
                <input 
                  className="btn btn-primary px-2" 
                  type="button" 
                  value="닫기" 
                  onClick={closeModal}
                />
              </div>
            </div>
          </div>
        </div>
        <ModalDialog
          className="modal-control discover-register-modal"
          isOpen={isOpen}
          onToggle={this.handleModal}
          size="md"
        >
          <p className="p-3 text-center">{message}</p>
          <div className="text-center">
            <div>
              <button 
                className="btn btn-primary p-2" 
                type="button" 
                onClick={() => {
                  this.handleTemplateSubmit(status);
                }}
              >
                {status === 0 ? '삭제' : '수정'}
              </button>&nbsp;
              <button 
                className="btn btn-danger p-2" 
                type="button" 
                onClick={this.handleModal}
              >
                취소
              </button>
            </div>
          </div>
        </ModalDialog>
        <AlertModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          onClick={this.handleAlertModal}
          onToggle={this.handleAlertModal}
        />
      </Fragment>
    );
  }
}

Template.propTypes = {
  dispatch : PropTypes.func,
  closeModal : PropTypes.func,
  templateResult : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  template : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loading : bool,
  onFinishSelect : PropTypes.func,
  onSubmit : PropTypes.func,
  onChange : PropTypes.func,
  onSelect : PropTypes.func,
  onResetTemplate : PropTypes.func,
  onPaging : PropTypes.func
};

Template.defaultProps = {
  dispatch : () => {},
  closeModal : () => {},
  templateResult : {},
  template : null,
  loading : false,
  onFinishSelect : () => {},
  onSubmit : () => {},
  onChange : () => {},
  onSelect : () => {},
  onResetTemplate : () => {},
  onPaging : () => {}
};

export default compose(
  reduxForm({form: 'dynamicSearchForm'}),
  connect(),
)(Template);

