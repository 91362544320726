import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory, {
  selectFilter,
  textFilter
} from 'react-bootstrap-table2-filter';
import renderHTML from 'react-render-html';

import AlertModal from 'components/modal/AlertModal';
import ModalDialog from 'components/modal/ModalDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import SearchForm from 'components/commons/SearchForm/index';
import Loader from 'components/commons/Loader';

import { getFormatDate } from 'lib/time';
import TrackingDelivery from 'components/trackingDelivery/TrackingDelivery';
import { sendNotice } from 'api/sms';
// eslint-disable-next-line import/named
import { updateDeliverer, updateDeliveryStatus } from 'api/delivery';
import { getPriceFormat } from 'lib/utils/numberUtils';
import { getHost } from 'lib/utils/envUtils';

import Paging from 'components/commons/Paging/Index';

// const options = [{
//   id : 2,
//   type : 'M', // modal
//   data : {value : 'P'},
//   fnName : 'provider'
// }];

const options = [
  {
    id: 1,
    type: 'S', // select
    data: [
      { text: '구매자 이름', id: 'buyerName' },
      { text: '공급자 이름', id: 'providerId' }
    ],
    fnName: 'type'
  },
  // {
  //     id : 2,
  //     type : 'I', // input
  //     fnName : 'keyword'
  // },
  {
    id: 2,
    type: 'IM', // input
    openModalIndex: 1,
    fnName: 'keyword'
  }
];

class DeliveryAdjList extends Component {
  // 이벤트 정의
  initFunction = {
    type: (value) => {
      this.setInitValues('type', value);
    },
    keyword: (value) => {
      this.setInitValues('keyword', value);
    },
    // 송장입력여부
    serial: (value) => {
      this.setInitValues('serial', value);
    },
    // 공급자 id
    id: (value) => {
      this.setInitValues('id', value);
    },
    // 공급자
    provider: (value) => {
      this.setInitValues('provider', value);
    },
    selectedOptionIndex: (value) => {
      this.selectedOptionIndex = value;
    }
  };

  constructor() {
    super();

    this.state = {
      isOpen: false,
      isCancelOpen: false,
      type: 'buyerName',
      selected: [],
      selectedData: [],
      errorMessage: '',
      isOpenErrorModal: false
      // startDate : new Date(),
      // endDate : new Date()
    };

    // 결제 취소 storeId, paymentId
    this.storeId = '';
    this.paymentUid = '';
    this.selectedOptionIndex = 0;

    this.selectOptions = {
      cleared: 'cleared',
      resolving: 'resolving',
      clearing: 'clearing',
      delivered: 'delivered',
      issued: 'issued',
      confirmed: 'confirmed',
      requested: 'requested',
      ordered: 'ordered',
      canceled: 'canceled'
    };

    this.columns = [
      {
        dataField: 'status',
        text: '상태',
        headerStyle: {
          width: '80px'
        },
        classes: (cell) => `${cell}-status`,
        filter: selectFilter({
          options: this.selectOptions,
          placeholder: 'all'
        })
      },
      {
        dataField: 'payType',
        text: '유형',
        sort: true,
        formatter: (cell, row) => {
          const { status } = row;
          let cellName = '';

          if (cell === 'buy') {
            cellName = '구매';
          } else {
            cellName = '선물';
          }

          return (
            <Fragment>
              <div>{cellName}</div>
              {status === 'canceled' && (
                <div>
                  <span className="text-danger">취소됨</span>
                </div>
              )}
            </Fragment>
          );
        },
        headerStyle: {
          width: '60px',
          textAlign: 'center'
        }
      },
      {
        dataField: 'createDate',
        text: '구매일',
        sort: true,
        formatter: (cell) => getFormatDate(cell, 'YYYY.MM.DD HH:mm'),
        csvFormatter: (cell) => getFormatDate(cell, 'YYYY.MM.DD HH:mm'),
        headerStyle: {
          width: '80px'
        }
      },
      {
        dataField: 'buyerName',
        text: '구매자',
        headerStyle: {
          width: '60px'
        }
      },
      {
        dataField: 'providerName',
        text: '공급자',
        headerStyle: {
          width: '80px'
        }
      },
      {
        dataField: 'providerId',
        text: '공급자Id',
        headerStyle: {
          width: '80px'
        },
        hidden: true
      },
      {
        dataField: 'productName',
        text: '상품',
        headerStyle: {
          width: '120px'
        },
        filter: textFilter({
          placeholder: '상품명 입력'
        })
      },
      {
        dataField: 'optionName',
        text: '상품옵션',
        headerStyle: {
          width: '120px'
        }
      },
      {
        dataField: 'order.discountPrice',
        text: '판매단가',
        headerStyle: {
          width: '60px'
        }
      },
      {
        dataField: 'unitPrice',
        text: '정산단가',
        formatter: (cell, row) => {
          const { quantity, payable } = row;
          const { toProvider } = payable || {};

          return getPriceFormat(Math.floor((toProvider || 0) / quantity));
        },
        csvFormatter: (cell, row) => {
          const { quantity, payable } = row;
          const { toProvider } = payable || {};
          return (toProvider || 0) / quantity;
        },
        headerStyle: {
          width: '60px'
        }
      },
      {
        dataField: 'quantity',
        text: '수량',
        headerStyle: {
          width: '40px'
        }
      },
      {
        dataField: 'payable.toProvider',
        text: '정산액',
        formatter: (cell) => getPriceFormat(Math.floor(cell)),
        headerStyle: {
          width: '60px'
        }
      },
      {
        dataField: 'payable.deliveryFee',
        text: '배송비',
        formatter: (cell) => getPriceFormat(Math.floor(cell)),
        headerStyle: {
          width: '60px'
        }
      },
      {
        dataField: 'priceSum',
        text: '합계',
        formatter: (cell, row) => {
          const { payable } = row;
          const { toProvider, deliveryFee } = payable || {};
          return getPriceFormat(Math.floor(toProvider + deliveryFee));
        },
        csvFormatter: (cell, row) => {
          const { payable } = row;
          const { toProvider, deliveryFee } = payable || {};
          return toProvider + deliveryFee;
        },
        headerStyle: {
          width: '60px'
        }
      },
      {
        dataField: 'paymentLogsId',
        text: '주문번호',
        hidden: true
      },
      {
        dataField: 'invoiceyesorno',
        text: '운송장',
        formatter: (cell, row) => {
          const { id, status, deliveryName, deliverer, providerId } = row;
          if (deliverer || deliveryName) {
            return (
              <span style={{ color: '#6a74e2' }}>
                {deliverer.name || deliveryName}
              </span>
            );
          }
          return status === 'canceled' ? (
            <span>취소됨</span>
          ) : (
            <span>
              <button
                id={`${id}-confirm-btn`}
                type="button"
                className="btn btn-outline-danger btn-sm mt-3"
                onClick={(e) => {
                  e.stopPropagation();
                  this.handleInvoiceModify(id, providerId, cell, row);
                }}
              >
                수령확인
              </button>
            </span>
          );
        },
        csvFormatter: (cell, row) => {
          const { invoiceNumber, deliveryName } = row;
          if (invoiceNumber || deliveryName) {
            return deliveryName;
          }
          return '없음';
        },
        headerStyle: {
          width: '120px'
        }
      },
      {
        dataField: 'id',
        text: '배송번호/주문번호',
        formatter: (cell, row) => {
          const { paymentLogsId } = row;
          return (
            <Fragment>
              <div>{cell}</div>
              <div>{paymentLogsId}</div>
            </Fragment>
          );
        },
        csvFormatter: (cell, row) => `${cell}\n${row.paymentLogsId}`,
        headerStyle: {
          width: '150px'
        }
      }
    ];
  }

  componentDidMount() {}

  openPopup = (deliveryCode, invoiceNumber) => {
    this.setState({
      isOpen: true,
      deliveryCode,
      invoiceNumber,
      hasDeliveryInfo: true
    });
  };

  // 취소모달
  openCancelModal = () => {
    const { isCancelOpen } = this.state;
    this.setState({
      isCancelOpen: !isCancelOpen
    });
  };

  // 결제 취소
  handleCloseCancelPayment = () => {
    const { handleCancelPayment } = this.props;
    handleCancelPayment(this.storeId, this.paymentUid);
    this.handleCloseModal('isCancelOpen');
    this.storeId = '';
    this.paymentUid = '';
  };

  handleCloseModal = (type) => {
    this.setState({
      [type]: false
    });
  };

  /**
   * 수정 페이지로 이동
   */
  handlerModify = (deliveryId, providerId, flag) => {
    const { handleMovePage } = this.props;
    const { id, serial, provider } = this.state;
    const param = {};

    if (serial) {
      param.serial = serial;
    }

    if (id) {
      param.id = id;
    }

    if (provider) {
      param.provider = provider;
    }

    handleMovePage(deliveryId, providerId, param, flag);
  };

  // 현장확인
  handleInvoiceModify = async (deliveryId, providerId, cell, row) => {
    const params = {
      deliverer: {
        id: '000',
        name: '직접전달',
        trackingNumber: getFormatDate(Date.now(), 'YYYY.MM.DD HH:mm:ss a')
      },
      id: deliveryId
    };

    try {
      const result = await updateDeliverer(providerId, deliveryId, params);

      if (result && result.data && result.data.success) {
        console.log(`success`);
        console.log(row);
        console.log(cell);

        const x = document.getElementById(`${deliveryId}-confirm-btn`);
        // const y = document.getElementById(`${deliveryId}-input-btn`);

        x.style.display = 'none';
        // y.style.display = "none";
      }
    } catch (error) {
      console.log(error);
    }
  };

  setInitValues = (column, value) => {
    const { onChangeSearchForm } = this.props;
    this.setState({
      [column]: value
    });

    onChangeSearchForm(column, value);
  };

  onSelectCellButton = (row, isSelect, rowIndex, e) => {
    console.log(row);
    console.log(isSelect);
    console.log(rowIndex);
    console.log(e);
  };

  handleStatusUpdate = () => {
    const { selectedData } = this.state;
    const { selectToStaus } = this.props;

    this.setState(
      {
        isUpdateLoading: true
      },
      async () => {
        console.log(selectedData);

        const postData = selectedData.map((data) => {
          const { deliveryId, fromStatus } = data;

          return {
            id: deliveryId,
            fromStatus,
            toStatus: selectToStaus
          };
        });

        console.log(postData);

        try {
          const { onSearch } = this.props;

          const result = await updateDeliveryStatus(postData);

          if (result && result.data && result.data.success) {
            console.log(`success`);
          } else {
            console.log(result);
            this.setState({
              errorMessage: '배송 상태 업데이트 도중 오류가 발생했습니다.',
              isOpenErrorModal: true,
              isUpdateLoading: false
            });
          }

          onSearch({ status: 'reset' });
          this.handleStatusClear();
          this.setState({
            isUpdateLoading: false
          });
        } catch (error) {
          console.log(error);
          this.setState({
            errorMessage: '배송 상태 업데이트 도중 오류가 발생했습니다.',
            isOpenErrorModal: true,
            isUpdateLoading: false
          });
        }
      }
    );
  };

  handleOnSelect = (row, isSelect) => {
    const { selected, selectedData } = this.state;
    if (isSelect) {
      const { quantity, payable } = row;
      const { toProvider, deliveryFee } = payable || {};
      const priceSum = Math.floor(toProvider + deliveryFee);
      this.setState(() => ({
        selected: [...selected, row.id],
        selectedData: [
          ...selectedData,
          {
            deliveryId: row.id,
            fromStatus: row.status,
            priceSum: priceSum || 0,
            quantity
          }
        ]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter((x) => x !== row.id),
        selectedData: selectedData.filter((x) => x.deliveryId !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const { selected, selectedData } = this.state;

    const ids = rows.map((r) => r.id);
    const datas = rows.map((r) => {
      const { id, status, quantity, payable } = r;
      const { toProvider, deliveryFee } = payable || {};
      const priceSum = Math.floor(toProvider + deliveryFee);

      return {
        deliveryId: id,
        fromStatus: status,
        priceSum: priceSum || 0,
        quantity
      };
    });

    // if (isSelect) {
    //   this.setState(() => ({
    //     selected: ids
    //   }));
    // } else {
    //   this.setState(() => ({
    //     selected: []
    //   }));
    // }

    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, ...ids],
        selectedData: [...selectedData, ...datas]
      }));
    } else {
      let tempSelected = [];
      let tempSelectedData = [];

      tempSelected = selected.filter((s) => !ids.includes(s));
      tempSelectedData = selectedData.filter(
        (s) => !ids.includes(s.deliveryId)
      );

      this.setState(() => ({
        selected: tempSelected,
        selectedData: tempSelectedData
      }));
    }
  };

  handleStatusClear = () => {
    this.setState({
      selected: [],
      selectedData: []
    });
  };

  /**
   * 이전 페이지 이동
   */
  handlePrev = (page) => {
    this.onSearch(page);
  };

  /**
   * 다음 페이지 이동
   */
  handleNext = (page) => {
    this.onSearch(page);
  };

  /**
   * @param integer, integer
   */
  onSearch = (page) => {
    const { onSearch } = this.props;

    onSearch({
      page
    });
  };

  onSubmit = (e) => {
    console.log(e);

    const { onSearch } = this.props;
    // const { id, provider } = this.state;
    // onSearch({ id, provider });
    const { type, keyword, id } = this.state;
    onSearch({
      type,
      keyword,
      id,
      isModalSearch: this.selectedOptionIndex === options[1].openModalIndex
    });
  };

  sendMessage = (row) => {
    if (row) {
      this.setState({
        sendIsOpen: true,
        rowData: row
      });
    }
  };

  /**
   * 메세지 전송
   */
  handlerSend = async () => {
    try {
      const { rowData } = this.state;

      if (rowData) {
        const {
          providerMobile,
          providerName,
          providerId,
          productName,
          productSelectOpt,
          deliveryInfo,
          paymentLogsId
        } = rowData;
        const message = this.makerMessage(
          productName,
          productSelectOpt,
          deliveryInfo,
          providerId,
          paymentLogsId
        );
        let returnMessage = '';

        const params = {
          sendNumber: '0269511234',
          subject: '프롬',
          msgBody: message,
          recvNumber: providerMobile
        };

        const response = await sendNotice(params);
        if (response.status === 200) {
          returnMessage += `<p><span>*</span> 공급자 ${providerName} 님에게 알림을 발송하였습니다.<p><br><p className="work-break">${message}</p>`;
        }

        if (message !== '') {
          this.setState({
            isOpenConfirm: true,
            message: returnMessage
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 메세지 만들기
   */
  makerMessage = (
    productName,
    productSelectOpt,
    deliveryInfo,
    providerId,
    paymentLogsId
  ) => {
    let address = '';
    if (deliveryInfo.addr) {
      if (deliveryInfo.addrSub) {
        address = `${deliveryInfo.addr} ${deliveryInfo.addrSub}`;
      } else {
        address = deliveryInfo.addr;
      }
    }

    const link = `${getHost}/provider/${providerId}/setting/deliveryproducts`;
    const name = deliveryInfo ? deliveryInfo.name : '';
    const mobile = deliveryInfo ? deliveryInfo.mobile : '';
    const message = `[배송정보 입력요청]
다음 주문에 대해 배송정보를 입력해 주세요. 운송정보가 입력되지 않은 주문은 정산이 지연될 수 있습니다.

주문번호: ${paymentLogsId}
수령인: ${name}
연락처: ${mobile}
배송지 : ${address}
상품: ${productName} X ${productSelectOpt}
    
배송정보 입력하기: ${link}`;
    return message;
  };

  selectedDataKeySum = (key) => {
    const { selectedData } = this.state;
    const priceTotal = selectedData.reduce((prev, next) => prev + next[key], 0);

    return priceTotal;
  };

  render() {
    const {
      gridList,
      page,
      isLast,
      resultId,
      resultName,
      onChangeSelect,
      onChangeStatusSelect,
      selectListCount,
      selectToStaus,
      isLoading
    } = this.props;
    const {
      isOpen,
      sendIsOpen,
      isOpenConfirm,
      isCancelOpen,
      message,
      deliveryCode,
      hasDeliveryInfo,
      invoiceNumber,
      rowData,
      type,
      selected,
      errorMessage,
      isOpenErrorModal,
      isUpdateLoading
    } = this.state;
    const { ExportCSVButton } = CSVExport;
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      selected,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
      style: { backgroundColor: '#c8e6c9' }
    };

    return (
      <Fragment>
        <ToolkitProvider
          keyField="id"
          data={gridList}
          columns={this.columns}
          exportCSV={{
            fileName: `배송리스트_.csv`,
            noAutoBOM: false,
            blobType: 'text/plain;charset=utf-8',
            exportAll: false,
            onlyExportFiltered: true
          }}
        >
          {(props) => (
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <h4 className="h4">정산관리v2</h4>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-4">
                  <SearchForm
                    condition={options}
                    initFunction={this.initFunction}
                    onSubmit={this.onSubmit}
                    resultId={resultId}
                    resultName={resultName}
                    type={type}
                  />
                </div>
                <div>
                  <select
                    className="form-control"
                    onChange={onChangeSelect}
                    value={selectListCount}
                  >
                    <option>100</option>
                    <option>150</option>
                    <option>200</option>
                  </select>
                </div>
                <div className="col-4 text-right gift-list-btn">
                  <ExportCSVButton {...props.csvProps}>
                    Export CSV <FontAwesomeIcon icon={faFileExcel} />
                  </ExportCSVButton>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="form-row">
                    <div className="form-inline form-group pl-1">
                      상태
                      <select
                        className="form-control ml-2"
                        onChange={onChangeStatusSelect}
                        value={selectToStaus}
                      >
                        <option value="cleared">cleared(정산완료)</option>
                        <option value="resolving">resolving(정산대기)</option>
                        <option value="clearing">clearing(정산대상)</option>
                        <option value="delivered">delivered(운송장있음)</option>
                        <option value="issued">issued(티켓발행)</option>
                        <option value="confirmed">confirmed(선물확인)</option>
                        <option value="canceled">canceled(취소)</option>
                      </select>
                      <button
                        type="button"
                        className="btn btn-info mx-1"
                        onClick={this.handleStatusUpdate}
                        disabled={isLoading || isUpdateLoading}
                      >
                        업데이트
                      </button>
                    </div>
                    <div className="form-group pl-1">
                      선택된 row: {selected.length} \\ 선택된 row 수량:{' '}
                      {this.selectedDataKeySum('quantity')}개 \\ 선택된 row
                      총합:{' '}
                      {getPriceFormat(this.selectedDataKeySum('priceSum'))}원
                      <button
                        type="button"
                        className="btn btn-info mx-1"
                        onClick={this.handleStatusClear}
                      >
                        CLEAR
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {isLoading || isUpdateLoading ? (
                <Loader />
              ) : (
                <Fragment>
                  <div className="row mb-3">
                    <div className="col text-center">
                      <Paging
                        page={page}
                        isLast={isLast}
                        handlePrev={this.handlePrev}
                        handleNext={this.handleNext}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col" style={{ overflow: 'auto' }}>
                      <BootstrapTable
                        {...props.baseProps}
                        // selectRow={{
                        //   mode: 'radio',
                        //   clickToSelect: false,
                        //   onSelect: (row, isSelect, rowIndex, e) => {
                        //     this.onSelectCellButton(row, isSelect, rowIndex, e);
                        //   }
                        // }}
                        options={{ noDataText: '데이터가 없습니다.' }}
                        filter={filterFactory()}
                        selectRow={selectRow}
                        ref={(delivery) => {
                          this.deliveryRef = delivery;
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-center">
                      <Paging
                        page={page}
                        isLast={isLast}
                        handlePrev={this.handlePrev}
                        handleNext={this.handleNext}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          )}
        </ToolkitProvider>
        <ModalDialog
          className="modal-control discover-register-modal"
          isOpen={isOpen}
          onToggle={() => {
            this.handleCloseModal('isOpen');
          }}
          size="md"
        >
          <TrackingDelivery
            deliveryCode={deliveryCode} // 택배사 코드
            hasDeliveryInfo={hasDeliveryInfo} // 운송장 번호 입력 여부 boolean
            invoiceNumber={invoiceNumber} // 운송장 번호
          />
        </ModalDialog>
        <ModalDialog
          className="modal-control discover-register-modal"
          isOpen={sendIsOpen}
          onToggle={() => {
            this.handleCloseModal('sendIsOpen');
          }}
          size="md"
        >
          <div className="container-fluid">
            <div className="row p-2">
              <div className="col">
                <p className="h5">알림발송</p>
              </div>
            </div>
            <div className="row p-2">
              <div className="col-6">
                <span>공급자명 : {rowData ? rowData.providerName : null}</span>
              </div>
              <div className="col-6">
                <span>
                  전화번호 : {rowData ? rowData.providerMobile : null}
                </span>
              </div>
            </div>
            <div className="row p-2">
              <div className="col">
                <p>알림을 발송하시겠습니까?</p>
              </div>
            </div>
            <div className="row p-2">
              <div className="col text-center">
                <span className="px-1">
                  <button
                    className="btn btn-info"
                    type="button"
                    onClick={this.handlerSend}
                  >
                    발송
                  </button>
                </span>
                <span className="px-1">
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => this.handleCloseModal('sendIsOpen')}
                  >
                    취소
                  </button>
                </span>
              </div>
            </div>
          </div>
        </ModalDialog>
        <ModalDialog
          isOpen={isOpenConfirm}
          onToggle={() => {
            this.handleCloseModal('sendIsOpen');
            this.handleCloseModal('isOpenConfirm');
          }}
          size="md"
        >
          <div>{message ? renderHTML(message) : null}</div>
        </ModalDialog>
        <ModalDialog
          isOpen={isCancelOpen}
          onToggle={() => {
            this.handleCloseModal('isCancelOpen');
          }}
          size="md"
        >
          <div className="container-fluid">
            <div className="row p-2">
              <div className="col">
                <p>결제를 취소하시겠습니까?</p>
              </div>
            </div>
            <div className="row p-2">
              <div className="col text-center">
                <span className="px-1">
                  <button
                    className="btn btn-info"
                    type="button"
                    onClick={this.handleCloseCancelPayment}
                  >
                    결제취소
                  </button>
                </span>
                <span className="px-1">
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => this.handleCloseModal('isCancelOpen')}
                  >
                    취소
                  </button>
                </span>
              </div>
            </div>
          </div>
        </ModalDialog>
        <AlertModal
          contentText={errorMessage}
          isOpen={isOpenErrorModal}
          onClick={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
        />
      </Fragment>
    );
  }
}

DeliveryAdjList.propTypes = {
  onSearch: PropTypes.func,
  // moveInvoice: PropTypes.func,
  handleCancelPayment: PropTypes.func
};

DeliveryAdjList.defaultProps = {
  onSearch: () => {},
  // moveInvoice: () => {},
  handleCancelPayment: () => {}
};

export default DeliveryAdjList;
