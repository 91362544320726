/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import storage from 'lib/storage';

import { getDeliveryList, cancelPayment } from 'api/delivery';

import ReceiverList from 'components/sendgift/ReceiverList';
import GiftForm from 'components/sendgift/GiftForm';

function SendGiftContainer() {
  console.log('sendgiftContainer');
  return (
    <Fragment>
      <GiftForm>GiftFrom</GiftForm>
      <ReceiverList>sendgiftcontainer</ReceiverList>;
    </Fragment>
  );
}
export default withRouter(SendGiftContainer);
