import React from "react";
import PropTypes from "prop-types";

import FroalaEditor from "react-froala-wysiwyg";

// import 'react-froala-wysiwyg/node_modules/froala-editor/js/plugins.pkgd.min';
// import 'react-froala-wysiwyg/node_modules/froala-editor/js/languages/ko';
// import 'react-froala-wysiwyg/node_modules/froala-editor/js/froala_editor.pkgd.min';

// import 'react-froala-wysiwyg/node_modules/froala-editor/css/froala_editor.min.css';
// import 'react-froala-wysiwyg/node_modules/froala-editor/css/froala_editor.pkgd.min.css';
// import 'react-froala-wysiwyg/node_modules/froala-editor/css/froala_style.min.css';
// import 'react-froala-wysiwyg/node_modules/froala-editor/js/third_party/image_tui.min';
// import 'react-froala-wysiwyg/node_modules/froala-editor/css/third_party/image_tui.min.css';

import "froala-editor/js/plugins.pkgd.min";
import "froala-editor/js/languages/ko";
import "froala-editor/js/froala_editor.pkgd.min";
import "froala-editor/js/third_party/image_tui.min";

import "froala-editor/css/froala_editor.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/image_tui.min.css";

const editorConfig = {
  key: "cJC7bB5C2C2F2G2I2yQNDMIJg1IQNSEa1EUAi1XVFQd1EaG3C2A5A4C4D3B2D4G2E2==",
  language: "ko",
  imageUploadURL:
    "https://akuotb3hfi.execute-api.ap-northeast-2.amazonaws.com/dev/s3/upload",
  imageDefaultWidth: 420,
  width: 430,
  fontFamily: {
    "Nanum Sqaure": "Nanum Sqaure",
    "Nanum SqaureB": "Nanum Sqaure Bold",
    "Nanum SqaureEB": "Nanum Sqaure Extra Bold"
  },
  fontSize: [
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "36",
    "48",
    "60",
    "72",
    "96"
  ],
  lineHeights: {
    Default: "",
    "1": "1",
    "1.1": "1.1",
    "1.2": "1.2",
    "1.3": "1.3",
    "1.4": "1.4",
    "1.5": "1.5",
    "1.6": "1.6",
    "1.7": "1.7",
    "1.8": "1.8",
    "1.9": "1.9",
    "2": "2"
  },
  fontFamilyDefaultSelection: "Nanum Sqaure",
  toolbarStickyOffset: 89
};

function EditorField({ htmlProductInfo, onModelChange }) {
  return (
    <div className="sample" id="editor">
      <FroalaEditor
        model={htmlProductInfo}
        onModelChange={onModelChange}
        config={editorConfig}
      />
    </div>
  );
}

EditorField.propTypes = {
  onModelChange: PropTypes.func,
  htmlProductInfo: PropTypes.string
};

EditorField.defaultProps = {
  onModelChange: () => {},
  htmlProductInfo: ""
};

export default EditorField;
